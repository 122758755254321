////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { COLUMN_HEADERS } from 'SRC/constants';
import styles from '../styles.scss';

/* Component */
import TransactionsHeader from '../shared/TransactionsHeader';
import MobileTableHeader from './header';
import MobileTableBody from './body';
import TableFooter from '../shared/TableFooter';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TransactionsMobile({
  columns,
  filterCount,
  isCollapsed,
  onCollapse,
  loading,
  onChangeSort,
  onShowLess,
  onShowMore,
  sortBy,
  sortOrder,
  displayedTransactions,
  rawTransactions,
  filteredTransactions,
  viewport,
  visibleRows,
  odometerUnits,
  successfulLookup
}) {
  const columnFirst = [
    COLUMN_HEADERS.SALE_PRICE,
    COLUMN_HEADERS.ODOMETER,
    COLUMN_HEADERS.CONDITION_GRADE
  ];

  const columnSecond = [
    COLUMN_HEADERS.DATE_SOLD,
    COLUMN_HEADERS.ENGINE_TRANSMISSION,
    COLUMN_HEADERS.EXTERIOR_COLOR
  ];

  const columnThird = [
    COLUMN_HEADERS.TYPE,
    COLUMN_HEADERS.REGION,
    COLUMN_HEADERS.AUCTION
  ];

  const columnSets = [columnFirst, columnSecond, columnThird];

  return (
    <div>
      <TransactionsHeader
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        filterCount={filterCount}
        visibleRows={visibleRows}
        filteredTransactions={filteredTransactions}
      />
      <div
        className={`${styles.collapsible} ${
          isCollapsed ? styles.isCollapsed : ''
        }`}
      >
        <MobileTableHeader
          columns={columns}
          columnSets={columnSets}
          onChangeSort={onChangeSort}
          sortBy={sortBy}
          sortOrder={sortOrder}
          transactions={displayedTransactions}
          odometerUnits={odometerUnits}
        />
        <MobileTableBody
          columnSets={columnSets}
          loading={loading}
          transactions={displayedTransactions}
          visibleRows={visibleRows}
          successfulLookup={successfulLookup}
        />
        <TableFooter
          loading={loading}
          onShowLess={onShowLess}
          onShowMore={onShowMore}
          transactions={filteredTransactions}
          rawTransactions={rawTransactions}
          viewport={viewport}
          visibleRows={visibleRows}
        />
      </div>
    </div>
  );
}

TransactionsMobile.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.string),
  filterCount: PropTypes.number,
  loading: PropTypes.bool,
  onChangeSort: PropTypes.func,
  onShowMore: PropTypes.func,
  onShowLess: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  successfulLookup: PropTypes.bool,
  displayedTransactions: PropTypes.arrayOf(PropTypes.object),
  rawTransactions: PropTypes.arrayOf(PropTypes.object),
  filteredTransactions: PropTypes.arrayOf(PropTypes.object),
  visibleRows: PropTypes.number,
  odometerUnits: PropTypes.string,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg'])
};
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TransactionsMobile;
