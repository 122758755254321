////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { includes, startsWith } from 'lodash';

/* Internal */
import { adjustmentSelected, resetValuation } from 'SRC/store/actions/creators';
import { getEditionDisplay } from 'SRC/store/reducers/edition';
import Svg from 'SRC/components/Svg';
import { EXTERNAL } from 'SRC/constants';

/* Component */
import SelectCountry from '../../components/SelectCountry';
import MMRBlueLogo from './MMRBlueLogo';

/* Local */
import styles from './styles.scss';
import MMRWhiteLogo from './assets/MMRWhiteLogo.svg';
import MMRWhiteLogoShort from './assets/MMRWhiteLogoShort.svg';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class Heading extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: '',
      external: (window.MMR && window.MMR.external) || false
    };
  }

  render() {
    const {
      country,
      dispatchAdjustmentSelected,
      edition,
      loading,
      mid
    } = this.props;

    const { layout, external } = this.state;

    const disableAction = external;
    const forceMobile = external;
    const hideCountrySelect =
      external &&
      window.MMR &&
      window.MMR.externalOptions.showCountryDropdown === false;

    const showEdition =
      external && window.MMR && window.MMR.externalOptions.showEdition === true;
    const whiteLogo = (forceMobile ||
      includes(['mobile', 'popup'], layout)) && (
      <span>
        <Svg
          markup={MMRWhiteLogo}
          width="260px"
          height="39px"
          className={`${styles.logo} ${disableAction ? styles.disable : ''}`}
        />
        <Svg
          markup={MMRWhiteLogoShort}
          width="96px"
          height="39px"
          className={`${styles.logo} ${styles.shortened} ${
            disableAction ? styles.disable : ''
          }`}
        />
      </span>
    );

    const blueText = !forceMobile && !includes(['mobile', 'popup'], layout) && (
      <div className={styles.title}>
        <span className={styles.mmrLink} title="clear valuation">
          Manheim Market Report
        </span>
        <span
          className={`${styles.mmrLink} ${styles.mobileMmrLink}`}
          title="clear valuation"
        >
          MMR
        </span>
      </div>
    );

    const clickHandler = !disableAction && { onClick: this.clearAll };
    const altHeaderStyles = `${styles.header} ${styles.altHeader}`;
    const headerStyles =
      forceMobile || includes(['mobile', 'popup'], layout)
        ? altHeaderStyles
        : styles.header;

    return (
      <div className={headerStyles} id="emmr-header" data-test="emmr-header">
        <div className={styles.logoAndTitle}>
          <div className="print--hide" data-test="mmrLogo" {...clickHandler}>
            {whiteLogo}
            {blueText}
            {!startsWith(edition, 'null') && showEdition && (
              <span
                className={`${styles.externalEdition} print--hide`}
                data-test="extEdition"
              >
                {edition}
              </span>
            )}
          </div>
          <div className="hide print--show--block">
            <MMRBlueLogo />
            <span className={`${styles.edition} hide print--show--inline`}>
              {edition}
            </span>
          </div>
        </div>
        {!hideCountrySelect && (
          <div className={styles.countrySelect} id="countryRegion">
            <SelectCountry
              layout={layout}
              country={country}
              loading={loading}
              mid={mid}
              onSubmit={dispatchAdjustmentSelected}
            />
          </div>
        )}
      </div>
    );
  }

  componentDidMount() {
    this.setLayout();
    this.setExternal();
  }

  setExternal() {
    this.setState({
      external: EXTERNAL
    });
  }

  setLayout() {
    // layout types include "popup", "mobile", or ""
    this.setState({
      layout: window.MMR.layout
    });
  }

  clearAll = () => {
    this.props.dispatchResetValuation();
  };
}

Heading.propTypes = {
  country: PropTypes.string,
  edition: PropTypes.string,
  loading: PropTypes.bool,
  mid: PropTypes.string,
  dispatchAdjustmentSelected: PropTypes.func,
  dispatchResetValuation: PropTypes.func
};

const stateProps = state => ({
  edition: getEditionDisplay(state),
  loading: state.loading,
  mid: state.mostRecentLookup.mid,
  country: state.adjustments.selections.country
});

const actionProps = {
  dispatchAdjustmentSelected: adjustmentSelected,
  dispatchResetValuation: resetValuation
};

const HeadingContainer = connect(
  stateProps,
  actionProps
)(Heading);

export default HeadingContainer;
export { Heading };
