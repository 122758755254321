////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Export */

/* Internal */
import { COLUMN_HEADERS } from 'SRC/constants';
import {
  maybeCurrency,
  formatOdometer,
  formatDate,
  formatConditionGrade,
  shortenTransmission,
  titleize,
  formatEngineType,
  formatOdometerUnits,
  formatLocationName
} from 'SRC/util/formatting';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function adjustmentsMatch(transaction, selections) {
  const transactionID = transaction.region ? transaction.region.id : null;
  const values = [
    [transaction.vehicleDetails.grade, selections.grade],
    [transaction.vehicleDetails.color, selections.color],
    [transactionID, selections.region]
  ];
  let adjustmentsUsed = false;

  for (let i = 0; i < values.length; i += 1) {
    if (values[i].length > 1) {
      const tValue = values[i][0];
      const sValue = values[i][1];
      adjustmentsUsed = adjustmentsUsed || !!sValue;
      if (
        sValue &&
        String(tValue).toLowerCase() !== String(sValue).toLowerCase()
      ) {
        return false;
      }
    }
  }

  if (adjustmentsUsed) {
    return true;
  }

  return false;
}

function checkAdjustments(transactions, selections) {
  return transactions.map(t => ({
    ...t,
    adjustmentsMatch: adjustmentsMatch(t, selections)
  }));
}

function getColumnsFrom(transactions) {
  return transactions.map(t => ({
    [COLUMN_HEADERS.DATE_SOLD]: Date.parse(t.purchaseDate.trim()),
    [COLUMN_HEADERS.SALE_PRICE]: t.purchasePrice,
    [COLUMN_HEADERS.ODOMETER]: t.vehicleDetails.odometer,
    [COLUMN_HEADERS.CONDITION_GRADE]: t.vehicleDetails.grade,
    [COLUMN_HEADERS.ENGINE_TRANSMISSION]: `${formatEngineType(
      t.vehicleDetails.engine
    )}/${shortenTransmission(t.vehicleDetails.transmission)}`,
    [COLUMN_HEADERS.EXTERIOR_COLOR]: titleize(t.vehicleDetails.color).trim(),
    [COLUMN_HEADERS.TYPE]: titleize(t.saleType).trim(), // eslint-disable-line quote-props
    [COLUMN_HEADERS.REGION]: titleize(t.region.name).trim(), // eslint-disable-line quote-props
    [COLUMN_HEADERS.AUCTION]: `${formatLocationName(t.location.locationName)}`,
    inSample: t.inSample,
    adjustmentsMatch: t.adjustmentsMatch
  }));
}

function formatNumbers(transactions, currency, shouldRenderAsterisk = true) {
  return transactions.map(transaction => ({
    ...transaction,
    [COLUMN_HEADERS.DATE_SOLD]: formatDate(
      transaction[COLUMN_HEADERS.DATE_SOLD]
    ),
    [COLUMN_HEADERS.ODOMETER]: formatOdometer(
      transaction[COLUMN_HEADERS.ODOMETER]
    ),
    [COLUMN_HEADERS.CONDITION_GRADE]: formatConditionGrade(
      transaction[COLUMN_HEADERS.CONDITION_GRADE]
    ),
    [COLUMN_HEADERS.SALE_PRICE]: `${maybeCurrency(
      transaction[COLUMN_HEADERS.SALE_PRICE],
      currency
    )}${renderAsterisk(shouldRenderAsterisk, transaction.inSample)}`
  }));
}

function renderAsterisk(shouldRenderAsterisk, inSample) {
  return inSample !== 'Yes' && shouldRenderAsterisk ? '*' : '';
}

function formatHeader(header, odometerUnits) {
  if (header === COLUMN_HEADERS.ODOMETER && odometerUnits) {
    return `${header} (${formatOdometerUnits(odometerUnits)})`;
  }
  return `${header}`;
}

function filterTransactions(filters, transactions) {
  const truthyFilters = getTruthyFilters(filters).filters;
  const categories = Object.keys(truthyFilters);
  const hasFilters = categories.length > 0;

  if (!hasFilters) {
    return transactions;
  }

  return transactions.filter(transaction => {
    let includeTransaction = false;
    categories.forEach(category => {
      switch (category) {
        case 'Region':
          includeTransaction = truthyFilters[category].includes(
            transaction.region.id
          );
          break;
        //case 'Color':
        //  colorBool = truthyFilters[category].includes(transaction.vehicleDetails.color.id);
        //  break;
        default:
          break;
      }
    });
    // return colorBool && regionBool && etc
    return includeTransaction;
  });
}

function getTruthyFilters(filters) {
  const filterCategories = Object.keys(filters);
  const truthyFilters = {};
  let truthyFilterCount = 0;

  filterCategories.forEach(category => {
    const criteria = Object.keys(filters[category]);
    criteria.forEach(criterion => {
      if (filters[category][criterion] === true) {
        if (!Array.isArray(truthyFilters[category])) {
          truthyFilters[category] = [];
        }
        truthyFilters[category].push(criterion);
        truthyFilterCount += 1;
      }
    });
  });

  return {
    filters: truthyFilters,
    count: truthyFilterCount
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  checkAdjustments,
  getColumnsFrom,
  formatNumbers,
  formatHeader,
  filterTransactions,
  getTruthyFilters
};
