////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Component */
import LoadingSpinner from 'SRC/components/LoadingSpinner';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function LoadingContent(props) {
  const loadingStyle = props.loading ? { visibility: 'hidden' } : {};

  const containerProps = { ...props };
  delete containerProps.loading;

  return (
    <div style={{ ...props.style, position: 'relative' }} {...containerProps}>
      {props.loading && <LoadingSpinner />}
      <div style={loadingStyle}>{props.children}</div>
    </div>
  );
}

LoadingContent.propTypes = {
  loading: PropTypes.bool,
  style: PropTypes.object,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default LoadingContent;
