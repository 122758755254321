////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import {
  formatConditionGrade,
  maybeCurrency,
  formatOdometer,
  formatOdometerUnits
} from 'SRC/util/formatting';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function ModalDesktopTable({ country, vehicles, onSelect }) {
  let odoUnits = '';

  if (country === 'US') {
    odoUnits = 'miles';
  } else if (country === 'CA') {
    odoUnits = 'kilometers';
  }
  return (
    <div className={`${styles.tableContainer}`}>
      <table data-test="ModalTable" className="mui-table">
        <thead>
          <tr>
            <th>Year</th>
            <th>Make</th>
            <th>Model</th>
            <th>Style</th>
            <th>Base MMR</th>
            <th>Avg Odo ({formatOdometerUnits(odoUnits)})</th>
            <th>Avg Cond</th>
          </tr>
        </thead>
        <tbody>
          {vehicles.map((vehicle, i) => (
            <tr
              key={i}
              onClick={() => {
                onSelect(vehicle);
              }}
            >
              <td data-test={`vehicle${i}_year`}>{vehicle.description.year}</td>
              <td data-test={`vehicle${i}_make`}>
                {vehicle.description.make.name}
              </td>
              <td data-test={`vehicle${i}_model`}>
                {vehicle.description.model.name}
              </td>
              <td data-test={`vehicle${i}_style`}>
                {vehicle.description.style.name}
              </td>
              <td data-test={`vehicle${i}_mmr`}>
                {maybeCurrency(
                  vehicle.wholesale && vehicle.wholesale.average,
                  vehicle.currency
                )}
              </td>
              <td data-test={`vehicle${i}_odo`}>
                {formatOdometer(vehicle.averageOdometer)}
              </td>
              <td data-test={`vehicle${i}_cond`}>
                {formatConditionGrade(vehicle.averageGrade, true)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

ModalDesktopTable.propTypes = {
  country: PropTypes.string,
  onSelect: PropTypes.func,
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      make: PropTypes.object,
      model: PropTypes.object,
      style: PropTypes.object,
      wholesaleValues: PropTypes.object,
      averageOdometer: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
      ]),
      averageGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      odometerUnits: PropTypes.string,
      currency: PropTypes.string
    })
  )
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default ModalDesktopTable;
