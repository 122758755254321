////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export const ADJUSTMENT_CHANGED = 'ADJUSTMENT_CHANGED';
export const ADJUSTMENT_CHANGED_SUCCESS = 'ADJUSTMENT_CHANGED_SUCCESS';
export const ADJUSTMENT_CHANGED_FAILURE = 'ADJUSTMENT_CHANGED_FAILURE';

export const MID_CHANGED = 'MID_CHANGED';
export const MID_CHANGED_FAILURE = 'MID_CHANGED_FAILURE';
export const CLEAR_VALUATION = 'CLEAR_VALUATION';

export const VIN_CHANGED = 'VIN_CHANGED';
export const VIN_FULLY_DECODED = 'VIN_FULLY_DECODED';
export const VIN_DECODER_REPLIED = 'VIN_DECODER_REPLIED';
export const VIN_CHANGED_FAILURE = 'VIN_CHANGED_FAILURE';
export const VIN_LOOKUP_CLEARED = 'VIN_LOOKUP_CLEARED';
export const CLEAR_VIN = 'CLEAR_VIN';
export const VIN_FIELD_SELECTED = 'VIN_FIELD_SELECTED';
export const CLEAR_MID_AND_VIN = 'CLEAR_MID_AND_VIN';

export const YMMS_FIELD_SELECTED = 'YMMS_FIELD_SELECTED';
export const MULTIPLE_YMMS_FIELDS_SELECTED = 'MULTIPLE_YMMS_FIELDS_SELECTED';
export const YMMS_SELECTION_CLEARED = 'YMMS_SELECTION_CLEARED';
export const ADJUSTMENT_CLEARED = 'ADJUSTMENT_CLEARED';
export const ALL_ADJUSTMENTS_CLEARED = 'ALL_ADJUSTMENTS_CLEARED';

export const YMMS_YEARS_LOADED = 'YMMS_YEARS_LOADED';
export const YMMS_MAKES_LOADED = 'YMMS_MAKES_LOADED';
export const YMMS_MODELS_LOADED = 'YMMS_MODELS_LOADED';
export const YMMS_STYLES_LOADED = 'YMMS_STYLES_LOADED';
export const ALL_REGIONS_LOCATIONS_LOADED = 'ALL_REGIONS_LOCATIONS_LOADED';
export const YMMS_POSSIBILITIES_LOADED = 'YMMS_POSSIBILITIES_LOADED';

export const FAILED_LOADING_YMMS_YEARS = 'FAILED_LOADING_YMMS_YEARS';
export const FAILED_LOADING_YMMS_MAKES = 'FAILED_LOADING_YMMS_MAKES';
export const FAILED_LOADING_YMMS_MODELS = 'FAILED_LOADING_YMMS_MODELS';
export const FAILED_LOADING_YMMS_STYLES = 'FAILED_LOADING_YMMS_STYLES';
export const FAILED_LOADING_ALL_REGIONS_LOCATIONS =
  'FAILED_LOADING_ALL_REGIONS_LOCATIONS';
export const FAILED_LOADING_YMMS_POSSIBILITIES =
  'FAILED_LOADING_YMMS_POSSIBILITIES';

export const EDITION_LOADED = 'EDITION_LOADED';
export const FAILED_LOADING_EDITION = 'FAILED_LOADING_EDITION';

export const USER_INFO_LOADED = 'USER_INFO_LOADED';
export const COLORS_LOADED_SUCCESS = 'COLORS_LOADED_SUCCESS';
export const GRADES_LOADED_SUCCESS = 'GRADES_LOADED_SUCCESS';
export const REGIONS_LOADED_SUCCESS = 'REGIONS_LOADED_SUCCESS';
export const COLORS_LOADED_FAILURE = 'COLORS_LOADED_FAILURE';
export const GRADES_LOADED_FAILURE = 'GRADES_LOADED_FAILURE';
export const REGIONS_LOADED_FAILURE = 'REGIONS_LOADED_FAILURE';
export const ADJUSTMENT_SELECTED = 'ADJUSTMENT_SELECTED';
export const VIN_PARTIALLY_DECODED = 'VIN_PARTIALLY_DECODED';
export const VIN_STYLES_LOADED = 'VIN_STYLES_LOADED';

export const MULTI_DECODE_MODAL_OPENED = 'MULTI_DECODE_MODAL_OPENED';
export const MULTI_DECODE_MODAL_CLOSED = 'MULTI_DECODE_MODAL_CLOSED';

export const CLOSE_MOBILE_EDIT_LOOKUP = 'CLOSE_MOBILE_EDIT_LOOKUP';
export const OPEN_MOBILE_EDIT_LOOKUP = 'OPEN_MOBILE_EDIT_LOOKUP';
export const OPEN_LOOKUP_WIZARD = 'OPEN_LOOKUP_WIZARD';
export const CLOSE_LOOKUP_WIZARD = 'CLOSE_LOOKUP_WIZARD';
export const SET_WIZARD_STEP = 'SET_WIZARD_STEP';

export const BEGIN_INITIAL_LOADING = 'BEGIN_INITIAL_LOADING';
export const END_INITIAL_LOADING = 'END_INITIAL_LOADING';

export const VALUATION_CHANGED = 'VALUATION_CHANGED';
export const RESET_VALUATION = 'RESET_VALUATION';

export const HYDRATE_STATE = 'HYDRATE_STATE';

export const REFRESH_CACHE = 'REFRESH_CACHE';
export const CHECK_CACHE = 'CHECK_CACHE';
export const ADD_TO_CACHE = 'ADD_TO_CACHE';
export const ADD_ALL_TO_CACHE = 'ADD_ALL_TO_CACHE';
export const REFRESH_CACHE_SUCCESS = 'REFRESH_CACHE_SUCCESS';
export const REFRESH_CACHE_FAILURE = 'REFRESH_CACHE_FAILURE';
export const SHOW_TRANSACTIONS = 'SHOW_TRANSACTIONS';

export const FILTER_CLICKED = 'FILTER_CLICKED';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const CANCEL_FILTERS = 'CANCEL_FILTERS';
export const REMOVE_FILTER = 'REMOVE_FILTER';

export const OPEN_TRANSACTION_FILTERS = 'OPEN_TRANSACTION_FILTERS';
export const CLOSE_TRANSACTION_FILTERS = 'CLOSE_TRANSACTION_FILTERS';

export const INVENTORY_DATA_REQUESTED = 'INVENTORY_DATA_REQUESTED';
export const INVENTORY_DATA_RECEIVED = 'INVENTORY_DATA_RECEIVED';
export const INVENTORY_DATA_CLEARED = 'INVENTORY_DATA_CLEARED';
export const OPEN_INVENTORY_MODAL = 'OPEN_INVENTORY_MODAL';
export const CLOSE_INVENTORY_MODAL = 'CLOSE_INVENTORY_MODAL';
export const ADD_INVENTORY_FIELD_TO_HIGHLIGHT =
  'ADD_INVENTORY_FIELD_TO_HIGHLIGHT';
export const INVENTORY_DATA_FAILURE = 'INVENTORY_DATA_FAILURE';

export const SONAR_SEARCH_REQUESTED = 'SONAR_SEARCH_REQUESTED';
export const SONAR_SEARCH_RECEIVED = 'SONAR_SEARCH_RECEIVED';
export const SONAR_SEARCH_FAILURE = 'SONAR_SEARCH_FAILURE';
export const SONAR_SEARCH_STATUS = 'SONAR_SEARCH_STATUS';

export const AB_TEST_CONFIG_CHANGED = 'AB_TEST_CONFIG_CHANGED';
