////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { put } from 'redux-saga/effects';

/* Internal */
import { vinChanged } from 'SRC/store/actions/creators';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function hashStringToJSON(hashString) {
  const jsonObj = {};
  hashString
    .slice(1)
    .split('&')
    .map(v => {
      const [key, value] = v.split('=');
      jsonObj[key] = decodeURIComponent(value || '');
    });

  return jsonObj;
}

function* handleHash(history, hash) {
  const hashObject = hashStringToJSON(hash);
  if (hashObject.source === 'vinscan') {
    yield put(vinChanged(hashObject.vin));
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { handleHash, hashStringToJSON };
