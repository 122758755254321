////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { connect } from 'react-redux';
import deepEqual from 'deep-equal';

/* Internal */
import { ymmsFieldSelected } from 'SRC/store/actions/creators';

/* Local */
import List from './List';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class Step extends Component {
  state = {
    disabled: false,
    previousWizardStep: ''
  };

  render() {
    const { currentWizardStep, apiError } = this.props;

    const stepArray = ['year', 'make', 'model', 'style'];
    const currentIndex = stepArray.indexOf(currentWizardStep);
    const previousIndex = stepArray.indexOf(this.state.previousWizardStep);
    const items = this.getItems(this.props);
    const apiErrorValue = apiError || {};

    const picklist = transitionDirection => {
      // transitionDirection of list-transition moves left to right
      // transitionDirection of list-transition-reverse moves right to left
      return (
        <ReactCSSTransitionGroup
          transitionName={transitionDirection}
          transitionEnterTimeout={300}
          transitionLeaveTimeout={300}
        >
          <List
            key={currentWizardStep}
            items={items}
            handleSelectItem={this.handleSelectItem}
            currentWizardStep={currentWizardStep}
            disabled={this.state.disabled}
            apiError={apiErrorValue}
          />
        </ReactCSSTransitionGroup>
      );
    };

    return currentIndex < previousIndex
      ? picklist('list-transition-reverse')
      : picklist('list-transition');
  }

  componentWillReceiveProps() {
    this.setState({ previousWizardStep: this.props.currentWizardStep });
  }

  componentWillUpdate(nextProps) {
    if (this.propsHaveChanged(this.props, nextProps)) {
      // eslint-disable-next-line react/no-will-update-set-state
      this.setState({ disabled: false });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.propsHaveChanged(prevProps, this.props)) {
      this.scrollToTop();
    }
  }

  componentWillUnmount() {
    this.scrollToTop();
  }

  propsHaveChanged = (props1, props2) => {
    const differentStep = props1.currentWizardStep !== props2.currentWizardStep;
    const sameStepDifferentData = !deepEqual(
      this.getItems(props2),
      this.getItems(props1)
    );

    return Boolean(differentStep || sameStepDifferentData);
  };

  getItems = props => {
    return (
      props.possibilities[`${props.currentWizardStep}s`] ||
      props.possibilities.years
    );
  };

  handleSelectItem = item => {
    if (this.state.disabled) {
      return false;
    }

    if (this.props.currentWizardStep === 'year') {
      this.props.dispatchFieldSelected(this.props.currentWizardStep, item);
    } else {
      this.props.dispatchFieldSelected(this.props.currentWizardStep, item.id);
    }

    this.setState({ disabled: true });
  };

  scrollToTop = () => {
    window.scrollTo(0, 0);
  };
}

Step.propTypes = {
  currentWizardStep: PropTypes.string,
  dispatchFieldSelected: PropTypes.func,
  apiError: PropTypes.shape({
    lookupType: PropTypes.string,
    message: PropTypes.string
  })
};

const stateProps = state => ({
  possibilities: state.ymmsLookup.possibilities,
  currentWizardStep: state.currentWizardStep,
  apiError: state.errors.api ? state.errors.api.MMRDecoder : null
});

const actionProps = {
  dispatchFieldSelected: ymmsFieldSelected
};

const StepContainer = connect(
  stateProps,
  actionProps
)(Step);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default StepContainer;
export { Step };
