////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';

/* Internal */
import { numberWithCommas } from 'SRC/util/formatting';

/* Component */
import AdjustmentInput from 'SRC/components/AdjustmentInput';

/* Local */
import styles from './styles.scss';
import SpeedSVG from './assets/speedometer';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class OdometerEntry extends Component {
  state = {
    textField: ''
  };

  render() {
    const isStable = this.isStable();

    return (
      <form onSubmit={this.handleSubmit}>
        {this.renderOdometerComponent(isStable)}
      </form>
    );
  }

  componentWillReceiveProps(nextProps) {
    const odometerValueHasChanged =
      nextProps.odometer !== this.props.odometer ||
      (nextProps.odometer &&
        this.formatInputForDisplay(nextProps.odometer) !==
          this.state.textField);

    if (odometerValueHasChanged) {
      this.setState({
        textField: this.formatInputForDisplay(nextProps.odometer || '')
      });
    }
  }

  shouldComponentUpdate(nextProps) {
    return nextProps.adjustmentsCompleted === true;
  }

  renderSpeedometerIcon = () => (
    <SpeedSVG disabled={this.props.forceDisabled} />
  );

  renderOdometerComponent = isStable => (
    <AdjustmentInput
      image={this.renderSpeedometerIcon()}
      title="Odometer"
      printValue={numberWithCommas(this.props.odometer)}
      delta={this.props.delta}
      currency={this.props.currency}
      odometerUnits={this.props.odometerUnits}
      disabled={this.props.forceDisabled}
      loading={this.props.loading}
      adjustmentsCompleted={this.props.adjustmentsCompleted}
      showNullState={this.props.showNullState}
    >
      <input
        type="tel"
        id="Odometer"
        placeholder=" Enter Odo"
        className={`${styles.inputField} input--sm`}
        value={this.state.textField}
        onInput={this.handleTextFieldInput}
        onChange={this.handleTextFieldInput} /* both needed in IE10 */
        onBlur={this.handleBlur}
        data-test="odometerTextInput"
        disabled={this.props.forceDisabled}
      />
      <button
        type="submit"
        data-test="odometerGoBtn"
        className={`${styles.button} ${
          isStable ? styles.stable : ''
        } mui-button--secondary mui-button-sm mui-button--icon`}
        disabled={this.props.forceDisabled}
      >
        <i className="icon-chevron-right" />
        <i className="icon-checkmark-bold" />
      </button>
    </AdjustmentInput>
  );

  handleSubmit = event => {
    this.props.onSubmit(
      'odometer',
      this.formatInputForSubmit(this.state.textField)
    );
    event.preventDefault();
  };

  handleBlur = event => {
    // Relies on the Clear link being a "button" element in order to get `relatedTarget`
    const relatedTarget =
      event.relatedTarget || event.nativeEvent.explicitOriginalTarget;
    if (relatedTarget && relatedTarget.innerHTML === 'Clear') {
      this.setState({ textField: '' });
    } else {
      this.handleSubmit(event);
    }
  };

  handleTextFieldInput = event => {
    this.setState({ textField: event.target.value });
  };

  formatInputForDisplay = str => {
    const oldValue = this.state.textField;
    //    if str to string is not equal to  '0' then do the stuff below
    let newValue;
    if (str.toString() !== '0') {
      newValue = str
        .toString()
        .replace(/\..*$/, '') // Strip trailing decimal value
        .replace(/[^\d, ]/g, '') // Strip anything not a number, comma, or space
        .replace(/ /g, ',') // Convert spaces to commas
        .replace(/,+/g, ',') // Condense consecutive commas
        .replace(/^[,0]+/, ''); // Strip leading commas or 0s
    } else {
      newValue = '0';
    }

    // If the input is now empty, leave it alone
    if (newValue === '') {
      return '';
    }

    // If it was previously formatted correctly and they deleted
    // a character, reformat
    if (
      oldValue === numberWithCommas(oldValue) &&
      oldValue.length > newValue.length
    ) {
      return numberWithCommas(newValue);
    }

    const commaThenMoreThanThreeDigits = /,\d{4,}$/.test(newValue);
    const stillEnteringNumberWithComma = /,\d{0,2}$/.test(newValue);
    const wasPasted = newValue.length - oldValue.length > 1;

    if (
      commaThenMoreThanThreeDigits ||
      !stillEnteringNumberWithComma ||
      wasPasted
    ) {
      return numberWithCommas(newValue);
    }

    return newValue;
  };

  formatInputForSubmit = str => {
    if (str) {
      return str
        .toString()
        .split('.')[0]
        .replace(/\D/g, '');
    }
    return '';
  };

  isStable = () => {
    const format = this.formatInputForDisplay;
    const odometerFromApi = this.props.odometer || '';

    return (
      !this.props.forceDisabled &&
      this.state.textField !== '' &&
      format(odometerFromApi) === this.state.textField
    );
  };
}

OdometerEntry.propTypes = {
  odometer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onSubmit: PropTypes.func,
  forceDisabled: PropTypes.bool,
  loading: PropTypes.bool,
  delta: PropTypes.number,
  currency: PropTypes.string,
  odometerUnits: PropTypes.string,
  adjustmentsCompleted: PropTypes.bool,
  showNullState: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default OdometerEntry;
