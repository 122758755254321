////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { combineReducers } from 'redux';
import { cloneDeep } from 'lodash';

/* Internal */
import {
  ADJUSTMENT_SELECTED,
  COLORS_LOADED_FAILURE,
  COLORS_LOADED_SUCCESS,
  FAILED_LOADING_YMMS_MAKES,
  FAILED_LOADING_YMMS_MODELS,
  FAILED_LOADING_YMMS_STYLES,
  YMMS_FIELD_SELECTED,
  GRADES_LOADED_FAILURE,
  GRADES_LOADED_SUCCESS,
  REGIONS_LOADED_FAILURE,
  REGIONS_LOADED_SUCCESS,
  ADJUSTMENT_CLEARED,
  YMMS_SELECTION_CLEARED,
  ALL_ADJUSTMENTS_CLEARED,
  VIN_CHANGED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialPossibilities = {
  colors: [],
  grades: [],
  regions: []
};

const additionalColors = [
  {
    value: 'Camouflage'
  },
  {
    value: 'Lime'
  }
];
function findLowestApiGrade(action) {
  try {
    const lowend =
      action.grades && action.grades[0] ? parseInt(action.grades[0].value) : 10;
    return lowend;
  } catch (err) {
    LOGGER.error(err.message);
  }
  return 10;
}

function generateLowerConditionValues(action) {
  const lowend = findLowestApiGrade(action);
  const lowerCond = [];
  for (let i = 0; i < lowend; i += 1) {
    lowerCond.push({ value: i.toString() }); // eslint-disable-line prefer-template
  }
  return lowerCond;
}

// replace any regions loaded for action.country
function handleRegions(state, action) {
  const existingRegions = state.regions.filter(
    region => region.country !== action.country
  );
  return existingRegions.concat(injectCountry(cloneDeep(action)));
}

// add country property since we will have all regions loaded and filter them
// for use by adjustments.selections.country value
function injectCountry(action) {
  action.regions.forEach(region => {
    region.country = action.country; // eslint-disable-line no-param-reassign
  });
  return action.regions;
}

function possibilities(state = initialPossibilities, action = {}) {
  const grades = generateLowerConditionValues(action).concat(action.grades);
  const colors = additionalColors.concat(action.colors);

  switch (action.type) {
    case COLORS_LOADED_SUCCESS:
      return { ...state, colors };
    case GRADES_LOADED_SUCCESS:
      return { ...state, grades };
    case REGIONS_LOADED_SUCCESS:
      return { ...state, regions: handleRegions(state, action) };
    case COLORS_LOADED_FAILURE:
    case GRADES_LOADED_FAILURE:
    case REGIONS_LOADED_FAILURE:
    default:
      return state;
  }
}

const initialSelections = {
  country: 'US',
  region: null,
  odometer: null,
  color: null,
  grade: null
};

function selections(state = initialSelections, action = {}) {
  if (action.keepAdjustments) {
    return state;
  }

  switch (action.type) {
    case YMMS_FIELD_SELECTED:
      if (action.keepSelection) {
        return state;
      }
      if (action.field === 'style') {
        return {
          ...state,
          region: initialSelections.region,
          color: initialSelections.color,
          grade: initialSelections.grade,
          odometer: initialSelections.odometer
        };
      }
      return { ...state, odometer: null, region: null };
    case ADJUSTMENT_SELECTED:
      return { ...state, [action.field]: action.value };
    case ADJUSTMENT_CLEARED:
      return {
        ...state,
        [action.field]: initialSelections[action.field]
      };
    case ALL_ADJUSTMENTS_CLEARED:
    case YMMS_SELECTION_CLEARED:
      return {
        ...state,
        color: initialSelections.color,
        grade: initialSelections.grade,
        odometer: initialSelections.odometer,
        region: initialSelections.region
      };
    case FAILED_LOADING_YMMS_MAKES:
    case FAILED_LOADING_YMMS_MODELS:
    case FAILED_LOADING_YMMS_STYLES:
      return { ...state, color: null, grade: null, region: null };
    case VIN_CHANGED:
      return { ...initialSelections, country: state.country };
    default:
      return state;
  }
}

const adjustments = combineReducers({ possibilities, selections });

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default adjustments;
export {
  generateLowerConditionValues,
  possibilities,
  selections,
  additionalColors
};
