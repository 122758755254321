////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import Svg from 'SRC/components/Svg';

/* Local */
import styles from './styles.scss';
import usImage from '../assets/US.svg';
import caImage from '../assets/CA.svg';

////////////////////////////////////////////////////////////////////////////////
/*** Core**********************************************************************/

function Value({ children, title, value }) {
  const flagImage = value.value === 'CA' ? caImage : usImage;

  return (
    <div className="Select-value" title={title} data-test="select-country">
      <span className="Select-value-label">
        <Svg
          markup={flagImage}
          width="20px"
          height="15px"
          className={styles.flag}
        />
        {children}
      </span>
    </div>
  );
}

Value.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  value: PropTypes.object
};

export default Value;
