////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { includes } from 'lodash';

/* Internal */
import { COLUMN_HEADERS, ZERO_TXNS, NULL_VALUE } from 'SRC/constants';
import { maybeValue } from 'SRC/util/formatting';

/* Component */
import LoadingSpinner from 'SRC/components/LoadingSpinner';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function DesktopTableBody({
  loading,
  columns,
  transactions,
  visibleRows,
  successfulLookup
}) {
  if (loading) {
    return (
      <tbody data-test="TxnTableBody">
        <tr>
          <td className={`align--left ${styles.emptyBody}`} colSpan="100%">
            <LoadingSpinner
              style={{ position: 'relative', width: '100%', marginTop: '10px' }}
            />
          </td>
        </tr>
      </tbody>
    );
  }

  if (transactions && transactions.length > 0) {
    return (
      <tbody data-test="TxnTableBody" id="TxnTableBody">
        {transactions.slice(0, visibleRows).map((transaction, index) => (
          <tr
            className={`${styles.desktopTransaction} ${
              transaction.adjustmentsMatch
                ? `${styles.adjustmentsMatchRow}`
                : ''
            }`}
            key={index}
          >
            {columns.map(header => {
              const isAuctionCell = header === COLUMN_HEADERS.AUCTION;
              const isOdometerOrPriceOrCondition = includes(
                [
                  COLUMN_HEADERS.SALE_PRICE,
                  COLUMN_HEADERS.ODOMETER,
                  COLUMN_HEADERS.CONDITION_GRADE
                ],
                header
              );
              const alignRight = { textAlign: 'right' };
              const salePriceNotInSample =
                header === COLUMN_HEADERS.SALE_PRICE &&
                transaction.inSample === 'No';

              return (
                <td
                  key={header}
                  style={isOdometerOrPriceOrCondition ? alignRight : {}}
                  {...(isAuctionCell ? { className: `${header}Cell` } : {})}
                >
                  {salePriceNotInSample
                    ? displaySalePrice(transaction[header])
                    : maybeValue(transaction[header])}
                </td>
              );
            })}
          </tr>
        ))}
      </tbody>
    );
  }

  if (successfulLookup && transactions && transactions.length === 0) {
    return displaySuccessfulLookup();
  }

  if (!successfulLookup) {
    return displayFailedLookup();
  }
}

function displaySuccessfulLookup() {
  return (
    <tbody data-test="TxnTableBody">
      <tr>
        <td className={styles.emptyBody} colSpan="100%">
          <div className={styles.emptyBody}>{ZERO_TXNS}</div>
        </td>
      </tr>
    </tbody>
  );
}
function displayFailedLookup() {
  const alignCenter = { textAlign: 'center' };
  return (
    <tbody data-test="TxnTableBody">
      <tr>
        <td className={styles.emptyBody} colSpan="100%">
          <div
            data-test="nullValue"
            className={styles.nullState}
            style={alignCenter}
          >
            {NULL_VALUE}
          </div>
        </td>
      </tr>
    </tbody>
  );
}

function displaySalePrice(price) {
  return (
    <span>
      {maybeValue(price)}
      {maybeValue(price) !== NULL_VALUE && asterisk()}
    </span>
  );
}

function asterisk() {
  return <span className={styles.priceAsterisk}>*</span>;
}

DesktopTableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
  visibleRows: PropTypes.number,
  successfulLookup: PropTypes.bool
};
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export default DesktopTableBody;
