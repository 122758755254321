////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put, cancelled, select, take } from 'redux-saga/effects';
import deepEqual from 'deep-equal';
import { uniqBy } from 'lodash';

/* Internal */
import {
  getVINValuation,
  getTransactions,
  expandRegionAndLocation
} from 'SRC/api';
import {
  vinChangedFailure,
  vinFullyDecoded,
  vinPartiallyDecoded,
  valuationChanged,
  vinDecoderReplied,
  multipleYmmsFieldsSelected,
  openMultiDecodeModal,
  clearValuation,
  endInitialLoading
} from 'SRC/store/actions/creators';
import { MULTI_DECODE_MODAL_CLOSED } from 'SRC/store/actions/types';
import { setAnalyticsCode } from 'SRC/util/helperUtils';
import { midToYMMS } from 'SRC/util/mids';
import { inventoryDataLookupSaga } from './inventoryData';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function allTheSame(values) {
  return values.every(v => deepEqual(v, values[0]));
}

function completeVIN(vin) {
  return vin && vin.length === 17;
}

function dedupedValues(vinValues) {
  const deduped = { vehicles: vinValues.vehicles };
  let fullyDecoded = true;

  function validateAllSame(transform, field) {
    const fieldPlural = `${field}s`;
    const values = vinValues[field];
    if (allTheSame(values) && fullyDecoded) {
      deduped[field] = transform(values[0]);
      deduped[fieldPlural] = [values[0]];
    } else {
      deduped[fieldPlural] = uniqBy(values, transform);
      fullyDecoded = false;
    }
  }

  validateAllSame(x => x, 'year');
  ['make', 'model', 'style'].forEach(field =>
    validateAllSame(x => x.id, field)
  );

  return { deduped, fullyDecoded };
}

function* vinChangedSaga({ vin, mid = null, geolocation = null }) {
  try {
    if (completeVIN(vin)) {
      yield call(inventoryDataLookupSaga, { vin });
    }

    const { color, grade, region, odometer, country } = yield select(
      state => state.adjustments.selections
    );

    const response = yield call(
      getVINValuation,
      vin,
      country,
      region,
      color,
      grade,
      odometer,
      geolocation
    );
    if (!response.success) {
      yield put(clearValuation());
      throw response.payload;
    }
    const vinValues = response.payload;
    const { deduped, fullyDecoded } = dedupedValues(vinValues);

    yield put(vinDecoderReplied(vin, vinValues));
    if (fullyDecoded) {
      yield put(
        vinFullyDecoded(
          deduped.vehicles[0],
          setAnalyticsCode(deduped.vehicles[0])
        )
      );
    } else {
      const vehicleWithMid = deduped.vehicles.filter(
        vehicle => mid && vehicle.mid === mid
      );
      const bestMatch = deduped.vehicles.filter(vehicle => vehicle.bestMatch);
      if (bestMatch.length > 0) {
        yield put(vinFullyDecoded(bestMatch[0], 'mmr_multi'));
      } else if (vehicleWithMid.length > 0) {
        yield put(vinFullyDecoded(vehicleWithMid[0]));
      } else {
        yield put(vinPartiallyDecoded(vin, deduped));
        yield put(openMultiDecodeModal());
        yield take(MULTI_DECODE_MODAL_CLOSED);
      }
    }
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(vinChangedFailure(err.message, 'mmr_nomid'));
      yield put(endInitialLoading());
    }
  }
}

function* vinDecodedSaga() {
  const refinedVehicle = yield select(
    state => state.vinRefinement.selections.vehicle
  );

  const { vin } = yield select(state => state);

  if (completeVIN(vin)) {
    yield call(inventoryDataLookupSaga, { vin });
  }

  const { color, grade, region, odometer, country } = yield select(
    state => state.adjustments.selections
  );

  const selection = {
    ...refinedVehicle,
    adjustments: {
      color,
      grade,
      region,
      country,
      odometer
    }
  };
  const { initialLoading } = yield select(state => state);
  yield put(
    multipleYmmsFieldsSelected(midToYMMS(selection.mid), initialLoading)
  );
  const rawTransactions = yield call(getTransactions, selection.mid, country);
  let transactions = [];
  if (rawTransactions.success) {
    transactions = yield call(expandRegionAndLocation, rawTransactions.payload);
  }
  yield put(valuationChanged(selection, transactions));
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default vinChangedSaga;
export { vinDecodedSaga };
