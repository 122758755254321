////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import zipObject from 'lodash/zipObject';

/* Internal */
import {
  ADD_TO_CACHE,
  ADD_ALL_TO_CACHE,
  REFRESH_CACHE,
  CHECK_CACHE,
  REFRESH_CACHE_SUCCESS,
  REFRESH_CACHE_FAILURE
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function addToCache(href, response) {
  return {
    type: ADD_TO_CACHE,
    href,
    response
  };
}

function addAllToCache(hrefs, responses) {
  return {
    type: ADD_ALL_TO_CACHE,
    newEntries: zipObject(hrefs, responses)
  };
}

function refreshCache(reason = '', newCacheMap) {
  return {
    type: REFRESH_CACHE,
    reason,
    newCacheMap
  };
}

function checkCache(initial = false) {
  return {
    type: CHECK_CACHE,
    initial
  };
}

function refreshCacheSuccess() {
  return {
    type: REFRESH_CACHE_SUCCESS
  };
}

function refreshCacheFailure(message = '') {
  return {
    type: REFRESH_CACHE_FAILURE,
    message
  };
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  addToCache,
  addAllToCache,
  refreshCache,
  checkCache,
  refreshCacheSuccess,
  refreshCacheFailure
};
