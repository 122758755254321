////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function CarFaxIcon() {
  return (
    <svg
      className={`${styles.svg} ${styles.car_fax_icon}`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 16 0.7"
    >
      <title>CarFax icon</title>
      <path
        className={styles.st0}
        d="M0.2,2.4c0-0.2,0.1-0.4,0.4-0.4c0.4,0,0.9,0,1,0s0.2,0.1,0.3,0.1c0.1,0.1,0.2,0.5,0.3,0.5c0.1,0,0.6,0,0.6,0
        L2.7,3.4H1.8c0,0-0.8,0-0.8,0C0.7,3.4,0.2,3,0.2,2.8L0.2,2.4z"
      />
      <path
        className={styles.st0}
        d="M15.9,2.4c0-0.2-0.1-0.4-0.4-0.4c-0.4,0-0.9,0-1,0c-0.1,0-0.2,0.1-0.3,0.1c-0.1,0.1-0.2,0.5-0.3,0.5
        c-0.1,0-0.6,0-0.6,0l0.1,0.7h0.9c0,0,0.8,0,0.8,0c0.4,0,0.8-0.3,0.8-0.6L15.9,2.4z"
      />
      <path
        className={styles.st0}
        d="M3.6,7.2C3.8,7.2,4,7.4,4,7.6V10c0,0.2-0.2,0.4-0.4,0.4H1.7c-0.2,0-0.4-0.2-0.4-0.4V7.6
        c0-0.2,0.2-0.4,0.4-0.4H3.6z"
      />
      <path
        className={styles.st0}
        d="M12.5,7.2c-0.2,0-0.4,0.2-0.4,0.4V10c0,0.2,0.2,0.4,0.4,0.4h1.9c0.2,0,0.4-0.2,0.4-0.4V7.6
        c0-0.2-0.2-0.4-0.4-0.4H12.5z"
      />
      <path
        className={styles.st0}
        d="M14.4,3.8c-0.2-0.2-0.7-0.6-0.7-0.6s-0.2-0.6-0.5-1.2c-0.3-0.5-0.5-0.8-0.5-0.8S12.6,1,12.4,0.9
        c-0.1-0.1-0.3-0.3-0.7-0.4c-0.4-0.1-0.9-0.1-0.9-0.1L9.6,0.3l-1.1,0l-0.6,0l-1.2,0L5.5,0.4c0,0-0.5,0-0.9,0.1
        C4.2,0.5,4,0.7,3.8,0.9C3.7,1,3.4,1.3,3.4,1.3S3.2,1.6,3,2.1C2.7,2.6,2.5,3.2,2.5,3.2S2.1,3.6,1.8,3.8C1.4,4.2,1.1,4.3,1.1,5.1
        c0,1.3,0.1,2.3,0.1,2.3l0.1,0.8c0,0,0.1,0.5,0.2,0.7c0.2,0.3,0.7,0.3,0.7,0.3H14c0,0,0.5,0,0.7-0.3C14.9,8.7,15,8.2,15,8.2l0.1-0.8
        c0,0,0.1-1,0.1-2.3C15.2,4.3,14.9,4.2,14.4,3.8z"
      />
      <path
        className={styles.st1}
        d="M12.7,2.7c-0.2-0.5-0.4-0.8-0.4-0.8s-0.2-0.3-0.4-0.5c-0.2-0.1-0.6-0.2-0.9-0.2c-0.8-0.1-2.2-0.1-2.2-0.1H8.1
        H7.3c0,0-1.3,0-2.2,0.1c-0.4,0-0.8,0.1-0.9,0.2C4.1,1.5,3.8,1.8,3.8,1.8S3.6,2.2,3.4,2.7C3.2,3.1,3.6,3.2,3.7,3.2
        c0.2,0,2.8,0,2.8,0l1.5,0l1.5,0c0,0,2.6,0,2.8,0C12.6,3.2,12.9,3.1,12.7,2.7z"
      />
      <path
        className={styles.st1}
        d="M4.7,6c0.2,0,0.3-0.3,0.2-0.4C4.6,5.1,4.1,4.9,4,4.9C3.3,4.6,2.9,4.5,2.4,4.5c-0.5,0-0.5,0.2-0.5,0.6
        c0,0.3,0.2,0.6,0.4,0.8C2.5,6,2.7,6.1,3.3,6C3.7,6,4.1,6.1,4.7,6z"
      />
      <path
        className={styles.st1}
        d="M11.5,6c-0.2,0-0.3-0.3-0.2-0.4c0.3-0.4,0.7-0.6,0.9-0.6c0.7-0.3,1.1-0.4,1.6-0.4c0.5,0,0.5,0.2,0.5,0.6
        c0,0.3-0.2,0.6-0.4,0.8c-0.2,0.1-0.4,0.2-1,0.2C12.5,6,12.1,6.1,11.5,6z"
      />
      <path
        className={styles.st1}
        d="M2,7.5c0-0.2,0.1-0.2,0.3-0.2c0.1,0,1,0,1,0s0.2,0,0.4,0.1C3.9,7.4,4,7.6,4,7.6s0.1,0.2,0.1,0.3
        C4.1,7.9,4,8,3.6,8C3.1,8,2.8,8,2.4,8C2.1,8,2,7.6,2,7.5z"
      />
      <path
        className={styles.st1}
        d="M14.1,7.5c0-0.2-0.1-0.2-0.3-0.2c-0.1,0-1,0-1,0s-0.2,0-0.4,0.1c-0.1,0.1-0.2,0.2-0.2,0.2s-0.1,0.2-0.1,0.3
        c0,0.1,0,0.2,0.4,0.2c0.5,0,0.8,0,1.2,0C14,8,14.1,7.6,14.1,7.5z"
      />
      <path
        className={styles.st1}
        d="M10.8,6.5c-0.2-0.5-0.5-0.7-1-0.8c-0.5-0.1-1-0.1-1.7-0.1c-0.6,0-1.2,0-1.7,0.1C5.9,5.8,5.6,6,5.4,6.5
        C5.3,6.7,5.2,6.9,5.2,7.1c0,0.6,0.8,0.7,1.2,0.7c0.4,0,1.6,0,1.6,0s1.2,0,1.6,0s1.2-0.1,1.2-0.7C10.9,6.9,10.9,6.7,10.8,6.5z"
      />
    </svg>
  );
}

export default CarFaxIcon;
