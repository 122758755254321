////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { AB_TEST_CONFIG_CHANGED } from 'SRC/store/actions/types';

const initialState = {
  showImage: true,
  showMileage: true,
  showMMR: true,
  showYear: true,
  showYMM: true
};

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
function abTestConfig(state = initialState, action = {}) {
  switch (action.type) {
    case AB_TEST_CONFIG_CHANGED:
      return {
        ...state,
        ...action.payload
      };
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default abTestConfig;
export { abTestConfig };
