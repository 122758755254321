/* eslint-disable */

// Copied shamelessly from m.com
function getCookie(name, unescapeFlag = true) {
  var cookie = document.cookie;
  var prefix = name + '=';
  var begin = cookie.indexOf('; ' + prefix);
  var end = cookie.length;
  if (begin == -1) {
    begin = cookie.indexOf(prefix);
    if (begin != 0) return null;
  } else {
    begin += 2;
    end = document.cookie.indexOf(';', begin);
    if (end == -1) {
      end = cookie.length;
    }
  }
  const result = cookie.substring(begin + prefix.length, end);
  return unescapeFlag ? unescape(result) : result;
}

function onMobileApp() {
  return Boolean(getCookie('manheim_mobile_application_flag'));
}

function onMobileWeb() {
  return Boolean(/m\.manheim/.test(document.referrer));
}

function mobileAppVersion() {
  return getCookie('appVersion');
}

function mobileAppPlatform() {
  return getCookie('manheim_mobile_application_flag');
}

function getUserInfo(authTicket = getAuthTicket()) {
  let userId = '';
  let userName = '';
  const data = authTicket ? authTicket.split('!') : undefined;
  userId = data ? data[0].substring(40, data[0].length) : '';
  userName = data ? data[2] : '';
  // We must decode the userName in case it has special characteres
  // as it will have been escaped at the time the auth_tkt was
  // created.
  userName = userName && decodeURIComponent(userName);
  userName = userName && userName.split('|')[0];
  userName = userName ? userName.replace(/\+/g, ' ') : '';
  return { userId, userName };
}

function isBase64(value) {
  // if we do not have enough string to tell just return false
  try {
    window.atob(value);
    return true;
  } catch (e) {
    return false;
  }
}

function decodeAuthTicket(value) {
  // OVE sends us base64 auth_tkt, m.com is just escaped
  const decodedValue = decodeURIComponent(value);
  return isBase64(decodedValue)
    ? new Buffer(decodedValue, 'base64').toString()
    : decodedValue;
}

function getAuthTicket() {
  const authTicket = getCookie('auth_tkt', false);
  return authTicket ? decodeAuthTicket(authTicket.split(';')[0]) : authTicket;
}

export default getCookie;
export {
  onMobileApp,
  onMobileWeb,
  mobileAppVersion,
  mobileAppPlatform,
  getAuthTicket,
  getUserInfo
};
