////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { combineReducers } from 'redux';

/* Internal */
import {
  YMMS_FIELD_SELECTED,
  YMMS_MAKES_LOADED,
  YMMS_MODELS_LOADED,
  MULTIPLE_YMMS_FIELDS_SELECTED,
  YMMS_POSSIBILITIES_LOADED,
  YMMS_SELECTION_CLEARED,
  YMMS_STYLES_LOADED,
  VIN_CHANGED,
  YMMS_YEARS_LOADED,
  CLEAR_VALUATION,
  VIN_FULLY_DECODED,
  VALUATION_CHANGED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialPossibilities = {
  years: [],
  makes: [],
  models: [],
  styles: []
};

function possibilities(state = initialPossibilities, action = {}) {
  switch (action.type) {
    case YMMS_FIELD_SELECTED:
      if (action.field === 'year' && state.makes.length > 1) {
        // When the year is selected, only clear out the makes.
        // The rest are potentially cleared out by the taxonomy
        // saga after validating the current selections.
        return { ...state, makes: [] };
      }
      return clearPossibilitiesBelow(action.field, state);
    case YMMS_SELECTION_CLEARED:
      return clearPossibilitiesBelow(action.field, state);
    case YMMS_YEARS_LOADED:
      return { ...state, years: action.years.sort().reverse() };
    case YMMS_MAKES_LOADED:
      return { ...state, makes: action.makes };
    case YMMS_MODELS_LOADED:
      return { ...state, models: action.models };
    case YMMS_STYLES_LOADED:
      return { ...state, styles: action.styles };
    case YMMS_POSSIBILITIES_LOADED:
      return {
        ...state,
        makes: action.results.makes || [],
        models: action.results.models || [],
        styles: action.results.styles || []
      };
    case VIN_CHANGED:
      return clearPossibilitiesBelow('year', state);
    case VIN_FULLY_DECODED:
      return {
        ...state,
        years:
          state.years.length === 0
            ? [action.vehicle.description.year]
            : state.years,
        makes: [action.vehicle.description.make] || [],
        models: [action.vehicle.description.model] || [],
        styles: [action.vehicle.description.style] || []
      };
    case VALUATION_CHANGED:
      // if vin lookup we already have data (so makes will be single entry matching selection)
      // if not lets load single entries to match selections
      if (
        state.makes.length === 0 ||
        state.models.length === 0 ||
        state.styles.length === 0
      ) {
        return {
          ...state,
          years:
            state.years.length === 0 ? [action.description.year] : state.years,
          makes: [action.description.make] || [],
          models: [action.description.model] || [],
          styles: [action.description.style] || []
        };
      }
      // if we have a full set of possibilities just use them
      return state;
    default:
      return state;
  }
}

function clearPossibilitiesBelow(field, state) {
  switch (field) {
    case 'year':
      return { ...state, styles: [], models: [], makes: [] };
    case 'make':
      return { ...state, styles: [], models: [] };
    case 'model':
      return { ...state, styles: [] };
    default:
      return state;
  }
}

const initialSelections = {
  year: null,
  make: null,
  model: null,
  style: null
};

function selections(state = initialSelections, action = {}) {
  switch (action.type) {
    case YMMS_FIELD_SELECTED:
      return { ...state, [action.field]: action.value };
    case MULTIPLE_YMMS_FIELDS_SELECTED:
      return {
        year: action.year || initialSelections.year,
        make: action.make || initialSelections.make,
        model: action.model || initialSelections.model,
        style: action.style || initialSelections.style
      };
    case YMMS_SELECTION_CLEARED:
      return clearSelectionsBelow(action.field, state);
    case VIN_CHANGED:
    case CLEAR_VALUATION:
      return initialSelections;
    case VIN_FULLY_DECODED:
      return {
        ...state,
        year: action.vehicle.description.year || state.year,
        make: action.vehicle.description.make.id || state.make,
        model: action.vehicle.description.model.id || state.model,
        style: action.vehicle.description.style.id || state.style
      };
    case VALUATION_CHANGED:
      return {
        ...state,
        make: action.description.make.id || state.make,
        model: action.description.model.id || state.model,
        style: action.description.style.id || state.style
      };
    default:
      return state;
  }
}

function clearSelectionsBelow(field, state) {
  switch (field) {
    case 'year':
      return { ...state, style: null, model: null, make: null, year: null };
    case 'make':
      return { ...state, style: null, model: null, make: null };
    case 'model':
      return { ...state, style: null, model: null };
    case 'style':
      return { ...state, style: null };
    default:
      return state;
  }
}

const ymmsLookup = combineReducers({ possibilities, selections });

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default ymmsLookup;
export { possibilities, selections };
