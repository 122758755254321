////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Region({ disabled }) {
  return (
    <svg
      version="1.1"
      id="region"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 15 15"
      className={`${styles.iconClass} ${disabled ? styles.iconDisabled : ''}`}
      style={{ enableBackground: 'new 0 0 15 15' }}
      xmlSpace="preserve"
    >
      <title>Region</title>
      <path
        className={`${styles.icon} ${disabled ? styles.disabled : ''}`}
        d="M7.5,0.5c0.6,0,1.2,0.1,1.7,0.3c1.1,0.4,1.9,1.3,2.3,2.3c0.2,0.5,0.3,1.1,0.3,1.7c0,1.1-0.2,2.3-0.7,3.4
        c-0.4,1.1-0.9,2.1-1.5,3.1c-0.7,1.1-1.4,2.2-2.2,3.2c-0.8-1-1.5-2.1-2.2-3.2c-0.6-1-1.1-2-1.5-3.1C3.4,7.1,3.2,6,3.2,4.8
        c0-0.6,0.1-1.2,0.3-1.7C4,2.1,4.8,1.2,5.8,0.8C6.4,0.6,7,0.5,7.5,0.5z M7.5,7.5c1.4,0,2.6-1.1,2.6-2.6c0,0,0,0,0,0
        c0-0.7-0.3-1.4-0.8-1.8C8.9,2.5,8.2,2.2,7.5,2.2C6.8,2.2,6.2,2.5,5.7,3C5.2,3.5,4.9,4.2,4.9,4.9c0,0.7,0.3,1.4,0.8,1.9
        C6.2,7.2,6.8,7.5,7.5,7.5z"
      />
    </svg>
  );
}

Region.propTypes = {
  disabled: PropTypes.bool
};

export default Region;
