////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { USER_INFO_LOADED } from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function userInfoLoaded(response) {
  return {
    type: USER_INFO_LOADED,
    bearerToken: response.accessToken,
    userId: response.userId,
    userName: response.userName
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { userInfoLoaded };
