////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { maybeCurrency } from 'SRC/util/formatting';

//[> Local <]
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function CurrencyDisplay({
  value,
  currency,
  error,
  asterisk = false,
  size,
  showZero = false
}) {
  return (
    <div
      data-test="currencyDisplay"
      className={`${styles.currency} show--inline-block`}
      style={{ fontSize: size }}
    >
      {maybeCurrency(value, currency, error, showZero)}
      {asterisk && value && '*'}
    </div>
  );
}

CurrencyDisplay.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  currency: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  asterisk: PropTypes.string,
  showZero: PropTypes.bool,
  size: PropTypes.string
};

export default CurrencyDisplay;
