////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put, select } from 'redux-saga/effects';

/* Internal */
import {
  midChanged,
  vinStylesLoaded,
  vinFieldSelected
} from 'SRC/store/actions/creators';
import { ymmsToMid } from 'SRC/util/mids';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* loadVinStylesSaga(value) {
  const { vinValues, selections } = yield select(state => state.vinRefinement);
  const { models, styles } = vinValues;
  const previousStyle = selections.style;
  const stylesPossibilities = styles.filter(
    (style, index) => models[index].id === value
  );
  const previousStyleInResults = stylesPossibilities.some(
    possibility => possibility.id === previousStyle
  );
  yield put(vinStylesLoaded(stylesPossibilities));
  if (stylesPossibilities.length === 1) {
    // If there is only one result, auto-select the only result
    yield put(vinFieldSelected('style', stylesPossibilities[0].id));
  } else if (previousStyleInResults) {
    // Or, if new style is same as in previous lookup, auto-select result
    yield put(vinFieldSelected('style', previousStyle));
  }
}

function* vinFieldSelectedSaga({ field, value }) {
  let newMid;

  const { year, make, model } = yield select(
    state => state.vinRefinement.selections
  );
  switch (field) {
    case 'model':
      // We must use call rather than spawn here because loadVinStylesSaga does not call GAPI
      yield call(loadVinStylesSaga, value);
      break;
    case 'style':
      newMid = ymmsToMid({ year, make, model, style: value });
      if (value !== '') {
        yield put(midChanged(newMid));
      }
      break;
    default:
      break;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { vinFieldSelectedSaga, loadVinStylesSaga };
