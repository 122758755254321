/* eslint-disable camelcase  */
////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, spawn, take, put, select } from 'redux-saga/effects';

/* Internal */
import {
  midChanged,
  filterClicked,
  vinChanged,
  adjustmentSelected,
  openLookupWizard,
  endInitialLoading,
  inventoryDataRequested,
  openInventoryModal,
  highlightInventoryField
} from 'SRC/store/actions/creators';
import {
  YMMS_YEARS_LOADED,
  INVENTORY_DATA_RECEIVED,
  FAILED_LOADING_YMMS_YEARS,
  VIN_FULLY_DECODED,
  VIN_PARTIALLY_DECODED,
  VIN_CHANGED_FAILURE,
  INVENTORY_DATA_FAILURE
} from 'SRC/store/actions/types';

import {
  truthyOrZero,
  validateCondGrade,
  titleizeColor,
  validateMileage,
  validateRegion,
  validateCountry,
  isAllowableListing,
  validateVehicleUniqueId,
  hasQueryParams,
  isEmptyNullUndefinedString
} from 'SRC/util/helperUtils';
import { formatConditionGradeFromURL } from 'SRC/util/formatting';
import { modifyUrlField, sanitizeParams } from 'SRC/util/queryParams';
import { EXTERNAL } from 'SRC/constants';
import { getHistory } from './browserHistory';
import { respondToURLChanges } from './urlObserver';
import { respondToStateChanges } from './stateObserver';

/* Local */
import { completeValuationActions } from './config';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

window.MMR = window.MMR || Object.create(null);

function* deepLink(query) {
  /*eslint-disable prefer-const*/
  /*eslint-disable prefer-destructuring*/
  let {
    country,
    region,
    region_filter,
    color,
    condition,
    mileage,
    vin,
    mid,
    wizard,
    vehicle_unique_id,
    tracer,
    geolocation
  } = query;

  if (tracer) {
    window.MMR.tracer = tracer;
  }

  if (isAllowableListing(vehicle_unique_id)) {
    yield put(inventoryDataRequested({ vehicleUniqueId: vehicle_unique_id }));
    yield take([INVENTORY_DATA_RECEIVED, INVENTORY_DATA_FAILURE]);
    const { inventoryData } = yield select(state => state);
    if (inventoryData.id && inventoryData.status !== 'Expired') {
      LOGGER.log('we found listing for ', inventoryData.id, inventoryData);

      let openModal = false;

      if (diffsBetweenInventoryAndUrlParams(vin, inventoryData.vin, 'vin')) {
        vin = inventoryData.vin;
        openModal = true;
      }

      if (
        diffsBetweenInventoryAndUrlParams(color, inventoryData.color, 'color')
      ) {
        color = inventoryData.color;
        yield put(highlightInventoryField('color'));
        openModal = true;
      }

      if (
        diffsBetweenInventoryAndUrlParams(
          condition,
          inventoryData.condition,
          'condition'
        )
      ) {
        condition = inventoryData.condition;
        yield put(highlightInventoryField('condition'));
        if (inventoryData.isAutogradeConditionGrade) {
          openModal = true;
        }
      }

      if (
        diffsBetweenInventoryAndUrlParams(
          mileage,
          inventoryData.odometer,
          'odometer'
        )
      ) {
        mileage = inventoryData.odometer;
        yield put(highlightInventoryField('odometer'));
        openModal = true;
      }

      if (
        inventoryData.pickupCountry &&
        diffsBetweenInventoryAndUrlParams(
          country,
          inventoryData.pickupCountry,
          'country'
        )
      ) {
        country = inventoryData.pickupCountry;
        openModal = true;
      }

      if (openModal && hasQueryParams(query) && vin) {
        yield spawn(inventoryModalHandler);
      }
    }
  }

  const fixedCountry = validateCountry(country);
  yield put(adjustmentSelected('country', fixedCountry));

  if (wizard) {
    yield put(openLookupWizard());
  }

  if (region) {
    yield put(adjustmentSelected('region', region));
  }

  if (region_filter) {
    const myString = String(unescape(region_filter));
    const filterArray = myString.split(',');
    for (let i = 0; i < filterArray.length; i += 1) {
      yield put(
        filterClicked('Region', {
          target: { value: filterArray[i].toUpperCase(), checked: true }
        })
      );
    }
  }

  if (color) {
    yield put(adjustmentSelected('color', color));
  }

  if (condition) {
    yield put(adjustmentSelected('grade', condition * 10));
  }

  if (truthyOrZero(mileage)) {
    yield put(adjustmentSelected('odometer', mileage));
  }

  // Perform a valuation if one has been deep-linked
  if (vin) {
    yield put(vinChanged(vin, { keepAdjustments: true, mid, geolocation }));
  } else if (mid) {
    yield put(midChanged(mid));
  }

  // Wait for the app to "stabilize" to avoid extra URLs
  if (vin || mid) {
    yield take(completeValuationActions);
  } else {
    // crh testing
    yield take([YMMS_YEARS_LOADED, FAILED_LOADING_YMMS_YEARS]);
    yield put(openLookupWizard());
  }

  yield put(endInitialLoading());
}

function diffsBetweenInventoryAndUrlParams(
  inventoryDataParam,
  urlQueryParam,
  type
) {
  let formattedUrlParam = isEmptyNullUndefinedString(urlQueryParam)
    ? null
    : String(urlQueryParam);
  let formattedInventoryParam = isEmptyNullUndefinedString(inventoryDataParam)
    ? null
    : String(inventoryDataParam);
  if (type === 'condition' && formattedInventoryParam !== null) {
    formattedInventoryParam = formatConditionGradeFromURL(
      formattedInventoryParam
    );
  }
  return shouldShowModal(formattedInventoryParam, formattedUrlParam);
}

function shouldShowModal(inventoryDataParam, urlParam) {
  return (
    Boolean(!urlParam && inventoryDataParam) ||
    Boolean(urlParam !== inventoryDataParam)
  );
}

// we do not know if the multidecode modal needs to be displayed
// until we have decoded the VIN from the listing.
// Since we do not want to show both inventory and multidecode modals
// we spawn this saga to await the outcome of the vin decode
// before deciding whether to show the inventory modal.
function* inventoryModalHandler() {
  LOGGER.log('inventoryModalHandler: waiting for decoder reply');
  const action = yield take([
    VIN_FULLY_DECODED,
    VIN_PARTIALLY_DECODED,
    VIN_CHANGED_FAILURE
  ]);
  if (action.type !== VIN_PARTIALLY_DECODED) {
    LOGGER.log('inventoryModalHandler: requesting modal');
    yield put(openInventoryModal());
  }
  LOGGER.log('inventoryModalHandler: completed.');
}

function preprocessURL(history) {
  // Pre-processing on incoming URL
  modifyUrlField(history, 'vin', vin =>
    vin.trim().replace(/[^0-9a-zA-z]/g, '')
  );

  modifyUrlField(history, 'country', validateCountry);
  modifyUrlField(history, 'region', validateRegion);
  modifyUrlField(history, 'mileage', validateMileage);
  modifyUrlField(history, 'color', titleizeColor);
  modifyUrlField(history, 'condition', validateCondGrade);
  modifyUrlField(history, 'auto_adjust', () => {
    return undefined;
  });
  modifyUrlField(history, 'region', region => region.toUpperCase());
  modifyUrlField(history, 'vehicle_unique_id', validateVehicleUniqueId);
}

function* urlSaga(history = getHistory()) {
  try {
    // Emit actions to sync the incoming URL with the state
    if (EXTERNAL) {
      yield call(deepLink, sanitizeParams(window.MMR.externalParams));
    } else {
      preprocessURL(history);
      yield call(deepLink, history.getCurrentLocation().query);
    }

    // Kick off two "watchers" that monitor the URL and the state
    // to keep them in sync with each other
    yield spawn(respondToStateChanges, history);
    yield spawn(respondToURLChanges, history);
  } catch (err) {
    LOGGER.error(err);
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default urlSaga;
export { urlSaga, deepLink, inventoryModalHandler };
