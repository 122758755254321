////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import { closeInventoryModal } from 'SRC/store/actions/creators';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class InventoryModal extends Component {
  constructor() {
    super();
    this.handleClick = this.handleClick.bind(this);
  }

  render() {
    if (this.props.inventoryModalOpened !== true) {
      return null;
    }

    return (
      <div className={`${styles.modalContainer}`}>
        <div className={`${styles.modalBox}`} data-test="inventoryModal">
          <div className={`${styles.modalText}`} data-test="inventoryModalText">
            We have located this vehicle in inventory and have updated the MMR
            value
          </div>
          <button
            type="button"
            className={`${styles.modalButton}`}
            onClick={this.handleClick}
            data-test="inventoryModalButton"
          >
            Continue
          </button>
        </div>
      </div>
    );
  }

  handleClick(e) {
    e.preventDefault();
    this.props.dispatchModalClose();
  }
}

InventoryModal.propTypes = {
  inventoryModalOpened: PropTypes.bool,
  dispatchModalClose: PropTypes.func
};

const stateProps = state => ({
  inventoryModalOpened: state.showInventoryModal
});

const actionProps = {
  dispatchModalClose: closeInventoryModal
};

const InventoryModalContainer = connect(
  stateProps,
  actionProps
)(InventoryModal);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default InventoryModalContainer;
