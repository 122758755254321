import PropTypes from 'prop-types';
import Slider from 'react-slick';
import React, { PureComponent } from 'react';
import debounce from 'lodash/debounce';
import first from 'lodash/first';
import get from 'lodash/get';
import isFunction from 'lodash/isFunction';

import shouldBeSameWindowFunction from '../shouldBeSameWindowFunction';
import VehicleComponent from '../VehicleComponent';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from './simVehiclesComponent.scss';

const vehicleWidth = 167;
const animationSpeed = 500;

class SimVehiclesComponent extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      isCollapsed: false,
      numberOfVehiclesToDisplay: props.numberOfVehiclesToDisplay
    };
    // Arrow click can't be committed more often than animation duration
    this.carouselArrowClicked = debounce(
      props.analyticsCallbacks.carouselArrowClicked,
      animationSpeed
    );

    this.attachLoggingToArrowClicks = this.attachLoggingToArrowClicks.bind(
      this
    );
    this.updateNumberOfVehiclesToDisplay = debounce(
      this.updateNumberOfVehiclesToDisplay.bind(this),
      200
    );
    this.onCollapse = this.onCollapse.bind(this);
    this.getContainerRef = this.getContainerRef.bind(this);
  }

  render() {
    const { numberOfVehiclesToDisplay, isCollapsed } = this.state;
    const {
      vehicles,
      oveLinkUrl,
      analyticsCallbacks: { viewAllClicked, carouselSwiped, vehicleClicked }
    } = this.props;

    const isScrollable = vehicles.length > numberOfVehiclesToDisplay;
    const hrefProps = shouldBeSameWindowFunction()
      ? {}
      : {
          target: '_blank',
          rel: 'noreferrer noopener'
        };
    return (
      <div className="similarVehiclesDesktop" ref={this.getContainerRef}>
        <div className={styles.desktopContainer}>
          <div className={styles.desktopText}>
            <div className={styles.collapseContainer}>
              <span
                className={
                  isCollapsed ? styles.expandButton : styles.collapseButton
                }
                onClick={this.onCollapse}
              >
                Similar Vehicles for Sale
              </span>
            </div>
            <a
              id="oveSimVehCarouselVA"
              href={oveLinkUrl}
              {...hrefProps}
              onClick={viewAllClicked}
              className={isCollapsed ? styles.viewAllLinkHidden : null}
            >
              <span className={styles.viewAllLink}>View All </span>
              <span
                className={`${styles.viewAllCaret} icon-chevron-right mui-m-h-r`}
              />
            </a>
          </div>
          <Slider
            speed={animationSpeed}
            onSwipe={carouselSwiped}
            ref={this.attachLoggingToArrowClicks}
            variableWidth={!isScrollable}
            accessibility
            arrows
            infinite={false}
            slidesToScroll={isScrollable ? numberOfVehiclesToDisplay : 1}
            slidesToShow={
              isScrollable ? numberOfVehiclesToDisplay : vehicles.length
            }
            className={isCollapsed ? 'slick-slider--hidden' : null}
          >
            {vehicles.map((vehicle, index) => (
              <VehicleComponent
                vehicle={vehicle}
                vehicleIndex={index}
                key={vehicle.vin}
                showImage={this.props.showImage}
                showMileage={this.props.showMileage}
                showMMR={this.props.showMMR}
                showYear={this.props.showYear}
                showYMM={this.props.showYMM}
                vehicleClickedCallback={vehicleClicked}
              />
            ))}
          </Slider>
        </div>
      </div>
    );
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateNumberOfVehiclesToDisplay);
    this.updateNumberOfVehiclesToDisplay();
    this.props.analyticsCallbacks.componentMounted();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateNumberOfVehiclesToDisplay);
  }

  onCollapse() {
    this.setState(state => {
      this.props.analyticsCallbacks.componentVisibilityChanged(
        !state.isCollapsed
      );
      return { isCollapsed: !state.isCollapsed };
    });
  }

  attachLoggingToArrowClicks(ref) {
    if (ref) {
      const sliderRef = ref;
      // Code below relies on undocumented call of changeSlide function by arrow click
      const changeSlidePreviousHandler = get(
        sliderRef,
        'innerSlider.changeSlide'
      );

      if (isFunction(changeSlidePreviousHandler)) {
        sliderRef.innerSlider.changeSlide = (...args) => {
          this.carouselArrowClicked(first(args).message);
          changeSlidePreviousHandler(...args);
        };
      }
    }
  }

  getContainerRef(ref) {
    this.containerRef = ref;
  }

  updateNumberOfVehiclesToDisplay() {
    if (this.containerRef) {
      const numberOfVehiclesToDisplay =
        this.containerRef.clientWidth / vehicleWidth;
      this.setState({ numberOfVehiclesToDisplay });
    }
  }
}

SimVehiclesComponent.propTypes = {
  vehicles: PropTypes.array,
  numberOfVehiclesToDisplay: PropTypes.number,
  oveLinkUrl: PropTypes.string,
  showImage: PropTypes.bool,
  showMileage: PropTypes.bool,
  showMMR: PropTypes.bool,
  showYear: PropTypes.bool,
  showYMM: PropTypes.bool,

  analyticsCallbacks: PropTypes.shape({
    componentMounted: PropTypes.func,
    vehicleClicked: PropTypes.func,
    viewAllClicked: PropTypes.func,
    carouselSwiped: PropTypes.func,
    componentVisibilityChanged: PropTypes.func,
    carouselArrowClicked: PropTypes.func
  })
};

SimVehiclesComponent.defaultProps = {
  analyticsCallbacks: {
    componentMounted: () => {},
    vehicleClicked: () => {},
    viewAllClicked: () => {},
    carouselSwiped: () => {},
    carouselArrowClicked: () => {},
    componentVisibilityChanged: () => {}
  },
  numberOfVehiclesToDisplay: 6,
  vehicles: []
};

export { SimVehiclesComponent };
export default SimVehiclesComponent;
