////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { COLUMN_HEADERS } from 'SRC/constants';

/* Local */
import styles from '../styles.scss';
import { formatHeader } from '../formatting';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function DesktopTableHeader({
  columns,
  sortBy,
  sortOrder,
  onChangeSort,
  odometerUnits
}) {
  const clickHandler = onChangeSort || (() => undefined);

  const headersMarkup = columns.map(header => {
    let sortArrow = `${styles.biggerArrow} icon-triangle-`;
    if (sortBy === header) {
      if (sortOrder === 'asc') sortArrow += 'up';
      else sortArrow += 'down';
    } else {
      sortArrow += `down print--hide ${styles.light}`;
    }

    return (
      <th
        key={header}
        className={`${styles.header} ${styles.noSelect}`}
        unselectable="on"
        onClick={() => clickHandler(header)}
      >
        {`${formatHeader(header, odometerUnits)} `}
        <i className={sortArrow} data-test="TxnSortArrow" />
      </th>
    );
  });

  return (
    <thead data-test="DesktopTableHeaderDisplay">
      <tr>{headersMarkup}</tr>
    </thead>
  );
}

DesktopTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  sortBy: PropTypes.oneOf(Object.values(COLUMN_HEADERS)),
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onChangeSort: PropTypes.func,
  odometerUnits: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default DesktopTableHeader;
