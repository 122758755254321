////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Component */
import AverageValuation from 'SRC/components/AverageValuation';
import LoadingContent from 'SRC/components/LoadingContent';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function ProjectedAverage({ projectedAverages, currency, loading }) {
  const { nextMonth = {} } = projectedAverages || {};

  return (
    <div className="font--lato" data-test="ProjectedAverageDisplay">
      <h3 className={`mui-m-h-b mui-m-t ${styles.title}`}>Projected Average</h3>
      <LoadingContent loading={loading} className="mui-row">
        <div className="mui-col mui-col-1-3" data-test="MMRNextMonthDisplay">
          <AverageValuation
            header="Next Month"
            averagePrice={nextMonth.wholesale}
            hideOdometer
            currency={currency}
          />
        </div>
      </LoadingContent>
    </div>
  );
}

ProjectedAverage.propTypes = {
  projectedAverages: PropTypes.shape({
    wholesale: PropTypes.number,
    retail: PropTypes.number,
    nextMonth: PropTypes.shape({
      wholesale: PropTypes.number,
      retail: PropTypes.number
    }),
    nextSixMonths: PropTypes.shape({
      wholesale: PropTypes.number,
      retail: PropTypes.number
    }),
    nextYear: PropTypes.shape({
      wholesale: PropTypes.number,
      retail: PropTypes.number
    })
  }),
  currency: PropTypes.string,
  loading: PropTypes.bool
};

const stateProps = state => ({
  projectedAverages: state.mostRecentLookup.projectedAverages,
  currency: state.mostRecentLookup.currency,
  values: state.mostRecentLookup.retailValues,
  loading: state.loading
});

const ProjectedAverageContainer = connect(stateProps)(ProjectedAverage);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default ProjectedAverageContainer;
export { ProjectedAverage };
