////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { ZERO_TXNS, NULL_VALUE } from 'SRC/constants';
import { maybeValue } from 'SRC/util/formatting';

/* Component */
import LoadingSpinner from 'SRC/components/LoadingSpinner';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function MobileTableBody({
  columnSets,
  loading,
  transactions,
  visibleRows,
  successfulLookup
}) {
  if (loading) {
    return (
      <div className={styles.emptyBody}>
        <LoadingSpinner
          style={{ position: 'relative', width: '100%', marginTop: '75px' }}
        />
      </div>
    );
  }

  if (transactions && transactions.length > 0) {
    return (
      <ul className={styles.list}>
        {transactions.slice(0, visibleRows).map((transaction, index) => (
          <li
            key={index}
            className={`${styles.mobileTransaction} ${
              transaction.adjustmentsMatch ? styles.adjustmentsMatchRow : ''
            }`}
          >
            <div className="mui-row">
              {columnSets.map(column => (
                <div className="mui-col mui-col-1-3" key={column}>
                  {column.map(header => (
                    <div key={header} className="mui-row">
                      <div
                        className={`mui-col mui-col-1-1 ${styles.mainDataCol}`}
                      >
                        {maybeValue(transaction[header])}
                      </div>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
    );
  }

  if (successfulLookup) {
    return displaySuccessfulLookup();
  }

  if (!successfulLookup) {
    return displayFailedLookup();
  }
}
function displaySuccessfulLookup() {
  return (
    <div data-test="TxnTableBody">
      <div>
        <div className={styles.list} colSpan="100%">
          <div className={styles.emptyBody}>{ZERO_TXNS}</div>
        </div>
      </div>
    </div>
  );
}
function displayFailedLookup() {
  const alignCenter = { textAlign: 'center' };
  return (
    <div data-test="TxnTableBody">
      <div>
        <div className={styles.list} colSpan="100%">
          <div
            data-test="nullValue"
            className={styles.nullState}
            style={alignCenter}
          >
            {NULL_VALUE}
          </div>
        </div>
      </div>
    </div>
  );
}

MobileTableBody.propTypes = {
  columnSets: PropTypes.arrayOf(PropTypes.array),
  loading: PropTypes.bool,
  successfulLookup: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
  visibleRows: PropTypes.number
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default MobileTableBody;
