////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  YMMS_FIELD_SELECTED,
  MULTIPLE_YMMS_FIELDS_SELECTED,
  YMMS_SELECTION_CLEARED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function ymmsFieldSelected(field, value, shouldValidate, keepSelection) {
  return {
    type: YMMS_FIELD_SELECTED,
    field,
    value,
    shouldValidate: Boolean(shouldValidate),
    keepSelection: Boolean(keepSelection)
  };
}

function multipleYmmsFieldsSelected(
  { year, make, model, style, country, region, color, grade },
  initialLoading
) {
  return {
    type: MULTIPLE_YMMS_FIELDS_SELECTED,
    year,
    make,
    model,
    style,
    country,
    region,
    color,
    grade,
    initialLoading
  };
}

function ymmsSelectionCleared(field, { keepAdjustments } = {}) {
  return {
    type: YMMS_SELECTION_CLEARED,
    field,
    keepAdjustments: Boolean(keepAdjustments)
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { ymmsFieldSelected, multipleYmmsFieldsSelected, ymmsSelectionCleared };
