////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import {
  maybeCurrency,
  numberWithCommas,
  formatOdometerUnits
} from 'SRC/util/formatting';
import { NULL_VALUE } from 'SRC/constants';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function AverageValuation({
  header,
  averagePrice,
  odometer,
  hideOdometer,
  currency,
  odometerUnits
}) {
  const valueDisplay = maybeCurrency(averagePrice, currency);
  const odometerDisplay = hideOdometer
    ? ''
    : displayOdometer(odometer, odometerUnits);

  return (
    <div className={styles.container}>
      <span className={styles.header}>{header}</span>
      <div className={`bold ${styles.value}`}>{valueDisplay}</div>
      {odometerDisplay}
    </div>
  );
}

AverageValuation.propTypes = {
  header: PropTypes.string.isRequired,
  averagePrice: PropTypes.number,
  odometer: PropTypes.number,
  hideOdometer: PropTypes.bool,
  currency: PropTypes.string,
  odometerUnits: PropTypes.string
};

function displayOdometer(odometer, odometerUnits) {
  const formatted = odometerUnits
    ? `${numberWithCommas(odometer)} ${formatOdometerUnits(odometerUnits)}`
    : `${numberWithCommas(odometer)}`;

  return (
    <div className={styles.odometer}>
      <span className="bold">
        {formatted && formatted.startsWith('0') ? NULL_VALUE : formatted}
      </span>
    </div>
  );
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default AverageValuation;
