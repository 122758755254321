////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { VALUATION_CHANGED } from 'SRC/store/actions/types';

/* external */
import {
  replaceFleetWithFactory,
  setAnalyticsCode,
  setSearchTypeForAnalytics
} from 'SRC/util/helperUtils';
import { EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function valuationChanged(response, transactions) {
  const analyticsCode = setAnalyticsCode(response);
  if (!EXTERNAL && window.webData.searchType === '') {
    setSearchTypeForAnalytics(analyticsCode);
  }
  return {
    type: VALUATION_CHANGED,
    adjustments: response.adjustments,
    wholesaleValues: response.wholesale,
    retailValues: response.retail,
    historicalAverages: response.historicalAverages,
    projectedAverages: response.forecast,
    averageOdometer: response.averageOdometer,
    averageGrade: response.averageGrade,
    odometerUnits: response.odometerUnits,
    adjustedPricing: response.adjustedPricing,
    currency: response.currency,
    mid: response.mid,
    description: response.description,
    transactions: replaceFleetWithFactory(transactions)
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { valuationChanged };
