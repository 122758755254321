////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { spawn, cancelled, call, put, select } from 'redux-saga/effects';
import { sortBy } from 'lodash';

/* Internal */
import {
  getMultipleFields,
  getYears,
  getMakes,
  getModels,
  getStyles
} from 'SRC/api';
import {
  ymmsMakesLoaded,
  failedLoadingYmmsMakes,
  ymmsModelsLoaded,
  failedLoadingYmmsModels,
  ymmsStylesLoaded,
  failedLoadingYmmsStyles,
  midChanged,
  ymmsFieldSelected,
  ymmsPossibilitiesLoaded,
  failedLoadingYmmsPossibilities,
  ymmsSelectionCleared,
  ymmsYearsLoaded,
  failedLoadingYmmsYears
} from 'SRC/store/actions/creators';
import { FAKE_RAM_MODEL_ID, DODGE_MAKE_ID, RAM_MAKE_ID } from 'SRC/constants';
import { ymmsToMid } from 'SRC/util/mids';
import valueInSet from 'SRC/util/valueInSet';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function fetchingSaga(
  field,
  transformResult,
  apiCall,
  successAction,
  failureAction
) {
  return function* loadSaga(
    shouldValidate,
    keepSelection,
    value,
    ...apiCallArgs
  ) {
    try {
      // Make the API call with any arguments passed to the saga
      const response = yield call(apiCall, value, ...apiCallArgs);

      if (!response.success) {
        throw response.payload;
      }

      const results = response.payload;
      const { selections } = yield select(state => state.ymmsLookup);

      // If there are results and the array is not empty, the API call was a success.
      // Otherwise, there was either an error with the API call or there were no
      // results from the decoder. Either way, this API call was a failure. Throw an
      // error that will get caught below.
      if (results && results.length > 0) {
        if (field === 'model' && dodgeNoRam(selections.year, value)) {
          results.push({ name: 'RAM', id: FAKE_RAM_MODEL_ID });
        }
        yield put(successAction(sortBy(results, 'name')));
      } else {
        throw new Error(
          `Results were ${results ? 'an empty array' : 'undefined'}.`
        );
      }
      const isWizard = yield select(state => state.showLookupWizard);
      if (results.length === 1 && !isWizard) {
        // If there is only one result and the request is not from mobile
        // Auto-select the only result
        yield put(
          ymmsFieldSelected(
            field,
            transformResult(results[0]),
            shouldValidate,
            keepSelection
          )
        );
      } else if (shouldValidate) {
        // If we are supposed to validate the next field
        // If the current selection is in the results, re-select it and make sure the
        // next step gets validated as well.
        if (valueInSet(selections[field], results, transformResult)) {
          yield put(
            ymmsFieldSelected(
              field,
              selections[field],
              shouldValidate,
              keepSelection
            )
          );
        } else if (!keepSelection) {
          yield put(ymmsSelectionCleared(field));
        }
      } else if (!keepSelection) {
        yield put(ymmsSelectionCleared(field));
      }

      // Return the results (for testing purposes)
      // TODO: see if this is still necessary
      return results;
    } catch (err) {
      LOGGER.error(err);
      yield put(failureAction(err.message));
    }
  };
}

function dodgeNoRam(year, id) {
  return Number(year) >= 2011 && id === DODGE_MAKE_ID;
}

const loadYmmsStylesSaga = fetchingSaga(
  'style',
  style => style.id,
  getStyles,
  ymmsStylesLoaded,
  failedLoadingYmmsStyles
);
const loadYmmsModelsSaga = fetchingSaga(
  'model',
  model => model.id,
  getModels,
  ymmsModelsLoaded,
  failedLoadingYmmsModels
);
const loadYmmsMakesSaga = fetchingSaga(
  'make',
  make => make.id,
  getMakes,
  ymmsMakesLoaded,
  failedLoadingYmmsMakes
);
const loadYmmsYearsSaga = fetchingSaga(
  'year',
  year => year,
  getYears,
  ymmsYearsLoaded,
  failedLoadingYmmsYears
);

function* ymmsFieldSelectedSaga({
  field,
  value,
  shouldValidate,
  keepSelection
}) {
  const { year, make, model } = yield select(
    state => state.ymmsLookup.selections
  );
  const { country } = yield select(state => state.adjustments.selections);
  let newMid;

  if (!value) {
    yield put(ymmsSelectionCleared(field));
    return;
  }

  switch (field) {
    case 'year':
      yield spawn(
        loadYmmsMakesSaga,
        shouldValidate,
        keepSelection,
        value,
        country
      );
      break;
    case 'make':
      yield spawn(
        loadYmmsModelsSaga,
        shouldValidate,
        keepSelection,
        value,
        year,
        country
      );
      break;
    case 'model':
      if (value === FAKE_RAM_MODEL_ID) {
        yield put(ymmsSelectionCleared('model'));
        yield put(
          ymmsFieldSelected('make', RAM_MAKE_ID, shouldValidate, keepSelection)
        );
      } else {
        yield spawn(
          loadYmmsStylesSaga,
          shouldValidate,
          keepSelection,
          value,
          make,
          year,
          country
        );
      }
      break;
    case 'style':
      newMid = ymmsToMid({ year, make, model, style: value });
      yield put(midChanged(newMid));
      break;
    default:
      break;
  }
}

function* multipleYmmsFieldsSelectedSaga({ year, make, model }) {
  try {
    const { country } = yield select(state => state.adjustments.selections);
    const responses = yield call(getMultipleFields, year, make, model, country);

    if (!responses.every(r => r.success)) {
      // we want the failures in order
      if (!responses[0].success) {
        yield put(failedLoadingYmmsMakes(responses[0].payload.message));
      } else if (!responses[1].success) {
        yield put(ymmsMakesLoaded(sortBy(responses[0].payload, 'name')));
        yield put(failedLoadingYmmsModels(responses[1].payload.message));
      } else if (!responses[2].success) {
        yield put(ymmsMakesLoaded(sortBy(responses[0].payload, 'name')));
        yield put(ymmsModelsLoaded(sortBy(responses[1].payload, 'name')));
        yield put(failedLoadingYmmsStyles(responses[2].payload.message));
      }
      throw responses.find(r => !r.success);
    }

    const multiplePossibilities = {
      makes: responses[0] ? responses[0].payload : null,
      models: responses[1] ? responses[1].payload : null,
      styles: responses[2] ? responses[2].payload : null
    };

    if (dodgeNoRam(year, make)) {
      multiplePossibilities.models.push({ name: 'RAM', id: FAKE_RAM_MODEL_ID });
      multiplePossibilities.models = sortBy(
        multiplePossibilities.models,
        'name'
      );
    }

    yield put(ymmsPossibilitiesLoaded(multiplePossibilities));
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(failedLoadingYmmsPossibilities());
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  ymmsFieldSelectedSaga,
  loadYmmsYearsSaga,
  multipleYmmsFieldsSelectedSaga,
  fetchingSaga,
  loadYmmsMakesSaga,
  loadYmmsModelsSaga,
  loadYmmsStylesSaga
};
