////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import Select from 'react-select';
import { includes } from 'lodash';
import PropTypes from 'prop-types';
import 'react-select/dist/react-select.css';

/* Component */
import Option from './Option';
import Value from './Value';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const options = [
  {
    value: 'US',
    label: 'US'
  },
  {
    value: 'CA',
    label: 'CA'
  }
];

class SelectCountry extends Component {
  render() {
    const altSelectStyles = `${styles.select} ${styles.altSelect}`;
    const selectStyles = includes(['mobile', 'popup'], this.props.layout)
      ? altSelectStyles
      : styles.select;

    return (
      <span className={selectStyles}>
        <Select
          name="country-select"
          optionComponent={Option}
          options={options}
          value={this.props.country}
          valueComponent={Value}
          onChange={this.selectCountry}
          clearable={false}
          searchable={false}
          instanceId="country-select"
        />
      </span>
    );
  }

  selectCountry = country => {
    if (country !== this.props.country) {
      this.props.onSubmit('country', country);
    }
  };
}

SelectCountry.propTypes = {
  onSubmit: PropTypes.func,
  layout: PropTypes.string,
  country: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default SelectCountry;
