//////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  VIN_CHANGED,
  VIN_FULLY_DECODED,
  VIN_CHANGED_FAILURE,
  CLEAR_VIN,
  YMMS_FIELD_SELECTED,
  CLEAR_MID_AND_VIN
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = null;

function vin(state = initialState, action = {}) {
  switch (action.type) {
    case VIN_CHANGED:
      return action.vin;
    case VIN_FULLY_DECODED:
      return action.vehicle.vin;
    case CLEAR_VIN:
    case CLEAR_MID_AND_VIN:
      return initialState;
    case YMMS_FIELD_SELECTED:
      if (action.keepSelection === true) {
        return state;
      }
      return initialState;
    case VIN_CHANGED_FAILURE:
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default vin;
