////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put } from 'redux-saga/effects';
import { union, difference } from 'lodash';

/* Internal */
import {
  colorsLoadedSuccess,
  colorsLoadedFailure,
  gradesLoadedSuccess,
  gradesLoadedFailure,
  editionLoaded,
  failedLoadingEdition,
  regionsLoadedSuccess,
  regionsLoadedFailure,
  allRegionsLocationsLoaded,
  failedLoadingAllRegionsLocations
} from 'SRC/store/actions/creators';
import { REFRESH_CACHE } from 'SRC/store/actions/types';
import { getInitialResources, cacheAllItems } from 'SRC/api';
import { getCacheKeys, updateCache, trimCache } from 'SRC/api/gatewayClient';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
let initialResourceKeys = [];

function* handleLocationsAndRegions(locations, usRegions, caRegions) {
  try {
    yield* handleResponse(locations, null, null, () => {
      cacheAllItems(locations.payload);
    });
    yield* handleResponse(
      usRegions,
      regionsLoadedSuccess,
      regionsLoadedFailure,
      () => {
        cacheAllItems(usRegions.payload);
      },
      'US'
    );
    yield* handleResponse(
      caRegions,
      regionsLoadedSuccess,
      regionsLoadedFailure,
      () => {
        cacheAllItems(caRegions.payload);
      },
      'CA'
    );

    yield put(
      allRegionsLocationsLoaded(
        [locations, usRegions, caRegions].map(r => r.payload)
      )
    );
  } catch (err) {
    LOGGER.error(err);
    yield put(failedLoadingAllRegionsLocations(err));
  }
}

function* handleResponse(
  response,
  successAction,
  failureAction,
  cacheAction = () => {},
  country = undefined
) {
  try {
    if (response.success) {
      cacheAction();
      if (successAction) {
        if (country) {
          yield put(successAction(country, response.payload));
        } else {
          yield put(successAction(response.payload));
        }
      }
    } else {
      throw response.payload;
    }
  } catch (err) {
    LOGGER.error(err);
    if (failureAction) {
      yield put(failureAction());
    } else {
      throw err;
    }
  }
}

function* commonResourceLoader() {
  const [
    colors,
    grades,
    usEdition,
    caEdition,
    locations,
    usRegions,
    caRegions
  ] = yield call(getInitialResources);

  yield* handleResponse(colors, colorsLoadedSuccess, colorsLoadedFailure);
  yield* handleResponse(grades, gradesLoadedSuccess, gradesLoadedFailure);
  yield* handleResponse(
    usEdition,
    editionLoaded.bind(null, 'US'),
    failedLoadingEdition
  );
  yield* handleResponse(
    caEdition,
    editionLoaded.bind(null, 'CA'),
    failedLoadingEdition
  );
  yield* handleLocationsAndRegions(locations, usRegions, caRegions);
}

function* initialResourcesSaga() {
  /* eslint-disable no-unused-vars */ // caEdition
  yield* commonResourceLoader();
  // save cache keys so we know all current initial resources key values post expansion
  initialResourceKeys = getCacheKeys();
  LOGGER.log('initialResource cacheKeys=', initialResourceKeys.length);
}

function* refreshResourcesSaga(action = null) {
  /* eslint-disable no-unused-vars */ // caEdition
  const refreshCache = action && action.type === REFRESH_CACHE;
  if (refreshCache && action.newCacheMap) {
    initialResourceKeys = updateCurrentCacheValues(action.newCacheMap);
  }

  yield* commonResourceLoader();
  // now that we have expanded the cache we can trim all
  // non initial resource cache data so all valuations
  // are current rolling forward
  if (refreshCache) {
    trimCache(initialResourceKeys);
  }
}

function updateCurrentCacheValues(newCacheMap) {
  updateCache(newCacheMap);
  const newCacheKeys = [...newCacheMap.keys()];
  const temp = union(initialResourceKeys, newCacheKeys);
  if (temp.length !== initialResourceKeys.length) {
    LOGGER.log(
      'initialResources differ ',
      difference(temp, initialResourceKeys)
    );
  }
  // if we have added a new item then lets not trim it back out
  return temp;
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default initialResourcesSaga;
export {
  handleLocationsAndRegions,
  handleResponse,
  commonResourceLoader,
  refreshResourcesSaga,
  updateCurrentCacheValues
};
