////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  EDITION_LOADED,
  FAILED_LOADING_EDITION
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = {
  US: null,
  CA: null
};

function edition(state = initialState, action = {}) {
  switch (action.type) {
    case EDITION_LOADED:
      return action.country in state
        ? {
            ...state,
            [action.country]: action.edition
          }
        : state;
    case FAILED_LOADING_EDITION:
      return initialState;
    default:
      return state;
  }
}

function getEdition(state) {
  const { country } = state.adjustments.selections;

  if (!country) {
    return '';
  }

  return state.edition[country];
}

function getEditionDisplay(state) {
  const { country } = state.adjustments.selections;

  if (!country) {
    return '';
  }

  const countryDisplay = { US: 'US', CA: 'Canada' }[country];

  return `${state.edition[country]} ${countryDisplay} Edition`;
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default edition;
export { getEdition, getEditionDisplay };
