////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Component */
import resizable from 'SRC/components/resizable';
import CurrencyDisplay from 'SRC/components/CurrencyDisplay';
import Title from 'SRC/components/Title';
import ValueSpread from 'SRC/components/ValueSpread';
import VerticalLabel from 'SRC/components/VerticalLabel';

/* Internal */
import {
  formatConditionGrade,
  maybeCurrency,
  formatOdometer,
  formatOdometerUnits
} from 'SRC/util/formatting';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function AverageMMR({
  wholesaleValues,
  currency,
  averageOdometer,
  averageGrade,
  odometerUnits,
  error,
  viewport
}) {
  const avgOdometer = formatOdometer(averageOdometer);
  const odoUnits = formatOdometerUnits(odometerUnits);
  const mainString = wholesaleValues && wholesaleValues.average;
  const highString = maybeCurrency(
    wholesaleValues && wholesaleValues.above,
    currency,
    error
  );
  const lowString = maybeCurrency(
    wholesaleValues && wholesaleValues.below,
    currency,
    error
  );
  const avgCond = formatConditionGrade(averageGrade, true);

  return (
    <div className={`${styles.container} font--lato`} data-test="AverageMMR">
      <div className={`${styles.innerContainer}`}>
        <div className={`${styles.valuationsContainer}`}>
          <Title size="16px" printVersion="BASE">
            {viewport === 'lg' ? 'BASE' : 'BASE MMR'}
          </Title>
          <div data-test="currencyDisplay" className={styles.currencyContainer}>
            <CurrencyDisplay
              size="30px"
              value={mainString}
              currency={currency}
              error={error}
            />
          </div>
        </div>
        <div className={`${styles.odoCondContainer}`}>
          <div data-test="odometer" className={styles.odometer}>
            <VerticalLabel
              label={`Avg Odo${odoUnits ? ` (${odoUnits})` : ''}`}
              value={avgOdometer}
            />
          </div>
          <div className={styles.horizontalLine} />
          <div data-test="condition" className={styles.condition}>
            <VerticalLabel label="Avg Cond" value={avgCond} />
          </div>
        </div>
      </div>
      <div className={styles.valueSpreadContainer}>
        <div data-test="value_spread" className={styles.valueSpread}>
          <ValueSpread
            title="Typical Range"
            above={highString}
            below={lowString}
          />
        </div>
      </div>
    </div>
  );
}

// Optional, provides type-checking in development only
AverageMMR.propTypes = {
  wholesaleValues: PropTypes.object,
  currency: PropTypes.string,
  error: PropTypes.bool,
  averageOdometer: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  odometerUnits: PropTypes.string,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg']),
  averageGrade: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

const stateProps = state => ({
  error: state.errors.valuation,
  wholesaleValues: state.mostRecentLookup.wholesaleValues,
  currency: state.mostRecentLookup.currency,
  averageOdometer: state.mostRecentLookup.averageOdometer,
  averageGrade: state.mostRecentLookup.averageGrade,
  odometerUnits: state.mostRecentLookup.odometerUnits
});

const resizableAverageMMR = resizable(AverageMMR);

const AverageMMRContainer = connect(stateProps)(resizableAverageMMR);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default AverageMMRContainer;
export { AverageMMR };
