////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import { vehicleSummary as summary } from 'SRC/util/vehicleSummary';
import { formatYYYYMMDD } from 'SRC/util/formatting';
import { getEdition } from 'SRC/store/reducers/edition';
import {
  closeTransactionFilters,
  openTransactionFilters,
  cancelFilters
} from 'SRC/store/actions/creators';

/* Component */
import TransactionCount from 'SRC/components/TransactionCount';
import { onMobileApp } from 'SRC/util/cookie';

/* Local */
import FilterIcon from 'SRC/containers/FilterBox/FilterIcon';
import styles from './styles.scss';
import CSVIcon from '../CSVIcon';
import { exportCSV } from './export';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TransactionsHeader({
  loading,
  rawTransactions,
  filteredTransactions,
  visibleRows,
  odometerUnits,
  currency,
  vehicleSummary,
  edition,
  filterCount,
  isCollapsed,
  onCollapse,
  showTransactionFilters,
  appliedFilters,
  dispatchOpenTransactionFilters,
  dispatchCloseTransactionFilters,
  dispatchCancelFilters
}) {
  const canFilter = (rawTransactions ? rawTransactions.length : 0) > 0;
  const canExport =
    (filteredTransactions ? filteredTransactions.length : 0) > 0;

  const transactionCount = (
    <span
      className={`${styles.showing} ${
        visibleRows > 0 ? styles.showingWithRows : ''
      }`}
      data-test="UpperTxnCountDisplay"
    >
      <TransactionCount
        loading={loading}
        rawTransactions={rawTransactions}
        visibleRows={visibleRows}
      />
    </span>
  );

  const filterLink = (
    <a
      id="filterLinkId"
      disabled={!canFilter}
      className={`ignore--filterLink pull--right ${styles.links} ${
        canFilter ? styles.enabledFilterLink : styles.disabledFilterLink
      } ${onMobileApp() ? styles.mobileFilterLink : ''} ${
        showTransactionFilters ? styles.openedFilterLink : ''
      }`}
      onClick={handleClick.bind(this)}
    >
      <FilterIcon enabled={canFilter} />
      <span className={styles.filter_text} data-test="filterText">
        Filter
      </span>
      <span className={styles.filter_count}>
        {filterCount > 0 && ` (${filterCount})`}
      </span>
    </a>
  );

  const exportLink = (
    <a
      disabled={!canExport}
      className={`pull--right ${styles.links} ${
        canExport ? styles.enabledExportLink : styles.disabledExportLink
      }`}
      onClick={() =>
        exportCSV(
          filteredTransactions,
          odometerUnits,
          currency,
          vehicleSummary,
          edition
        )
      }
    >
      <CSVIcon enabled={canExport} />
      <span className={styles.export_text} data-test="exportText">
        Export
      </span>
    </a>
  );

  return (
    <div className={`${styles.title} ${isCollapsed ? 'print--hide' : ''}`}>
      <span
        className={`h3 ${styles.collapse}
          ${
            isCollapsed
              ? styles.filterSetTriangleUp
              : styles.filterSetTriangleDown
          }`}
        onClick={() => onCollapse()}
      >
        Transactions
      </span>
      {!isCollapsed && transactionCount}
      {!onMobileApp() && !isCollapsed && exportLink}
      {!isCollapsed && filterLink}
      <br className="clear" />
    </div>
  );

  function handleClick() {
    if (!showTransactionFilters) {
      dispatchOpenTransactionFilters();
    } else {
      dispatchCancelFilters(appliedFilters);
      dispatchCloseTransactionFilters();
    }
  }
}

TransactionsHeader.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  loading: PropTypes.bool,
  rawTransactions: PropTypes.arrayOf(PropTypes.object),
  filteredTransactions: PropTypes.arrayOf(PropTypes.object),
  filterCount: PropTypes.number,
  visibleRows: PropTypes.number,
  odometerUnits: PropTypes.string,
  currency: PropTypes.string,
  vehicleSummary: PropTypes.object,
  edition: PropTypes.string,
  appliedFilters: PropTypes.object,
  showTransactionFilters: PropTypes.bool,
  dispatchOpenTransactionFilters: PropTypes.func,
  dispatchCloseTransactionFilters: PropTypes.func,
  dispatchCancelFilters: PropTypes.func
};

const stateProps = state => ({
  loading: state.loading,
  odometerUnits: state.mostRecentLookup.odometerUnits,
  currency: state.mostRecentLookup.currency,
  vehicleSummary: summary(
    state.ymmsLookup.selections,
    state.ymmsLookup.possibilities
  ),
  edition: formatYYYYMMDD(getEdition(state)),
  rawTransactions: state.mostRecentLookup.transactions,
  appliedFilters: state.appliedFilters,
  showTransactionFilters: state.showTransactionFilters
});

const actionProps = {
  dispatchOpenTransactionFilters: openTransactionFilters,
  dispatchCloseTransactionFilters: closeTransactionFilters,
  dispatchCancelFilters: cancelFilters
};

const TransactionsHeaderContainer = connect(
  stateProps,
  actionProps
)(TransactionsHeader);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export default TransactionsHeaderContainer;
export { TransactionsHeader };
