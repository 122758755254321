////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function valueInSet(value, set, transform) {
  return set.map(v => String(transform(v))).includes(String(value));
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default valueInSet;
