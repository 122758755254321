////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put } from 'redux-saga/effects';

/* Internal */
import { userInfoLoaded } from 'SRC/store/actions/creators';
import { callRefreshEndpoint, setBearerToken } from 'SRC/api/gatewayClient';
import { EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* oauthAuthenticationSaga() {
  try {
    const response = yield call(callRefreshEndpoint);
    LOGGER.groupCollapsed('Authentication');
    LOGGER.log(
      `Authentication: logged in as ${response.userName} (${response.userId})`
    );
    LOGGER.groupEnd();

    setBearerToken(response.accessToken);
    yield put(userInfoLoaded(response));

    if (!EXTERNAL) {
      Rollbar.configure({
        payload: {
          person: {
            username: response.userId || 'dev',
            name: response.userName || 'Velocity Developer'
          }
        }
      });
    }
  } catch (e) {
    window.redirectError();
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default oauthAuthenticationSaga;
