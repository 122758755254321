////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Component */

/* Local */
import SimVehiclesComponent from './SimVehiclesComponent';
import simVehiclesAnalyticsFunctions from './simVehiclesAnalyticsFunctions';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function SimilarVehiclesCarousel({
  vehicles = [],
  oveLinkUrl = '',
  showImage = true,
  showMileage = true,
  showMMR = true,
  showYear = true,
  showYMM = true
}) {
  return vehicles.length ? (
    <SimVehiclesComponent
      vehicles={vehicles}
      oveLinkUrl={oveLinkUrl}
      showImage={showImage}
      showMileage={showMileage}
      showMMR={showMMR}
      showYear={showYear}
      showYMM={showYMM}
      analyticsCallbacks={simVehiclesAnalyticsFunctions}
    />
  ) : null;
}

SimilarVehiclesCarousel.propTypes = {
  vehicles: PropTypes.array,
  oveLinkUrl: PropTypes.string,
  showImage: PropTypes.bool,
  showMileage: PropTypes.bool,
  showMMR: PropTypes.bool,
  showYear: PropTypes.bool,
  showYMM: PropTypes.bool
};

const stateProps = state => ({
  vehicles: state.similarVehicles.vehicles,
  oveLinkUrl: state.similarVehicles.oveLinkUrl,
  showImage: state.abTestConfig.showImage,
  showMileage: state.abTestConfig.showMileage,
  showMMR: state.abTestConfig.showMMR,
  showYear: state.abTestConfig.showYear,
  showYMM: state.abTestConfig.showYMM
});

export { SimVehiclesComponent };

export default connect(stateProps)(SimilarVehiclesCarousel);
