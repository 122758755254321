////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { combineReducers } from 'redux';

/* Internal */
import {
  CLEAR_VALUATION,
  CLEAR_VIN,
  YMMS_FIELD_SELECTED,
  MID_CHANGED,
  MID_CHANGED_FAILURE,
  VALUATION_CHANGED,
  VIN_CHANGED_FAILURE,
  VIN_FULLY_DECODED,
  VIN_PARTIALLY_DECODED,
  FAILED_LOADING_YMMS_YEARS,
  FAILED_LOADING_YMMS_MAKES,
  FAILED_LOADING_YMMS_MODELS,
  FAILED_LOADING_YMMS_STYLES,
  VIN_CHANGED,
  YMMS_YEARS_LOADED,
  INVENTORY_DATA_FAILURE
} from 'SRC/store/actions/types';

import { MMR_DECODER_API_FAILURE_MSG } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialValuationError = false;

function valuation(state = initialValuationError, action = {}) {
  switch (action.type) {
    case CLEAR_VALUATION:
    case MID_CHANGED:
    case VALUATION_CHANGED:
      return false;
    case MID_CHANGED_FAILURE:
      return true;
    default:
      return state;
  }
}

const initialYMMSError = null;

function ymms(state = initialYMMSError, action = {}) {
  switch (action.type) {
    case MID_CHANGED_FAILURE:
      return action.errorMessage;
    default:
      return state;
  }
}

const initialVinError = null;

function vin(state = initialVinError, action = {}) {
  switch (action.type) {
    case CLEAR_VIN:
    case YMMS_FIELD_SELECTED:
    case VIN_FULLY_DECODED:
    case VIN_PARTIALLY_DECODED:
      return null;
    case VIN_CHANGED_FAILURE:
      return action.errorMessage;
    default:
      return state;
  }
}

const initialAPIError = null;

function api(state = initialAPIError, action = {}) {
  switch (action.type) {
    case CLEAR_VIN:
    case CLEAR_VALUATION:
    case YMMS_FIELD_SELECTED:
    case VIN_CHANGED:
      if (
        state &&
        state.MMRDecoder &&
        state.MMRDecoder.lookupType === 'YEARS'
      ) {
        return state;
      }
      return null;
    case YMMS_YEARS_LOADED:
      return null;
    case FAILED_LOADING_YMMS_YEARS:
      return {
        MMRDecoder: {
          lookupType: 'YEARS',
          message: MMR_DECODER_API_FAILURE_MSG
        }
      };
    case FAILED_LOADING_YMMS_MAKES:
      return {
        MMRDecoder: {
          lookupType: 'MAKES',
          message: MMR_DECODER_API_FAILURE_MSG
        }
      };
    case FAILED_LOADING_YMMS_MODELS:
      return {
        MMRDecoder: {
          lookupType: 'MODELS',
          message: MMR_DECODER_API_FAILURE_MSG
        }
      };
    case FAILED_LOADING_YMMS_STYLES:
      return {
        MMRDecoder: {
          lookupType: 'STYLES',
          message: MMR_DECODER_API_FAILURE_MSG
        }
      };
    case INVENTORY_DATA_FAILURE:
      return {
        SONAR: {
          type: 'listings',
          message: action.errorMessage
        }
      };
    default:
      return state;
  }
}
const errors = combineReducers({ vin, valuation, ymms, api });

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default errors;
export { valuation, vin, ymms };
