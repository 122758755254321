////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { USER_INFO_LOADED } from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = {
  bearerToken: '',
  userId: '',
  userName: ''
};

function userInfo(state = initialState, action = {}) {
  switch (action.type) {
    case USER_INFO_LOADED:
      return {
        bearerToken: action.bearerToken,
        userId: action.userId,
        userName: action.userName
      };
    default:
      return state;
  }
}

const { bearerToken, userName, userId } = userInfo;

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default userInfo;
export { bearerToken, userName, userId };
