////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  YMMS_YEARS_LOADED,
  FAILED_LOADING_YMMS_YEARS,
  YMMS_MAKES_LOADED,
  FAILED_LOADING_YMMS_MAKES,
  YMMS_MODELS_LOADED,
  FAILED_LOADING_YMMS_MODELS,
  YMMS_STYLES_LOADED,
  FAILED_LOADING_YMMS_STYLES,
  ALL_REGIONS_LOCATIONS_LOADED,
  FAILED_LOADING_ALL_REGIONS_LOCATIONS,
  YMMS_POSSIBILITIES_LOADED,
  FAILED_LOADING_YMMS_POSSIBILITIES
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function successAction(type, resultFieldName) {
  return function loadSuccess(results) {
    return {
      type,
      [resultFieldName]: results
    };
  };
}

function failureAction(type) {
  return function loadFailure(errorMessage = '') {
    return { type, errorMessage };
  };
}

const ymmsYearsLoaded = successAction(YMMS_YEARS_LOADED, 'years');
const ymmsMakesLoaded = successAction(YMMS_MAKES_LOADED, 'makes');
const ymmsModelsLoaded = successAction(YMMS_MODELS_LOADED, 'models');
const ymmsStylesLoaded = successAction(YMMS_STYLES_LOADED, 'styles');
const allRegionsLocationsLoaded = successAction(
  ALL_REGIONS_LOCATIONS_LOADED,
  'results'
);
const ymmsPossibilitiesLoaded = successAction(
  YMMS_POSSIBILITIES_LOADED,
  'results'
);

const failedLoadingYmmsYears = failureAction(FAILED_LOADING_YMMS_YEARS);
const failedLoadingYmmsMakes = failureAction(FAILED_LOADING_YMMS_MAKES);
const failedLoadingYmmsModels = failureAction(FAILED_LOADING_YMMS_MODELS);
const failedLoadingYmmsStyles = failureAction(FAILED_LOADING_YMMS_STYLES);
const failedLoadingYmmsPossibilities = failureAction(
  FAILED_LOADING_YMMS_POSSIBILITIES
);
const failedLoadingAllRegionsLocations = failureAction(
  FAILED_LOADING_ALL_REGIONS_LOCATIONS
);
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  ymmsYearsLoaded,
  failedLoadingYmmsYears,
  ymmsMakesLoaded,
  failedLoadingYmmsMakes,
  ymmsModelsLoaded,
  failedLoadingYmmsModels,
  ymmsStylesLoaded,
  failedLoadingYmmsStyles,
  allRegionsLocationsLoaded,
  failedLoadingAllRegionsLocations,
  ymmsPossibilitiesLoaded,
  failedLoadingYmmsPossibilities,
  successAction,
  failureAction
};
