const uiqTourId = MMR_ENV === 'prod' ? 2680 : 2654;

function startUserIQTour() {
  if (
    window.UserIQTour &&
    window.UserIQTour.activeTour &&
    window.UserIQTour.activeTour.cancel
  ) {
    window.UserIQTour.activeTour.cancel();
  }

  _uiq_activateCampaign(uiqTourId, 'Tour'); // eslint-disable-line no-undef
}

export { startUserIQTour };
