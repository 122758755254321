/* eslint-disable no-mixed-operators, no-restricted-properties */

////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
const MINIMUM_NEEDLE_ANGLE = -90;

function angleFor({ adjusted, average, above, below, active = true }) {
  let angle = 0;
  if (active) {
    if (adjusted === 0) {
      return MINIMUM_NEEDLE_ANGLE;
    }
    if (adjusted && average && above && below) {
      const stdDev = adjusted > average ? above - average : average - below;
      const zScore = standardScore(adjusted, average, stdDev);
      angle = 180 * percentile(zScore) - 90;
    }
  }
  return angle;
}

function standardScore(x, mean, std) {
  return (x - mean) / std;
}

// Just ask Nick, save yourself some time
function percentile(z) {
  let value;

  if (z < -8) {
    value = 0;
  } else if (z > 8) {
    value = 1;
  } else {
    const [B1, B2, B3] = [-0.0004406, 0.0418198, 0.9];
    value =
      1 /
      (1 +
        Math.pow(
          Math.E,
          -Math.sqrt(Math.PI) *
            (B1 * Math.pow(z, 5) + B2 * Math.pow(z, 3) + B3 * z)
        ));
  }

  return value;
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default angleFor;
export { percentile };
