////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { difference, uniqBy } from 'lodash';
import { select, put, cancelled } from 'redux-saga/effects';

/* Internal */
import { getTruthyFilters } from 'SRC/containers/Transactions/formatting';

import { applyFilters, removeFilter } from 'SRC/store/actions/creators';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* regionFilterSaga({ transactions }) {
  if (transactions) {
    try {
      const { selectedFilters } = yield select(state => state);

      const availableRegions = getFilters(transactions).map(t => {
        return t.id;
      });
      const candidateFilters = getTruthyFilters(selectedFilters).filters.Region;
      const missingFilters = difference(candidateFilters, availableRegions);
      for (let i = 0; i < missingFilters.length; i += 1) {
        const filter = missingFilters[i];
        yield put(removeFilter('Region', filter));
      }
      const filters = yield select(state => state.selectedFilters);
      if (filters) {
        yield put(applyFilters(filters));
      }
    } catch (err) {
      if (!(yield cancelled())) {
        LOGGER.error(err);
      }
    }
  }
}

function getFilters(transactions) {
  return transactions
    ? uniqBy(transactions.map(t => t.region), region => region.id)
    : [];
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default regionFilterSaga;
