////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';

/* Internal */
import { COLUMN_HEADERS } from 'SRC/constants';

/* Local */
import styles from '../styles.scss';
import { formatHeader } from '../formatting';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class MobileTableHeader extends Component {
  render() {
    const {
      columns,
      columnSets,
      onChangeSort,
      sortBy,
      sortOrder,
      transactions,
      odometerUnits
    } = this.props;
    const sortOrderChecked = sortOrder === 'asc';

    return (
      <div className="mui-row">
        <div className={`mui-col mui-col-1-1 ${styles.noSelect}`}>
          <div className={`mui-row ${styles.mobileSorting}`}>
            <div className="mui-col mui-col-3-4">
              <div className={`valign--middle ${styles.mobileSelect}`}>
                <span className="bold">Sort by</span>
                <select
                  data-test="MobileTxnTableSortBy"
                  disabled={transactions.length === 0}
                  onChange={() => onChangeSort(this.select.value)}
                  ref={c => (this.select = c)} //eslint-disable-line no-return-assign
                  style={{ marginLeft: '1em', width: '130px' }}
                  value={sortBy}
                >
                  {columns.map(column => (
                    <option key={column} value={column}>
                      {column}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mui-col mui-col-1-4">
              <div className={styles.mobileReverse}>
                <label htmlFor="sortOrderInput">
                  <div className="valign--middle">
                    <input
                      checked={sortOrderChecked}
                      id="sortOrderInput"
                      name="sortOrderInput"
                      onChange={() => onChangeSort(this.select.value)}
                      disabled={transactions.length < 1}
                      type="checkbox"
                    />
                    <span>Reverse</span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div className={`mui-row ${styles.mobileColumnHeaders}`}>
            <div className="mui-col mui-col-1-1">
              <div className={styles.columnKey}>
                {columnSets.map(column => (
                  <div className="mui-col mui-col-1-3" key={column}>
                    {column.map(header => (
                      <div className="mui-row" key={header}>
                        <div
                          className={`mui-col mui-col-1-1 align--left ${styles.mainDataCol}`}
                        >
                          {formatHeader(header, odometerUnits)}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MobileTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  columnSets: PropTypes.arrayOf(PropTypes.array),
  onChangeSort: PropTypes.func,
  sortBy: PropTypes.oneOf(Object.values(COLUMN_HEADERS)),
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  transactions: PropTypes.arrayOf(PropTypes.object),
  odometerUnits: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default MobileTableHeader;
