////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import { ymmsFieldSelected } from 'SRC/store/actions/creators';

/* Component */
import Dropdown from 'SRC/components/Dropdown';

/* Local */
import cssStyles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
class MakeModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showApiError: false
    };
  }

  render() {
    const { possibilities, selections, apiError } = this.props;
    const { showApiError } = this.state;
    const { years, makes, models, styles } = possibilities || {};
    const { year, make, model, style } = selections || {};
    const apiMsg = showApiError ? this.renderMessages(apiError) : '';

    return (
      <div className={`${cssStyles.makeModelContainer} mui-row`}>
        <div className={`${cssStyles.messageContainer} mui-row`}>{apiMsg}</div>
        <div className={`${cssStyles.dropdownContainer} mui-row`}>
          <Dropdown
            labelName="Year"
            possibilities={years}
            selected={year}
            onChange={this.props.dispatchFieldSelected}
            forceDisabled={this.forceDisabled('YEARS')}
            onClick={this.renderOnClick(apiError)}
          />
          <Dropdown
            labelName="Make"
            possibilities={makes}
            selected={make}
            onChange={this.props.dispatchFieldSelected}
            forceDisabled={this.forceDisabled('MAKES')}
            onClick={this.renderOnClick(apiError)}
          />
          <Dropdown
            labelName="Model"
            possibilities={models}
            selected={model}
            onChange={this.props.dispatchFieldSelected}
            forceDisabled={this.forceDisabled('MODELS')}
            onClick={this.renderOnClick(apiError)}
          />
          <Dropdown
            labelName="Style"
            possibilities={styles}
            selected={style}
            onChange={this.props.dispatchFieldSelected}
            forceDisabled={this.forceDisabled('STYLES')}
            onClick={this.renderOnClick(apiError)}
          />
        </div>
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    const { years } = nextProps.possibilities;
    const { make, model, style } = nextProps.selections;
    const { vin, successfulLookup, initialLoading, apiError } = nextProps;
    if (apiError && !initialLoading) {
      if (successfulLookup && (vin || (make && model && style))) {
        this.setState({ showApiError: false });
      } else if (!vin) {
        this.setState({ showApiError: true });
      }
    } else if (!apiError && years.length > 1) {
      this.setState({ showApiError: false });
    }
  }

  forceDisabled(_type) {
    const { showApiError } = this.state;
    return showApiError;
  }

  renderOnClick(apiError) {
    return apiError && apiError.message ? this.onClickHandler : undefined;
  }

  renderMessages(apiError) {
    if (apiError && apiError.message) {
      return (
        <div
          className={`mui-m-q-b ${cssStyles.apiErrorMsg}`}
          data-test="apiErrorMsg"
        >
          <i className="icon-notification-o mui-m-h-r" />
          {apiError.message}
          <a
            className={`${cssStyles.apiErrorRefreshLnk}`}
            onClick={this.refreshHandler}
          >
            Refresh
          </a>
        </div>
      );
    }
    return '';
  }

  refreshHandler = () => {
    window.location.reload();
  };

  onClickHandler = () => {
    this.setState({ showApiError: true });
  };
}

const idAndName = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string
});

MakeModel.propTypes = {
  possibilities: PropTypes.shape({
    years: PropTypes.arrayOf(PropTypes.number),
    makes: PropTypes.arrayOf(idAndName),
    models: PropTypes.arrayOf(idAndName),
    styles: PropTypes.arrayOf(idAndName)
  }),
  selections: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    make: PropTypes.string,
    model: PropTypes.string,
    style: PropTypes.string
  }),
  apiError: PropTypes.shape({
    lookupType: PropTypes.string,
    message: PropTypes.string
  }),
  dispatchFieldSelected: PropTypes.func,
  vin: PropTypes.string,
  successfulLookup: PropTypes.bool,
  initialLoading: PropTypes.bool
};

const stateProps = state => ({
  possibilities: state.ymmsLookup.possibilities,
  selections: state.ymmsLookup.selections,
  apiError: state.errors.api ? state.errors.api.MMRDecoder : null,
  vin: state.vin,
  initialLoading: state.initialLoading,
  successfulLookup: state.mostRecentLookup.successfulLookup
});

const actionProps = {
  dispatchFieldSelected: ymmsFieldSelected
};

const MakeModelContainer = connect(
  stateProps,
  actionProps
)(MakeModel);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default MakeModelContainer;
export { MakeModel };
