////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { NULL_VALUE } from 'SRC/constants';
import { truthyOrZero } from 'SRC/util/helperUtils';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function possibilityWithValue(possibilities, value) {
  return possibilities.find(possibility => valueFor(possibility) == value); // eslint-disable-line eqeqeq
}

function displayTextFor(possibility, labelName) {
  const prefix = ['Region', 'Grade', 'Ext Color'].includes(labelName)
    ? '\u00a0'
    : '';

  if (typeof possibility === 'object') {
    if (possibility.name) {
      return prefix + possibility.name;
    }
    if (possibility.value) {
      const { value } = possibility;
      return prefix + value;
    }
  }

  return prefix + possibility;
}

function valueFor(possibility) {
  if (possibility && typeof possibility === 'object') {
    if (possibility.id) {
      return possibility.id;
    }
    if (possibility.value) {
      // eslint-disable-next-line no-restricted-globals
      if (!isNaN(possibility.value)) {
        return possibility.value * 10;
      }
      return possibility.value;
    }
  }

  return possibility;
}

function Dropdown({
  labelName,
  possibilities,
  selected,
  onChange,
  forceDisabled = false,
  hasLabel,
  onClick = undefined
}) {
  const disabled =
    forceDisabled || !possibilities || possibilities.length === 0;
  const options = possibilities
    ? possibilities.map((possibility, i) => {
        return (
          <option key={i} value={valueFor(possibility)}>
            {displayTextFor(possibility, labelName)}
          </option>
        );
      })
    : [];
  // onClick represents an apiError which is displayed in MakeModel
  // if onClick is passed we convert onChange to a no-op function
  const onChangeValue = onClick
    ? () => {}
    : e =>
        onChange(
          formatLabel(labelName.toLowerCase()),
          e.target.value,
          shouldValidate
        );
  const label = labelFor(labelName);
  const shouldValidate = Boolean(labelName !== 'Style');

  return (
    <div className={`${styles.select} mui-col ${muiColFor(labelName)}`}>
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      {hasLabel && <label htmlFor={labelName}>{label}</label>}
      <div className="hide print--show--block mui-m-b-h">
        <strong className="mui-m-l">
          {displayTextFor(possibilityWithValue(possibilities, selected)) ||
            NULL_VALUE}
        </strong>
      </div>
      <select
        className="print--hide"
        id={labelName}
        disabled={disabled}
        value={truthyOrZero(selected) ? selected : ''}
        onChange={onChangeValue}
        onClick={onClick}
      >
        {defaultOption(labelName)}
        {options}
      </select>
    </div>
  );
}

// downstream actions depend upon labelNames (ie, 'color' label fires 'color' actions)
// formatLabel allows decoupling of labelName from actions
function formatLabel(labelName) {
  switch (labelName) {
    case 'Ext Color':
    case 'ext color':
      return 'color';
    default:
      return labelName;
  }
}

function muiColFor(labelName) {
  switch (labelName) {
    case 'Region':
    case 'Ext Color':
    case 'Grade':
      return 'mui-col-1-1';
    case 'Year':
      return 'mui-col mui-col-1-1 mui-col-1-2-sm mui-col-3-24-md';
    case 'Make':
      return 'mui-col mui-col-1-1 mui-col-1-2-sm mui-col-5-24-md';
    case 'Model':
    case 'Style':
      return 'mui-col mui-col-1-1 mui-col-1-2-sm mui-col-8-24-md';
    default:
      return 'mui-col-1-2-sm mui-col-1-1-xs mui-col-1-1';
  }
}

function defaultOption(labelName) {
  switch (labelName) {
    case 'Year':
    case 'Make':
    case 'Model':
    case 'Style':
      return <option value="">{labelName}</option>;
    case 'Grade':
      return <option value="">&nbsp;AutoGrade</option>;
    case 'Region':
    case 'Ext Color':
      return (
        <option value="">
          &nbsp;
          {labelName}
        </option>
      );
    default:
      return <option value="">{labelName}</option>;
  }
}

function labelFor(labelName) {
  switch (labelName) {
    case 'Grade':
      return 'Condition Grade';
    default:
      return labelName;
  }
}

Dropdown.defaultProps = {
  possibilities: []
};

Dropdown.propTypes = {
  labelName: PropTypes.string,
  onChange: PropTypes.func,
  possibilities: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  forceDisabled: PropTypes.bool,
  hasLabel: PropTypes.bool,
  onClick: PropTypes.func
};

export default Dropdown;
