////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, pickBy } from 'lodash';

/* Internal */
import { maybeCurrency } from 'SRC/util/formatting';
import { adjustmentsFootnote, truthyOrZero } from 'SRC/util/helperUtils';

/* Component */
import LoadingContent from 'SRC/components/LoadingContent';
import ValueSpread from 'SRC/components/ValueSpread';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function RetailDisplay({
  adjustedValues,
  adjustedBy,
  selections,
  values,
  currency,
  loading,
  error
}) {
  const mainString = maybeCurrency(
    adjustedValues && adjustedValues.average,
    currency,
    error
  );
  const highString = maybeCurrency(values && values.above, currency, error);
  const lowString = maybeCurrency(values && values.below, currency, error);
  const { average } = Object(values);
  const notAdjustedMessage = adjustmentsFootnote(adjustedBy, selections);
  const { region, color, grade, odometer } = selections;
  const adjustmentRequested = !isEmpty(
    pickBy({ region, color, grade, odometer }, truthyOrZero)
  );
  const valuationAvailable = !!average;
  const showAdjusted = adjustmentRequested && valuationAvailable;

  return (
    <div className="font--lato" data-test="RetailDisplay">
      <h3 className={`mui-m-h-b mui-m-t ${styles.title}`}>
        Estimated Retail Value
        <div className={styles.subHeading}>
          Based on Advertised Retail Prices
        </div>
      </h3>
      <LoadingContent loading={loading}>
        <div className="retailDisplay">
          <div className="mui-col mui-col-1-2-xs mui-col-8-21-sm mui-col-1-3-md">
            <div className="mui-row">
              <div
                data-test="MMRValueAverage"
                className={`${styles.retail} mui-col mui-col-1-1`}
              >
                {mainString}
                {showAdjusted && notAdjustedMessage && '*'}
              </div>
              <div className={`${styles.range} mui-col mui-col-1-1`}>
                <ValueSpread
                  title="Typical Range"
                  above={highString}
                  below={lowString}
                  currencySize="1.125em"
                />
                {notAdjustedMessage && showAdjusted && (
                  <div
                    className={`${styles.footnote}`}
                    data-test="MMRNotAdjustedFooter"
                  >
                    {notAdjustedMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </LoadingContent>
    </div>
  );
}

// Optional, provides type-checking in development only
RetailDisplay.propTypes = {
  adjustedValues: PropTypes.shape({
    above: PropTypes.number,
    average: PropTypes.number,
    below: PropTypes.number
  }),
  adjustedBy: PropTypes.object,
  values: PropTypes.shape({
    above: PropTypes.number,
    average: PropTypes.number,
    below: PropTypes.number
  }),
  selections: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    make: PropTypes.string,
    model: PropTypes.string,
    style: PropTypes.string,
    region: PropTypes.string,
    color: PropTypes.string,
    grade: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    odometer: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  currency: PropTypes.string,
  loading: PropTypes.bool,
  error: PropTypes.bool
};
// Given the store's state, what props does this component care about?
// adjustedPricing.retail always shows the same value as retailValues or in case of an adjustment, the adjusted retail
const stateProps = state => ({
  values: state.mostRecentLookup.retailValues,
  adjustedBy: state.mostRecentLookup.adjustedPricing.adjustedBy,
  selections: state.adjustments.selections,
  adjustedValues: state.mostRecentLookup.adjustedPricing.retail,
  currency: state.mostRecentLookup.currency,
  loading: state.loading,
  error: state.errors.valuation
});

// Create a container component that can be rendered with a store
const RetailDisplayContainer = connect(stateProps)(RetailDisplay);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default RetailDisplayContainer;
export { RetailDisplay };
