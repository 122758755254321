////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function resizable(Wrapped) {
  return class Resizable extends Component {
    displayName = `resizable(${Wrapped.displayName || Wrapped.name})`;

    state = { viewport: null };

    render() {
      return <Wrapped viewport={this.state.viewport} {...this.props} />;
    }

    componentWillMount() {
      this.handleResize();
      window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }

    handleResize = () => {
      const size = window.innerWidth;
      const currentViewport = this.state.viewport;
      let viewport = currentViewport;

      if (size < 568) {
        viewport = 'sm';
      } else if (size >= 568 && size < 768) {
        viewport = 'md';
      } else {
        viewport = 'lg';
      }

      if (currentViewport !== viewport) {
        this.setState({ viewport });
      }
    };
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default resizable;
