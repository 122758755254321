////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Color({ disabled }) {
  return (
    <svg
      version="1.1"
      id="color"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 15 15"
      className={`${styles.iconClass} ${disabled ? styles.iconDisabled : ''}`}
      style={{ enableBackground: 'new 0 0 15 15' }}
      xmlSpace="preserve"
    >
      <title>Colors</title>
      <path
        className={`${styles.icon} ${disabled ? styles.disabled : ''}`}
        d="M12.2,6.9c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.1,0.8,0.1,1.2c0,0.4-0.1,0.8-0.1,1.2c-0.1,0.4-0.3,0.8-0.5,1.1
        c-0.2,0.4-0.5,0.8-0.8,1.1c-0.6,0.7-1.4,1.2-2.3,1.4c-0.9,0.3-1.9,0.3-2.8,0c-0.9-0.3-1.7-0.7-2.3-1.4c-0.3-0.3-0.6-0.7-0.8-1.1
        c-0.2-0.4-0.3-0.7-0.4-1.1C2.5,10,2.4,9.6,2.4,9.2c0-0.4,0-0.8,0.1-1.2c0.1-0.4,0.2-0.8,0.3-1.2C3,6.3,3.3,5.7,3.6,5.1
        C4,4.6,4.4,4,4.8,3.5C5.2,3,5.6,2.5,6.1,2C6.6,1.5,7,1,7.5,0.6C8,1,8.5,1.5,9,2c0.5,0.5,0.9,1,1.3,1.5c0.4,0.5,0.8,1.1,1.1,1.7
        C11.7,5.7,12,6.3,12.2,6.9z M10.5,10.7c0.3-0.5,0.4-1.1,0.4-1.7c0-0.5-0.1-1-0.3-1.5c-0.1-0.2-0.1-0.4-0.2-0.6l-0.3-0.6
        c0.1,0.5,0.2,1,0.1,1.6c0,0.6-0.2,1.2-0.5,1.7c-0.2,0.3-0.4,0.7-0.7,0.9c-0.3,0.3-0.6,0.5-0.9,0.7c-0.3,0.2-0.7,0.4-1.1,0.5
        c-0.4,0.1-0.8,0.2-1.2,0.2H5.8H5.5c0.3,0.2,0.6,0.4,1,0.5c0.3,0.1,0.7,0.2,1.1,0.2c0.6,0,1.2-0.2,1.7-0.6
        C9.8,11.7,10.2,11.3,10.5,10.7L10.5,10.7z"
      />
    </svg>
  );
}

Color.propTypes = {
  disabled: PropTypes.bool
};

export default Color;
