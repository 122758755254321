function numTruthyKeys(obj) {
  return Object.values(obj).filter(Boolean).length;
}

function numPopulatedArrays(obj) {
  return Object.values(obj).filter(arr => arr.length > 0).length;
}

function relevantPossibilities(state) {
  const ymmsPossibilities = state.ymmsLookup.possibilities;
  const vinPossibilities = state.vinRefinement.possibilities;

  if (
    numPopulatedArrays(ymmsPossibilities) > numPopulatedArrays(vinPossibilities)
  ) {
    return ymmsPossibilities;
  }
  return vinPossibilities;
}

function relevantSelections(state) {
  const ymmsSelections = state.ymmsLookup.selections;
  const vinSelections = state.vinRefinement.selections;

  if (numTruthyKeys(ymmsSelections) > numTruthyKeys(vinSelections)) {
    return ymmsSelections;
  }
  return vinSelections;
}

function vehicleSummary(selections, possibilities) {
  const nameFor = field => {
    const list = possibilities[`${field}s`];
    const found = list.find(p => p.id === selections[field]);
    return found ? found.name : '';
  };

  return {
    year: selections.year,
    make: nameFor('make'),
    model: nameFor('model'),
    style: nameFor('style')
  };
}

export { vehicleSummary, relevantSelections, relevantPossibilities };
