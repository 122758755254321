import { EXTERNAL } from 'SRC/constants';
import { titleize } from 'SRC/util/formatting';

function repeat(something, n) {
  return Array.from(Array(n).keys()).map(_ => something);
}

function hasProp(obj, property) {
  // To avoid eslint's no-negated-in-lhs error
  return property in obj;
}

// Because `typeof null` is a troll
function isObject(val) {
  return typeof val === 'object' && val !== null;
}

function notEnoughData(values) {
  return values && typeof values === 'object'
    ? !values.above && !values.average && !values.below
    : false;
}

function defaultNotSelected(name, value) {
  if (name === 'Region') {
    return value !== 'NA' && value !== null && value !== '';
  }
  return truthyOrZero(value);
}

function adjustmentDisplayValue(adjustment) {
  return adjustment === 'Grade' ? 'Condition' : adjustment;
}

function replaceFleetWithFactory(transactions = []) {
  return transactions.map(transaction => ({
    ...transaction,
    saleType:
      transaction.saleType === 'Fleet' ? 'Factory' : transaction.saleType
  }));
}

function adjustmentsFootnote(adjustedBy, selections) {
  const missingAdjustments = ['Region', 'Odometer', 'Grade', 'Color'].filter(
    adjustment =>
      selections &&
      defaultNotSelected(adjustment, selections[adjustment.toLowerCase()]) &&
      adjustedBy &&
      ((adjustedBy[adjustment] &&
        !adjustedBy[`${adjustment}AdjustmentValue`]) ||
        String(adjustedBy[adjustment]).toLowerCase() !==
          String(selections[adjustment.toLowerCase()]).toLowerCase())
  );
  const adjustmentsToDisplay = missingAdjustments.map(adjustmentDisplayValue);
  const missingList =
    missingAdjustments.length === 2
      ? adjustmentsToDisplay.join(' or ')
      : adjustmentsToDisplay.join(', ').replace(/,(?![^,]*,)/, ', or');

  return adjustmentsToDisplay.length > 0
    ? `*Not adjusted for ${missingList}`
    : '';
}

function truthyOrZero(value) {
  return value === 0 || Boolean(value); //eslint-disable-line eqeqeq
}

function decimalCondition(grade) {
  const asNumber = parseInt(grade);
  if (asNumber === 0) return 0;
  return asNumber ? String(asNumber) / 10 : null;
}

function titleizeColor(color) {
  if (color && typeof color === 'string') {
    return titleize(color);
  }
  return color;
}

function validateCountry(country) {
  if (country) {
    if (country.toUpperCase().includes('US')) {
      return 'US';
    }
    if (country.toUpperCase().includes('CA')) {
      return 'CA';
    }
  } else {
    return 'US';
  }
}

function validateRegion(region) {
  if (region) {
    return region.toUpperCase();
  }
}

function validateCondGrade(condition) {
  if (condition) {
    const value = condition * 10;
    if (value >= 0 && value <= 50) {
      return condition;
    }
    if (!EXTERNAL) {
      Rollbar.info('Invalid condition', { condition });
    }
  }
  return undefined;
}

function validateMileage(mileage) {
  if (mileage) {
    const withoutCommas = mileage.trim().replace(/,/g, '');

    if (/\D/.test(withoutCommas)) {
      if (!EXTERNAL) {
        Rollbar.info('Invalid mileage', { mileage });
      }
      return undefined;
    }
    return withoutCommas;
  }

  return undefined;
}

function wholesaleAverage(adjustmentValue) {
  return adjustmentValue
    ? Object(adjustmentValue.wholesale).average
    : undefined;
}

function getSelections(state) {
  return state.adjustments.selections;
}

function isEmptyNullUndefinedString(string) {
  return (
    string === undefined || string === null || string.toString().trim() === ''
  );
}

function setAnalyticsCode(vehicle) {
  try {
    const { above, average, below } = vehicle.wholesale;
    // if vehicle has no valuation, send 'mmr_novalue'
    if (above === 0 && average === 0 && below === 0) {
      return 'mmr_novalue';
    }
    return 'mmr_single';
  } catch (error) {
    return null;
  }
}

function setSearchTypeForAnalytics(analyticsCode) {
  window.webData = window.webData || Object.create(null);
  window.webData.searchType = analyticsCode;
}

function setContextCustomClickNameForAnalytics(clickName) {
  window.contextData = window.contextData || Object.create(null);
  window.contextData.customClickName = clickName;
}

function isAllowableListing(vehicleUniqueId) {
  return (
    vehicleUniqueId &&
    typeof vehicleUniqueId === 'string' &&
    (vehicleUniqueId.startsWith('OVE') || vehicleUniqueId.startsWith('RMS'))
  );
}

function validateVehicleUniqueId(value) {
  if (value) {
    // m.com is sending us OVE.AAAI.102625845/secureredirect?product=VVT
    return value.split('/')[0];
  }
  return value;
}

function hasQueryParams(query) {
  const keys = Object.keys(query);
  return (
    keys.includes('color') ||
    keys.includes('condition') ||
    keys.includes('mileage') ||
    keys.includes('country') ||
    keys.includes('vin')
  );
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  repeat,
  hasProp,
  isObject,
  notEnoughData,
  adjustmentsFootnote,
  truthyOrZero,
  decimalCondition,
  validateCondGrade,
  wholesaleAverage,
  replaceFleetWithFactory,
  getSelections,
  isEmptyNullUndefinedString,
  setAnalyticsCode,
  setSearchTypeForAnalytics,
  setContextCustomClickNameForAnalytics,
  titleizeColor,
  validateMileage,
  validateRegion,
  validateCountry,
  isAllowableListing,
  validateVehicleUniqueId,
  hasQueryParams
};
