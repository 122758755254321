////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { HYDRATE_STATE } from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function hydrateState(state) {
  return {
    type: HYDRATE_STATE,
    state
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { hydrateState };
