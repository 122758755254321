////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put, select } from 'redux-saga/effects';

/* Internal */
import { getRegions } from 'SRC/api';
import {
  regionsLoadedSuccess,
  regionsLoadedFailure,
  adjustmentSelected,
  adjustmentCleared,
  adjustmentChanged
} from 'SRC/store/actions/creators';
import valueInSet from 'SRC/util/valueInSet';
import { loadYmmsYearsSaga } from './ymmsFieldSelected';
import vinChangedSaga from './vinChanged';
import { getQueryParams } from './url/browserHistory';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* loadRegionsSaga(country = 'US') {
  try {
    const regions = yield call(getRegions, country);
    if (regions.success) {
      yield put(regionsLoadedSuccess(country, regions.payload));
      return regions.payload;
    }

    throw regions.payload;
  } catch (err) {
    LOGGER.error(err);
    yield put(regionsLoadedFailure());
    return [];
  }
}

function* adjustmentSelectedSaga({ field, value }) {
  const { adjustments, mostRecentLookup, vin } = yield select(state => state);
  const { colors, grades, regions } = adjustments.possibilities;
  const { country } = adjustments.selections;

  // If the country has changed, get new regions
  if (field === 'country') {
    if (['US', 'CA'].includes(value)) {
      yield put(adjustmentCleared('region'));
      // load regions only if we have none for this country
      const filteredRegions = regions.filter(
        region => region.country === value
      );
      if (filteredRegions.length === 0) {
        yield call(loadRegionsSaga, value);
      }
    } else {
      yield put(adjustmentSelected('country', 'US'));
      return;
    }

    if (vin) {
      yield call(vinChangedSaga, { vin });
    }

    // D-12533 loadYmmsYearsSaga needs to happen no matter what
    // since the call was removed from mainSaga it should happen unconditionally here
    const { mid: midParam, vin: vinParam } = yield call(getQueryParams);
    const shouldValidate =
      (!midParam && !vinParam) || Boolean(mostRecentLookup.mid);
    yield call(loadYmmsYearsSaga, shouldValidate, true, value);
  } else if (field === 'color') {
    const validColor = valueInSet(value, colors, c => c.value);
    if (value && !validColor) {
      yield put(adjustmentCleared('color'));
    }
  } else if (field === 'grade') {
    const validGrade = valueInSet(value, grades, g => g.value);
    if (value && !validGrade) {
      yield put(adjustmentCleared('grade'));
    }
  } else if (field === 'region') {
    const filteredRegions = regions.filter(
      region => region.country === country
    );
    const validRegion = valueInSet(value, filteredRegions, r => r.id);
    if (value && !validRegion) {
      yield put(adjustmentCleared('region'));
    }
  }
  const { successfulLookup, mid } = yield select(
    state => state.mostRecentLookup
  );
  if (successfulLookup) {
    yield put(adjustmentChanged(mid, field));
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { adjustmentSelectedSaga, loadRegionsSaga };
