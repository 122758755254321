/* eslint-disable jsx-a11y/href-no-hash */

////////////////////////////////////////////////////////////////////////////////

import '@babel/polyfill';
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Component */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { abTestConfigChanged } from 'SRC/store/actions/creators';
import VehicleSummary from 'SRC/containers/VehicleSummary';
import SelectVehicle from 'SRC/containers/SelectVehicle';
import RetailDisplay from 'SRC/containers/RetailDisplay';
import Transactions from 'SRC/containers/Transactions';
import HistoricalAverage from 'SRC/containers/HistoricalAverage';
import ProjectedAverage from 'SRC/containers/ProjectedAverage';
import VspLink from 'SRC/containers/VspLink';
import VehicleInfoLinks from 'SRC/containers/VehicleInfoLinks';
import Heading from 'SRC/containers/Heading';
import MultiDecodeModal from 'SRC/containers/MultiDecodeModal';
import InventoryModal from 'SRC/containers/InventoryModal';
import LookupWizard from 'SRC/containers/LookupWizard';
import MobileLoader from 'SRC/containers/MobileLoader';
import MMRHorizontalBar from 'SRC/components/MMRHorizontalBar';
import Valuations from 'SRC/containers/Valuations';
import SimilarVehiclesCarousel from 'SRC/containers/SimilarVehiclesCarousel';
import { startUserIQTour } from 'SRC/misc/user_iq';
import styles from './app.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function App({ onMobileApp, dispatchABTestConfigChange }) {
  // expose function for optimizely to insert a configuration object
  // note: object is of arbitrary shape and size
  window.setSplitTestConfig = dispatchABTestConfigChange;
  // TODO store.dispatch('UPDATE_SPLIT_TEST_CONFIG', config) or whatever you do
  return (
    <div data-mmr-component className="mui-row align--center">
      <div className="mui-col mui-col-1-1 outer-container align--left print--show--block float--none show--inline-block">
        <div className="mui-row print--show--block">
          <div className={`mui-col mui-col-1-1 ${styles.bodyContainer}`}>
            <div className="mui-row print--show--block ">
              <div className="mui-col mui-col-1-1 print--show--block">
                <Heading />
              </div>
            </div>
            <div className="mui-row">
              <div className="mui-col mui-col-1-1">
                <div className="left-nav">
                  <div className="mui-row print--hide">
                    <div className="mui-col mui-col-1-1">
                      <LookupWizard />
                      <MobileLoader />
                      <SelectVehicle />
                      <MultiDecodeModal />
                      <InventoryModal />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mui-row page-gutter hide-when-wizard-present">
              <div
                id="subnav"
                className="mui-col mui-col-4-4 mui-p-tb align--right link print--hide"
              >
                {!onMobileApp && <TourLink />}
                {!onMobileApp && <LearnMoreLink />}
                {!onMobileApp && <PrintLink />}
              </div>
              <div className="mui-row">
                <div className="mui-col mui-col-2-3 mui-p-t">
                  <VehicleSummary />
                  {!onMobileApp && <VehicleInfoLinks />}
                </div>
                {onMobileApp && <VspLink />}
              </div>
              <div className="mui-col mui-col-1-1 print--show--block">
                <div className="content-body">
                  <div className="mui-row">
                    <MMRHorizontalBar />
                    <Valuations />
                  </div>
                  <div className="mui-row">
                    <SimilarVehiclesCarousel />
                  </div>
                  <div className="mui-row">
                    <div className="mui-col mui-col-1-1 print--show--block">
                      <Transactions />
                    </div>
                    <div className="spacer" />
                  </div>
                  <div className="mui-row">
                    <div className="mui-col mui-col-1-1 mui-col-1-2-sm">
                      <HistoricalAverage />
                    </div>
                    <div
                      className={`mui-col mui-col-1-1 ${styles.hideDivider}`}
                    >
                      <div className="dynamic-rule average" />
                    </div>
                    <div
                      className={`mui-col mui-col-1-1 mui-col-1-2-sm ${styles.projectedAverage}`}
                    >
                      <ProjectedAverage />
                    </div>
                    <div className="mui-col mui-col-1-1">
                      <div className="dynamic-rule average" />
                    </div>
                    <div className="mui-col mui-col-1-1">
                      <RetailDisplay />
                    </div>
                    <div className="spacer print--hide" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function LearnMoreLink() {
  return (
    <a
      id="under-the-hood"
      target="_blank"
      rel="noopener noreferrer"
      href="https://publish.manheim.com/en/help/mmr/mmr-under-the-hood.html"
      className={`${styles.learnLink} mui-control--link`}
      title="Learn More"
    >
      <i className="icon-question mui-m-h-r" />
      <span>Learn More</span>
    </a>
  );
}

function TourLink() {
  return (
    <a
      id="guided-tour"
      href="#"
      onClick={startUserIQTour}
      className={`${styles.tourLink} mui-control--link`}
      title="Take a Tour"
    >
      <i className="icon-direction mui-m-h-r" />
      <span />
    </a>
  );
}

function PrintLink() {
  return (
    <a
      href="#"
      onClick={window.print}
      className={`${styles.printLink} mui-control--link`}
      data-test="MMRPrintButton"
      title="Print"
    >
      <i className="icon-printer mui-m-h-r" />
      <span>Print</span>
    </a>
  );
}

App.propTypes = {
  onMobileApp: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  dispatchABTestConfigChange: PropTypes.func
};

const actionProps = {
  dispatchABTestConfigChange: abTestConfigChanged
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export default connect(
  null,
  actionProps
)(App);
export { PrintLink, TourLink, LearnMoreLink, App };
