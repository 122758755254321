////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { createHistory, createMemoryHistory, useQueries } from 'history';

/* Internal */
import { EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
const browserHistory = initializeHistory();

function getHistory() {
  return browserHistory;
}

function getQueryParams() {
  return browserHistory.getCurrentLocation().query;
}

function initializeHistory(override) {
  if (override) {
    return override;
  }

  if (window.name === 'nodejs' || EXTERNAL) {
    return useQueries(createMemoryHistory)();
  }

  return useQueries(createHistory)();
}

export { getHistory, getQueryParams, initializeHistory };
