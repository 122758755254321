////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { orderBy, pickBy, isEmpty, capitalize } from 'lodash';
import { connect } from 'react-redux';

/* Internal */
import resizable from 'SRC/components/resizable';
import {
  adjustmentSelected,
  allAdjustmentsCleared
} from 'SRC/store/actions/creators';
import { formatConditionGrade } from 'SRC/util/formatting';
import { truthyOrZero, wholesaleAverage } from 'SRC/util/helperUtils';
import { NUMBER_DISCLAIMER } from 'SRC/constants';

/* Component */
import Dropdown from 'SRC/components/Dropdown';
import Title from 'SRC/components/Title';
import AdjustmentInput from 'SRC/components/AdjustmentInput';
import OdometerEntry from './OdometerEntry';

/* Local */
import ColorSVG from './assets/color';
import GradeSVG from './assets/grade';
import RegionSVG from './assets/region';
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function getRegionName(regions, region) {
  return regions.length > 0
    ? Object(regions.find(reg => reg.id === region)).name
    : null;
}

function Adjustments({
  possibilities = {},
  dispatchAdjustmentSelected,
  dispatchAllAdjustmentsCleared,
  mid,
  loading,
  adjustmentsCompleted,
  wholesaleValuesAverage,
  adjustedBy,
  currency,
  odometerUnits,
  viewport,
  country
}) {
  const {
    Region,
    RegionAdjustmentValue,
    Color,
    ColorAdjustmentValue,
    Grade,
    GradeAdjustmentValue,
    Odometer,
    OdometerAdjustmentValue
  } = Object(adjustedBy);
  // OdometerAdjustmentValue is only delta value that is a string
  const odometerDelta = OdometerAdjustmentValue
    ? parseInt(OdometerAdjustmentValue)
    : null;
  const regionDelta = wholesaleAverage(RegionAdjustmentValue);
  const colorDelta = wholesaleAverage(ColorAdjustmentValue);
  const gradeDelta = wholesaleAverage(GradeAdjustmentValue);
  const { colors, grades, regions } = possibilities;
  const filteredRegions = regions.filter(region => region.country === country);
  const noValuation = !wholesaleValuesAverage;
  const selectedRegion = Region && getRegionName(filteredRegions, Region);
  const decimalGrades = [];
  if (grades && grades.length > 0) {
    grades.forEach((g, i) => {
      decimalGrades[i] = {};
      decimalGrades[i].value = (g.value / 10).toFixed(1);
    });
  }
  const reversedGrades =
    grades &&
    grades.length > 0 &&
    decimalGrades &&
    orderBy(decimalGrades, 'value', 'desc');
  const possibleGrades = reversedGrades || grades;
  const adjustmentsPresent = !isEmpty(
    pickBy({ Color, Grade, Region, Odometer }, truthyOrZero)
  );
  // If there are any adjustments then show the disclaimer
  const disclaimerText =
    adjustmentsPresent && wholesaleValuesAverage ? NUMBER_DISCLAIMER : '';
  const sortedColors =
    (colors && colors.length > 0 && orderBy(colors, 'value', 'asc')) || colors;
  const regionIcon = (
    <RegionSVG
      disabled={noValuation}
      iconClass={styles.iconClass}
      disabledClass={styles.iconDisabled}
    />
  );
  const colorIcon = (
    <ColorSVG
      disabled={noValuation}
      iconClass={styles.iconClass}
      disabledClass={styles.iconDisabled}
    />
  );
  const gradeIcon = (
    <GradeSVG
      disabled={noValuation}
      iconClass={styles.iconClass}
      disabledClass={styles.iconDisabled}
    />
  );

  return (
    <div
      className={`mui-col mui-col-1-1 ${styles.container}`}
      data-test="Adjustments"
    >
      <div className="mui-row mui-m-q-t">
        <div className="mui-col mui-col-1-1">
          <div className="show--inline-block">
            <Title size="16px" printVersion="ADJUSTMENTS">
              {viewport === 'lg' ? 'ADJUSTMENTS' : 'MMR ADJUSTMENTS'}
            </Title>
          </div>
          <div className="show--inline-block pull--right print--hide">
            <ClearLink
              text="Clear"
              onClick={dispatchAllAdjustmentsCleared}
              disabled={noValuation}
            />
          </div>
        </div>
      </div>
      <AdjustmentRow>
        <OdometerEntry
          mid={mid}
          odometer={Odometer}
          odometerUnits={odometerUnits}
          onSubmit={dispatchAdjustmentSelected}
          forceDisabled={noValuation}
          loading={loading}
          delta={odometerDelta}
          currency={currency}
          adjustmentsCompleted={adjustmentsCompleted}
          showNullState={nullState(Odometer, OdometerAdjustmentValue)}
        />
      </AdjustmentRow>
      <AdjustmentRow>
        <AdjustmentInput
          image={regionIcon}
          title="Region"
          printValue={selectedRegion}
          delta={regionDelta}
          currency={currency}
          disabled={noValuation}
          loading={loading}
          adjustmentsCompleted={adjustmentsCompleted}
          showNullState={nullState(Region, RegionAdjustmentValue)}
        >
          <Dropdown
            labelName="Region"
            possibilities={filteredRegions}
            selected={Region}
            onChange={dispatchAdjustmentSelected}
            forceDisabled={noValuation}
          />
        </AdjustmentInput>
      </AdjustmentRow>
      <AdjustmentRow>
        <AdjustmentInput
          image={gradeIcon}
          title="AutoGrade"
          printValue={truthyOrZero(Grade) && formatConditionGrade(Grade)}
          delta={gradeDelta}
          currency={currency}
          disabled={noValuation}
          loading={loading}
          adjustmentsCompleted={adjustmentsCompleted}
          showNullState={nullState(Grade, GradeAdjustmentValue)}
        >
          <Dropdown
            labelName="Grade"
            possibilities={possibleGrades}
            selected={Grade}
            onChange={dispatchAdjustmentSelected}
            forceDisabled={noValuation}
          />
        </AdjustmentInput>
      </AdjustmentRow>
      <AdjustmentRow>
        <AdjustmentInput
          image={colorIcon}
          title="Ext Color"
          printValue={Color}
          delta={colorDelta}
          currency={currency}
          disabled={noValuation}
          loading={loading}
          adjustmentsCompleted={adjustmentsCompleted}
          showNullState={nullState(Color, ColorAdjustmentValue)}
        >
          <Dropdown
            labelName="Ext Color"
            possibilities={sortedColors}
            selected={capitalize(Color)}
            onChange={dispatchAdjustmentSelected}
            forceDisabled={noValuation}
          />
        </AdjustmentInput>
      </AdjustmentRow>
      <div
        className={`mui-row ${styles.disclaimer}`}
        data-test="AdjustmentDisclaimer"
      >
        <div className="mui-col mui-col-1-1">{disclaimerText}</div>
      </div>
    </div>
  );
}

function nullState(adjustmentSelection, adjustmentsObject) {
  return Boolean(adjustmentSelection) && !adjustmentsObject;
}

function AdjustmentRow({ children }) {
  return <div className={`mui-row ${styles.adjustment}`}>{children}</div>;
}

function ClearLink({ onClick, text, disabled }) {
  return (
    <button
      type="button"
      className={`${styles.clearAdjustment} align--right`}
      data-test="ClearLink"
      onClick={disabled ? '' : onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}
AdjustmentRow.propTypes = {
  children: PropTypes.object
};

ClearLink.propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string,
  disabled: PropTypes.bool
};

Adjustments.propTypes = {
  possibilities: PropTypes.object,
  dispatchAdjustmentSelected: PropTypes.func,
  dispatchAllAdjustmentsCleared: PropTypes.func,
  mid: PropTypes.string,
  wholesaleValuesAverage: PropTypes.number,
  loading: PropTypes.bool,
  adjustedBy: PropTypes.object,
  currency: PropTypes.string,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg']),
  odometerUnits: PropTypes.string,
  adjustmentsCompleted: PropTypes.bool,
  country: PropTypes.string
};

const stateProps = state => ({
  mid: state.mostRecentLookup.mid,
  possibilities: state.adjustments.possibilities,
  currency: state.mostRecentLookup.currency,
  odometerUnits: state.mostRecentLookup.odometerUnits,
  wholesaleValuesAverage: state.mostRecentLookup.wholesaleValues.average,
  loading: state.loading,
  adjustedBy: state.mostRecentLookup.adjustedPricing.adjustedBy,
  adjustmentsCompleted: state.mostRecentLookup.adjustmentsCompleted,
  country: state.adjustments.selections.country
});

const actionProps = {
  dispatchAdjustmentSelected: adjustmentSelected,
  dispatchAllAdjustmentsCleared: allAdjustmentsCleared
};

const resizableAdjustments = resizable(Adjustments);

const AdjustmentsContainer = connect(
  stateProps,
  actionProps
)(resizableAdjustments);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default AdjustmentsContainer;
export { Adjustments };
