////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function FilterIcon({ enabled }) {
  return (
    <svg
      className={`${styles.svg} ${styles.filter_icon} ${
        enabled ? styles.active : ''
      }`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 125.75 94.31"
    >
      <title>Filter icon</title>
      <path
        className={styles.fill}
        d="M136.19,13.32L89,60.47v39.3l-31.44,7.86V60.47L10.43,13.32H136.19Z"
        transform="translate(-10.43 -13.32)"
      />
    </svg>
  );
}

FilterIcon.propTypes = {
  enabled: PropTypes.bool
};

export default FilterIcon;
