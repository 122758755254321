////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import createLogger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';

/* Internal */
import {
  YMMS_FIELD_SELECTED,
  YMMS_SELECTION_CLEARED,
  ADJUSTMENT_CLEARED,
  ADJUSTMENT_SELECTED
} from 'SRC/store/actions/types';
import { EXTERNAL } from 'SRC/constants';

/* Local */
import mainSaga, { externalSaga } from './sagas';
import mainReducer from './reducers';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const sagaMiddleware = createSagaMiddleware({
  onError: LOGGER.error
});

const middleware = [sagaMiddleware];

const reduxDevTools =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION__; // eslint-disable-line no-underscore-dangle

if (reduxDevTools) {
  LOGGER.log(
    '%cRedux Devtools detected! See actions logged there instead of here.',
    'font-weight: bold'
  );
}

if (
  NODE_ENV !== 'test' &&
  MMR_ENV !== 'prod' &&
  !reduxDevTools &&
  window.name !== 'nodejs'
) {
  middleware.push(
    // From redux-logger. It prints a nice representation of actions to the
    // console as they happen, along with helpful information about the state.
    createLogger({
      duration: true,
      collapsed: true,
      actionTransformer: action => {
        if (
          [
            ADJUSTMENT_CLEARED,
            ADJUSTMENT_SELECTED,
            YMMS_SELECTION_CLEARED,
            YMMS_FIELD_SELECTED
          ].includes(action.type)
        ) {
          return { ...action, type: `${action.type} (${action.field})` };
        }
        return action;
      }
    })
  );
}

const store = createStore(
  mainReducer,
  composeWithDevTools(applyMiddleware(...middleware))
);

window.MMR = window.MMR || Object.create(null);
if (EXTERNAL) {
  sagaMiddleware.run(externalSaga);
} else {
  sagaMiddleware.run(mainSaga);
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default store;
