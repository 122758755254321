////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  numberWithCommas,
  formatConditionGrade,
  formatOdometerUnits,
  formatLocationName,
  titleize,
  country as getCountry
} from 'SRC/util/formatting';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function exportCSV(
  transactions,
  odometerUnits,
  currency,
  vehicleSummary,
  edition
) {
  const country = getCountry(currency);
  const csvContent = toCSV(
    transactions,
    vehicleSummary,
    edition,
    country,
    odometerUnits,
    currency
  );
  const filename = fileName(vehicleSummary, currency, edition, country);

  handleDownload(csvContent, filename);
}

function toCSV(
  transactions,
  vehicleSummary,
  edition,
  country,
  odometerUnits,
  currency
) {
  const data = transactions.map(t => ({
    ...t,
    ...vehicleSummary,
    edition,
    country
  }));
  const csvData = getRows(data);
  const headerRow = getHeaders(odometerUnits, currency);
  const dataRows = csvData.map(row => row.join(','));

  return [headerRow, ...dataRows].join('\n');
}

function fileName(vehicleSummary, currency, edition, country) {
  const { year, make, model, style } = vehicleSummary;
  const displayStyle = style.replace(' ', '-');

  return `Manheim_MMR_${country}_${edition}_${year}-${make}-${model}-${displayStyle}.csv`;
}

function getHeaders(odometerUnits, currency) {
  return [
    'Date',
    `Price (${currency})`,
    `Odometer (${formatOdometerUnits(odometerUnits)})`,
    'Condition',
    'Engine',
    'Transmission',
    'Exterior Color',
    'Type',
    'Region',
    'Auction',
    'In Sample',
    'Year',
    'Make',
    'Model',
    'Style',
    'Edition',
    'Country'
  ];
}

function getRows(data) {
  const returnBlank = true;
  const isAverage = false;

  return data.map(datum => {
    return [
      datum.purchaseDate,
      `$${datum.purchasePrice}`,
      numberWithCommas(datum.vehicleDetails.odometer),
      formatConditionGrade(datum.vehicleDetails.grade, isAverage, returnBlank),
      datum.vehicleDetails.engine && datum.vehicleDetails.engine,
      titleize(datum.vehicleDetails.transmission),
      titleize(datum.vehicleDetails.color),
      titleize(datum.saleType),
      titleize(datum.region.name),
      formatLocationName(datum.location.locationName),
      datum.inSample,
      datum.year,
      datum.make,
      datum.model,
      datum.style,
      datum.edition,
      datum.country
    ].map(field => `"${field.trim()}"`);
  });
}

// HTML5 download attribute vs older browsers
function handleDownload(csvContent, filename) {
  if (window.navigator.msSaveBlob) {
    //IE 10
    const mimeType = 'text/csv;';
    const blob = new window.Blob([csvContent], { type: mimeType });
    window.navigator.msSaveBlob(blob, `${filename}`);
  } else {
    const link = document.createElement('a');
    if ('download' in link) {
      downloadLink(link, csvContent, filename);
    } else {
      downloadInWindow(csvContent, filename);
    }
  }
}

function downloadLink(link, csvContent, filename) {
  // Browsers that support HTML5 download attribute
  const uri = encodeURI(`data:text/csv,${csvContent}`);
  link.setAttribute('href', uri);
  link.setAttribute('download', filename);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

function downloadInWindow(csvContent, filename) {
  // We could use `data:application/vnd.ms-excel` to get file to download
  // automatically but it will always be named Unknown
  // It was decided to load in new tab and let user save it / name it
  const url = `data: text/csv,${encodeURI(csvContent)}`;
  window.open(url, `${filename}`);
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { exportCSV, getHeaders, getRows };
