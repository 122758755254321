////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

//[> Local <]
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function VerticalLabel({ label, value }) {
  return (
    <div>
      <div data-test="label" className={styles.label}>
        {label}
      </div>
      <div data-test="value" className={styles.value}>
        {value}
      </div>
    </div>
  );
}

VerticalLabel.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string
};

export default VerticalLabel;
