////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function ModalMobileTable({ vehicles, onSelect }) {
  return (
    <ul data-test="ModalMobile">
      {vehicles.map((vehicle, i) => (
        <li
          key={i}
          onClick={() => {
            onSelect(vehicle);
          }}
          data-test={`vehicle${i}`}
        >
          <span>
            {vehicle.description.year} {vehicle.description.make.name}{' '}
            {vehicle.description.model.name} {vehicle.description.style.name}
          </span>
        </li>
      ))}
    </ul>
  );
}

ModalMobileTable.propTypes = {
  onSelect: PropTypes.func,
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      make: PropTypes.object,
      model: PropTypes.object,
      style: PropTypes.object
    })
  )
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default ModalMobileTable;
