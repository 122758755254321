////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { uniqBy } from 'lodash';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function formatVIN(vin) {
  return vin.toUpperCase().replace(/\s/g, '');
}

function waitingForSelection(possibilities, selection) {
  const uniquePossibilities = uniqBy(possibilities, x => {
    return x && typeof x === 'object' ? x.id : x;
  });
  return uniquePossibilities.length > 1 && selection === null;
}

function hasCompleteInfo(possibilities) {
  return possibilities.vehicles && possibilities.vehicles[0]
    ? possibilities.vehicles[0].wholesaleValues ||
        possibilities.vehicles[0].message
    : false;
}

function mobileOSDisplay(platform, version) {
  if (platform === 'android') {
    return versionGreaterThan(version, '1.10.4');
  }

  if (platform === 'iPhone') {
    return versionGreaterThan(version, '4.3.1');
  }

  return false;
}

function shouldOpenMultiDecodeModal(selections, possibilities) {
  const { models, styles } = possibilities || {};
  const { model, style } = selections || {};

  return Boolean(
    (waitingForSelection(models, model) ||
      waitingForSelection(styles, style)) &&
      hasCompleteInfo(possibilities)
  );
}

function versionGreaterThan(userVersion, minVersion) {
  const userVersionArray = userVersion.split('.');
  const minVersionArray = minVersion.split('.');
  for (let i = 0; i < 3; i += 1) {
    if (Number(userVersionArray[i]) < Number(minVersionArray[i])) {
      return false;
    }

    if (Number(userVersionArray[i]) > Number(minVersionArray[i])) {
      return true;
    }

    if (userVersionArray.length === i + 1) {
      if (Number(userVersionArray[i]) >= Number(minVersionArray[i])) {
        return true;
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  formatVIN,
  waitingForSelection,
  hasCompleteInfo,
  mobileOSDisplay,
  shouldOpenMultiDecodeModal,
  versionGreaterThan
};
