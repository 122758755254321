////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  SONAR_SEARCH_REQUESTED,
  SONAR_SEARCH_RECEIVED,
  SONAR_SEARCH_FAILURE,
  SONAR_SEARCH_STATUS
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function sonarSearchRequested(searchParameters = {}) {
  return {
    type: SONAR_SEARCH_REQUESTED,
    searchParameters
  };
}

function sonarSearchReceived(data) {
  return {
    type: SONAR_SEARCH_RECEIVED,
    data
  };
}

function sonarSearchFailure(errorMessage) {
  return {
    type: SONAR_SEARCH_FAILURE,
    errorMessage
  };
}

function sonarSearchStatus(status) {
  return {
    type: SONAR_SEARCH_STATUS,
    status
  };
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  sonarSearchRequested,
  sonarSearchReceived,
  sonarSearchFailure,
  sonarSearchStatus
};
