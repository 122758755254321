////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/
/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from '../../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Speedometer({ disabled }) {
  return (
    <svg
      version="1.1"
      id="speedometer"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 15 11"
      className={`${styles.iconClass} ${disabled ? styles.iconDisabled : ''}`}
      style={{ enableBackground: 'new 0 0 15 11' }}
      xmlSpace="preserve"
    >
      <title>Speedometer</title>
      <path
        className={`${styles.icon} ${disabled ? styles.disabled : ''}`}
        d="M8.9,7.6c0,0,0-0.1,0-0.1L5.4,1.6C5.3,1.4,5.1,1.4,4.9,1.4c-0.2,0-0.3,0.2-0.3,0.4l1.3,6.5c0,0.1,0,0.1,0.1,0.1
        c0,0.1,0,0.1,0,0.2c0.2,0.6,0.8,0.9,1.5,0.9c0.2,0,0.3,0,0.5-0.1c0.4-0.1,0.7-0.4,0.9-0.7C9,8.5,9,8.1,8.9,7.8
        C8.9,7.7,8.9,7.7,8.9,7.6z M7.5,1.9C7.3,1.9,7.1,2,7.1,2.2c0,0.2,0.2,0.4,0.4,0.4c3.5,0,6.4,2.5,6.4,5.7c0,0.2,0.2,0.4,0.4,0.4
        c0.2,0,0.4-0.2,0.4-0.4C14.7,4.7,11.5,1.9,7.5,1.9z M2.9,3.3c-1.7,1.1-2.7,3-2.7,5c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4
        c0,0,0,0,0,0c0-1.8,0.9-3.4,2.4-4.4c0.2-0.1,0.2-0.3,0.1-0.5c0,0,0,0,0-0.1C3.4,3.2,3.1,3.1,2.9,3.3z"
      />
    </svg>
  );
}
Speedometer.propTypes = {
  disabled: PropTypes.bool
};

export default Speedometer;
