////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  BEGIN_INITIAL_LOADING,
  END_INITIAL_LOADING
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function beginInitialLoading() {
  return {
    type: BEGIN_INITIAL_LOADING,
    initialLoading: true
  };
}

function endInitialLoading() {
  return {
    type: END_INITIAL_LOADING,
    initialLoading: false
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { beginInitialLoading, endInitialLoading };
