////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { delay } from 'redux-saga';
import { call, cancelled, select, put, spawn } from 'redux-saga/effects';
import { isEqual, differenceWith } from 'lodash';

/* Internal */
import {
  refreshCache,
  checkCache,
  refreshCacheSuccess,
  refreshCacheFailure
} from 'SRC/store/actions/creators';
import { REFRESH_CACHE, CHECK_CACHE } from 'SRC/store/actions/types';
import { readS3File } from 'SRC/util/fileReader';
import { sortAllItemsByHref } from 'SRC/util/cacheUtils';
import {
  CACHE_MONITOR_INTERVAL,
  INITIAL_CACHE_MONITOR_WAIT
} from 'SRC/constants';

import { refreshResourcesSaga } from './initialResources';
import { loadRegionsSaga } from './adjustments';
////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
function* refreshCacheSaga(action) {
  const { country } = yield select(state => state.adjustments.selections);
  LOGGER.log('REFRESH CACHE', action);
  yield call(refreshResourcesSaga, action);
  yield call(loadRegionsSaga, country);
  yield put(refreshCacheSuccess());
}

function* checkCacheSaga() {
  // At MONITOR_INTERVAL check store edition against current deployed previousInitialCache.json
  if (window.MMR && window.MMR.initialCache) {
    const ourCache = window.MMR.initialCache;
    const currentData = yield call(readS3File, 'previousInitialCache.json');
    if (currentData && Array.isArray(currentData)) {
      sortAllItemsByHref(ourCache);
      sortAllItemsByHref(currentData);
      if (!isEqual(ourCache, currentData)) {
        LOGGER.log(
          'difference',
          differenceWith(ourCache, currentData, isEqual)
        );
        yield put(refreshCache('data changed on S3', new Map(currentData)));
        window.MMR.initialCache = currentData;
      }
    }
  }
}

function* cacheMonitorSaga(action) {
  try {
    switch (action.type) {
      case REFRESH_CACHE:
        yield* refreshCacheSaga(action);
        break;
      case CHECK_CACHE:
        yield* checkCacheSaga();
        break;
      default:
        LOGGER.error('unsupported action', action);
    }
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      if (action.type === REFRESH_CACHE) {
        yield put(refreshCacheFailure(err.message));
      }
    }
  }
}

function* cacheMonitorWorker(initDelay = INITIAL_CACHE_MONITOR_WAIT) {
  // patch yarn run build hanging
  if (window.name === 'nodejs' && NODE_ENV !== 'test') {
    return;
  }
  let delayTime = initDelay;
  let initial = true;

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      yield call(delay, delayTime);
      yield put(checkCache(initial));
      delayTime = CACHE_MONITOR_INTERVAL; //MS_15_MIN after initial
      initial = false;
    } catch (err) {
      LOGGER.error(err.message);
    }
  }
}

function* runCacheMonitorWorker(initDelay = INITIAL_CACHE_MONITOR_WAIT) {
  yield spawn(cacheMonitorWorker, initDelay);
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default cacheMonitorSaga;
export { runCacheMonitorWorker, cacheMonitorWorker };
