////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Component */
import AverageValuation from 'SRC/components/AverageValuation';
import LoadingContent from 'SRC/components/LoadingContent';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function HistoricalAverage({
  historicalAverages,
  currency,
  loading,
  odometerUnits
}) {
  const { last30Days = {}, lastSixMonths = {}, lastYear = {} } =
    historicalAverages || {};

  const hideOdometer =
    !last30Days.odometer && !lastSixMonths.odometer && !lastYear.odometer;
  return (
    <div className="font--lato" data-test="MMRHistoricalDisplay">
      <h3 className={`mui-m-h-b mui-m-t ${styles.title}`}>
        Historical Average
      </h3>
      <LoadingContent loading={loading} className="mui-row font--lato mui-m-b">
        <div className="mui-col mui-col-1-3" data-test="MMRHistoricalThirtyDay">
          <AverageValuation
            header="Past 30 Days"
            averagePrice={last30Days.price}
            odometer={last30Days.odometer}
            currency={currency}
            odometerUnits={odometerUnits}
            hideOdometer={hideOdometer}
          />
        </div>
        <div className="mui-col mui-col-1-3" data-test="MMRHistoricalSixMonths">
          <AverageValuation
            header="6 Months Ago"
            averagePrice={lastSixMonths.price}
            odometer={lastSixMonths.odometer}
            currency={currency}
            odometerUnits={odometerUnits}
            hideOdometer={hideOdometer}
          />
        </div>
        <div className="mui-col mui-col-1-3" data-test="MMRHistoricalLastYear">
          <AverageValuation
            header="Last Year"
            averagePrice={lastYear.price}
            odometer={lastYear.odometer}
            currency={currency}
            odometerUnits={odometerUnits}
            hideOdometer={hideOdometer}
          />
        </div>
      </LoadingContent>
    </div>
  );
}

const historicalAverageType = PropTypes.shape({
  price: PropTypes.number,
  odometer: PropTypes.number
});

HistoricalAverage.propTypes = {
  historicalAverages: PropTypes.shape({
    last30Days: historicalAverageType,
    lastSixMonths: historicalAverageType,
    lastYear: historicalAverageType
  }),
  currency: PropTypes.string,
  loading: PropTypes.bool,
  odometerUnits: PropTypes.string
};

const stateProps = state => ({
  historicalAverages: state.mostRecentLookup.historicalAverages,
  currency: state.mostRecentLookup.currency,
  adjustedBy: state.mostRecentLookup.adjustedPricing.adjustedBy,
  selections: state.selections,
  loading: state.loading,
  odometerUnits: state.mostRecentLookup.odometerUnits
});

const HistoricalAverageContainer = connect(stateProps)(HistoricalAverage);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default HistoricalAverageContainer;
export { HistoricalAverage };
