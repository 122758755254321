////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  INVENTORY_DATA_REQUESTED,
  INVENTORY_DATA_RECEIVED,
  INVENTORY_DATA_CLEARED,
  VIN_CHANGED,
  VIN_CHANGED_FAILURE,
  MID_CHANGED,
  YMMS_SELECTION_CLEARED,
  CLEAR_VALUATION,
  OPEN_INVENTORY_MODAL,
  CLOSE_INVENTORY_MODAL,
  ADD_INVENTORY_FIELD_TO_HIGHLIGHT,
  HYDRATE_STATE,
  VIN_PARTIALLY_DECODED,
  YMMS_FIELD_SELECTED,
  ADJUSTMENT_SELECTED
} from 'SRC/store/actions/types';

import { formatOdometerUnits } from 'SRC/util/formatting';
////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = {};

function inventoryData(state = initialState, action = {}) {
  switch (action.type) {
    case INVENTORY_DATA_REQUESTED:
      return {
        ...action.inventoryData
      };
    case INVENTORY_DATA_RECEIVED:
      return parseReceivedData(state, action.data);
    case INVENTORY_DATA_CLEARED:
    case YMMS_SELECTION_CLEARED:
      return initialState;
    case ADJUSTMENT_SELECTED:
      if (action.field === 'country' && action.value !== state.pickupCountry) {
        return initialState;
      }
      return state;
    case YMMS_FIELD_SELECTED:
      if (action.keepSelection === true) {
        return state;
      }
      return initialState;
    case VIN_CHANGED:
      if (action.vin !== state.vin) {
        return initialState;
      }
      return state;
    default:
      return state;
  }
}

function parseReceivedData(state, data) {
  if (data.message) {
    return {
      ...state,
      message: data.message,
      status: 'NotFound'
    };
  }
  const status = data.statuses ? data.statuses[0] : undefined;
  if (
    status &&
    (status === 'Live' || status === 'Preview' || status === 'Expired')
  ) {
    return {
      ...state,
      vehicleUniqueId: status !== 'Expired' ? data.id : '',
      id: data.id,
      mid: data.mid,
      vin: data.vin,
      pickupLocation: data.pickupLocation,
      pickupRegion: data.pickupRegion,
      pickupCountry: data.pickupLocationCountry,
      channels: data.channels,
      currency: data.currency,
      status: data.statuses ? data.statuses[0] : undefined,
      condition: data.isAutogradeConditionGrade
        ? data.conditionGradeNumeric
        : null,
      thirdPartyConditionGrade: !data.isAutogradeConditionGrade
        ? data.conditionGradeNumeric
        : null,
      odometer: data.odometer,
      odometerUnits: formatOdometerUnits(data.odometerUnits),
      trim: data.trims ? data.trims[0] : undefined,
      color: data.exteriorColor,
      yearId: data.yearId ? data.yearId : undefined,
      makeId: data.makeId ? data.makeId : undefined,
      modelIds: data.modelIds ? data.modelIds : undefined,
      isAutogradeConditionGrade: data.isAutogradeConditionGrade,
      year: data.year ? data.year : undefined,
      make: data.make ? data.make : undefined,
      models: data.models ? data.models : undefined,
      hasAutocheck: data.hasAutocheck ? data.hasAutocheck : undefined,
      autocheckCsHash: data.autocheckCsHash ? data.autocheckCsHash : undefined,
      isEligibleForCarfax: data.isEligibleForCarfax
        ? data.isEligibleForCarfax
        : undefined,
      carfaxCsHash: data.carfaxCsHash ? data.carfaxCsHash : undefined,
      sellerNumber: data.sellerNumber ? data.sellerNumber : undefined
    };
  }
  return {
    ...state,
    message: 'listing in unsupported status',
    status: data.statuses ? data.statuses[0] : 'Unknown'
  };
}

function showInventoryModal(state = null, action = {}) {
  switch (action.type) {
    case OPEN_INVENTORY_MODAL:
      return action.showInventoryModal;
    case CLOSE_INVENTORY_MODAL:
      return false;
    case HYDRATE_STATE:
    case VIN_PARTIALLY_DECODED:
      return null;
    default:
      return state;
  }
}

function highlightInventoryFields(state = [], action = {}) {
  switch (action.type) {
    case ADD_INVENTORY_FIELD_TO_HIGHLIGHT:
      return [...state, action.field];
    case VIN_CHANGED_FAILURE:
    case MID_CHANGED:
    case YMMS_SELECTION_CLEARED:
    case CLEAR_VALUATION:
    case INVENTORY_DATA_CLEARED:
    case HYDRATE_STATE:
    case VIN_PARTIALLY_DECODED:
      return [];
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { showInventoryModal, highlightInventoryFields };

export default inventoryData;
