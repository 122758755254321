////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  MULTI_DECODE_MODAL_OPENED,
  MULTI_DECODE_MODAL_CLOSED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function openMultiDecodeModal(showMultiDecodeModal = true) {
  return {
    type: MULTI_DECODE_MODAL_OPENED,
    showMultiDecodeModal
  };
}

function closeMultiDecodeModal() {
  return {
    type: MULTI_DECODE_MODAL_CLOSED,
    showMultiDecodeModal: false
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { openMultiDecodeModal, closeMultiDecodeModal };
