////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';

/* Internal */
import { INITIAL_PAGE_SIZE, COLUMN_HEADERS } from 'SRC/constants';
import { vehicleSummary as summary } from 'SRC/util/vehicleSummary';
import { formatYYYYMMDD } from 'SRC/util/formatting';
import { getEdition } from 'SRC/store/reducers/edition';
import { showTransactions } from 'SRC/store/actions/creators';

/* Component */
import resizable from 'SRC/components/resizable';
import FilterBox from 'SRC/containers/FilterBox';
import TransactionsDesktop from './Desktop';
import TransactionsMobile from './Mobile';
import TransactionsTablet from './Tablet';

/* Local */
import {
  checkAdjustments,
  getColumnsFrom,
  formatNumbers,
  filterTransactions,
  getTruthyFilters
} from './formatting';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const orderedHeaders = [
  COLUMN_HEADERS.DATE_SOLD,
  COLUMN_HEADERS.SALE_PRICE,
  COLUMN_HEADERS.ODOMETER,
  COLUMN_HEADERS.CONDITION_GRADE,
  COLUMN_HEADERS.ENGINE_TRANSMISSION,
  COLUMN_HEADERS.EXTERIOR_COLOR,
  COLUMN_HEADERS.TYPE,
  COLUMN_HEADERS.REGION,
  COLUMN_HEADERS.AUCTION
];

const initialState = {
  isCollapsed: false,
  rowLimit: INITIAL_PAGE_SIZE,
  truncateFilteredTransactions: false,
  sortBy: COLUMN_HEADERS.DATE_SOLD,
  sortOrder: 'desc'
};

class Transactions extends Component {
  static defaultProps = {
    appliedFilters: {},
    viewport: 'lg',
    transactions: [],
    currency: '',
    selections: {},
    odometerUnits: 'miles',
    vehicleSummary: {}
  };

  state = initialState;

  render() {
    const {
      currency,
      loading,
      transactions,
      appliedFilters,
      viewport,
      selections,
      odometerUnits,
      successfulLookup,
      vehicleSummary,
      edition,
      showTransactionFilters
    } = this.props;

    const {
      isCollapsed,
      rowLimit,
      truncateFilteredTransactions,
      sortBy,
      sortOrder
    } = this.state;

    const rawTransactions = transactions;

    const filteredTransactions = filterTransactions(
      appliedFilters,
      transactions
    );

    const visibleRows = isCollapsed
      ? 0
      : !truncateFilteredTransactions && Object.keys(appliedFilters).length > 0
      ? filteredTransactions.length
      : Math.min(rowLimit, filteredTransactions.length);

    const adjustmentsTransactions = checkAdjustments(
      filteredTransactions,
      selections
    );
    const labeledTransactions = getColumnsFrom(adjustmentsTransactions);
    const orderedTransactions = orderBy(labeledTransactions, sortBy, sortOrder);

    const filterCount = getTruthyFilters(appliedFilters).count;

    function shouldRenderAsterisk() {
      return viewport !== 'lg';
    }

    const displayedTransactions = formatNumbers(
      orderedTransactions,
      currency,
      shouldRenderAsterisk()
    );

    const commonProps = {
      isCollapsed,
      filterCount,
      columns: orderedHeaders,
      loading,
      onCollapse: this.onCollapse,
      onChangeSort: this.onChangeSort,
      onShowLess: this.onShowLess,
      onShowMore: this.onShowMore,
      rowLimit,
      sortBy,
      sortOrder,
      rawTransactions,
      filteredTransactions,
      displayedTransactions,
      viewport,
      visibleRows,
      odometerUnits,
      successfulLookup,
      currency,
      vehicleSummary,
      edition
    };

    const sm = <TransactionsMobile {...commonProps} />;

    const md = (
      <TransactionsTablet {...commonProps} showHeaders={visibleRows > 0} />
    );

    const lg = (
      <TransactionsDesktop {...commonProps} showHeaders={visibleRows > 0} />
    );

    const print = (
      <TransactionsDesktop
        {...commonProps}
        showHeaders={visibleRows > 0}
        viewport="lg"
      />
    );

    const responsive = { sm, md, lg }[viewport] || lg;

    return (
      <div>
        {showTransactionFilters && (
          <FilterBox
            outsideClickIgnoreClass="ignore--filterLink"
            excludeScrollbar
          />
        )}
        <div className="print--hide">{responsive}</div>
        <div className="hide print--show--block">{print}</div>
      </div>
    );
  }

  onChangeSort = column => {
    const newSortOrder =
      this.state.sortBy === column ? flipOrder(this.state.sortOrder) : 'desc';

    this.setState({
      sortBy: column,
      sortOrder: newSortOrder
    });
  };

  onShowMore = () => {
    this.setState({
      rowLimit: this.props.transactions.length,
      truncateFilteredTransactions: false
    });
    this.props.dispatchShowTransactions(
      this.props.transactions.length,
      this.props.transactions,
      'mmr:view_all'
    );
  };

  onShowLess = () => {
    this.setState({
      rowLimit: INITIAL_PAGE_SIZE,
      truncateFilteredTransactions: true
    });
    this.props.dispatchShowTransactions(
      INITIAL_PAGE_SIZE,
      this.props.transactions,
      'mmr:view_less'
    );
  };

  onCollapse = () => {
    this.setState({
      isCollapsed: !this.state.isCollapsed
    });
  };
}

function flipOrder(order) {
  return order === 'asc' ? 'desc' : 'asc';
}

Transactions.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.object),
  appliedFilters: PropTypes.object,
  currency: PropTypes.string,
  loading: PropTypes.bool,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg']),
  selections: PropTypes.object,
  odometerUnits: PropTypes.string,
  successfulLookup: PropTypes.bool,
  vehicleSummary: PropTypes.object,
  edition: PropTypes.string,
  dispatchShowTransactions: PropTypes.func,
  showTransactionFilters: PropTypes.bool
};

const stateProps = state => ({
  transactions: state.mostRecentLookup.transactions,
  appliedFilters: state.appliedFilters,
  currency: state.mostRecentLookup.currency,
  selections: state.adjustments.selections,
  loading: state.loading,
  odometerUnits: state.mostRecentLookup.odometerUnits,
  successfulLookup: state.mostRecentLookup.successfulLookup,
  vehicleSummary: summary(
    state.ymmsLookup.selections,
    state.ymmsLookup.possibilities
  ),
  edition: formatYYYYMMDD(getEdition(state)),
  showTransactionFilters: state.showTransactionFilters
});

const actionProps = {
  dispatchShowTransactions: showTransactions
};

const resizableTransactions = resizable(Transactions);

const TransactionsContainer = connect(
  stateProps,
  actionProps
)(resizableTransactions);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TransactionsContainer;
export { Transactions, orderedHeaders };
