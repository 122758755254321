////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Component */
import resizable from 'SRC/components/resizable';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function MobileLoader({ loading, viewport }) {
  if (viewport === 'sm' && loading) {
    return (
      <div data-test="mobileLoader">
        <div className={styles.overlay} />
        <div className={styles.outerContainer}>
          <div className={styles.innerContainer}>
            <i
              className={`icon icon-spinner anim-spin align--center ${styles.icon}`}
            />
            <h3>LOADING</h3>
          </div>
        </div>
      </div>
    );
  }

  return null;
}

MobileLoader.propTypes = {
  loading: PropTypes.bool,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg'])
};

const stateProps = state => ({
  loading: state.initialLoading
});

const resizableMobileLoader = resizable(MobileLoader);

const MobileLoaderContainer = connect(stateProps)(resizableMobileLoader);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default MobileLoaderContainer;
export { MobileLoader };
