////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Component */
import Message from 'SRC/components/Message';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Notification({ text }) {
  return (
    <div
      className={`${styles.messageWarning} message--warning message--icon mui-m-h-b`}
      data-test="notification"
    >
      <Message text={text} />
    </div>
  );
}

Notification.propTypes = {
  text: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default Notification;
