/* eslint-disable camelcase */

////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { sortBy } from 'lodash';

/* Internal */

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

// from inside running app current country has years sort().reverse() applied
// so we apply reverse only to those not already reversed or our deep equal is unhappy
function sortAllItemsByHref(cache) {
  if (!Array.isArray(cache)) {
    return;
  }
  cache.forEach(([_, value]) => {
    if (Array.isArray(value.body.items)) {
      value.body.items = sortBy(value.body.items, 'href'); // eslint-disable-line no-param-reassign
    } else if (Array.isArray(value.body.year)) {
      value.body.year = value.body.year.sort().reverse(); // eslint-disable-line no-param-reassign
    }
  });
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export { sortAllItemsByHref };
