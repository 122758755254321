////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put, select, take, cancelled } from 'redux-saga/effects';
import { filter } from 'lodash';

/* Internal */
import { getListingsFromId, getListingsFromVin } from 'SRC/api';
import {
  inventoryDataReceived,
  inventoryDataFailure,
  inventoryDataRequested,
  adjustmentSelected
} from 'SRC/store/actions/creators';
import {
  INVENTORY_DATA_RECEIVED,
  INVENTORY_DATA_FAILURE
} from 'SRC/store/actions/types';
import { truthyOrZero } from 'SRC/util/helperUtils';
import { EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* inventoryDataSaga(vehicleInfo) {
  const { vin = null, vehicleUniqueId = null } = vehicleInfo.inventoryData;
  if (vin) {
    yield call(inventoryDataByVinSaga, vin);
  } else {
    yield call(inventoryDataByIdSaga, vehicleUniqueId);
  }
}

function* inventoryDataByIdSaga(vehicleUniqueId) {
  try {
    const response = yield call(getListingsFromId, vehicleUniqueId);
    if (!response.success) {
      throw response.payload;
    }
    if (response.success) {
      const data = response.payload;
      yield put(inventoryDataReceived(data));
    }
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(inventoryDataFailure(err.message));
    }
  }
}

function* inventoryDataByVinSaga(vin) {
  try {
    const { country } = yield select(state => state.adjustments.selections);
    LOGGER.log('country:', country);
    const currency = country === 'CA' ? 'CAD' : 'USD';
    const response = yield call(getListingsFromVin, vin);
    if (!response.success) {
      throw response.payload;
    }
    if (response.success) {
      if (Array.isArray(response.payload)) {
        //  Apply filters
        const eligibleListings = filter(
          response.payload,
          item =>
            (item.channels[0] === 'OVE' || item.channels[0] === 'RMS') &&
            (item.pickupLocationCountry === country ||
              (item.pickupLocationCountry === null &&
                item.currency === currency))
        );
        LOGGER.groupCollapsed('filterListings');
        LOGGER.info('we found eligible listings:', eligibleListings);
        const activeListings = filter(
          eligibleListings,
          listing => listing.statuses[0] !== 'Expired'
        );
        LOGGER.info('we found active listings:', activeListings);
        let applicableListings =
          activeListings.length > 0 ? activeListings[0] : eligibleListings[0];
        if (!applicableListings || applicableListings.length === 0) {
          applicableListings = {
            message: 'no applicable listings after filtering'
          };
        }
        LOGGER.info('using applicable listing:', applicableListings);
        LOGGER.groupEnd();
        const data = applicableListings;
        yield put(inventoryDataReceived(data));
      } else {
        yield put(inventoryDataReceived(response.payload));
      }
    }
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(inventoryDataFailure(err.message));
    }
  }
}

function* inventoryDataLookupSaga({ vin }) {
  // Do not run inside libMMR.js or onMobileApp
  if (EXTERNAL) {
    return;
  }
  LOGGER.info('inventoryDataLookupSaga vin=', vin);
  const { vehicleUniqueId } = yield select(state => state.inventoryData);
  if (!vehicleUniqueId && vin) {
    yield put(inventoryDataRequested({ vin }));
    yield take([INVENTORY_DATA_RECEIVED, INVENTORY_DATA_FAILURE]);
    const { inventoryData } = yield select(state => state);
    if (inventoryData.id && inventoryData.status !== 'Expired') {
      LOGGER.info('we found listing for ', inventoryData.vin, inventoryData);

      if (inventoryData.color) {
        yield put(adjustmentSelected('color', inventoryData.color));
      }

      if (inventoryData.condition) {
        yield put(adjustmentSelected('grade', inventoryData.condition * 10));
      }

      if (truthyOrZero(inventoryData.odometer)) {
        yield put(adjustmentSelected('odometer', inventoryData.odometer));
      }
    }
  }
  LOGGER.info('inventoryDataLookupSaga complete');
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export {
  inventoryDataByIdSaga,
  inventoryDataByVinSaga,
  inventoryDataLookupSaga
};
export default inventoryDataSaga;
