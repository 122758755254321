////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TransactionCount({
  className,
  visibleRows,
  rawTransactions,
  loading
}) {
  if (loading || visibleRows === 0) {
    return <span />;
  }

  const total = rawTransactions ? rawTransactions.length : 0;
  const showingText =
    total === 0 ? total : `${visibleRows || total} of ${total}`;

  return <span className={className}>Showing {showingText}</span>;
}

TransactionCount.propTypes = {
  className: PropTypes.string,
  visibleRows: PropTypes.number,
  rawTransactions: PropTypes.arrayOf(PropTypes.object),
  loading: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TransactionCount;
