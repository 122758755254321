////////////////////////////////////////////////////////////////////////////////

import first from 'lodash/first';

/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  SONAR_SEARCH_REQUESTED,
  SONAR_SEARCH_RECEIVED,
  SONAR_SEARCH_FAILURE,
  SONAR_SEARCH_STATUS,
  CLEAR_VALUATION,
  YMMS_FIELD_SELECTED,
  VALUATION_CHANGED,
  VIN_CHANGED,
  MID_CHANGED_FAILURE
} from 'SRC/store/actions/types';

import { OVE_SRP_LINK_PATH, OVE_VDP_LINK_PATH } from 'SRC/constants';
////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const mappingFields = [
  'currency',
  'year',
  'odometer',
  'exteriorColor',
  'vin',
  'mid',
  'pickupLocation',
  'make'
];

const initialState = { count: 0 };

function vehiclesMapping(item) {
  const vehicle = {};
  mappingFields.forEach(field => {
    vehicle[field] = item[field];
  });

  vehicle.model = first(item.models);
  vehicle.trim = first(item.trims);

  vehicle.adjustedMMR = item.hasMmrValuation
    ? item.valuationsMmr.adjustedValue
    : null;

  vehicle.thumbnail = item.mainImage
    ? `${item.mainImage.largeUrl}?size=w344h256`
    : null;

  vehicle.odometerUnits = item.odometerUnits || 'miles';
  if (item.hasConditionReport) {
    vehicle.grade = item.conditionGrade;
    vehicle.gradeType = 'CR';
  } else if (item.hasSellerDisclosure) {
    vehicle.grade = item.sellerDisclosureGrade;
    vehicle.gradeType = 'SD';
  }
  vehicle.vdp = buildOveVdpLink(item.vin);

  return vehicle;
}

function similarVehicles(state = initialState, action = {}) {
  switch (action.type) {
    case SONAR_SEARCH_REQUESTED:
      return {
        ...action.searchParameters,
        ...initialState,
        status: 'in-progress'
      };
    case SONAR_SEARCH_RECEIVED:
      return {
        ...state,
        vehicles: action.data.items.map(vehiclesMapping),
        count: action.data.totalCount,
        id: action.data.id,
        status: 'completed',
        oveLinkUrl: buildOveLinkUrl(action.data)
      };
    case SONAR_SEARCH_FAILURE:
      return {
        ...initialState,
        message: action.errorMessage,
        status: 'failed'
      };
    case SONAR_SEARCH_STATUS:
      return {
        ...state,
        status: action.status
      };
    case YMMS_FIELD_SELECTED:
      if (action.keepSelection === true) {
        return state;
      }
      return initialState;
    case VIN_CHANGED:
    case VALUATION_CHANGED:
    case CLEAR_VALUATION:
    case MID_CHANGED_FAILURE:
      return initialState;
    default:
      return state;
  }
}

function buildOveVdpLink(vin) {
  const match = document.location.origin.match(/man-\w+/);
  const environmentSegment = match ? `.${match[0]}` : '';
  return `https://www.ove${environmentSegment}.com${OVE_VDP_LINK_PATH}/${vin}/OVE`;
}

function buildOveLinkUrl(data) {
  const match = document.location.origin.match(/man-\w+/);
  const environmentSegment = match ? `.${match[0]}` : '';
  return data.id
    ? `https://www.ove${environmentSegment}.com${OVE_SRP_LINK_PATH}/${data.id}`
    : '';
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default similarVehicles;
