////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  ADJUSTMENT_CHANGED,
  ADJUSTMENT_CHANGED_SUCCESS,
  ADJUSTMENT_CHANGED_FAILURE
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function adjustmentChanged(mid, field) {
  return {
    type: ADJUSTMENT_CHANGED,
    mid,
    field
  };
}

function adjustmentChangedSuccess(response) {
  return {
    type: ADJUSTMENT_CHANGED_SUCCESS,
    adjustedPricing: response.adjustedPricing,
    currency: response.currency,
    adjustments: response.adjustments
  };
}

function adjustmentChangedFailure() {
  return {
    type: ADJUSTMENT_CHANGED_FAILURE
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  adjustmentChanged,
  adjustmentChangedSuccess,
  adjustmentChangedFailure
};
