////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import {
  formatOdometer,
  formatConditionGradeDisplay
} from 'SRC/util/formatting';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function displayCR(value) {
  if (value === 0 || value === null) {
    return '- -';
  }
  return formatConditionGradeDisplay(value);
}

function buildDetailDisplay(
  detailType,
  value,
  highlightedInventoryFields,
  showInventoryModal,
  showMultiDecodeModal
) {
  let applyStyles;
  if (
    highlightedInventoryFields !== undefined &&
    showInventoryModal === false &&
    showMultiDecodeModal === false
  ) {
    applyStyles = highlightedInventoryFields.includes(detailType)
      ? `${styles.highlight}`
      : null;
  }

  let detailTypeDisplay = '';
  if (detailType && value) {
    switch (detailType) {
      case 'condition':
        detailTypeDisplay = (
          <div data-test={detailType} className={styles.itemDetail}>
            <span className={applyStyles}>
              <strong>CR</strong> {value}
            </span>
          </div>
        );
        break;
      default:
        detailTypeDisplay = (
          <div data-test={detailType} className={styles.itemDetail}>
            <span className={applyStyles}>{value}</span>
          </div>
        );
    }
  } else {
    detailTypeDisplay = (
      <div data-test={detailType} className={styles.itemDetail}>
        <span className={applyStyles}>- -</span>
      </div>
    );
  }
  return detailTypeDisplay;
}

function VehicleDetails({
  inventoryData,
  highlightInventoryFields,
  showInventoryModal,
  showMultiDecodeModal
}) {
  const mileage = buildDetailDisplay(
    'odometer',
    `${formatOdometer(inventoryData.odometer)} ${inventoryData.odometerUnits}`,
    highlightInventoryFields,
    showInventoryModal,
    showMultiDecodeModal
  );
  const conditionGrade = buildDetailDisplay(
    'condition',
    displayCR(inventoryData.condition),
    highlightInventoryFields,
    showInventoryModal,
    showMultiDecodeModal
  );
  const color = buildDetailDisplay(
    'color',
    inventoryData.color,
    highlightInventoryFields,
    showInventoryModal,
    showMultiDecodeModal
  );
  const pickupLocation = buildDetailDisplay(
    'location',
    inventoryData.pickupLocation,
    highlightInventoryFields,
    showInventoryModal,
    showMultiDecodeModal
  );

  if (inventoryData.status === 'Live' || inventoryData.status === 'Preview') {
    return (
      <div className={styles.vehicleDetails} data-test="VehicleDetails">
        {mileage}
        {conditionGrade}
        {color}
        {pickupLocation}
      </div>
    );
  }
  return null;
}

VehicleDetails.propTypes = {
  highlightInventoryFields: PropTypes.array,
  showInventoryModal: PropTypes.bool,
  showMultiDecodeModal: PropTypes.bool,
  inventoryData: PropTypes.shape({
    id: PropTypes.string,
    pickupLocation: PropTypes.string,
    condition: PropTypes.number,
    odometer: PropTypes.number,
    odometerUnits: PropTypes.string,
    color: PropTypes.string,
    status: PropTypes.string
  })
};

const stateProps = state => ({
  inventoryData: state.inventoryData,
  highlightInventoryFields: state.highlightInventoryFields,
  showInventoryModal: state.showInventoryModal,
  showMultiDecodeModal: state.showMultiDecodeModal
});

const VehicleDetailsContainer = connect(stateProps)(VehicleDetails);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default VehicleDetailsContainer;
export { VehicleDetails };
