/* eslint-disable jsx-a11y/label-has-for */
////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';

/* Internal */
import { maybeCurrency, formatOdometerUnits } from 'SRC/util/formatting';
import { NULL_VALUE } from 'SRC/constants';
import { truthyOrZero } from 'SRC/util/helperUtils';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class AdjustmentInput extends Component {
  render() {
    const {
      children,
      image,
      delta,
      currency,
      error,
      printValue,
      title,
      odometerUnits,
      disabled,
      adjustmentsCompleted,
      showNullState
    } = this.props;
    const formattedValues = formattedAdjustmentValues({
      showNullState,
      adjustmentsCompleted,
      delta,
      printValue,
      currency,
      error
    });
    const inequalityStyle = disabled ? '' : formattedValues.inequalityStyle;
    const deltaText = disabled ? '' : formattedValues.deltaText;
    const deltaID = `${title}Delta`;
    const odoUnits =
      printValue && printValue !== NULL_VALUE
        ? formatOdometerUnits(odometerUnits)
        : null;
    const icon = (
      <label htmlFor={title}>
        <div
          className={`${styles.inputImage} ${
            disabled ? styles.iconDisabled : ''
          }`}
        >
          {image}
        </div>
      </label>
    );

    return (
      <div className="mui-col mui-col-1-1">
        <div className="print--hide">
          <div className={`${styles.box} ${inequalityStyle || ''}`}>
            {Boolean(image) && icon}
            <div className="form-group">{children}</div>
            <div className={styles.box__adjustment} id={deltaID}>
              {deltaText}
            </div>
          </div>
        </div>
        <div className={`${styles.print} hide print--show--block mui-m-q-t`}>
          <label htmlFor={title}>{title}</label>
          <strong className="mui-m-l">
            {printValue || NULL_VALUE}
            {odoUnits ? ` ${odoUnits}` : ''}
            {deltaText ? ` | ${deltaText}` : ''}
          </strong>
        </div>
      </div>
    );
  }

  shouldComponentUpdate(nextProps) {
    // Don't update while the API call is in flight, to prevent flashing
    return !nextProps.loading;
  }
}

function formattedAdjustmentValues({
  showNullState,
  adjustmentsCompleted,
  delta,
  printValue,
  currency,
  error
}) {
  let inequalityStyle;
  if (delta < 0) {
    inequalityStyle = styles.box_adjustment__negative;
  } else if (delta > 0) {
    inequalityStyle = styles.box_adjustment__positive;
  } else if (delta === 0) {
    inequalityStyle = styles.box_adjustment__zero;
  } else if (
    showNullState ||
    (adjustmentsCompleted &&
      adjustmentExists(printValue) &&
      (delta === undefined || delta === null))
  ) {
    inequalityStyle = styles.box_adjustment__null;
  }

  const showZero = true;
  const showSign = true;
  const deltaText = inequalityStyle
    ? maybeCurrency(delta, currency, error, showZero, showSign)
    : '';
  return { inequalityStyle, deltaText };
}

function adjustmentExists(value) {
  return truthyOrZero(value) && value !== NULL_VALUE;
}

AdjustmentInput.propTypes = {
  children: PropTypes.node,
  currency: PropTypes.string,
  delta: PropTypes.number,
  image: PropTypes.node,
  title: PropTypes.string,
  printValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  odometerUnits: PropTypes.string,
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  adjustmentsCompleted: PropTypes.bool,
  showNullState: PropTypes.bool,
  loading: PropTypes.bool
};

export default AdjustmentInput;
