import { setContextCustomClickNameForAnalytics } from 'SRC/util/helperUtils';

export default {
  componentVisibilityChanged: () => {},
  componentMounted: () => {},
  vehicleClicked: () => {},
  viewAllClicked: () => {},
  carouselSwiped: direction => {
    if (window._satellite) {
      setContextCustomClickNameForAnalytics(
        `SimilarVehiclesCarousel:swipe:${direction}`
      );
      window._satellite.track('SimVehSwipeAction');
    }
  },
  carouselArrowClicked: message => {
    if (window._satellite) {
      const direction = message === 'next' ? 'right' : 'left';
      setContextCustomClickNameForAnalytics(
        `SimilarVehiclesCarousel:scroll:${direction}`
      );
      window._satellite.track('SimVehScrollAction');
    }
  }
};
