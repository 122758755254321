////////////////////////////////////////////////////////////////////////////////
/****Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const { vspBaseURL } = INJECTED_CONFIG;

import {
  vehicleSummary,
  relevantSelections,
  relevantPossibilities
} from 'SRC/util/vehicleSummary';
import { midToYMMS } from 'SRC/util/mids';
import queryParams from 'SRC/util/queryParams';
import CarFaxIcon from './CarFaxIcon';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function replaceSpaces(stringValue) {
  return stringValue.replace(/ /g, '+');
}

function validVin(vin) {
  return vin && vin.toString().length === 17;
}

function getVspUrl(mid) {
  return mid ? `${vspBaseURL}/vin/summary` : `${vspBaseURL}/vin_decode`;
}

function VspLink({ possibilities, selections, vin, mid }) {
  const showLink = validVin(vin);

  const selected = vehicleSummary(selections, possibilities);
  const { year, make, model, style } = selected;
  const ymms = midToYMMS(mid);
  const params = mid
    ? queryParams({
        mid,
        vin,
        year,
        make_id: ymms.make,
        model_id: ymms.model,
        style_id: ymms.style,
        make: replaceSpaces(make),
        model: replaceSpaces(model),
        style: replaceSpaces(style)
      })
    : queryParams({ vin });

  const link = `${getVspUrl(mid)}?${params}`;

  if (showLink) {
    return (
      <div className={`${styles.vspLink} mui-col mui-col-1-1 print--hide`}>
        <a
          id="vspLink"
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          data-test="autoCheckText"
        >
          <CarFaxIcon />
          AutoCheck | CARFAX
        </a>
      </div>
    );
  }

  return null;
}

const idAndName = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string
});

VspLink.propTypes = {
  vin: PropTypes.string,
  mid: PropTypes.string,
  possibilities: PropTypes.shape({
    years: PropTypes.arrayOf(PropTypes.number),
    makes: PropTypes.arrayOf(idAndName),
    models: PropTypes.arrayOf(idAndName),
    styles: PropTypes.arrayOf(idAndName)
  }),
  selections: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    make: PropTypes.string,
    model: PropTypes.string,
    style: PropTypes.string
  })
};

const stateProps = state => ({
  vin: state.vin,
  mid: state.mostRecentLookup.mid,
  possibilities: relevantPossibilities(state),
  selections: relevantSelections(state)
});

const VspLinkContainer = connect(stateProps)(VspLink);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default VspLinkContainer;
export { VspLink, validVin };
