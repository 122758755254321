////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { setSearchTypeForAnalytics } from 'SRC/util/helperUtils';
import {
  VIN_CHANGED,
  VIN_CHANGED_FAILURE,
  VIN_FULLY_DECODED,
  VIN_DECODER_REPLIED,
  VIN_LOOKUP_CLEARED,
  CLEAR_VIN,
  CLEAR_MID_AND_VIN,
  VIN_FIELD_SELECTED,
  VIN_PARTIALLY_DECODED,
  VIN_STYLES_LOADED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function vinChanged(vin, { keepAdjustments, mid, geolocation } = {}) {
  return {
    type: VIN_CHANGED,
    vin,
    keepAdjustments: Boolean(keepAdjustments),
    mid,
    geolocation
  };
}

function vinChangedFailure(errorMessage, analyticsCode = '') {
  setSearchTypeForAnalytics(analyticsCode);
  return {
    type: VIN_CHANGED_FAILURE,
    errorMessage
  };
}

function clearVin() {
  return {
    type: CLEAR_VIN
  };
}

function clearMidAndVin() {
  return {
    type: CLEAR_MID_AND_VIN
  };
}

function vinFullyDecoded(vehicle, analyticsCode = '') {
  setSearchTypeForAnalytics(analyticsCode);
  return {
    type: VIN_FULLY_DECODED,
    vehicle
  };
}

function vinDecoderReplied(vin, { year, make, model, style, vehicles }) {
  return {
    type: VIN_DECODER_REPLIED,
    years: year,
    makes: make,
    models: model,
    styles: style,
    vehicles
  };
}

function vinLookupCleared() {
  return {
    type: VIN_LOOKUP_CLEARED
  };
}

function vinFieldSelected(field, value) {
  return {
    type: VIN_FIELD_SELECTED,
    field,
    value
  };
}

function vinPartiallyDecoded(
  vin,
  { year, make, model, style, years, makes, models, styles, vehicles }
) {
  return {
    type: VIN_PARTIALLY_DECODED,
    vin,
    year,
    make,
    model,
    style,
    years,
    makes,
    models,
    styles,
    vehicles
  };
}

function vinStylesLoaded(results) {
  return {
    type: VIN_STYLES_LOADED,
    styles: results
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  vinChanged,
  vinChangedFailure,
  vinFullyDecoded,
  vinDecoderReplied,
  vinLookupCleared,
  clearVin,
  clearMidAndVin,
  vinFieldSelected,
  vinPartiallyDecoded,
  vinStylesLoaded
};
