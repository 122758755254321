////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  COLORS_LOADED_SUCCESS,
  COLORS_LOADED_FAILURE,
  GRADES_LOADED_SUCCESS,
  GRADES_LOADED_FAILURE,
  REGIONS_LOADED_SUCCESS,
  REGIONS_LOADED_FAILURE,
  ADJUSTMENT_SELECTED,
  ADJUSTMENT_CLEARED,
  ALL_ADJUSTMENTS_CLEARED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function colorsLoadedSuccess(colors) {
  return {
    type: COLORS_LOADED_SUCCESS,
    colors
  };
}

function colorsLoadedFailure() {
  return { type: COLORS_LOADED_FAILURE };
}

function gradesLoadedSuccess(grades) {
  return {
    type: GRADES_LOADED_SUCCESS,
    grades
  };
}

function gradesLoadedFailure() {
  return { type: GRADES_LOADED_FAILURE };
}

function regionsLoadedSuccess(country, regions) {
  return {
    type: REGIONS_LOADED_SUCCESS,
    country,
    regions
  };
}

function regionsLoadedFailure() {
  return { type: REGIONS_LOADED_FAILURE };
}

function adjustmentSelected(field, value) {
  return {
    type: ADJUSTMENT_SELECTED,
    field,
    value
  };
}

function adjustmentCleared(field) {
  return {
    type: ADJUSTMENT_CLEARED,
    field
  };
}

function allAdjustmentsCleared() {
  return {
    type: ALL_ADJUSTMENTS_CLEARED
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  adjustmentSelected,
  colorsLoadedSuccess,
  colorsLoadedFailure,
  gradesLoadedSuccess,
  gradesLoadedFailure,
  regionsLoadedSuccess,
  regionsLoadedFailure,
  adjustmentCleared,
  allAdjustmentsCleared
};
