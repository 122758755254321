/* eslint-disable camelcase */

////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import superagent from 'superagent';
import { uniqWith, isEqual, find } from 'lodash';
import zipWith from 'lodash/zipWith';

/* Internal */
import { isObject, hasProp } from 'SRC/util/helperUtils';
import { patchColors } from 'SRC/util/formatting';
import { getAuthTicket, getUserInfo } from 'SRC/util/cookie';
import { EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
const { logGapiCall } = LOGGER;
const { apiURL, jwtToken } = INJECTED_CONFIG;

// This is a bit of a hack in order to get access to the `bearerToken`.
// We had trouble accessing it through the store without causing a
// circular dependency issue.
let bearerToken = null;
function setBearerToken(token) {
  bearerToken = token;
}

let responseCache =
  window.MMR && window.MMR.initialCache
    ? new Map(window.MMR.initialCache)
    : new Map();

window.MMR = window.MMR || Object.create(null);
window.MMR.responseCache = responseCache;

const gatewayEndpoint = `${apiURL}/gateway`;

if (!document.location.origin) {
  const url = `${document.location.protocol}//${document.location.hostname}`;
  const port = window.location.port ? `:${window.location.port}` : '';
  document.location.origin = `${url}${port}`;
}

function updateCache(newCacheMap) {
  // update current cache with pre expansion values
  if (newCacheMap) {
    let count = 0;
    newCacheMap.forEach((value, key) => {
      if (value.code === 200) {
        responseCache.set(key, value);
        count += 1;
      }
    });
    LOGGER.log('updateCache ', count, ' items');
  }
}

function trimCache(initialResourceKeys) {
  // if current cache has more than initial resources
  // remove all valuations etc and replace current cache
  if (responseCache.size > initialResourceKeys.length) {
    const tempCache = new Map(responseCache);
    [...tempCache.keys()].forEach(key => {
      if (!initialResourceKeys.includes(key)) {
        tempCache.delete(key);
      }
    });
    LOGGER.log('trimCache from ', responseCache.size, ' to ', tempCache.size);
    responseCache = tempCache;
  }
}

function getCacheEntries() {
  return [...responseCache];
}

function getCacheKeys() {
  return [...responseCache.keys()];
}

function withDefaultTransform(request) {
  return {
    transform: body => body,
    ...request
  };
}

function trimHref(request) {
  return {
    ...request,
    href: request.href.trim()
  };
}

function getFromCache(href) {
  return responseCache.get(href.trim());
}

// We need to fake a unique batch/ids href
// for use as a cache key
function getCacheKey(request) {
  if (request.href.includes('orgId')) {
    request.href = removeParamFromHref(request.href, 'orgId');
  }
  if (request.name && request.name.length > 0) {
    return `${request.href.trim()}_${request.name}`;
  }
  return request.href.trim();
}

function removeParamFromHref(href, unwantedParams) {
  const hrefParams = href.split('&');
  const wantedParams = hrefParams.filter(
    item => !item.includes(unwantedParams)
  );
  const newHref = wantedParams.join('&');
  return newHref;
}

function cacheBatchValuations(request, response) {
  request.body.map(href => {
    const dataItem =
      find(response.body.items[0].items, item => item.href === href) ||
      find(response.body.errors[0].errors, error => error.href === href);
    // we must insert region=NA here as the batch/ids
    // call does not include it in the href which we match upon
    // and yet our true valuations call will include it
    // which causes cache miss
    const alterHref = href.replace('&include', '&region=NA&include');
    const fakeBody = mimicSingleValuationResponse(dataItem);
    addToCache({ href: alterHref }, fakeBody);
  });
}

function cacheValuationsByIds(request, response) {
  response.body.items.map(item => {
    // we must insert region=NA here as the valuations/vin
    // call does not need it in the href which we match upon
    // and yet our valuations/id call will include it
    // which causes cache miss
    let alterHref = item.href.includes('region=')
      ? item.href
      : item.href.replace('&include', '&region=NA&include');
    // we also have to fix color values as they are returned all uppercase
    // which also causes a cache miss
    alterHref = alterHref.includes('color=')
      ? patchColors(alterHref)
      : alterHref;
    const fakeBody = mimicSingleValuationResponse(item);
    addToCache({ href: alterHref }, fakeBody);
  });
}

// we want to mimic the response body from true valuations call
function mimicSingleValuationResponse(item) {
  if (hasProp(item, 'message')) {
    return {
      code: 404,
      body: {
        message: item.message,
        developerMessage: item.developerMessage
      }
    };
  }
  return {
    body: {
      count: 1,
      items: [item]
    }
  };
}

function mimicNoTransactions() {
  return {
    code: 200,
    body: {
      count: 0,
      items: [],
      odometerUnits: 'miles',
      currency: 'USD'
    }
  };
}

function mimicNoLocations() {
  return {
    code: 200,
    body: {
      count: 0,
      items: []
    }
  };
}

function addCachedResponse(request) {
  const cachedResponse = getFromCache(getCacheKey(request));
  if (cachedResponse) {
    return { ...request, cachedResponse };
  }
  // External no transactions component
  // mimic empty transaction response
  if (
    EXTERNAL &&
    !window.MMR.externalIncludeTransactions &&
    request.href.includes('/valuation-samples/')
  ) {
    const mimicTxnResponse = mimicNoTransactions();
    addToCache(request, mimicTxnResponse);
    return { ...request, cachedResponse: mimicTxnResponse };
  }
  // External no transactions component
  // mimic empty locations response as well
  if (
    EXTERNAL &&
    !window.MMR.externalIncludeTransactions &&
    request.href.includes('/locations?operatedBy=MANHEIM')
  ) {
    const mimicLocationsResponse = mimicNoLocations();
    addToCache(request, mimicLocationsResponse);
    return { ...request, cachedResponse: mimicLocationsResponse };
  }
  return request;
}

function hasValidHref(request) {
  return (
    hasProp(request, 'href') &&
    typeof request.href === 'string' &&
    request.href.startsWith('http')
  );
}

function hasValidTransform(request) {
  return (
    hasProp(request, 'transform') && typeof request.transform === 'function'
  );
}

function requestsValidationError(requests) {
  if (!Array.isArray(requests)) {
    return new Error('`requests` must be an array');
  }
  if (!requests.every(isObject)) {
    return new Error('All requests must be objects');
  }
  if (!requests.every(hasValidHref)) {
    return new Error(
      'All requests must have a valid href starting with "http"'
    );
  }
  if (!requests.every(r => !hasProp(r, 'transform') || hasValidTransform(r))) {
    return new Error(
      'All requests must either have a ' +
        'valid transform function or not ' +
        'have a `transform` property at all'
    );
  }
}

function assertNotMakingRealAPICallInTests() {
  if (NODE_ENV === 'test') {
    throw new Error(
      'Oops! You are trying to make a real API call during a test.'
    );
  }
}

function preprocessRequests(rawRequests) {
  return rawRequests
    .map(withDefaultTransform)
    .map(trimHref)
    .map(addCachedResponse);
}

function constructPostBody(requests) {
  const requestProps = requests.map(request => {
    const props = {};
    const authTicket = getAuthTicket();
    const { userId } = getUserInfo(authTicket);

    props.href = userId ? formatHref(request.href, userId) : request.href;

    if (bearerToken) {
      props.bearer_token = bearerToken;
    }

    if (request.name) {
      props.name = request.name;
    }
    if (request.body && request.method) {
      props.body = request.body;
      props.method = request.method;
    }
    if (request.api_version) {
      props.api_version = request.api_version;
    }
    return props;
  });

  return {
    requests: uniqWith(requestProps, isEqual)
  };
}

function formatHref(href, userId) {
  if (href.includes('valuations/id') || href.includes('valuations/vin')) {
    const formattedHref = href.includes('?')
      ? `${href}&orgId=${userId}`
      : `${href}?orgId=${userId}`;
    return formattedHref;
  }
  return href;
}

// function gapiResponseValidationErrorOauth(error, postBody, response) {}

function gapiResponseValidationError(error, postBody, response) {
  try {
    const additionalInfo = `\n(postBody: ${postBody},\nerror: ${error})`;

    // Validate the GAPI response itself
    if (!isObject(response)) {
      return new Error(`GAPI response is ${response}${additionalInfo}`);
    }
    if (!hasProp(response, 'status') || typeof response.status !== 'number') {
      return new Error(
        `No status code in GAPI response: ${response}${additionalInfo}`
      );
    }
    switch (response.status) {
      case 401: // Unauthorized
        redirectToLogin();
        LOGGER.info(
          `Authorization failed: ${JSON.stringify(
            response.body.errors
          )}${additionalInfo}`
        );
        return new Error('Authorization failed');
      case 200: // Success
        break;
      default:
        // All others
        // Eventually, this will only indicate a GAPI failure.
        // For now, we still need to continue to find out what
        // went wrong, so we want to keep going.
        LOGGER.info(`Non-200 (${response.status}) GAPI response`);
        if (response.status >= 500 && response.status <= 596) {
          return new Error('Service Temporarily Unavailable.');
        }
        break;
    }
    if (!hasProp(response, 'body') || !isObject(response.body)) {
      return new Error(
        `No response body in GAPI response: ${response}${additionalInfo}`
      );
    }
    if (hasProp(response.body, 'errors')) {
      return new Error(
        `GAPI errors: ${JSON.stringify(response.body.errors)}${additionalInfo}`
      );
    }
    if (!hasProp(response.body, 'responses')) {
      return new Error(
        `No 'responses' array in GAPI response body: ` +
          `${JSON.stringify(response)}\n${additionalInfo}`
      );
    }
  } catch (err) {
    return err;
  }
}

function wrapResponse(response) {
  let checkedSuccess = false;

  return (function wrapped() {
    return {
      get success() {
        checkedSuccess = true;
        return !(response instanceof Error);
      },
      get payload() {
        // Force the developer using this object to at least superficially
        // deal with potential errors
        if (!checkedSuccess) {
          LOGGER.error(
            new Error(
              'You must check the `success` property before ' +
                'accessing `payload`, because `payload` could ' +
                'potentially be an Error object.'
            )
          );
        }
        return response;
      }
    };
  })();
}

function errorFromArray(errors) {
  if (errors.length > 0) {
    return new Error(errors[0].message);
  }
  return new Error('An `errors` field was present, but it was an empty array');
}

function constructResponse(requests, unfetchedRequests, responses) {
  const rawResponses = requests.map(request => {
    try {
      const isBatch = request.batch === true;
      const vinDecode =
        request.href && request.href.includes('/valuations/vin/');

      const isValuationSamples =
        request.href && request.href.includes('/valuation-samples/');

      if (hasProp(request, 'cachedResponse')) {
        if (
          hasProp(request, 'validate') &&
          typeof request.validate === 'function'
        ) {
          const responseError = request.validate(request.cachedResponse);
          if (responseError) {
            return responseError;
          }
        }
        return request.transform(request.cachedResponse.body);
      }

      const responseIndex = unfetchedRequests.findIndex(r => r === request);
      const response = responses[responseIndex];

      if (!isObject(response)) {
        return new Error('Invalid or mismatched response');
      }

      if (
        vinDecode &&
        (response.code === undefined ||
          (response.code >= 500 && response.code < 596))
      ) {
        return new Error('System Temporarily Unavailable.');
      }

      if (hasProp(response, 'errors')) {
        return errorFromArray(response.errors);
      }
      // if transactions fail we still want to be able to dislay valuations
      if (isValuationSamples && response.code !== 404 && response.code >= 400) {
        LOGGER.error(
          `valuationSamples call failed with code: ${response.code}`
        );
        return [];
      }

      if (response.code === 596) {
        return new Error('Service Not Found.');
      }

      if (response.code >= 500 && response.code <= 596) {
        return new Error('System Temporarily Unavailable.');
      }

      if (
        !hasProp(response, 'body') ||
        !isObject(response.body) ||
        !hasProp(response, 'code') ||
        !hasProp(response, 'name')
      ) {
        return new Error('Invalid response shape');
      }

      if (hasProp(response.body, 'errors')) {
        if (
          isBatch &&
          response.code !== 200 &&
          response.body.errors[0].errors.length > 0
        ) {
          return errorFromArray(response.body.errors[0].errors);
        }

        if (!isBatch) {
          return errorFromArray(response.body.errors);
        }
      }

      if (
        hasProp(request, 'validate') &&
        typeof request.validate === 'function'
      ) {
        const responseError = request.validate(response);
        if (responseError) {
          if (response.code && response.code === 404) {
            addToCache(request, response);
          }
          return responseError;
        }
      }

      // we only want to cache 200 or 404 responses
      if (response.code === 200 || response.code === 404) {
        addToCache(request, response);

        // expand batch results
        if (isBatch) {
          cacheBatchValuations(request, response);
        }
        // expand vinDecode results
        if (vinDecode) {
          cacheValuationsByIds(request, response);
        }
      }
      return request.transform(response.body);
    } catch (err) {
      return err;
    }
  });

  return rawResponses.map(wrapResponse);
}

function syncErrorOrCachedResponses(rawRequests) {
  const requestsError = requestsValidationError(rawRequests);
  if (requestsError) {
    return [false, requestsError];
  }

  const requests = preprocessRequests(rawRequests);
  const unfetchedRequests = requests.filter(request => !request.cachedResponse);

  if (unfetchedRequests.length === 0) {
    return [true, constructResponse(requests), requests, unfetchedRequests];
  }

  return [null, null, requests, unfetchedRequests];
}

// TODO: consider taking the `name` field into account for returning responses
function gapiCall(rawRequests) {
  return new Promise((resolve, reject) => {
    const [
      syncShouldResolve,
      syncResult,
      requests,
      unfetchedRequests
    ] = syncErrorOrCachedResponses(rawRequests);

    if (syncResult) {
      logGapiCall(requests, unfetchedRequests, syncResult);
      if (syncShouldResolve) {
        resolve(syncResult);
      } else {
        reject(syncResult);
      }
      return;
    }

    assertNotMakingRealAPICallInTests();

    const token = window.MMR.token || INJECTED_CONFIG.jwtToken;
    const postBody = constructPostBody(unfetchedRequests);
    const headers = window.MMR.tracer
      ? {
          Authorization: `Bearer ${token}`,
          'X-Mmr-Version': `${window.MMR.version}`,
          'X-Velocity-Tracer': `${window.MMR.tracer}`
        }
      : {
          Authorization: `Bearer ${token}`,
          'X-Mmr-Version': `${window.MMR.version}`
        };

    superagent('POST', gatewayEndpoint)
      .set(headers)
      .send(postBody)
      .end((error, response) => {
        try {
          const responseError = gapiResponseValidationError(
            error,
            JSON.stringify(postBody),
            response
          );
          if (responseError) {
            logGapiCall(
              requests,
              unfetchedRequests,
              null,
              response && response.body
            );
            reject(responseError);
            return;
          }
          const responses = constructResponse(
            requests,
            unfetchedRequests,
            response && response.body && response.body.responses
          );
          logGapiCall(
            requests,
            unfetchedRequests,
            responses,
            response && response.body
          );
          resolve(responses);
        } catch (err) {
          logGapiCall(
            requests,
            unfetchedRequests,
            null,
            response && response.body
          );
          reject(err);
        }
      });
  });
}

gapiCall.sync = function gapiCallSync(rawRequests) {
  const [syncShouldResolve, syncResult] = syncErrorOrCachedResponses(
    rawRequests
  );

  if (syncShouldResolve) {
    return syncResult;
  }

  throw new Error('Your synchronous GAPI call returned an error. Check stubs.');
};

function singleGapiCall(request) {
  return gapiCall([request]).then(responses => responses[0]);
}

singleGapiCall.sync = function singleGapiCallSync(request) {
  return gapiCall.sync([request])[0];
};

function getJWT(authTicket) {
  if (jwtToken) {
    LOGGER.log('Authentication: using static token from config');
    return jwtToken;
  }

  const base64AuthTicket = authTicket
    ? Buffer.from(authTicket).toString('base64')
    : undefined;

  return new Promise(resolve => {
    if (!base64AuthTicket) {
      LOGGER.log(`auth_tkt was ${base64AuthTicket}`);
      redirectToLogin();
      if (EXTERNAL) {
        resolve();
      }
      return;
    }

    LOGGER.log(`Authentication: requesting JWT for auth_tkt ${authTicket}`);
    superagent
      .get(`${gatewayEndpoint}/authenticate`)
      .set({ 'X-Mmr-Version': `${window.MMR.version}` })
      .query({ auth_tkt: base64AuthTicket })
      .end((error, response) => {
        if (!error && response.status === 200 && response.body.jwtToken) {
          resolve(response.body.jwtToken);
        } else {
          // Unauthorized, redirect to login page
          LOGGER.error('Invalid authentication response:', response);
          redirectToLogin();
        }
      });
  });
}

function callRefreshEndpoint() {
  return new Promise(resolve => {
    superagent
      .get(`${apiURL}/oauth/refresh`)
      .withCredentials()
      .end((error, response) => {
        if (!error && response.status === 200 && response.body.accessToken) {
          resolve(response.body);
        } else {
          window.location.href = `${apiURL}/oauth/login`;
        }
      });
  });
}

function addToCache(request, response) {
  responseCache.set(getCacheKey(request), response);
}

function wrapAddToCache(href, response) {
  addToCache({ href }, response);
}

function addResponsesToCache(hrefs, responses) {
  zipWith(hrefs, responses.map(r => r.body), wrapAddToCache);
}

function clearCache() {
  responseCache.clear();
}

function cacheSize() {
  return responseCache.size;
}

function redirectToLogin() {
  if (EXTERNAL) {
    if (
      window.MMR.authFailureCallback &&
      typeof window.MMR.authFailureCallback === 'function'
    ) {
      try {
        setTimeout(() => {
          window.MMR.authFailureCallback();
        }, 0);
      } catch (err) {
        LOGGER.error('error calling authFailureCallback provided:', err);
      }
    } else {
      LOGGER.error(
        'Invalid authFailureCallback provided:',
        window.MMR.authFailureCallback
      );
    }
    return;
  }

  if (NODE_ENV !== 'test' && window.name !== 'nodejs') {
    setTimeout(() => {
      window.location.href = redirectURL();
    }, 500);
  }
}

function redirectURL() {
  const origin = document.location.origin.replace(
    'mmr',
    window.MMR.layout === 'mobile' ? 'm' : 'www'
  );

  let href =
    window.MMR.layout === 'mobile' ? '/mobile/login?back=' : '/login?back=';
  href += encodeURIComponent(document.location.href);
  return `${origin}${href}`;
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  getJWT,
  gapiCall,
  singleGapiCall,
  callRefreshEndpoint,
  addCachedResponse,
  addToCache,
  assertNotMakingRealAPICallInTests,
  constructPostBody,
  constructResponse,
  getFromCache,
  addResponsesToCache,
  clearCache,
  cacheSize,
  gapiResponseValidationError,
  hasValidHref,
  hasValidTransform,
  trimHref,
  requestsValidationError,
  withDefaultTransform,
  wrapResponse,
  redirectURL,
  removeParamFromHref,
  getCacheEntries,
  getCacheKeys,
  updateCache,
  trimCache,
  setBearerToken
};
