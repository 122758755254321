////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

// We base64 encoded the src data, because this was the only way to get the
// img tag to NOT treat the markup it receives as a path to the resource.
// Most of the time, you'll want to just use the width/height of the SVG.
function Svg({ markup, width, height, ...otherProps }) {
  return (
    <span {...otherProps}>
      <img
        alt=""
        style={{ border: 'none', width, height }}
        src={`data:image/svg+xml;base64,${window.btoa(markup)}`}
      />
    </span>
  );
}

Svg.propTypes = {
  markup: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
};

export default Svg;
