////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import {
  resetValuation,
  openMobileEditLookup,
  closeMobileEditLookup,
  openLookupWizard
} from 'SRC/store/actions/creators';

/* Component */
import MakeModel from '../MakeModel';
import VINEntry from '../VINEntry';
import LookupButtons from './LookupButtons';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class SelectVehicle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editLookupPosition: this.hide()
    };
  }

  render() {
    const { editLookupPosition } = this.state;

    return (
      <div className="hide-when-wizard-present">
        <div
          className={`${styles.editLookupContainer} ${editLookupPosition ||
            ''}`}
          data-test="SelectVehicle"
        >
          <div className="mui-row">
            <div className="mui-col mui-col-1-1 mui-col-1-2-sm">
              <VINEntry />
            </div>
          </div>
          <h2 className={styles.lookupDivider}>or</h2>
          <MakeModel />
          <div
            className={`${styles.lookupActions} mui-row`}
            data-test="SelectVehicleLookupActions"
          >
            <div className="mui-col mui-col-1-1">
              <button
                type="button"
                onClick={this.handleView}
                className="mui-button--sm mui-button--primary"
                data-test="SelectVehicleView"
              >
                View
              </button>
            </div>
          </div>
        </div>
        <LookupButtons
          handleEditLookup={this.handleEdit}
          handleNewLookup={this.resetLookup}
        />
      </div>
    );
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.showMobileEditLookup === false) {
      this.setState({
        editLookupPosition: this.hide()
      });
    }

    if (
      this.props.showMobileEditLookup !== nextProps.showMobileEditLookup &&
      nextProps.showMobileEditLookup === true
    ) {
      this.setState({
        editLookupPosition: this.show()
      });
    }
  }

  resetLookup = () => {
    this.props.dispatchResetValuation();
  };

  handleEdit = () => {
    this.props.dispatchOpenMobileEditLookup();
  };

  handleView = () => {
    this.props.dispatchCloseMobileEditLookup();
  };

  show = () => {
    return styles.show;
  };

  hide = () => {
    return styles.hide;
  };
}

SelectVehicle.propTypes = {
  showMobileEditLookup: PropTypes.bool,
  dispatchResetValuation: PropTypes.func,
  dispatchOpenMobileEditLookup: PropTypes.func,
  dispatchCloseMobileEditLookup: PropTypes.func
};

const stateProps = state => ({
  showMobileEditLookup: state.showMobileEditLookup
});

const actionProps = {
  dispatchResetValuation: resetValuation,
  dispatchOpenMobileEditLookup: openMobileEditLookup,
  dispatchCloseMobileEditLookup: closeMobileEditLookup,
  dispatchOpenLookupWizard: openLookupWizard
};

const SelectVehicleContainer = connect(
  stateProps,
  actionProps
)(SelectVehicle);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default SelectVehicleContainer;
export { SelectVehicle };
