/* eslint-disable camelcase */

////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import superagent from 'superagent';

/* Internal */

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function readS3File(filename) {
  return new Promise((resolve, reject) => {
    const { origin } = document.location;
    const s3Url = `${origin}/${filename}`;
    if (origin === null || window.name === 'nodejs') {
      reject(new Error('no client connected yet'));
    }
    superagent
      .get(s3Url)
      .accept('json')
      .then(response => {
        // if s3 response.header.content-type will be octet-stream and
        // the file data will be a string in response.text instead of response.body
        resolve(response.body ? response.body : JSON.parse(response.text));
      })
      .catch(error => {
        reject(error);
      });
  });
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export { readS3File };
