////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  MID_CHANGED,
  MID_CHANGED_FAILURE,
  CLEAR_VALUATION,
  SHOW_TRANSACTIONS
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function midChanged(mid, country, region, color, grade, odometer) {
  return {
    type: MID_CHANGED,
    mid,
    country,
    region,
    color,
    grade,
    odometer
  };
}

function midChangedFailure(errorMessage = '') {
  return {
    type: MID_CHANGED_FAILURE,
    errorMessage
  };
}

function clearValuation() {
  return { type: CLEAR_VALUATION };
}

function showTransactions(visibleRows, transactions, analyticsCode = '') {
  return {
    type: SHOW_TRANSACTIONS,
    visibleRows,
    transactions,
    analyticsCode
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { midChanged, midChangedFailure, clearValuation, showTransactions };
