////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import VehicleDetails from 'SRC/containers/VehicleDetails';
import {
  vehicleSummary,
  relevantSelections,
  relevantPossibilities
} from 'SRC/util/vehicleSummary';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
function buildSummaryDisplay(selected, vin) {
  const { year, make, model, style } = selected;
  const summaryText = `${year} ${make} ${model} ${style}`.trim();

  let summaryDisplay = '';
  if (year) {
    summaryDisplay = (
      <h4
        data-test="MMRVehSummary"
        className={`${vin ? 'mui-m-q-b' : 'mui-m-b'} ${styles.summary}`}
      >
        {summaryText}
      </h4>
    );
  }
  return summaryDisplay;
}

function buildVinDisplay(vin) {
  let vinDisplay = '';
  if (vin) {
    const vinText =
      vin.length === 17
        ? [
            <span key="1">{vin.substr(0, 9)}</span>,
            <strong key="2">{vin.substr(-8)}</strong>
          ]
        : vin;

    vinDisplay = (
      <p data-test="MMRVehSummaryVin" className={`mui-m-n-t ${styles.vin}`}>
        {vinText}
      </p>
    );
  }
  return vinDisplay;
}

function VehicleSummary({ possibilities, selections, vin }) {
  const summaryDisplay = buildSummaryDisplay(
    vehicleSummary(selections, possibilities),
    vin
  );
  const vinDisplay = buildVinDisplay(vin);

  return (
    <div className={styles.vehicleSummary}>
      {summaryDisplay}
      {vinDisplay}
      <VehicleDetails />
    </div>
  );
}

const idAndName = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string
});

VehicleSummary.propTypes = {
  possibilities: PropTypes.shape({
    years: PropTypes.arrayOf(PropTypes.number),
    makes: PropTypes.arrayOf(idAndName),
    models: PropTypes.arrayOf(idAndName),
    styles: PropTypes.arrayOf(idAndName)
  }),
  selections: PropTypes.shape({
    year: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    make: PropTypes.string,
    model: PropTypes.string,
    style: PropTypes.string
  }),
  vin: PropTypes.string
};

const stateProps = state => ({
  possibilities: relevantPossibilities(state),
  selections: relevantSelections(state),
  showSummary: !state.loading && state.mostRecentLookup.successfulLookup,
  vin: state.vin
});

const VehicleSummaryContainer = connect(stateProps)(VehicleSummary);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default VehicleSummaryContainer;
export { VehicleSummary };
