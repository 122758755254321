////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { maybeCurrency } from 'SRC/util/formatting';
import angleFor from 'SRC/components/Speedometer/angleGenerator';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Speedometer({ above, below, average, adjusted, active, currency }) {
  const angle = angleFor({ adjusted, average, above, below, active });

  return (
    <div
      data-test="Speedometer"
      className={`${styles.container} ${active ? styles.active : ''}`}
    >
      <svg
        className={styles.svg}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="-20 0 140 81"
        preserveAspectRatio="xMidYMax meet"
      >
        <defs>
          <clipPath id="half-circle">
            <rect x="0" y="0" width="100" height="75" />
          </clipPath>
        </defs>
        <g clipPath="url(#half-circle)">
          <circle cx="50" cy="75" r="50" fill="#cececf" />
          <path
            className={styles.highlight}
            d="M 50 75
               L 6.1 51.1
               A 50 50 0 0 1 93.9 51.1
               Z"
          />
          <circle className={styles.dashed} cx="50" cy="75" r="40" />
          <g>
            <line
              x1="50"
              y1="75"
              x2="-1.5"
              y2="47"
              strokeWidth="0.2"
              strokeLinecap="round"
            />
            <line
              x1="50"
              y1="75"
              x2="101.5"
              y2="47"
              strokeWidth="0.2"
              strokeLinecap="round"
            />
            <line
              x1="50"
              y1="75"
              x2="50"
              y2="15"
              strokeWidth="0.2"
              strokeLinecap="round"
            />
          </g>
          <circle className={styles.cover} cx="50" cy="75" r="20" />
        </g>
        <g className={styles.needle}>
          <path
            d="M 47 75
               l 2.5 -42
               c 0.05 -0.65 0.85 -0.65 1  0
               l 2.5 42.2
               c -0.1 3.8 -5.9 3.8 -6 0
               Z"
            fill="#DDDCDD"
            style={{ transform: `rotate(${angle}deg)` }}
            transform={`rotate(${angle} 50 75)`}
          />
        </g>
        <g className={`${styles.labels} font--lato`}>
          <g transform="translate(50 10)">
            <text
              data-test="averageValue"
              className={`${styles.major} ${styles.value}`}
              x="0"
              y="0"
              textAnchor="middle"
            >
              {maybeCurrency(average, currency)}
            </text>
          </g>
          <g transform="translate(-20 25)">
            <text className={`${styles.minor} ${styles.title}`} x="0" y="0" />
            <text
              data-test="belowValue"
              className={`${styles.minor} ${styles.value}`}
              x="0"
              y="8"
            >
              {maybeCurrency(below, currency)}
            </text>
          </g>
          <g transform="translate(90 25)">
            <text className={`${styles.minor} ${styles.title}`} x="-7" y="0" />
            <text
              data-test="aboveValue"
              className={`${styles.minor} ${styles.value}`}
              x="-7"
              y="8"
            >
              {maybeCurrency(above, currency)}
            </text>
          </g>
        </g>
      </svg>
    </div>
  );
}

Speedometer.propTypes = {
  above: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  below: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  average: PropTypes.number,
  adjusted: PropTypes.number,
  active: PropTypes.bool,
  currency: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default Speedometer;
