////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  CLOSE_MOBILE_EDIT_LOOKUP,
  OPEN_MOBILE_EDIT_LOOKUP
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function closeMobileEditLookup() {
  return {
    type: CLOSE_MOBILE_EDIT_LOOKUP,
    showMobileEditLookup: false
  };
}

function openMobileEditLookup() {
  return {
    type: OPEN_MOBILE_EDIT_LOOKUP,
    showMobileEditLookup: true
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { closeMobileEditLookup, openMobileEditLookup };
