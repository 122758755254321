////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function LoadingSpinner({ style }) {
  const classNames = [
    'icon',
    'icon-spinner',
    'anim-spin',
    'valign--middle',
    'align--center',
    styles.custom
  ].join(' ');

  return <i className={classNames} style={style} />;
}

LoadingSpinner.propTypes = {
  style: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default LoadingSpinner;
