////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function MMRBlueLogo() {
  return (
    <span className={styles.logo}>
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 604.56 90.99"
        height="39px"
        width="260px"
      >
        <defs>
          <linearGradient
            id="linear-gradient"
            x1="70.37"
            y1="72.53"
            x2="132.13"
            y2="128.92"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ffeea2" />
            <stop offset="0.35" stopColor="#efb733" />
            <stop offset="0.47" stopColor="#f0b930" />
            <stop offset="0.57" stopColor="#f3bf25" />
            <stop offset="0.66" stopColor="#f8c914" />
            <stop offset="0.73" stopColor="#fed301" />
            <stop offset="1" stopColor="#93540b" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-2"
            x1="73.34"
            y1="58.21"
            x2="136.93"
            y2="154.29"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#cb962f" />
            <stop offset="0.1" stopColor="#f2df8a" />
            <stop offset="0.47" stopColor="#edad1f" />
            <stop offset="0.74" stopColor="#f6c76d" />
            <stop offset="1" stopColor="#ce8a2d" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-3"
            x1="113.89"
            y1="59.14"
            x2="89.84"
            y2="143.09"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.01" stopColor="#916e18" />
            <stop offset="0.03" stopColor="#ad9033" />
            <stop offset="0.07" stopColor="#c6af4b" />
            <stop offset="0.1" stopColor="#dbc95f" />
            <stop offset="0.14" stopColor="#ebdd6f" />
            <stop offset="0.19" stopColor="#f6eb7a" />
            <stop offset="0.25" stopColor="#fdf480" />
            <stop offset="0.34" stopColor="#fff682" />
            <stop offset="0.39" stopColor="#fcf27f" />
            <stop offset="0.43" stopColor="#f3e577" />
            <stop offset="0.47" stopColor="#e3d169" />
            <stop offset="0.52" stopColor="#cdb455" />
            <stop offset="0.57" stopColor="#b18f3c" />
            <stop offset="0.61" stopColor="#8f621e" />
            <stop offset="0.63" stopColor="#7a460b" />
            <stop offset="0.83" stopColor="#eac35c" />
            <stop offset="1" stopColor="#a2761d" />
          </linearGradient>
          <radialGradient
            id="radial-gradient"
            cx="369.87"
            cy="163.49"
            r="11.54"
            gradientTransform="translate(-1694.46 -665.74) scale(4.86)"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#003265" />
            <stop offset="0.11" stopColor="#063769" />
            <stop offset="0.26" stopColor="#174473" />
            <stop offset="0.43" stopColor="#335a84" />
            <stop offset="0.61" stopColor="#59789c" />
            <stop offset="0.8" stopColor="#8b9ebb" />
            <stop offset="1" stopColor="#c4cbde" />
          </radialGradient>
          <linearGradient
            id="linear-gradient-4"
            x1="75.35"
            y1="128.41"
            x2="125.47"
            y2="76.87"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#ebd47a" />
            <stop offset="0.1" stopColor="#fffde7" />
            <stop offset="0.47" stopColor="#e6903b" />
            <stop offset="0.74" stopColor="#fff4c1" />
            <stop offset="1" stopColor="#b56324" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-5"
            x1="102.27"
            y1="126.4"
            x2="102.27"
            y2="96.66"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#af8718" />
            <stop offset="0.24" stopColor="#dbc142" />
            <stop offset="0.58" stopColor="#f4d793" />
            <stop offset="1" stopColor="#9a8016" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-6"
            x1="95.08"
            y1="58.88"
            x2="110.43"
            y2="150.11"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.06" stopColor="#efbf5f" />
            <stop offset="0.25" stopColor="#f4d767" />
            <stop offset="0.47" stopColor="#dfad3f" />
            <stop offset="0.65" stopColor="#bf851a" />
            <stop offset="0.89" stopColor="#f6d459" />
            <stop offset="1" stopColor="#c28031" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-7"
            x1="58.44"
            y1="76.84"
            x2="149.87"
            y2="128.44"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#d09f43" />
            <stop offset="0.21" stopColor="#fffabe" />
            <stop offset="0.47" stopColor="#f0b944" />
            <stop offset="0.74" stopColor="#fff9ba" />
            <stop offset="1" stopColor="#d39542" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-8"
            x1="57.6"
            y1="81.18"
            x2="100.31"
            y2="81.18"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="#f3df80" />
            <stop offset="0.09" stopColor="#f6e79f" />
            <stop offset="0.21" stopColor="#faf1c0" />
            <stop offset="0.33" stopColor="#fdf8d9" />
            <stop offset="0.43" stopColor="#fefce8" />
            <stop offset="0.52" stopColor="#fffded" />
            <stop offset="0.59" stopColor="#fffbe8" />
            <stop offset="0.68" stopColor="#fef4d8" />
            <stop offset="0.78" stopColor="#fce9be" />
            <stop offset="0.89" stopColor="#f9da9b" />
            <stop offset="1" stopColor="#f6c76d" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-9"
            x1="-17.6"
            y1="-159.47"
            x2="21.01"
            y2="-159.47"
            gradientTransform="translate(125.53 -38.98) rotate(180)"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-10"
            x1="104.37"
            y1="122.16"
            x2="145.81"
            y2="122.16"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0.1" stopColor="#f2df8a" />
            <stop offset="0.47" stopColor="#a26611" />
            <stop offset="1" stopColor="#f6c76d" />
          </linearGradient>
          <linearGradient
            id="linear-gradient-11"
            x1="159.09"
            y1="-31.19"
            x2="201.82"
            y2="-31.19"
            gradientTransform="matrix(0.05, -1, 1, 0.05, 102.72, 305.23)"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-12"
            x1="-108.44"
            y1="-71.62"
            x2="-73.03"
            y2="-71.62"
            gradientTransform="translate(37.22 157.24) rotate(100.45)"
            xlinkHref="#linear-gradient-8"
          />
          <linearGradient
            id="linear-gradient-13"
            x1="-122.96"
            y1="-230.75"
            x2="-85.34"
            y2="-230.75"
            gradientTransform="translate(321.39 42.97) rotate(-83.94)"
            xlinkHref="#linear-gradient-10"
          />
          <linearGradient
            id="linear-gradient-14"
            x1="212.19"
            y1="29.25"
            x2="253.63"
            y2="29.25"
            gradientTransform="matrix(0.02, -1, 1, 0.02, 88.22, 310.05)"
            xlinkHref="#linear-gradient-10"
          />
          <linearGradient
            id="linear-gradient-15"
            x1="-224.68"
            y1="-143.33"
            x2="-186.06"
            y2="-143.33"
            gradientTransform="translate(248.72 266.76) rotate(14.29)"
            xlinkHref="#linear-gradient-10"
          />
        </defs>
        <title>MMR text blue logo</title>
        <circle className={styles.cls1} cx="45.81" cy="45.96" r="42.76" />
        <path
          className={styles.cls2}
          d="M58.44,100.77A42.76,42.76,0,1,0,101.19,58,42.76,42.76,0,0,0,58.44,100.77Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls3}
          d="M59.07,101.25a42.76,42.76,0,1,0,42.76-42.76A42.76,42.76,0,0,0,59.07,101.25Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cs4}
          d="M61,101.25a40.84,40.84,0,1,0,40.84-40.83A40.83,40.83,0,0,0,61,101.25Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cs5}
          d="M101.78,64.25a37,37,0,1,0,37,37A37,37,0,0,0,101.78,64.25Zm0,69.86a32.85,32.85,0,1,1,0-65.7c16.83,0,32.86,15.37,32.86,32.84A32.85,32.85,0,0,1,101.78,134.11Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls6}
          d="M101.83,55.87a45.39,45.39,0,1,0,45.38,45.38A45.38,45.38,0,0,0,101.83,55.87Zm0,85.32a39.94,39.94,0,1,1,39.94-39.94A39.94,39.94,0,0,1,101.83,141.19Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M125.41,78.42c5.87,5.88,10.38,13.7,10.38,22.67,0,5.81-3.58,12-6.24,16.77h0c2.66-4.73,5.48-11.16,5.48-17,0-9-4.52-16.78-10.39-22.67Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M120.57,127.85s-1,.59-1,.78c-5.18,3.47-10.72,4.81-17.41,4.81h0c8.92.81,15-2.36,16.54-3.52A5.16,5.16,0,0,0,120.57,127.85Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls8}
          d="M101.76,131.36a30.46,30.46,0,1,1,0-60.92c15.24,0,30.75,14.43,30.75,30.46C132.51,120.34,115.16,131.36,101.76,131.36Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls9}
          d="M131.24,93.2a30.43,30.43,0,0,0-29.48-22.76,31.08,31.08,0,0,0-6.36.66,30.47,30.47,0,0,0-22,41,30.46,30.46,0,0,1,8.17-29.47c-1.34,8.32-3.35,22.27-4.41,36.28a30.61,30.61,0,0,0,10.53,9v.12c.38-6.27,1.28-19.47,3-36.23,0,0,4.76,23.41,9.68,38.74h6.75a378.27,378.27,0,0,0,10.46-38.34c1.47,14.37,1.77,26.36,2.22,33.37A43.16,43.16,0,0,0,131,110.81c-1-10.33-1.81-16.37-2.89-23.3A31,31,0,0,1,131.24,93.2Zm-27.15,8.63s-3.56-11.16-7.53-27.17a28.14,28.14,0,0,1,6.34-.73,30.23,30.23,0,0,1,8.32,1.16C107.29,90.86,104.09,101.82,104.09,101.82Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls10}
          d="M101.76,60.77a40.49,40.49,0,1,0,40.48,40.48C142.25,79.69,122.53,60.77,101.76,60.77Zm0,70.59a30.46,30.46,0,1,1,0-60.92c15.29,0,30.64,13.4,30.75,30.46C132.66,120.39,115.21,131.36,101.76,131.36Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls11}
          d="M106.14,129.36C111,115.09,117.33,91.6,117.33,91.6c2.58,23.69,3.43,40.24,3.43,40.24l8.79-14c.1-2,.34-3.8.34-3.8-1-9.78-2.44-23.83-3.56-30.46,0,0-1.59-4.19-2-5.48a3.62,3.62,0,0,1,.54-3.75l-12-6.87c-4.85,19.41-9.43,33.4-9.43,33.4S99.3,88.66,94.82,71.42l-1.73-1.77L80.92,74.07a429.34,429.34,0,0,0-6.27,45.66l9.45,8.21,1.94-.84c.42-6.41,1.38-19.44,3.17-35.92,0,0,5.08,23.79,9.93,38.05Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls12}
          d="M102.18,61.9a39,39,0,1,0,39,39C141.19,80.16,122.14,61.9,102.18,61.9Zm32.85,39c0,5.81-2.82,12.24-5.48,17,0-.23-.3.85-.32.62-1.29-15-3.9-33.22-5.22-40.81-.06-.41.68.92.62.55C130.51,84.11,135,91.94,135,100.9ZM111.27,69.65l.85.3c-5.31,17.33-9.72,32.77-9.72,32.77s-4.67-14.91-9.32-33.06a32.71,32.71,0,0,1,18.18,0ZM79.13,77.93l.91-.64C78.73,85,75.8,103.06,74.5,118.41a39,39,0,0,1-4.86-17.51A32.46,32.46,0,0,1,79.13,77.93Zm5,50c.35-5.75,1.47-21.22,3.4-39,0,0,7.36,25,12.2,39.23h5.64c6.19-19.41,11.74-38.89,11.74-38.89,1.79,16.39,1.92,30.15,2.34,36.59a11.66,11.66,0,0,0,1.16,2s-1,.59-1,.78c-5.18,3.47-10.72,4.81-17.41,4.81A32.4,32.4,0,0,1,84.09,127.95Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls13}
          d="M74.5,118.41c1.64-16.65,4.63-34.84,5.54-41.11l-1.5,4c-1.3,7.51-3.23,19.76-4.44,32.61Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls14}
          d="M87.5,88.93s7.36,25,12.2,39.23l-.57,1.09c-3.5-10.3-8.09-25.87-10.37-33.71-.5-2-.85-3.84-.85-3.84Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls13}
          d="M102.41,102.71s4.85-14.92,9.72-32.77l-1.39,1.84c-3.37,13-6.56,23.06-7.89,27.14Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls13}
          d="M117.07,89.27c1.94,17.8,3.15,32.83,3.5,38.58l-1.16-2c-.42-6.34-1.28-17.56-2.75-31.56Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls15}
          d="M112.12,69.95a22.33,22.33,0,0,0-10.36-2,22.35,22.35,0,0,0-8.67,1.69h0a32.43,32.43,0,0,1,9.09-1.29,39,39,0,0,1,9.94,1.58Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M75.23,116.69l-.72,1.72c1.64-16.65,4.63-34.84,5.54-41.11C80,77.29,78.68,90.84,75.23,116.69Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M88.92,94.15l10.42,33C97,114.6,88.92,94.15,88.92,94.15Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M117.75,94.23c1.28,13.29,2.7,31.84,2.7,31.84A138.88,138.88,0,0,0,117.75,94.23Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M64.37,90.56h0c4.11-9.92,7.18-13.77,13-19.4l-1.61,1.28A32.61,32.61,0,0,0,64.37,90.56Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls7}
          d="M112.06,71.17c-1.16,9.64-9.65,31.54-9.65,31.54Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls16}
          d="M84.29,126.15c.77-12,2.89-33.77,2.89-33.77C83.72,113.8,84.29,126.15,84.29,126.15Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls16}
          d="M129,117.86c-1.86-23.26-3.59-31.55-3.59-31.55s1.93,23,2.32,25.47S129,117.86,129,117.86Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls16}
          d="M105.33,128.16c2.5-7.41,10.6-33.85,10.6-33.85C108.8,114.19,105.33,128.16,105.33,128.16Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls17}
          d="M101.83,57a44.32,44.32,0,1,0,44,44.32A44.17,44.17,0,0,0,101.83,57Zm0,85.77A41.45,41.45,0,1,1,143,101.33,41.32,41.32,0,0,1,101.83,142.78Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls18}
          d="M99.57,56.7A44.92,44.92,0,0,0,57.7,104.32c0,.13.19,1.38.34,1.37s.08-.87.07-1a44.73,44.73,0,0,1,11-32.4A44.16,44.16,0,0,1,99.58,56.91c.14,0,.75,0,.74-.13S99.71,56.69,99.57,56.7Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls19}
          d="M105.19,142.63A40.58,40.58,0,0,0,143,99.58c0-.13-.18-1.25-.3-1.24s-.07.79-.06.92a40.42,40.42,0,0,1-10,29.3,40,40,0,0,1-27.54,13.89c-.13,0-.68,0-.68.12S105.07,142.64,105.19,142.63Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls20}
          d="M145.56,98.6c-.15,0-.17.36-.15.5a43.69,43.69,0,0,1-40.7,46.27c-.14,0-.35.08-.34.22s.18.15.31.14a43.64,43.64,0,0,0,41-46.77C145.71,98.81,145.7,98.58,145.56,98.6Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls21}
          d="M57.62,101.54A44.92,44.92,0,0,0,103,145.85c.14,0,1.39-.13,1.39-.27s-.87-.12-1-.12a44.75,44.75,0,0,1-31.79-12.69,44.2,44.2,0,0,1-13.74-31.21c0-.15,0-.76-.1-.76S57.61,101.41,57.62,101.54Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls22}
          d="M143.07,101.33a40.44,40.44,0,0,0-9-26.16,41.3,41.3,0,0,0-27.55-15.35c-.14,0-1.28-.05-1.3.07s.78.22.91.23a41.72,41.72,0,0,1,27.56,15.32,40.48,40.48,0,0,1,9.18,26.24C142.83,102.89,143,102.65,143.07,101.33Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls23}
          d="M61,101.25A39.06,39.06,0,0,0,71.6,130a40,40,0,0,0,28.07,13c.13,0,1.26,0,1.26-.18s-.77-.15-.9-.15a40.45,40.45,0,0,1-28.08-13c-7.31-7.91-11.42-17.74-11-28.44Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls24}
          d="M99.67,56.88c0,.13.36.16.5.16A43.68,43.68,0,0,1,145.5,98.77c0,.14.07.36.21.35s.16-.17.15-.31A43.64,43.64,0,0,0,100,56.72C99.9,56.72,99.67,56.73,99.67,56.88Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls25}
          d="M95.55,142.22c-21.8-3.35-36.83-25.37-33.48-47.17s24.28-38.13,46.08-34.78a41.46,41.46,0,0,0-12.6,81.95Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls26}
          d="M142.58,107.59a41.31,41.31,0,0,0-34.4-47.23c21.8,3.36,36.86,25.22,33.5,47s-24.3,38.28-46.11,34.93A41.32,41.32,0,0,0,142.58,107.59Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls27}
          d="M108.63,60.32A40.62,40.62,0,0,0,61.3,92.7c0,.12-.13,1.25,0,1.27s.27-.75.29-.87A40.45,40.45,0,0,1,78.46,67.16a39.93,39.93,0,0,1,30.12-6.65c.13,0,.66.18.68,0S108.75,60.34,108.63,60.32Z"
          transform="translate(-56.33 -55.76)"
        />
        <line
          className={styles.cls28}
          x1="114.82"
          y1="4.16"
          x2="114.82"
          y2="86.83"
        />
        <path
          className={styles.cls29}
          d="M215.74,122.35h-4.48V95.93l-5.89,17.35h-2.76l-5.78-16.94v26h-4.48V85.25h4.22l7.5,20.64,7.45-20.64h4.22Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M229.59,85.25h4.33l8.65,37.1h-4.74l-1.77-8.75H227.4l-1.77,8.75h-4.69Zm5.63,24.33-3.49-17.09-3.49,17.09Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M265.87,122.35H261.8L252.22,97v25.38h-4.48V85.25h4.17l9.48,25.38V85.25h4.48Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M290.07,122.35h-4.48V105.47h-8.23v16.88h-4.48V85.25h4.48v16.21h8.23V85.25h4.48Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M311.4,89.26h-9.85v12.19H310v4h-8.49v12.87h9.85v4H297.07V85.25H311.4Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M321.92,122.35h-4.48V85.25h4.48Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M352.27,122.35h-4.48V95.93l-5.89,17.35h-2.76l-5.78-16.94v26h-4.48V85.25h4.22l7.5,20.64,7.45-20.64h4.22Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M395.21,122.35h-4.48V95.93l-5.89,17.35h-2.76L376.3,96.35v26h-4.48V85.25H376l7.5,20.64L391,85.25h4.22Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M409.06,85.25h4.33l8.65,37.1h-4.74l-1.77-8.75h-8.65l-1.77,8.75h-4.69Zm5.63,24.33L411.2,92.49l-3.49,17.09Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M435.59,85.25c5.63,0,8.75,2.45,8.75,10.58,0,5.16-1.25,8.18-4.48,9.69l5.68,16.83H440.8L435.7,106.4h-4v15.94H427.2V85.25Zm-3.91,4v13.18h4c3.44,0,4.22-2.66,4.22-6.62s-.78-6.57-4.22-6.57Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M462.06,100.57l8.6,21.78h-4.85l-6.57-16.78L456,112v10.37h-4.48V85.25H456v18.81l9-18.81h4.58Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M490.43,89.26h-9.85v12.19h8.49v4h-8.49v12.87h9.85v4H476.1V85.25h14.33Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M512.36,89.26h-6.2v33.09h-4.48V89.26h-6.25v-4h16.93Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M538.05,85.25c5.63,0,8.75,2.45,8.75,10.58,0,5.16-1.25,8.18-4.48,9.69L548,122.35h-4.74l-5.11-15.94h-4v15.94h-4.48V85.25Zm-3.91,4v13.18h4c3.44,0,4.22-2.66,4.22-6.62s-.78-6.57-4.22-6.57Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M568.33,89.26h-9.85v12.19H567v4h-8.49v12.87h9.85v4H554V85.25h14.33Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M582.71,85.25c5.63,0,8.81,2.61,8.81,10.84s-3.28,10.79-8.81,10.79h-3.86v15.48h-4.48V85.25Zm-3.86,4v13.6h3.7c3,0,4.48-1.82,4.48-6.77s-1.46-6.83-4.48-6.83Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M599.86,120.06c-2.35-2.71-2.71-6-2.71-16.26s.36-13.55,2.71-16.26a8,8,0,0,1,6.36-2.71,8.12,8.12,0,0,1,6.41,2.71c2.34,2.66,2.66,6,2.66,16.26s-.31,13.6-2.66,16.26a8.11,8.11,0,0,1-6.41,2.71A8,8,0,0,1,599.86,120.06Zm9.38-2.71c1.36-1.77,1.56-4.74,1.56-13.55s-.21-11.78-1.56-13.55a3.95,3.95,0,0,0-6,0c-1.36,1.77-1.56,4.74-1.56,13.55s.21,11.78,1.56,13.55a4,4,0,0,0,6,0Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M630.08,85.25c5.63,0,8.75,2.45,8.75,10.58,0,5.16-1.25,8.18-4.48,9.69L640,122.35H635.3l-5.11-15.94h-4v15.94h-4.48V85.25Zm-3.91,4v13.18h4c3.44,0,4.22-2.66,4.22-6.62s-.78-6.57-4.22-6.57Z"
          transform="translate(-56.33 -55.76)"
        />
        <path
          className={styles.cls29}
          d="M660.9,89.26h-6.2v33.09h-4.48V89.26H644v-4H660.9Z"
          transform="translate(-56.33 -55.76)"
        />
      </svg>
    </span>
  );
}

export default MMRBlueLogo;
