////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { COLUMN_HEADERS, NULL_VALUE, ZERO_TXNS } from 'SRC/constants';
import { maybeValue } from 'SRC/util/formatting';

/* Component */
import LoadingSpinner from 'SRC/components/LoadingSpinner';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
function TabletTableBody({
  columns,
  loading,
  transactions,
  visibleRows,
  successfulLookup
}) {
  if (loading) {
    return (
      <div className={styles.emptyBody}>
        <LoadingSpinner
          style={{ position: 'relative', width: '100%', marginTop: '75px' }}
        />
      </div>
    );
  }

  if (transactions && transactions.length > 0) {
    return (
      <ul className={styles.list}>
        {transactions.slice(0, visibleRows).map((transaction, index) => (
          <li
            key={index}
            className={`${styles.tabletTransaction} ${
              transaction.adjustmentsMatch ? styles.adjustmentsMatchRow : ''
            }`}
          >
            <div className={`${styles.tabletDataRow} mui-row`}>
              {columns.map(header => (
                <div
                  key={header}
                  className={`${styles.tabletValues} mui-col ${
                    header === COLUMN_HEADERS.AUCTION
                      ? 'mui-col-2-5'
                      : 'mui-col-1-5'
                  }`}
                >
                  {maybeValue(transaction[header])}
                </div>
              ))}
            </div>
          </li>
        ))}
      </ul>
    );
  }

  if (successfulLookup) {
    return displayNoTransactions();
  }

  if (!successfulLookup) {
    return displayNullValue();
  }
}

function displayNoTransactions() {
  return (
    <div data-test="TxnTableBody">
      <div>
        <div className={styles.emptyBody} colSpan="100%">
          <div className={styles.emptyBody}>{ZERO_TXNS}</div>
        </div>
      </div>
    </div>
  );
}

function displayNullValue() {
  const centerTableText = { textAlign: 'center' };
  return (
    <div data-test="TxnTableBody">
      <div>
        <div className={styles.emptyBody} colSpan="100%">
          <div
            data-test="nullValue"
            className={styles.nullState}
            style={centerTableText}
          >
            {NULL_VALUE}
          </div>
        </div>
      </div>
    </div>
  );
}

TabletTableBody.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  loading: PropTypes.bool,
  transactions: PropTypes.arrayOf(PropTypes.object),
  visibleRows: PropTypes.number,
  successfulLookup: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TabletTableBody;
