////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  HYDRATE_STATE,
  VIN_FULLY_DECODED,
  VIN_FIELD_SELECTED,
  CLOSE_MOBILE_EDIT_LOOKUP,
  OPEN_MOBILE_EDIT_LOOKUP
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = false;

function showMobileEditLookup(state = initialState, action = {}) {
  switch (action.type) {
    case HYDRATE_STATE:
      return false;
    case VIN_FULLY_DECODED:
    case CLOSE_MOBILE_EDIT_LOOKUP:
      return false;
    case VIN_FIELD_SELECTED:
      return action.field !== 'style';
    case OPEN_MOBILE_EDIT_LOOKUP:
      return true;
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { showMobileEditLookup };
