////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  EDITION_LOADED,
  FAILED_LOADING_EDITION
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function editionLoaded(country, response) {
  return {
    type: EDITION_LOADED,
    country,
    edition: response
  };
}

function failedLoadingEdition() {
  return { type: FAILED_LOADING_EDITION };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { editionLoaded, failedLoadingEdition };
