////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEqual } from 'lodash';
import onClickOutside from 'react-onclickoutside';

/* Internal */
import {
  clearFilters,
  cancelFilters,
  applyFilters,
  closeTransactionFilters
} from 'SRC/store/actions/creators';
import { getTruthyFilters } from 'SRC/containers/Transactions/formatting';

/* Component */
import FilterGroup from 'SRC/containers/FilterGroup';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
class FilterBox extends Component {
  static defaultProps = {
    appliedFilters: {},
    selectedFilters: {}
  };

  render() {
    const { selectedFilters, appliedFilters } = this.props;

    return (
      <div className={styles.filterOuter}>
        <div className={styles.filterContainer} data-test="Filter">
          <div className={styles.filterHeader}>
            <div className="mui-row">
              <div className={`${styles.filterBy} mui-col mui-col-1-2`}>
                <span className="bold">Filter By</span>
              </div>
              <div className="mui-col mui-col-1-2">
                <button
                  type="button"
                  id="filterCancel"
                  className={styles.actionLink}
                  data-test="FilterCancel"
                  onClick={this.handleCancel}
                >
                  Cancel
                </button>
              </div>
            </div>
            <div className="mui-row">
              <div className="mui-col mui-col-1-1">
                <button
                  type="button"
                  id="filterClear"
                  className={styles.actionLink}
                  data-test="FilterClear"
                  onClick={this.handleClearAll}
                  disabled={
                    allFalsyFilters(appliedFilters) &&
                    allFalsyFilters(selectedFilters)
                  }
                >
                  Clear All
                </button>
              </div>
            </div>
            <div className="mui-row">
              <div className="mui-col mui-col-1-1">
                <button
                  type="button"
                  id="filterApply"
                  onClick={this.handleApply}
                  className={`${styles.filterApply} mui-button--sm mui-button--primary`}
                  data-test="FilterApply"
                  disabled={shouldDisable(appliedFilters, selectedFilters)}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
          <div className={styles.filterBody}>
            <FilterGroup groupName="Region" />
          </div>
        </div>
      </div>
    );
  }

  handleClickOutside = () => {
    this.handleCancel();
  };

  handleCancel = () => {
    this.props.dispatchCancelFilters(this.props.appliedFilters);
    this.props.dispatchCloseTransactionFilters();
  };

  handleClearAll = () => {
    this.props.dispatchClearFilters();
    this.props.dispatchCloseTransactionFilters();
  };

  handleApply = () => {
    const noTruthyFilters =
      getTruthyFilters(this.props.selectedFilters).count === 0;
    if (noTruthyFilters) {
      this.handleClearAll();
    } else {
      this.props.dispatchApplyFilters(this.props.selectedFilters);
      this.props.dispatchCloseTransactionFilters();
    }
  };
}

FilterBox.propTypes = {
  selectedFilters: PropTypes.object,
  appliedFilters: PropTypes.object,
  dispatchClearFilters: PropTypes.func,
  dispatchCancelFilters: PropTypes.func,
  dispatchApplyFilters: PropTypes.func,
  dispatchCloseTransactionFilters: PropTypes.func
};

const stateProps = state => ({
  selectedFilters: state.selectedFilters,
  appliedFilters: state.appliedFilters
});

const actionProps = {
  dispatchClearFilters: clearFilters,
  dispatchCancelFilters: cancelFilters,
  dispatchApplyFilters: applyFilters,
  dispatchCloseTransactionFilters: closeTransactionFilters
};

const FilterBoxClickWrapper = onClickOutside(FilterBox);

const FilterBoxContainer = connect(
  stateProps,
  actionProps
)(FilterBoxClickWrapper);

function allFalsyFilters(filters) {
  return Object.keys(filters).every(filterGroup => {
    return Object.keys(filters[filterGroup]).every(filter => {
      return filters[filterGroup][filter] === false;
    });
  });
}

function shouldDisable(appliedFilters, selectedFilters) {
  const appliedAndSelectedMatch = isEqual(appliedFilters, selectedFilters);
  const appliedAndSelectedEmpty =
    allFalsyFilters(appliedFilters) && allFalsyFilters(selectedFilters);

  return (
    (allFalsyFilters(appliedFilters) && appliedAndSelectedEmpty) ||
    (appliedAndSelectedMatch && !appliedAndSelectedEmpty)
  );
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default FilterBoxContainer;
export { FilterBox };
