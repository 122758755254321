////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function CSVIcon({ enabled }) {
  return (
    <svg
      className={`${styles.svg} ${styles.csv_icon} ${
        enabled ? styles.active : ''
      }`}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 14 14"
    >
      <title>CSV icon</title>
      <path
        className={styles.fill}
        d="M12.6,5.1h-0.3V3.5l0,0c0-0.1,0-0.1-0.1-0.2L9.6,0.2l0,0H9.5l0,0H9.5H2.8c-0.3,0-0.5,0.2-0.5,0.5v4.3H1.9
        c-0.4,0-0.8,0.3-0.8,0.8v4c0,0.4,0.3,0.8,0.8,0.8h0.3v2.8c0,0.3,0.2,0.5,0.5,0.5h9c0.3,0,0.5-0.2,0.5-0.5v-2.7h0.3
        c0.4,0,0.8-0.3,0.8-0.8v-4C13.4,5.5,13.1,5.1,12.6,5.1L12.6,5.1z M2.8,0.7h6.3v2.8c0,0.1,0.1,0.3,0.3,0.3l0,0h2.4v1.4h-9L2.8,0.7z
        M6.9,8C6.2,7.8,5.8,7.4,5.8,6.8s0.6-1.2,1.5-1.2c0.3,0,0.7,0.1,1,0.2L8.1,6.5C7.9,6.4,7.6,6.3,7.3,6.3c-0.4,0-0.6,0.2-0.6,0.4
        s0.2,0.4,0.8,0.6c0.7,0.3,1,0.6,1,1.2S8,9.8,6.9,9.8c-0.4,0-0.8-0.1-1.2-0.2l0.2-0.8C6.2,9,6.6,9,6.9,9.1c0.4,0,0.6-0.2,0.6-0.4
        S7.4,8.2,6.9,8L6.9,8z M2.1,7.8C2,6.6,2.9,5.7,4.1,5.6c0.1,0,0.2,0,0.2,0c0.3,0,0.7,0,1,0.2L5.1,6.5C4.9,6.4,4.6,6.4,4.4,6.4
        c-0.7,0-1.3,0.5-1.3,1.1c0,0.1,0,0.1,0,0.2C3,8.4,3.5,9,4.1,9c0.1,0,0.1,0,0.2,0c0.3,0,0.5,0,0.8-0.1l0.1,0.7
        c-0.3,0.1-0.7,0.2-1,0.2c-1.1,0.1-2-0.7-2.1-1.7C2.1,8,2.1,7.9,2.1,7.8L2.1,7.8z M11.8,13.3h-9v-2.6h9V13.3z M11.2,9.8h-1.1L8.8,5.7
        h1l0.5,1.7c0.1,0.5,0.3,0.9,0.4,1.4l0,0c0.1-0.5,0.2-1,0.4-1.4l0.5-1.7h1L11.2,9.8z"
      />
    </svg>
  );
}

CSVIcon.propTypes = {
  enabled: PropTypes.bool
};

export default CSVIcon;
