import {
  VIN_PARTIALLY_DECODED,
  YMMS_MAKES_LOADED,
  YMMS_MODELS_LOADED,
  YMMS_STYLES_LOADED,
  YMMS_POSSIBILITIES_LOADED,
  YMMS_SELECTION_CLEARED,
  ADJUSTMENT_CHANGED_SUCCESS,
  ADJUSTMENT_CHANGED_FAILURE,
  VALUATION_CHANGED,
  MID_CHANGED_FAILURE,
  ADJUSTMENT_SELECTED,
  REGIONS_LOADED_SUCCESS,
  MULTI_DECODE_MODAL_OPENED,
  OPEN_LOOKUP_WIZARD,
  CLEAR_MID_AND_VIN,
  INVENTORY_DATA_CLEARED,
  APPLY_FILTERS,
  CLEAR_FILTERS,
  FAILED_LOADING_YMMS_YEARS,
  FAILED_LOADING_YMMS_MAKES,
  FAILED_LOADING_YMMS_MODELS,
  FAILED_LOADING_YMMS_STYLES,
  SONAR_SEARCH_RECEIVED
} from 'SRC/store/actions/types';

const urlMatchers = [
  action => action.type === ADJUSTMENT_CHANGED_SUCCESS,
  action => action.type === ADJUSTMENT_CHANGED_FAILURE,
  action => action.type === VALUATION_CHANGED,
  action => action.type === MID_CHANGED_FAILURE,
  action => action.type === VIN_PARTIALLY_DECODED,
  action => action.type === YMMS_SELECTION_CLEARED,
  action => action.type === OPEN_LOOKUP_WIZARD,
  action => action.type === CLEAR_MID_AND_VIN,
  action => action.type === APPLY_FILTERS,
  action => action.type === CLEAR_FILTERS,
  (action, query) =>
    [YMMS_MAKES_LOADED, YMMS_MODELS_LOADED, YMMS_STYLES_LOADED].includes(
      action.type
    ) && query.wizard,
  (action, query) =>
    action.type === ADJUSTMENT_SELECTED &&
    action.field === 'country' &&
    query.country !== undefined &&
    query.mid === undefined &&
    query.vin === undefined,
  action => action.type === FAILED_LOADING_YMMS_YEARS,
  action => action.type === FAILED_LOADING_YMMS_MAKES,
  action => action.type === FAILED_LOADING_YMMS_MODELS,
  action => action.type === FAILED_LOADING_YMMS_STYLES
];

const stateMatchers = [
  action => action.type === ADJUSTMENT_CHANGED_SUCCESS,
  action => action.type === ADJUSTMENT_CHANGED_FAILURE,
  action => action.type === VALUATION_CHANGED,
  action => action.type === MID_CHANGED_FAILURE,
  (action, query) =>
    action.type === YMMS_POSSIBILITIES_LOADED && query.mid !== undefined,
  (action, query) =>
    action.type === REGIONS_LOADED_SUCCESS &&
    query.country !== undefined &&
    query.mid === undefined &&
    query.vin === undefined,
  action => action.type === MULTI_DECODE_MODAL_OPENED,
  action => action.type === INVENTORY_DATA_CLEARED,
  action => action.type === SONAR_SEARCH_RECEIVED
];

const temporaryMatchers = [
  action => action.type === YMMS_SELECTION_CLEARED,
  action => action.type === CLEAR_MID_AND_VIN,
  action => action.type === OPEN_LOOKUP_WIZARD,
  action => action.type === 'LINK',
  action => action.type === INVENTORY_DATA_CLEARED,
  action => action.type === FAILED_LOADING_YMMS_YEARS,
  action => action.type === FAILED_LOADING_YMMS_MAKES,
  action => action.type === FAILED_LOADING_YMMS_MODELS,
  action => action.type === FAILED_LOADING_YMMS_STYLES
];

const completeValuationActions = [
  VALUATION_CHANGED,
  MID_CHANGED_FAILURE,
  VIN_PARTIALLY_DECODED
];

const actionableMatchers = [
  ...urlMatchers,
  ...stateMatchers,
  ...temporaryMatchers
];

export {
  urlMatchers,
  stateMatchers,
  temporaryMatchers,
  actionableMatchers,
  completeValuationActions
};
