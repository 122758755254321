////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { combineReducers } from 'redux';

/* Internal */
import { HYDRATE_STATE } from 'SRC/store/actions/types';

/* Local */
import abTestConfig from './abTestConfig';
import adjustments from './adjustments';
import edition from './edition';
import errors from './errors';
import loading, { initialLoading } from './loading';
import mostRecentLookup from './mostRecentLookup';
import ymmsLookup from './ymmsLookup';
import userInfo from './userInfo';
import vin from './vin';
import { showLookupWizard, currentWizardStep } from './lookupWizard';
import { showMultiDecodeModal } from './vinMultiDecode';
import { showMobileEditLookup } from './mobileEditLookup';
import vinRefinement from './vinRefinement';
import inventoryData, {
  showInventoryModal,
  highlightInventoryFields
} from './inventoryData';
import {
  selectedFilters,
  appliedFilters,
  showTransactionFilters
} from './filters';
import similarVehicles from './similarVehicles';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const mainReducer = combineReducers({
  abTestConfig,
  adjustments,
  edition,
  errors,
  loading,
  initialLoading,
  mostRecentLookup,
  ymmsLookup,
  userInfo,
  vin,
  showLookupWizard,
  currentWizardStep,
  showMultiDecodeModal,
  showMobileEditLookup,
  vinRefinement,
  selectedFilters,
  appliedFilters,
  showTransactionFilters,
  inventoryData,
  showInventoryModal,
  highlightInventoryFields,
  similarVehicles
});

// https://github.com/reactjs/redux/pull/658
function hydratableReducer(state = mainReducer(), action = {}) {
  switch (action.type) {
    case HYDRATE_STATE:
      return mainReducer(action.state, action);
    default:
      return mainReducer(state, action);
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default hydratableReducer;
export { mainReducer };
