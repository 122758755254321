////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { COLUMN_HEADERS } from 'SRC/constants';

/* Local */
import styles from '../styles.scss';
import { formatHeader } from '../formatting';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TabletTableHeader({
  columns,
  sortBy,
  sortOrder,
  onChangeSort,
  odometerUnits
}) {
  const clickHandler = onChangeSort || (() => undefined);

  const headerStyles = [
    styles.header,
    styles.noSelect,
    styles.tabletHeaderTitles
  ].join(' ');

  const headersMarkup = columns.map(header => {
    const engTransStyle =
      header === COLUMN_HEADERS.ENGINE_TRANSMISSION
        ? { paddingRight: '1em' }
        : {};
    let sortArrow = `${styles.biggerArrow} mui-m-q-l icon-triangle-`;
    if (sortBy === header) {
      if (sortOrder === 'asc') sortArrow += 'up';
      else sortArrow += 'down';
    } else {
      sortArrow += `down ${styles.light}`;
    }

    return (
      <div
        key={header}
        className={`mui-col mui-col-1-5 bold ${headerStyles}`}
        style={engTransStyle}
        unselectable="on"
        onClick={() => clickHandler(header)}
      >
        {formatHeader(header, odometerUnits)}
        <i className={sortArrow} />
      </div>
    );
  });

  return (
    <div
      className={`mui-row ${styles.tabletHeader}`}
      data-test="TabletTxnTableHeader"
    >
      {headersMarkup}
    </div>
  );
}

TabletTableHeader.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.string),
  sortBy: PropTypes.oneOf(Object.values(COLUMN_HEADERS)),
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  onChangeSort: PropTypes.func,
  odometerUnits: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TabletTableHeader;
