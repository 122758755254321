function store(key, value) {
  return window.localStorage
    ? window.localStorage.setItem(key, JSON.stringify(value))
    : undefined;
}

function retrieve(key) {
  return window.localStorage
    ? JSON.parse(window.localStorage.getItem(key))
    : null;
}

export { store, retrieve };
