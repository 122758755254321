import {
  truthyOrZero,
  decimalCondition,
  validateMileage,
  validateRegion,
  validateCountry,
  titleizeColor,
  validateCondGrade,
  validateVehicleUniqueId
} from 'SRC/util/helperUtils';

function joinQueryParams(params) {
  return params.filter(x => x !== '').join('&');
}

function queryParam(key, params) {
  const val = params[key];

  if (val === undefined || val === '' || val === null) {
    return '';
  }

  if (Array.isArray(val)) {
    if (val.length === 0) {
      return '';
    }

    return `${key}=${val.join(',')}`;
  }

  return `${key}=${val}`;
}

function queryParams(params) {
  return joinQueryParams(Object.keys(params).map(k => queryParam(k, params)));
}

function modifyUrlField(history, field, modify) {
  const location = history.getCurrentLocation();
  if (location.query[field]) {
    const newValue = modify(location.query[field]);
    if (truthyOrZero(newValue)) {
      history.replace({
        query: {
          ...location.query,
          [field]: newValue
        }
      });
    } else {
      delete location.query[field];
      history.replace({ query: location.query });
    }
  }
}

function stateToQuery(state) {
  return {
    mid: state.mostRecentLookup.mid,
    vin: state.vin,
    country: state.adjustments.selections.country,
    region: state.adjustments.selections.region,
    color: state.adjustments.selections.color,
    condition: decimalCondition(state.adjustments.selections.grade),
    mileage: state.adjustments.selections.odometer,
    wizard: state.showLookupWizard && state.currentWizardStep,
    year: state.showLookupWizard && state.ymmsLookup.selections.year,
    make: state.showLookupWizard && state.ymmsLookup.selections.make,
    model: state.showLookupWizard && state.ymmsLookup.selections.model,
    vehicle_unique_id: state.inventoryData.vehicleUniqueId,
    region_filter: loadFilter(state.appliedFilters)
  };
}

function loadFilter(filters) {
  if (filters.Region) {
    const regions = Object.keys(filters.Region);
    return regions.toString();
  }
  return null;
}

function sanitizeParams(params) {
  return {
    ...params,
    country: validateCountry(params.country),
    mileage: validateMileage(params.mileage),
    color: titleizeColor(params.color),
    condition: validateCondGrade(params.condition),
    region: validateRegion(params.region),
    vehicle_unique_id: validateVehicleUniqueId(params.vehicle_unique_id)
  };
}

export default queryParams;
export { modifyUrlField, stateToQuery, sanitizeParams };
