////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Message({ text }) {
  return (
    <div>
      <div data-test="messageContent" style={{ letterSpacing: 'normal' }}>
        {text}
      </div>
    </div>
  );
}

Message.propTypes = {
  text: PropTypes.string
};

export default Message;
