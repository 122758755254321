////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { select, call, put, cancelled } from 'redux-saga/effects';

/* Internal */
import { getMMR } from 'SRC/api';
import { getSelections } from 'SRC/util/helperUtils';
import {
  adjustmentChangedSuccess,
  adjustmentChangedFailure
} from 'SRC/store/actions/creators';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* adjustmentChangedSaga({ mid }) {
  try {
    const { region, color, grade, odometer, country } = yield select(
      getSelections
    );
    const regionToUse = region || 'NA';
    const mmrData = yield call(
      getMMR,
      mid,
      country,
      regionToUse,
      color,
      grade,
      odometer
    );
    if (mmrData.success) {
      mmrData.payload.adjustments = { region, color, grade, odometer, country };
      yield put(adjustmentChangedSuccess(mmrData.payload));
    } else {
      throw [mmrData].find(r => !r.success).payload;
    }
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(adjustmentChangedFailure());
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default adjustmentChangedSaga;
