////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Grade({ disabled }) {
  return (
    <svg
      version="1.1"
      id="CR"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 15 11"
      className={`${styles.iconClass} ${disabled ? styles.iconDisabled : ''}`}
      style={{ enableBackground: 'new 0 0 15 11' }}
      xmlSpace="preserve"
    >
      <title>CR</title>
      <path
        className={`${styles.icon} ${disabled ? styles.disabled : ''}`}
        d="M7,8c0.1,0,0.1,0,0.2,0.1l0.5,0.5C7.3,9,6.8,9.3,6.4,9.6C5.8,9.8,5.2,9.9,4.5,9.9C4,9.9,3.4,9.8,2.9,9.6
        C2.4,9.4,1.9,9.1,1.6,8.7C1.2,8.3,0.9,7.8,0.7,7.2C0.5,6.7,0.4,6.1,0.4,5.5c0-0.6,0.1-1.2,0.3-1.8c0.2-0.5,0.5-1,0.9-1.4
        C2,1.9,2.5,1.6,3,1.4c0.6-0.2,1.2-0.3,1.8-0.3c0.6,0,1.1,0.1,1.6,0.3c0.5,0.2,0.9,0.5,1.2,0.8L7.2,2.7c0,0-0.1,0.1-0.1,0.1
        c-0.1,0-0.1,0-0.2,0c-0.1,0-0.1,0-0.2-0.1L6.6,2.6L6.3,2.4C6.2,2.3,6.1,2.3,5.9,2.2C5.8,2.2,5.6,2.1,5.4,2.1C5.2,2,5,2,4.7,2
        C4.3,2,3.9,2.1,3.5,2.3C3.1,2.4,2.8,2.7,2.5,3C2.2,3.3,2,3.6,1.8,4C1.7,4.5,1.6,5,1.6,5.5c0,0.5,0.1,1,0.2,1.4C2,7.3,2.2,7.7,2.5,8
        c0.3,0.3,0.6,0.5,1,0.7c0.4,0.2,0.8,0.2,1.2,0.2c0.2,0,0.5,0,0.7,0c0.2,0,0.4-0.1,0.6-0.1C6,8.7,6.2,8.6,6.3,8.5
        c0.2-0.1,0.3-0.2,0.4-0.4C6.8,8.1,6.9,8,7,8z"
      />
      <path
        className={`${styles.icon} ${disabled ? styles.disabled : ''}`}
        d="M9.5,6.2v3.6H8.4V1.2h2.4c0.5,0,0.9,0.1,1.4,0.2c0.4,0.1,0.7,0.3,1,0.5c0.2,0.2,0.4,0.5,0.6,0.8
        c0.1,0.3,0.2,0.7,0.2,1c0,0.3,0,0.6-0.1,0.8c-0.1,0.3-0.2,0.5-0.4,0.7c-0.2,0.2-0.4,0.4-0.7,0.5c-0.3,0.2-0.6,0.3-0.9,0.3
        c0.2,0.1,0.3,0.2,0.4,0.4l2.5,3.4h-1c-0.2,0-0.4-0.1-0.5-0.2L11,6.4c-0.1-0.1-0.1-0.2-0.2-0.2c-0.1,0-0.2-0.1-0.4-0.1L9.5,6.2z
         M9.5,5.3h1.2c0.3,0,0.6,0,0.9-0.1c0.2-0.1,0.5-0.2,0.6-0.3c0.2-0.1,0.3-0.3,0.4-0.5c0.1-0.2,0.1-0.5,0.1-0.7c0-0.4-0.2-0.9-0.5-1.2
        c-0.4-0.3-1-0.4-1.5-0.4H9.5V5.3z"
      />
    </svg>
  );
}

Grade.propTypes = {
  disabled: PropTypes.bool
};

export default Grade;
