////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { uniqBy, lowerCase } from 'lodash';

/* Internal */
import { filterClicked } from 'SRC/store/actions/creators';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
class FilterGroup extends Component {
  static defaultProps = {
    selectedFilters: {}
  };

  render() {
    const availableFilters = {
      region: this.getRegionFilters
    };

    const { groupName } = this.props;
    const filtersFromTransactions = availableFilters[lowerCase(groupName)]();

    return (
      <fieldset data-test={`${groupName}FilterGroup`}>
        <legend
          id={`${groupName}Legend`}
          onClick={this.toggleFilterSet}
          className={styles.filterSetTriangleDown}
        >
          {groupName}
        </legend>
        <div id={`${groupName}Filter`} className={styles.filterSet}>
          {filtersFromTransactions.map((filter, i) => (
            <label key={i} htmlFor={filter.id} className={styles.filterItem}>
              <input
                type="checkbox"
                value={filter.id}
                id={filter.id}
                name={groupName}
                onChange={this.handleClick}
                className={styles.filterCheckbox}
                checked={this.isChecked(filter.id)}
              />
              {filter.name}
            </label>
          ))}
        </div>
      </fieldset>
    );
  }

  toggleFilterSet = () => {
    //hide the filterSet div
    document
      .getElementById(`${this.props.groupName}Filter`)
      .classList.toggle(styles.hideFilterSet);

    //change the triangle icon
    const { classList } = document.getElementById(
      `${this.props.groupName}Legend`
    );
    classList.toggle(styles.filterSetTriangleDown);
    classList.toggle(styles.filterSetTriangleUp);
  };

  handleClick = event => {
    this.props.dispatchFilterClicked(this.props.groupName, event);
  };

  getRegionFilters = () => {
    return this.props.transactions
      ? uniqBy(this.props.transactions.map(t => t.region), region => region.id)
      : [];
  };

  filterSetSelectionExists(id) {
    return (
      this.props.selectedFilters[this.props.groupName] &&
      this.props.selectedFilters[this.props.groupName][id]
    );
  }

  isChecked(id) {
    return this.filterSetSelectionExists(id)
      ? this.props.selectedFilters[this.props.groupName][id]
      : false;
  }
}

FilterGroup.propTypes = {
  dispatchFilterClicked: PropTypes.func,
  groupName: PropTypes.string,
  selectedFilters: PropTypes.object,
  transactions: PropTypes.arrayOf(PropTypes.object)
};

const stateProps = state => ({
  selectedFilters: state.selectedFilters,
  transactions: state.mostRecentLookup.transactions
});

const actionProps = {
  dispatchFilterClicked: filterClicked
};

const FilterGroupContainer = connect(
  stateProps,
  actionProps
)(FilterGroup);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export default FilterGroupContainer;
export { FilterGroup };
