////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  FILTER_CLICKED,
  CLEAR_FILTERS,
  CANCEL_FILTERS,
  APPLY_FILTERS,
  MULTIPLE_YMMS_FIELDS_SELECTED,
  YMMS_FIELD_SELECTED,
  OPEN_TRANSACTION_FILTERS,
  CLOSE_TRANSACTION_FILTERS,
  CLEAR_VALUATION,
  REMOVE_FILTER,
  YMMS_SELECTION_CLEARED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = {};

function selectedFilters(state = initialState, action = {}) {
  switch (action.type) {
    case FILTER_CLICKED:
      return {
        ...state,
        [action.groupName]: {
          ...state[action.groupName],
          [action.label]: action.selected
        }
      };
    case REMOVE_FILTER:
      return removeFilter(state, action);
    case CANCEL_FILTERS:
      return action.appliedFilters;
    case CLEAR_VALUATION:
    case CLEAR_FILTERS:
      return initialState;
    case YMMS_FIELD_SELECTED:
      if (action.initialLoading === true) {
        return state;
      }
      return initialState;
    case YMMS_SELECTION_CLEARED:
      return initialState;
    case MULTIPLE_YMMS_FIELDS_SELECTED:
      if (action.initialLoading === true) {
        return state;
      }
      return initialState;
    default:
      return state;
  }
}

function appliedFilters(state = initialState, action = {}) {
  switch (action.type) {
    case APPLY_FILTERS:
      return action.selectedFilters;
    case CLEAR_VALUATION:
    case CLEAR_FILTERS:
      return initialState;
    case MULTIPLE_YMMS_FIELDS_SELECTED:
      if (action.initialLoading === true) {
        return state;
      }
      return initialState;
    case YMMS_FIELD_SELECTED:
      if (action.initialLoading === true) {
        return state;
      }
      return initialState;
    default:
      return state;
  }
}

const initialShowState = false;

function showTransactionFilters(state = initialShowState, action = {}) {
  switch (action.type) {
    case CLOSE_TRANSACTION_FILTERS:
      return false;
    case OPEN_TRANSACTION_FILTERS:
      return true;
    default:
      return state;
  }
}

function removeFilter(state, action) {
  delete state[action.groupName][action.label]; // eslint-disable-line
  return {
    ...state
  };
}

export { selectedFilters, appliedFilters, showTransactionFilters };
