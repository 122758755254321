////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { select, take, actionChannel } from 'redux-saga/effects';
import { buffers } from 'redux-saga';

/* Internal */
import { vehicleSummary } from 'SRC/util/vehicleSummary';
import { getEditionDisplay } from 'SRC/store/reducers/edition';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

// TODO: unit test this function
function stateToTitle(state) {
  const { year, make, model, style } = vehicleSummary(
    state.ymmsLookup.selections,
    state.ymmsLookup.possibilities
  );
  const makeModelString =
    year && make && model && style && !state.errors.valuation && !state.loading
      ? ` | ${make} ${model}`
      : '';
  const edition = getEditionDisplay(state);
  const editionString = edition ? ` | ${edition}` : '';
  return `Manheim | MMR${makeModelString}${editionString}`;
}

function* titleSaga() {
  const bufferedActions = yield actionChannel('*', buffers.expanding(10));

  // eslint-disable-next-line no-constant-condition
  while (true) {
    try {
      // Wait for any action to happen (meaning the state might have changed)
      yield take(bufferedActions);

      const currentState = yield select(state => state);
      const oldTitle = document.title;
      const newTitle = stateToTitle(currentState);

      if (oldTitle !== newTitle) {
        document.title = newTitle;
      }
    } catch (err) {
      LOGGER.error(err);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default titleSaga;
export { stateToTitle };
