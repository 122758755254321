////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { take, put, select } from 'redux-saga/effects';
import { eventChannel, buffers } from 'redux-saga';
import pickBy from 'lodash/pickBy';

/* Internal */
import { hydrateState } from 'SRC/store/actions/creators';
import { handleHash } from 'SRC/store/sagas/hash';
import { truthyOrZero } from 'SRC/util/helperUtils';
import { stateToQuery } from 'SRC/util/queryParams';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* handleReplace(history, newLocation) {
  const currentState = yield select(state => state);
  const newQuery = pickBy(
    { ...newLocation.query, ...stateToQuery(currentState) },
    truthyOrZero
  );
  history.replace({
    ...newLocation,
    query: newQuery,
    hash: '',
    state: { action: { type: 'LINK' }, state: currentState }
  });
}

function* respondToURLChanges(history) {
  const urlChanges = eventChannel(history.listen, buffers.expanding(10));

  // eslint-disable-next-line no-constant-condition
  while (true) {
    const newLocation = yield take(urlChanges);
    if (newLocation.hash.length > 0) {
      yield* handleHash(history, newLocation.hash);
      yield* handleReplace(history, newLocation);
    } else if (newLocation.action === 'POP') {
      if (newLocation.search && newLocation.state) {
        yield put(hydrateState(newLocation.state.state));
      } else if (newLocation.search && !newLocation.state) {
        yield* handleReplace(history, newLocation);
      } else if (newLocation.query.mid || newLocation.query.vin) {
        window.location.reload();
      }
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { respondToURLChanges };
