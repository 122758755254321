////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/
import { isEmpty, each, clone, capitalize } from 'lodash';

/* Internal */
import { isEmptyNullUndefinedString } from 'SRC/util/helperUtils';
import {
  ADJUSTMENT_CHANGED,
  ADJUSTMENT_CHANGED_SUCCESS,
  ADJUSTMENT_CHANGED_FAILURE,
  MID_CHANGED,
  VALUATION_CHANGED,
  MID_CHANGED_FAILURE,
  VIN_CHANGED,
  CLEAR_VALUATION,
  YMMS_SELECTION_CLEARED,
  ADJUSTMENT_SELECTED,
  CLEAR_MID_AND_VIN,
  ALL_ADJUSTMENTS_CLEARED,
  YMMS_FIELD_SELECTED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = {
  mid: null,
  successfulLookup: false,
  wholesaleValues: {},
  retailValues: {},
  historicalAverages: {},
  projectedAverages: {},
  adjustedPricing: {},
  transactions: [],
  averageOdometer: null,
  averageGrade: null,
  odometer: '',
  odometerUnits: '',
  currency: 'USD',
  adjustmentsCompleted: false,
  summary: {}
};

function mostRecentLookup(state = initialState, action = {}) {
  switch (action.type) {
    case ADJUSTMENT_CHANGED:
      return {
        ...state,
        mid: action.mid,
        adjustmentsCompleted: false
      };
    case ADJUSTMENT_CHANGED_SUCCESS:
      return {
        ...state,
        wholesaleValues: isEmpty(action.adjustedPricing.adjustedBy)
          ? action.adjustedPricing.wholesale
          : state.wholesaleValues,
        adjustedPricing: addKeyForBlankResponse(
          action.adjustments,
          action.adjustedPricing
        ),
        odometer: adjustedOdometer(action),
        currency: action.currency,
        adjustmentsCompleted: true
      };
    case ADJUSTMENT_CHANGED_FAILURE:
      return {
        ...initialState,
        mid: state.mid,
        adjustmentsCompleted: true
      };
    case MID_CHANGED:
      return {
        ...state,
        mid: action.mid
      };
    case VALUATION_CHANGED:
      return {
        ...state,
        successfulLookup: true,
        wholesaleValues: action.wholesaleValues,
        retailValues: action.retailValues,
        historicalAverages: valueToUse(action, 'historicalAverages'),
        projectedAverages: valueToUse(action, 'forecast', 'projectedAverages'),
        adjustedPricing: addKeyForBlankResponse(
          action.adjustments,
          action.adjustedPricing
        ),
        transactions: action.transactions,
        averageOdometer: action.averageOdometer,
        averageGrade: action.averageGrade,
        odometerUnits: action.odometerUnits,
        currency: action.currency,
        mid: action.mid ? action.mid : state.mid,
        adjustmentsCompleted: true,
        summary: {
          year: action.description.year,
          make: action.description.make.name
            ? action.description.make.name
            : action.description.make,
          model: action.description.model.name
            ? action.description.model.name
            : action.description.model,
          style: action.description.trim
        }
      };
    case MID_CHANGED_FAILURE:
      return {
        ...initialState,
        successfulLookup: true,
        mid: state.mid,
        adjustmentsCompleted: true
      };
    case VIN_CHANGED:
    case YMMS_SELECTION_CLEARED:
    case CLEAR_VALUATION:
    case CLEAR_MID_AND_VIN:
    case YMMS_FIELD_SELECTED:
      return initialState;
    case ADJUSTMENT_SELECTED:
      return {
        ...state,
        adjustmentsCompleted: false
      };
    case ALL_ADJUSTMENTS_CLEARED:
      return {
        ...state,
        adjustmentsCompleted: true
      };
    default:
      return state;
  }
}

function adjustedOdometer(action) {
  return (
    (action.adjustedPricing &&
      action.adjustedPricing.adjustedBy &&
      action.adjustedPricing.adjustedBy.Odometer) ||
    ''
  );
}

function valueToUse(action, responseField, stateField) {
  if (action.adjustedPricing) {
    return (
      action.adjustedPricing[responseField] ||
      action[stateField || responseField]
    );
  }
  return action[stateField || responseField];
}

function shouldAddKey(adjustment, key) {
  return !isEmptyNullUndefinedString(adjustment) && key !== 'Country';
}

function shouldRemoveKey(adjustments, key) {
  if (key === 'Region') {
    return isEmptyNullUndefinedString(adjustments.region);
  }
}

function addKeyForBlankResponse(adjustments, adjustedPricing) {
  const newAdjustedPricing = clone(adjustedPricing);
  if (newAdjustedPricing && newAdjustedPricing.adjustedBy) {
    each(adjustments, (adjustment, key) => {
      const newKey = capitalize(key);
      if (shouldAddKey(adjustment, newKey)) {
        newAdjustedPricing.adjustedBy[newKey] = adjustment;
      } else if (shouldRemoveKey(adjustments, newKey)) {
        delete newAdjustedPricing.adjustedBy[newKey];
      }
    });
  }
  return newAdjustedPricing;
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default mostRecentLookup;
export { valueToUse };
