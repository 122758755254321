////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { select, call, spawn, put, cancelled } from 'redux-saga/effects';

/* Internal */
import { getMMRAndTransactions, expandRegionAndLocation } from 'SRC/api';
import {
  valuationChanged,
  midChangedFailure,
  multipleYmmsFieldsSelected,
  ymmsSelectionCleared
} from 'SRC/store/actions/creators';
import { midToYMMS } from 'SRC/util/mids';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function isValidMid(mid) {
  return mid && /\d+/.test(mid.toString());
}

function* syncFields(newMid) {
  if (isValidMid(newMid)) {
    const { initialLoading } = yield select(state => state);
    yield put(multipleYmmsFieldsSelected(midToYMMS(newMid), initialLoading));
  } else {
    yield put(ymmsSelectionCleared('year'));
  }
}

function* midChangedSaga({ mid }) {
  try {
    const { color, grade, odometer, region, country } = yield select(
      state => state.adjustments.selections
    );
    yield spawn(syncFields, mid);

    const regionToUse = region || 'NA';
    const [mmrData, rawTransactions] = yield call(
      getMMRAndTransactions,
      mid,
      country,
      regionToUse,
      color,
      grade,
      odometer
    );
    if (mmrData.success) {
      mmrData.payload.adjustments = { region, color, grade, odometer, country };
      // only expand transactions if they succeeded
      let transactions = [];
      if (rawTransactions.success) {
        transactions = yield call(
          expandRegionAndLocation,
          rawTransactions.payload
        );
      }

      yield put(valuationChanged(mmrData.payload, transactions));
    } else {
      throw [mmrData, rawTransactions].find(r => !r.success).payload;
    }
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(midChangedFailure(err.message));
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default midChangedSaga;
export { syncFields };
