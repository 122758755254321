////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import {
  INITIAL_PAGE_SIZE,
  TRANSACTION_NOT_IN_SAMPLE,
  TRANSACTION_AUTOGRADE_CAPTION
} from 'SRC/constants';
import TransactionCount from 'SRC/components/TransactionCount';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TableFooter({
  loading,
  onShowLess,
  onShowMore,
  rawTransactions,
  transactions,
  viewport,
  visibleRows
}) {
  let displayButton;
  if (transactions.length <= INITIAL_PAGE_SIZE) {
    displayButton = false;
  } else if (transactions.length > visibleRows) {
    displayButton = showMore(onShowMore, 'View All');
  } else if (transactions.length <= visibleRows) {
    displayButton = showMore(onShowLess, 'View Less');
  }

  const asteriskText = (
    <p className="mui-m-n-tb">{TRANSACTION_NOT_IN_SAMPLE}</p>
  );

  const autogradeCaptionText = (
    <p className="mui-m-n-tb" data-test="agCaption">
      {TRANSACTION_AUTOGRADE_CAPTION}
    </p>
  );

  const showAutogradeCaption = transactions && transactions.length > 0;
  const showAsterisk = transactions && containsNotInSample(transactions);
  const showAdditionalText = showAsterisk || showAutogradeCaption;
  const footerStyles = showAdditionalText ? {} : { lineHeight: '3.6rem' };
  const mobileFooterClass = [styles.mobileFooter, styles.tabletFooter].join(
    ' '
  );

  const footerText = (
    <div className="valign--middle">
      <div
        className={`pull--left ${styles.showingText}`}
        style={footerStyles}
        data-test="LowerTxnCountDisplay"
      >
        <p className="mui-m-n-tb bold">
          <TransactionCount
            loading={loading}
            rawTransactions={rawTransactions}
            visibleRows={visibleRows}
          />
        </p>
        {!loading && showAsterisk && asteriskText}
        {!loading && showAutogradeCaption && autogradeCaptionText}
      </div>
      <div className="pull--right print--hide">{displayButton}</div>
    </div>
  );

  if (viewport === 'lg') {
    return (
      <tfoot data-test="DesktopTableFooterDisplay">
        <tr>
          <td colSpan="100%">{footerText}</td>
        </tr>
      </tfoot>
    );
  }

  return (
    <div className={`mui-row ${viewport !== 'lg' ? mobileFooterClass : ''}`}>
      <div className="mui-col mui-col-1-1">{footerText}</div>
    </div>
  );
}

function containsNotInSample(transactions) {
  return transactions.some(t => t.inSample !== 'Yes');
}

function showMore(onClick, text) {
  return (
    <button
      type="button"
      className="mui-button--secondary mui-button--sm"
      data-test="TxnDisplayButton"
      onClick={onClick}
    >
      {text}
    </button>
  );
}

TableFooter.propTypes = {
  loading: PropTypes.bool,
  onShowMore: PropTypes.func,
  onShowLess: PropTypes.func,
  rawTransactions: PropTypes.arrayOf(PropTypes.object),
  transactions: PropTypes.arrayOf(PropTypes.object),
  viewport: PropTypes.string,
  visibleRows: PropTypes.number
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TableFooter;
