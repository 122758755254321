/* eslint-disable no-underscore-dangle */
////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, select } from 'redux-saga/effects';
import pickBy from 'lodash/pickBy';
import keys from 'lodash/keys';
import isEqual from 'lodash/isEqual';
import toPairs from 'lodash/toPairs';
import assign from 'lodash/assign';

/* Internal */
import {
  ADJUSTMENT_CHANGED,
  VALUATION_CHANGED,
  MID_CHANGED_FAILURE,
  VIN_CHANGED_FAILURE,
  SHOW_TRANSACTIONS,
  SONAR_SEARCH_RECEIVED
} from 'SRC/store/actions/types';
// import { vehicleSummary } from 'SRC/util/vehicleSummary';
import { titleize } from 'SRC/util/formatting';
import { truthyOrZero } from 'SRC/util/helperUtils';
import { ymmsToMid } from 'SRC/util/mids';
import { webData as externalizedWebData } from 'SRC/misc/analytics';

import { INITIAL_PAGE_SIZE, EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

//These MUST exist in the entire file scope to track values between analytics calls
let previousFilters = {};
let previousMid = '';
let adjustedFieldName = '';

function formatOutput(value) {
  if (typeof value === 'string') {
    return (
      {
        grade: 'cgr',
        odometer: 'odo',
        color: 'ecl',
        country: 'cst',
        region: 'reg'
      }[value] || value
    );
  }

  if (Array.isArray(value)) {
    return value.map(formatOutput);
  }

  return value;
}

function resetForTestOnly() {
  previousFilters = {};
  previousMid = '';
}

function analyticsCall(type) {
  if (window._satellite) {
    if (type === 'componentImpression') {
      window._satellite.track('componentImpression');
      LOGGER.log('Tracked (external):', window.contextData);
    } else {
      window._satellite.track('pageView');
      LOGGER.log('Tracked:', window.webData);
      clearWebDataSearchType();
    }
  } else {
    setTimeout(analyticsCall.bind(null, type), 100);
  }
}

function getErrors(wasSuccessful, wasFailure) {
  if (wasSuccessful) {
    return [];
  }
  if (wasFailure) {
    return ['MMR not available'];
  }

  return window.webData.errors;
}

function getSearchCriteria(filters, summary, vin) {
  return {
    country: filters.country,
    year: summary.year ? [summary.year] : [],
    make: [summary.make],
    model: [summary.model],
    style: [summary.style],
    vin: vin ? vin.split() : [],
    filters: filters ? formatOutput(keys(filters)) : []
  };
}

function getSearchRefinementCriterion(filters) {
  if (filters) {
    return toPairs(filters).map(([key, value]) => `${titleize(key)}:${value}`);
  }
  return [];
}

function resolveClickName(
  actionType,
  filtersHaveChanged,
  fieldName,
  analyticsCode = null
) {
  if (actionType === SHOW_TRANSACTIONS) {
    return analyticsCode;
  }
  if (actionType === ADJUSTMENT_CHANGED && !fieldName) {
    return 'clear';
  }
  return filtersHaveChanged ? formatOutput(fieldName) : '';
}

function resolveSearchResults(transactionsLength, visibleRows) {
  function getResultsPerPage() {
    if (visibleRows) {
      return visibleRows;
    }
    if (transactionsLength <= INITIAL_PAGE_SIZE) {
      return transactionsLength;
    }
    return INITIAL_PAGE_SIZE;
  }

  return EXTERNAL
    ? {}
    : {
        count: transactionsLength,
        resultsPerPage: getResultsPerPage()
      };
}

function resolvePageName(actionType, filtersHaveChanged, vin) {
  if (EXTERNAL) {
    if (actionType === ADJUSTMENT_CHANGED) {
      return 'MMRWidgetAdjustment';
    }
    if (actionType === SHOW_TRANSACTIONS) {
      return 'MMRWidgetShowTransactions';
    }
    return vin ? 'MMRWidgetVinLookup' : 'MMRWidgetMidLookup';
  }
  if (filtersHaveChanged && actionType === ADJUSTMENT_CHANGED) {
    return 'MMR/Adjustment';
  }
  return vin ? 'MMR/VINLookup' : 'MMR/YMMSLookup';
}

function getSummary(summary) {
  return {
    year: summary.year ? summary.year : '',
    make: summary.make ? summary.make : '',
    model: summary.model ? summary.model : '',
    style: summary.style ? summary.style : ''
  };
}

function getMid(vin, vinRefinement, ymmsLookup) {
  if (vin) {
    return ymmsToMid(vinRefinement.selections);
  }

  return ymmsToMid(ymmsLookup.selections);
}

function clearWebDataSearchType() {
  window.webData.searchType = '';
}

function* analyticsSaga(action) {
  const {
    ymmsLookup,
    adjustments,
    userInfo,
    vin,
    vinRefinement,
    mostRecentLookup,
    inventoryData
  } = yield select(state => state);

  const filters = pickBy(adjustments.selections, truthyOrZero);
  const filtersHaveChanged = !isEqual(previousFilters, filters);
  if (!filtersHaveChanged && action.type === ADJUSTMENT_CHANGED) {
    //return because this scenario happens because
    //the odometer button was clicked and/or user updated the odometer field
    return;
  }
  if (filtersHaveChanged) {
    previousFilters = filters;
  }

  const mid = getMid(vin, vinRefinement, ymmsLookup);
  const midHasChanged = !isEqual(previousMid, mid);
  if (!midHasChanged && action.type === VALUATION_CHANGED) {
    //return because a YMMS_FIELD_SELECTED(style) fired another MID_CHANGED
    //after a successful VIN lookup populated the dropdowns
    return;
  }

  if (midHasChanged) {
    previousMid = mid;
  }

  const wasSuccessful = action.type === VALUATION_CHANGED;
  const wasFailure =
    action.type === MID_CHANGED_FAILURE || action.type === VIN_CHANGED_FAILURE;
  // ADJUSTMENT_CHANGED sets adjustedFieldName
  if (action.type === ADJUSTMENT_CHANGED) {
    adjustedFieldName = action.field;
  }
  const summary = getSummary(mostRecentLookup.summary);

  const missingInfo =
    action.type === VALUATION_CHANGED &&
    (summary.make === '' || summary.model === '' || summary.style === '');

  if (missingInfo) {
    // webData will be incomplete
    LOGGER.error(
      'missing data for analytics webData processing action.type=',
      action.type
    );
  }

  const dataValues = {
    applicationVersion: window.MMR.version || '',
    loginName: userInfo.userId,
    errors: getErrors(wasSuccessful, wasFailure),
    searchCriteria: getSearchCriteria(filters, summary, vin),
    searchRefinementCriterion: getSearchRefinementCriterion(filters),
    pageName: resolvePageName(action.type, filtersHaveChanged, vin),
    customClickName: resolveClickName(
      action.type,
      filtersHaveChanged,
      adjustedFieldName,
      action.analyticsCode
    ),
    searchResults: resolveSearchResults(
      action.transactions ? action.transactions.length : null,
      action.visibleRows
    ),
    listingID:
      inventoryData && inventoryData.id && inventoryData.status !== 'Expired'
        ? inventoryData.id
        : ''
  };

  LOGGER.groupCollapsed('Analytics call');
  LOGGER.log('action type', action.type);
  if (!EXTERNAL) {
    assign(window.webData, dataValues);
    LOGGER.log('Current webData:', window.webData);
    yield call(analyticsCall, 'pageView', window.webData);
    LOGGER.groupEnd();
  } else {
    assign(window.contextData, externalizedWebData, dataValues, {
      application: 'MMRWidget'
    });
    LOGGER.log('Current contextData:', window.contextData);
    yield call(analyticsCall, 'componentImpression', window.contextData);
  }
  LOGGER.groupEnd();
}

function handleSimVehSearchCriteria(vin, similarVehicles) {
  return {
    year: similarVehicles.years ? similarVehicles.years.itemDefault.name : '',
    make: similarVehicles.make.name,
    model: similarVehicles.models[0].name,
    vin: vin || ''
  };
}

function* componentImpressionSaga(action) {
  const {
    vin,
    mostRecentLookup,
    inventoryData,
    similarVehicles
  } = yield select(state => state);

  if (!window.contextData) {
    window.contextData = Object.create(null);
  }

  if (action.type === SONAR_SEARCH_RECEIVED && similarVehicles.count > 0) {
    const dataValues = {
      pageName: window.webData.pageName,
      pageType: window.webData.pageType,
      loginName: window.webData.loginName,
      siteCode: window.webData.siteCode,
      sessionID: window.webData.sessionID,
      environment: window.webData.environment,
      pageTitle: document.title,
      application: 'similarVehiclesCarousel',
      listingID: inventoryData.id ? inventoryData.id : '',
      confirmationID: mostRecentLookup.mid
        ? `MMR.MID.${mostRecentLookup.mid}`
        : '',
      searchCriteria: handleSimVehSearchCriteria(vin, similarVehicles),
      searchResults: { count: similarVehicles.count }
    };
    LOGGER.groupCollapsed('Analytics: ComponentImpression call');
    LOGGER.log('action type', action.type);
    assign(window.contextData, dataValues);
    LOGGER.log('Current contextData:', window.contextData);
    yield call(analyticsCall, 'componentImpression', window.contextData);
    LOGGER.groupEnd();
  }
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default analyticsSaga;
export { analyticsCall, resetForTestOnly, componentImpressionSaga };
