////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import { notEnoughData } from 'SRC/util/helperUtils';
import { NOT_ENOUGH_DATA, EXTERNAL } from 'SRC/constants';

/* Component */
import Adjustments from 'SRC/containers/Adjustments';
import AdjustedMMR from 'SRC/containers/AdjustedMMR';
import AverageMMR from 'SRC/containers/AverageMMR';
import Notification from 'SRC/components/Notification';

/* Local */
import styles from './styles.scss';
import stylesSpeed from '../../components/Speedometer/styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Valuations({
  values,
  successfulLookup,
  adjustmentsCompleted,
  viewport,
  errors,
  external
}) {
  if (showError(external, errors, 'vin')) {
    return <Notification text={errors.vin} />;
  }
  if (showError(external, errors, 'ymms')) {
    return <Notification text={errors.ymms} />;
  }
  if (showNotification(values, successfulLookup, adjustmentsCompleted)) {
    return <Notification text={NOT_ENOUGH_DATA} />;
  }
  return (
    <div data-test="Valuations" className="mui-col mui-col-1-1 mui-m-b">
      <div className={styles.mmrContainer}>
        <div className="mui-row">
          <div className="mui-col mui-col-1-1">
            <AverageMMR viewport={viewport} />
          </div>
        </div>
      </div>
      <div className={styles.adjustmentsBoxContainer}>
        <div className={styles.adjustmentsBox}>
          <div className="mui-row">
            <div
              className={`mui-col mui-col-1-1 mui-col-1-2-md ${
                stylesSpeed['mui-col-1-2-xs']
              } ${styles.mobileAdjustmentsPrint}`}
            >
              <Adjustments viewport={viewport} />
            </div>
            <div
              className={`mui-col mui-col-1-1 mui-col-1-2-md ${
                stylesSpeed['mui-col-1-2-xs']
              } ${styles.mobileAdjustedPrint}`}
            >
              <AdjustedMMR viewport={viewport} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function showNotification(values, successfulLookup, adjustmentsCompleted) {
  return notEnoughData(values) && successfulLookup && adjustmentsCompleted;
}

function showError(external, errors, text) {
  return external && errors && errors[text];
}

// Optional, provides type-checking in development only
Valuations.propTypes = {
  values: PropTypes.shape({
    above: PropTypes.number,
    average: PropTypes.number,
    below: PropTypes.number
  }),
  successfulLookup: PropTypes.bool,
  adjustmentsCompleted: PropTypes.bool,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg']),
  errors: PropTypes.object,
  external: PropTypes.bool
};

// Given the store's state, what props does this component care about?
const stateProps = state => ({
  values: state.mostRecentLookup.wholesaleValues,
  successfulLookup: state.mostRecentLookup.successfulLookup,
  adjustmentsCompleted: state.mostRecentLookup.adjustmentsCompleted,
  errors: state.errors,
  external: EXTERNAL
});

// Create a container component that can be rendered with a store
const ValuationsContainer = connect(stateProps)(Valuations);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default ValuationsContainer;
export { Valuations };
