////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  MID_CHANGED,
  VALUATION_CHANGED,
  MID_CHANGED_FAILURE,
  CLEAR_VALUATION,
  END_INITIAL_LOADING,
  BEGIN_INITIAL_LOADING
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = false;

function loading(state = initialState, action = {}) {
  switch (action.type) {
    case MID_CHANGED:
      return true;
    case CLEAR_VALUATION:
    case VALUATION_CHANGED:
    case MID_CHANGED_FAILURE:
      return false;
    default:
      return state;
  }
}

function initialLoading(state = initialState, action = {}) {
  switch (action.type) {
    case BEGIN_INITIAL_LOADING:
      return true;
    case END_INITIAL_LOADING:
      return false;
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default loading;
export { initialLoading };
