////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Component */
import VINEntry from 'SRC/containers/VINEntry';
import resizable from 'SRC/components/resizable';
import VehicleSummary from '../VehicleSummary';
import Step from './Step';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class LookupWizard extends Component {
  render() {
    const { showLookupWizard, currentWizardStep, viewport } = this.props;

    if (viewport === 'sm' && showLookupWizard) {
      return (
        <div data-test="lookupWizard" className={styles.container}>
          <div className={styles.vehicleSummary}>
            <ComplementaryComponent />
          </div>
          <Step />
        </div>
      );
    }

    return null;

    function ComplementaryComponent() {
      if (currentWizardStep === 'year') {
        return (
          <div data-test="wizardVinEntry">
            <VINEntry />
          </div>
        );
      }

      return (
        <div data-test="wizardVehicleSummary">
          <VehicleSummary />
        </div>
      );
    }
  }

  componentWillMount() {
    this.showHideExtras();
  }

  componentWillUpdate(nextProps) {
    this.showHideExtras(nextProps);
  }

  componentWillUnmount() {
    this.showHideExtras();
  }

  showHideExtras = nextProps => {
    const props = nextProps || this.props;

    if (props.viewport === 'sm' && props.showLookupWizard) {
      this.extrasHider =
        document.getElementById('extrasHider') ||
        document.createElement('style');
      this.extrasHider.id = 'extrasHider';
      this.extrasHider.textContent =
        '#oo_tab, .hide-when-wizard-present { display: none !important; }';
      document.head.appendChild(this.extrasHider);
    } else {
      try {
        document.head.removeChild(this.extrasHider);
      } catch (err) {
        // Swallow error if extrasHider is not present
      }
    }
  };
}

LookupWizard.propTypes = {
  showLookupWizard: PropTypes.bool,
  currentWizardStep: PropTypes.string,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg'])
};

const stateProps = state => ({
  showLookupWizard: state.showLookupWizard,
  currentWizardStep: state.currentWizardStep
});

const resizableLookupWizard = resizable(LookupWizard);

const LookupWizardContainer = connect(stateProps)(resizableLookupWizard);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default LookupWizardContainer;
export { LookupWizard };
