/* eslint-disable no-underscore-dangle */
import getCookie, { getUserInfo } from 'SRC/util/cookie';

const userID = function getUserID() {
  return getUserInfo().userId;
};

const siteCode = function siteCodeByPlatform() {
  const platform = getCookie('manheim_mobile_application_flag');
  if (platform === 'iPhone') {
    return 'MMIOS';
  }
  if (platform === 'android') {
    return 'MMAND';
  }
  if (/m\.manheim/.test(document.referrer)) {
    return 'MMWEB';
  }
  if (
    /manheim\.ca/.test(document.referrer) ||
    /manheimcanada/.test(document.referrer)
  ) {
    return 'MHMCA';
  }
  return 'MHMUS';
};

const webData = {
  siteCode: siteCode(),
  pageType: 'page',
  pageName: 'MMR',
  environment: MMR_ENV,
  application: 'MMR',
  applicationVersion: '',
  language: 'en',
  sessionID: getCookie('visitor_session_id') || '',
  loginName: userID(),
  errors: [],
  linkSource: document.referrer || '',
  searchRefinementCriterion: [],
  customClickName: '',
  searchCriteria: {
    make: [],
    model: [],
    style: [],
    year: [],
    vin: [],
    filters: []
  },
  searchType: ''
};

export { webData, siteCode };
