/* eslint-disable camelcase */

////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put } from 'redux-saga/effects';

/* Internal */
import { userInfoLoaded } from 'SRC/store/actions/creators';
import { getJWT } from 'SRC/api/gatewayClient';
import { getAuthTicket, getUserInfo } from 'SRC/util/cookie';
import { store, retrieve } from 'SRC/util/localStorage';
import { EXTERNAL } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function storeJwtToken(authTicket, jwtToken) {
  store('Auth', { authTicket, jwtToken });
}

function getTokenFor(authTicket) {
  const storedAuth = retrieve('Auth');
  return storedAuth && storedAuth.authTicket === authTicket
    ? storedAuth.jwtToken
    : null;
}

function* authenticationSaga() {
  const authTicket = getAuthTicket();
  const userInfo = getUserInfo(authTicket);
  LOGGER.groupCollapsed('Authentication');
  LOGGER.log(
    `Authentication: logged in as ${userInfo.userName} (${userInfo.userId})`
  );
  yield put(userInfoLoaded(userInfo));

  const savedToken = getTokenFor(authTicket);
  if (savedToken) {
    window.MMR.token = savedToken;
    document.cookie = `gapi_token=${savedToken};domain=.manheim.com;path=/`;
  } else {
    const jwtToken = yield call(getJWT, authTicket);
    if (jwtToken) {
      storeJwtToken(authTicket, jwtToken);
      window.MMR.token = jwtToken;
      document.cookie = `gapi_token=${jwtToken};domain=.manheim.com;path=/`;
    }
  }

  LOGGER.log(`Authentication: JWT is (${window.MMR.token})`);
  LOGGER.groupEnd();

  if (!EXTERNAL) {
    Rollbar.configure({
      payload: {
        person: {
          username: userInfo.userId || 'dev',
          name: userInfo.userName || 'Velocity Developer'
        }
      }
    });
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default authenticationSaga;
