////////////////////////////////////////////////////////////////////////////////
/****Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import md5 from 'md5-hash';

/* Local */
import styles from './styles.scss';
import { mollifyHost } from '../../util/oveUtils';
import { AUTOCHECK_SALT } from '../../constants';

const { autoCheckBaseURL, carfaxBaseURL } = INJECTED_CONFIG;

import CarFaxIcon from './CarFaxIcon';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function getAutocheckRedirectFunc(layout, link) {
  return e => {
    switch (layout) {
      case 'popup': {
        break;
      }
      case 'mobile': {
        window.open(link, '_self');
        e.preventDefault();
        return false;
      }
      default: {
        window.open(link, '_blank', 'width=768, height=590');
        e.preventDefault();
        return false;
      }
    }
  };
}

function getCarfaxRedirectFunc(layout, link) {
  return e => {
    switch (layout) {
      case 'popup':
        break;
      case 'mobile':
        window.open(link, '_self');
        e.preventDefault();
        return false;
      default:
        window.open(link, '_blank', 'width=1024, height=997');
        e.preventDefault();
        return false;
    }
  };
}

function getAutocheckLinkElement(layout, vin, info) {
  const csHash = info.autocheckCsHash
    ? info.autocheckCsHash
    : md5(`${vin}${AUTOCHECK_SALT}`);
  const link = `${mollifyHost(autoCheckBaseURL)}?vin=${vin}&cs=${csHash}`;
  return (
    <a
      id="autoCheckLink"
      href={link}
      onClick={getAutocheckRedirectFunc(layout, link)}
      target="_blank"
      rel="noopener noreferrer"
      data-test="autoCheckText"
    >
      AutoCheck
    </a>
  );
}

function getCarfaxLinkElement(layout, vin, info) {
  const accountNumber = info.sellerNumber ? info.sellerNumber : 0;
  const csHash = info.carfaxCsHash
    ? info.carfaxCsHash
    : md5(`${accountNumber}${vin}`);
  const link = `${mollifyHost(
    carfaxBaseURL
  )}?app=OVE&accountNumber=${accountNumber}&cs=${csHash}&username=${
    info.username
  }&vehicleType=V&vin=${vin}&year=${info.year}`;
  return (
    <a
      id="carfaxLink"
      href={link}
      onClick={getCarfaxRedirectFunc(layout, link)}
      target="_blank"
      rel="noopener noreferrer"
      data-test="carfaxText"
    >
      CARFAX
    </a>
  );
}

function VehicleInfoLinks({
  vin,
  showInfoLinks,
  autocheckInfo,
  carfaxInfo,
  layout
}) {
  if (!showInfoLinks) {
    return null;
  }
  const linksElements = [];
  linksElements.push(getAutocheckLinkElement(layout, vin, autocheckInfo));
  linksElements.push(getCarfaxLinkElement(layout, vin, carfaxInfo));
  return (
    <div
      className={`${styles.vehicleInfoLinks} mui-col mui-col-2-2 print--hide`}
      data-test="vehiclesInfoLinks"
    >
      <CarFaxIcon />
      {linksElements.map((el, i) => (
        <span key={i}>
          {i > 0 && ' | '}
          {el}
        </span>
      ))}
    </div>
  );
}

VehicleInfoLinks.propTypes = {
  vin: PropTypes.string,
  carfaxInfo: PropTypes.shape({
    carfaxCsHash: PropTypes.string,
    sellerNumber: PropTypes.string,
    year: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    username: PropTypes.string
  }),
  autocheckInfo: PropTypes.shape({
    autocheckCsHash: PropTypes.string
  }),
  layout: PropTypes.string,
  showInfoLinks: PropTypes.bool
};

const stateProps = state => ({
  vin: state.vin,
  carfaxInfo: {
    carfaxCsHash: state.inventoryData.carfaxCsHash,
    sellerNumber: state.inventoryData.sellerNumber,
    year: state.ymmsLookup.selections.year,
    username: state.userInfo.userId
  },
  autocheckInfo: {
    autocheckCsHash: state.inventoryData.autocheckCsHash
  },
  showInfoLinks:
    state.mostRecentLookup.successfulLookup &&
    state.vin &&
    state.vin.length === 17,
  layout: window.MMR.layout,
  loading: state.loading
});

const VehicleInfoLinksContainer = connect(stateProps)(VehicleInfoLinks);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default VehicleInfoLinksContainer;
export { VehicleInfoLinks };
