////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { put, select, call } from 'redux-saga/effects';

/* Internal */
import {
  ymmsSelectionCleared,
  clearMidAndVin,
  clearValuation,
  vinLookupCleared,
  allAdjustmentsCleared,
  openLookupWizard,
  clearFilters
} from 'SRC/store/actions/creators';

import { loadYmmsYearsSaga } from './ymmsFieldSelected';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* resetValuationSaga() {
  try {
    const { adjustments, ymmsLookup } = yield select(state => state);
    const { country } = adjustments.selections;
    const { years } = ymmsLookup.possibilities;
    yield put(vinLookupCleared());
    yield put(clearValuation());
    yield put(clearMidAndVin());
    yield put(ymmsSelectionCleared('year'));
    yield put(allAdjustmentsCleared());
    if (years.length === 1) {
      yield call(loadYmmsYearsSaga, false, false, country);
    }
    yield put(openLookupWizard());
    yield put(clearFilters());
  } catch (err) {
    LOGGER.error(err);
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default resetValuationSaga;
