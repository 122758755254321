////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

//[> Local <]
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function Title({ children, size, printVersion }) {
  return (
    <div
      className={styles.titleComponent}
      style={{ fontSize: size }}
      data-test="Title"
    >
      <span className={printVersion ? 'print--hide' : ''}>{children}</span>
      <span className={printVersion ? 'hide print--show--block' : 'hide'}>
        {printVersion}
      </span>
    </div>
  );
}

Title.propTypes = {
  children: PropTypes.string,
  size: PropTypes.string,
  printVersion: PropTypes.string
};

export default Title;
