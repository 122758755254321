import PropTypes from 'prop-types';
import isNil from 'lodash/isNil';

import imagePlaceholder from '../assets/vehicleNotAvailable.png';
import shouldBeSameWindowFunction from '../shouldBeSameWindowFunction';

import styles from './vehicleComponent.scss';

function getAdjustedMMRComponent(vehicle, showMMR) {
  return showMMR &&
    vehicle.adjustedMMR &&
    vehicle.adjustedMMR.toLocaleString ? (
    <div className={styles.vehicleMmr}>
      {`Adj MMR $${vehicle.adjustedMMR.toLocaleString()}`}
    </div>
  ) : (
    <div className={styles.vehicleMmr}>Adj MMR $ - -</div>
  );
}

function getTrimComponent(vehicle) {
  if (!vehicle.trim) {
    return <div className={styles.vehicleEmptyTrim} />;
  }
  return <div className={styles.vehicleTrim}>{vehicle.trim}</div>;
}

function getOdometerComponent(vehicle, showMileage) {
  if (!(showMileage && vehicle.odometer && vehicle.odometer.toLocaleString)) {
    return <div className={styles.vehicleEmptyOdometer} />;
  }
  const odometer = vehicle.odometer.toLocaleString();

  let odometerUnits;
  switch (vehicle.odometerUnits) {
    case 'hours':
      odometerUnits = 'hrs';
      break;
    case 'kilometers':
      odometerUnits = 'km';
      break;
    default:
      odometerUnits = 'mi';
  }

  const gradeString = isNil(vehicle.gradeType)
    ? ''
    : isNil(vehicle.grade)
    ? ` | ${vehicle.gradeType}`
    : ` | ${vehicle.gradeType} ${vehicle.grade}`;

  return (
    <div className="vehicleOdometer">
      <span>{odometer + odometerUnits + gradeString}</span>
    </div>
  );
}

function getYearModelComponent(vehicle, showYear, showYMM) {
  const yearString =
    showYear && vehicle.year && vehicle.year.toString
      ? `'${vehicle.year.toString().slice(-2)} `
      : '';
  return showYMM ? (
    <div className={styles.vehicleModel}>
      <span title={yearString + vehicle.model}>
        {yearString + vehicle.model}
      </span>
    </div>
  ) : null;
}

function getRedirectFunc(vehicle, vehicleIndex, vehicleClickedCallback) {
  return vehicle.vdp
    ? () => {
        vehicleClickedCallback(vehicleIndex);
        const destination = vehicle.vdp;
        if (shouldBeSameWindowFunction()) {
          window.location.href = destination;
        } else {
          const vdpWindow = window.open(destination);
          if (vdpWindow) {
            vdpWindow.opener = null;
            vdpWindow.location = destination;
          }
        }
      }
    : () => {};
}

function getImageComponent(vehicle, showImage) {
  return !showImage ? (
    <div className={styles.vehicleImageHidden} />
  ) : (
    <img
      alt=""
      className={styles.vehicleImage}
      src={vehicle.thumbnail || imagePlaceholder}
    />
  );
}

function VehicleComponent({
  vehicle = {},
  vehicleIndex,
  showImage,
  showMileage,
  showMMR,
  showYear,
  showYMM,
  vehicleClickedCallback
}) {
  return (
    <div
      className={styles.desktopVehicleContainer}
      onClick={getRedirectFunc(vehicle, vehicleIndex, vehicleClickedCallback)}
    >
      <div className={styles.vehicleImageContainer}>
        {getImageComponent(vehicle, showImage)}
        {getAdjustedMMRComponent(vehicle, showMMR)}
      </div>
      <div className={styles.vehicleDescription}>
        {getYearModelComponent(vehicle, showYear, showYMM)}
        {getTrimComponent(vehicle)}
        {getOdometerComponent(vehicle, showMileage)}
      </div>
    </div>
  );
}

VehicleComponent.propTypes = {
  vehicle: PropTypes.object,
  vehicleIndex: PropTypes.number,
  showImage: PropTypes.bool,
  showMileage: PropTypes.bool,
  showMMR: PropTypes.bool,
  showYear: PropTypes.bool,
  showYMM: PropTypes.bool,

  vehicleClickedCallback: PropTypes.func
};

VehicleComponent.defaultProps = {
  vehicleClickedCallback: () => {}
};

export default VehicleComponent;
