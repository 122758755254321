////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import { mobileAppVersion, mobileAppPlatform } from 'SRC/util/cookie';
import { vinChanged, openMultiDecodeModal } from 'SRC/store/actions/creators';

/* Component */
import Svg from 'SRC/components/Svg';
import resizable from 'SRC/components/resizable';

/* Local */
import styles from './styles.scss';
import vinScanIcon from './assets/VIN_scan_icon.svg';
import { formatVIN, mobileOSDisplay } from './utils';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
class VINEntry extends Component {
  // Used instead of property initializer because of __proto__ support in IE10.
  // Needed to use the `props` argument instead of `this.props`, which will be
  // undefined at this point in IE10.
  constructor(props) {
    super(props);
    this.state = {
      vin: props.vin || '',
      encodedUrl: encodeURIComponent(
        `${window.location.origin}/?mobile=true&source=vinscan${props.country &&
          `&country=${props.country}`}`
      ),
      geolocation: undefined
    };
    this.previousVin = '';
  }

  render() {
    const onNativeApp = mobileOSDisplay(
      mobileAppPlatform(),
      mobileAppVersion()
    );

    return (
      <div className={styles.formContainer}>
        <form
          data-test="VinInputForm"
          onSubmit={this.handleSubmit}
          className={styles.vinTextEntry}
        >
          <div className={styles.vinEntry}>
            <input
              type="text"
              id="vinText"
              value={this.state.vin}
              placeholder="Enter VIN"
              className={styles.vinInput}
              style={{ borderColor: this.props.errorMessage && 'red' }}
              onInput={this.handleInput}
              onChange={this.handleInput} /* both needed in IE10 */
              autoComplete={
                window.navigator.vendor === 'Apple Computer, Inc.' &&
                window.name !== 'nodejs'
                  ? 'off'
                  : 'on'
              } /*autocomplete off for Safari*/
            />
            <button
              className={`${styles.vinButton} btn-primary`}
              type="submit"
              disabled={formatVIN(this.state.vin).length < 10}
              data-test="VinButton"
            >
              <span className="container-icon-search">
                <span className="icon-search" />
              </span>
            </button>
          </div>
          {this.renderMessages()}
        </form>
        {onNativeApp && this.renderVinScan()}
      </div>
    );
  }

  componentDidMount() {
    if (window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        position => {
          this.setState({
            geolocation: encodeURIComponent(
              `&geolocation=${position.coords.latitude},${position.coords.longitude}`
            )
          });
        },
        error => {
          LOGGER.error('VinScan geoLocation: ', error.message);
        },
        { enableHighAccuracy: true, timeout: 60000, maximumAge: 1000 }
      );
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      vin:
        this.state.vin !== nextProps.vin ? nextProps.vin || '' : this.state.vin
    });

    if (this.state.vin === nextProps.vin && this.previousVin === '') {
      this.previousVin = this.state.vin;
    }
    if (nextProps.vin === null) {
      this.previousVin = '';
    }
    if (nextProps.country !== this.props.country && nextProps.country) {
      this.handleRouteChange(nextProps.country);
    }
  }

  handleInput = event => {
    this.setState({ vin: event.target.value });
  };

  handleRouteChange = country => {
    this.setState({
      encodedUrl: encodeURIComponent(
        `${
          window.location.origin
        }/?mobile=true&source=vinscan&country=${country}${
          this.state.geolocation ? `${this.state.geolocation}` : ''
        }`
      )
    });
  };

  handleSubmit = event => {
    const formattedVIN = formatVIN(this.state.vin);
    if (this.previousVin !== formattedVIN) {
      this.props.dispatchVinChanged(formattedVIN);
      this.previousVin = formattedVIN;
    } else if (this.props.possibilities.vehicles.length > 1) {
      const bestMatch = this.props.possibilities.vehicles.some(
        vehicle => vehicle.bestMatch
      );
      if (!bestMatch) this.props.dispatchOpenMultiDecodeModal();
    }
    event.preventDefault();
    this.setState({ vin: formattedVIN });
  };

  renderMessages = () => {
    return (
      <p className={styles.vinErrorMsg} data-test="VinErrorMessage">
        {this.props.errorMessage}
      </p>
    );
  };

  renderVinScan = () => {
    return (
      <a
        href={`manheim://vinscan${this.state.encodedUrl &&
          `?url=${this.state.encodedUrl}${
            this.state.geolocation ? `${this.state.geolocation}` : ''
          }`}`}
        className={`mui-button--tertiary ${styles.vinScanButton}`}
        data-test="VinScan"
      >
        <Svg
          markup={vinScanIcon}
          width="43px"
          height="34px"
          className={styles.vinScanIcon}
        />
      </a>
    );
  };
}

const idAndName = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string
});

VINEntry.propTypes = {
  vin: PropTypes.string,
  errorMessage: PropTypes.string,
  possibilities: PropTypes.shape({
    years: PropTypes.arrayOf(PropTypes.number),
    makes: PropTypes.arrayOf(idAndName),
    vehicles: PropTypes.arrayOf(
      PropTypes.shape({
        year: PropTypes.number,
        make: PropTypes.object,
        model: PropTypes.object,
        style: PropTypes.object
      })
    )
  }),
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      make: PropTypes.object,
      model: PropTypes.object,
      style: PropTypes.object
    })
  ),
  country: PropTypes.string,
  dispatchOpenMultiDecodeModal: PropTypes.func,
  dispatchVinChanged: PropTypes.func
};

const stateProps = state => ({
  possibilities: state.vinRefinement.possibilities,
  country: state.adjustments.selections.country,
  vehicles: state.vinRefinement.possibilities.vehicles,
  vin: state.vin,
  errorMessage: state.errors.vin,
  showMultiDecodeModal: state.showMultiDecodeModal
});

const actionProps = {
  dispatchVinChanged: vinChanged,
  dispatchOpenMultiDecodeModal: openMultiDecodeModal
};

const ResizableVINEntry = resizable(VINEntry);

const VINEntryContainer = connect(
  stateProps,
  actionProps
)(ResizableVINEntry);

////////////////////////////////////////////////////////////////////////////////
//&& navigator.vendor !== 'Apple Computer, Inc.'
/*** Exports (default, others) ************************************************/

export default VINEntryContainer;
export { VINEntry };
