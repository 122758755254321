////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  INVENTORY_DATA_REQUESTED,
  INVENTORY_DATA_RECEIVED,
  INVENTORY_DATA_CLEARED,
  OPEN_INVENTORY_MODAL,
  CLOSE_INVENTORY_MODAL,
  ADD_INVENTORY_FIELD_TO_HIGHLIGHT,
  INVENTORY_DATA_FAILURE
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function inventoryDataRequested(inventoryData = {}) {
  return {
    type: INVENTORY_DATA_REQUESTED,
    inventoryData
  };
}

function inventoryDataReceived(data) {
  return {
    type: INVENTORY_DATA_RECEIVED,
    data
  };
}

function inventoryDataCleared() {
  return {
    type: INVENTORY_DATA_CLEARED
  };
}

function inventoryDataFailure(errorMessage) {
  return {
    type: INVENTORY_DATA_FAILURE,
    errorMessage
  };
}

function openInventoryModal(showInventoryModal = true) {
  return {
    type: OPEN_INVENTORY_MODAL,
    showInventoryModal
  };
}

function closeInventoryModal() {
  return {
    type: CLOSE_INVENTORY_MODAL,
    showInventoryModal: false
  };
}

function highlightInventoryField(field) {
  return {
    type: ADD_INVENTORY_FIELD_TO_HIGHLIGHT,
    field
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  inventoryDataRequested,
  inventoryDataReceived,
  inventoryDataCleared,
  inventoryDataFailure,
  openInventoryModal,
  closeInventoryModal,
  highlightInventoryField
};
