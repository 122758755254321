/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

/* Internal */
import {
  openMultiDecodeModal,
  vinFullyDecoded,
  closeMultiDecodeModal
} from 'SRC/store/actions/creators';
import resizable from 'SRC/components/resizable';
import ModalDesktopTable from './Desktop';
import ModalMobileTable from './Mobile';

/* Component */

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class MultiDecodeModal extends Component {
  render() {
    const { country, viewport, vehicles } = this.props;
    if (!this.props.modalOpened) {
      return null;
    }

    const Responsive =
      {
        sm: ModalMobileTable,
        lg: ModalDesktopTable
      }[viewport] || ModalDesktopTable;

    const responsive = (
      <Responsive
        country={country}
        vehicles={vehicles}
        closeModal={this.props.dispatchModalClose}
        onSelect={this.handleSelect}
      />
    );

    return (
      <div style={{ display: 'block' }} data-test="Modal">
        <div className={styles.modal} onClick={this.props.dispatchModalClose}>
          <div
            className={`${styles.overlay} show--inline-block`}
            data-mui-col="23/24"
            onClick={this.clickInsideModal}
          >
            <div className={`${styles.modalContainer}`}>
              <div className={`${styles.close} align--right`}>
                <i
                  className="icon-cross"
                  data-test="closeModal"
                  onClick={this.props.dispatchModalClose}
                />
              </div>
              <div className={styles.header}>
                <h3>
                  {vehicles.length} vehicle styles for “{this.props.vin}”
                </h3>
                <span className={styles.subHeader}>
                  Please select from the list below.
                </span>
              </div>
              {responsive}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentWillMount() {
    window.addEventListener('keydown', this.onEsc, false);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.onEsc, false);
  }

  handleSelect = vehicle => {
    this.props.dispatchVinFullyDecoded(vehicle, 'mmr_multi');
    this.props.dispatchModalClose(vehicle);
  };

  clickInsideModal = event => {
    event.stopPropagation();
  };

  onEsc = () => {
    const { event } = window;

    if (this.props.modalOpened) {
      if ('key' in event && (event.key === 'Escape' || event.key === 'Esc')) {
        this.props.dispatchModalClose();
      } else if (event.keyCode === 27) {
        this.props.dispatchModalClose();
      }
    }
  };
}

MultiDecodeModal.propTypes = {
  country: PropTypes.string,
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      year: PropTypes.number,
      make: PropTypes.object,
      model: PropTypes.object,
      style: PropTypes.object
    })
  ),
  vin: PropTypes.string,
  dispatchVinFullyDecoded: PropTypes.func,
  viewport: PropTypes.oneOf(['sm', 'md', 'lg']),
  dispatchModalClose: PropTypes.func,
  modalOpened: PropTypes.bool
};

const stateProps = state => ({
  country: state.adjustments.selections.country,
  modalOpened: state.showMultiDecodeModal,
  vehicles: state.vinRefinement.possibilities.vehicles,
  vin: state.vin
});

const actionProps = {
  dispatchVinFullyDecoded: vinFullyDecoded,
  dispatchModalOpen: openMultiDecodeModal,
  dispatchModalClose: closeMultiDecodeModal
};

const resizeableModal = resizable(MultiDecodeModal);

const MultiDecodeModalContainer = connect(
  stateProps,
  actionProps
)(resizeableModal);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default MultiDecodeModalContainer;
export { MultiDecodeModal };
