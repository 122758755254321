////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import { NULL_VALUE } from 'SRC/constants';

//[> Local <]
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function ValueSpread({ title, above, below, currencySize }) {
  const hasValue = above !== NULL_VALUE && below !== NULL_VALUE;
  const style = {
    fontSize: currencySize || '15px'
  };

  return (
    <div className={styles.spread}>
      <div className={styles.title}>{title}</div>
      {hasValue ? (
        <div data-test="spreadValues">
          <span data-test="below" className={styles.currency} style={style}>
            {below}
          </span>{' '}
          -
          <span
            data-test="above"
            className={`${styles.currency} ${styles.below}`}
            style={style}
          >
            {above}
          </span>
        </div>
      ) : (
        <div data-test="spreadValues">{NULL_VALUE}</div>
      )}
    </div>
  );
}

ValueSpread.propTypes = {
  title: PropTypes.string,
  above: PropTypes.string,
  below: PropTypes.string,
  currencySize: PropTypes.string
};

export default ValueSpread;
