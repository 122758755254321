////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import { NULL_VALUE } from 'SRC/constants';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function numberWithCommas(number) {
  const isValidNumberOrString =
    (typeof number === 'number' && !Number.isNaN(number)) ||
    (typeof number === 'string' && /^-?\d+$/.test(number.replace(/,/g, '')));

  if (isValidNumberOrString) {
    return number
      .toString()
      .replace(/,/g, '')
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  return NULL_VALUE;
}

function maybeCurrency(value, currency, error, showZero, showSign) {
  if (!error && typeof value === 'number' && (showZero || value !== 0)) {
    const commaValue = numberWithCommas(Math.abs(value));
    const defaultValue = `$${commaValue}`;

    let sign = '';
    if (showSign) {
      if (value > 0) {
        sign = '+';
      }
      if (value < 0) {
        sign = '-';
      }
    }

    return (
      {
        USD: `${sign}${defaultValue}`,
        CAD: `${sign}C$${commaValue}`
      }[currency] || `${sign}${defaultValue}`
    );
  }

  return NULL_VALUE;
}

function maybeValue(value) {
  return value && value !== 0 ? value : NULL_VALUE;
}

function titleize(str) {
  return str.toLowerCase().replace(/(?:^|\s|-|_)\S/g, l => l.toUpperCase());
}

function formatDate(date) {
  if (!date) {
    return NULL_VALUE;
  }
  const d = new Date(date);
  const month = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  const year = d
    .getUTCFullYear()
    .toString()
    .slice(2);

  return `${month}/${day}/${year}`;
}

function formatYYYYMMDD(date) {
  const d = new Date(date);
  const month = d.getUTCMonth() + 1;
  const day = d.getUTCDate();
  const year = d.getUTCFullYear().toString();

  return `${year}-${month < 10 ? 0 : ''}${month}-${day}`;
}

function formatConditionGrade(grade, isAverage = false, isBlank = false) {
  const scaled = parseInt(grade) / 10;
  if (scaled > 0 && scaled < 1) {
    return scaled;
  }
  if ((!isAverage && scaled === 0) || scaled >= 1) {
    return scaled.toPrecision(2);
  }
  return isBlank ? '' : NULL_VALUE;
}

function shortenTransmission(t) {
  return (
    {
      Automatic: 'A',
      Manual: 'M',
      Steptronic: 'P',
      '6 Speed': '6'
    }[t.trim()] ||
    t.trim()[0] ||
    NULL_VALUE
  );
}

function formatEngineType(inputValue) {
  const value = inputValue ? inputValue.trim() : '';
  return value && value.length !== 0 ? value : NULL_VALUE;
}

function formatOdometer(odometer) {
  const odometerValue = numberWithCommas(odometer);
  return odometerValue && odometerValue !== '0' ? odometerValue : NULL_VALUE;
}

function formatOdometerUnits(unit) {
  let formattedUnits = '';
  switch (unit) {
    case 'miles':
      formattedUnits = 'mi';
      break;
    case 'kilometers':
      formattedUnits = 'km';
      break;
    default:
      formattedUnits = '';
  }
  return formattedUnits;
}

function formatLocationName(locationName) {
  return locationName ? locationName.replace(/^Manheim /, '').trim() : '';
}

function country(currency) {
  switch (currency) {
    case 'CAD':
      return 'CA';
    default:
      return 'US';
  }
}

function patchColors(href) {
  const origColor = href.split('color=')[1].split('&')[0];
  const patchedHref = href.replace(origColor, titleize(origColor));
  return patchedHref;
}

function formatConditionGradeFromURL(param) {
  const newParam = param.includes('.0') ? param.split('.')[0] : param;
  return newParam;
}

function formatConditionGradeDisplay(value) {
  if ((value || value === 0) && typeof value === 'number') {
    return Number.isInteger(value) ? value.toFixed(1) : value.toString();
  }
  return value;
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  numberWithCommas,
  maybeCurrency,
  maybeValue,
  titleize,
  formatDate,
  formatYYYYMMDD,
  formatConditionGrade,
  formatConditionGradeFromURL,
  shortenTransmission,
  formatEngineType,
  formatOdometer,
  formatOdometerUnits,
  formatLocationName,
  country,
  patchColors,
  formatConditionGradeDisplay
};
