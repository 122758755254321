////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put, select, cancelled } from 'redux-saga/effects';
import { find, filter, clone, merge, isInteger } from 'lodash';

/* Internal */
import {
  getTaxonomyYears,
  getTaxonomyMakes,
  getTaxonomyModels,
  getSimilarVehicles
} from 'SRC/api';
import { vehicleSummary } from 'SRC/util/vehicleSummary';
import { YMMfromMid } from 'SRC/util/mids';
import {
  sonarSearchReceived,
  sonarSearchFailure,
  sonarSearchRequested,
  sonarSearchStatus
} from 'SRC/store/actions/creators';
import { ADJUSTMENT_CHANGED } from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/
const { enableFuzzySearch } = INJECTED_CONFIG;

function* similarVehiclesSaga(action = null) {
  try {
    if (
      action &&
      action.type === ADJUSTMENT_CHANGED &&
      action.field !== 'odometer'
    ) {
      LOGGER.info(
        'similarVehiclesSaga ADJUSTMENT_CHANGED field is not odometer skipping...'
      );
      return;
    }
    if (enableFuzzySearch) {
      LOGGER.info('similarVehiclesSaga...fuzzySearch enabled');
    } else {
      LOGGER.info('similarVehiclesSaga...');
    }
    LOGGER.groupCollapsed('state');
    const {
      vin,
      inventoryData,
      mostRecentLookup,
      ymmsLookup,
      vinRefinement,
      userInfo
    } = yield select(state => state);
    LOGGER.info(
      'got state params:',
      vin,
      inventoryData,
      mostRecentLookup,
      ymmsLookup,
      vinRefinement,
      userInfo
    );
    LOGGER.groupEnd();
    // Prevent New Relic tests creating thousands of saved searches
    if (userInfo.userId === 'TestBot_eMMR') {
      LOGGER.info('NewRelic user skip saved searches, exiting...');
      yield put(sonarSearchStatus('NewRelic user skipping SONAR search'));
      return;
    }
    const { country, odometer } = yield select(
      state => state.adjustments.selections
    );
    const adjustedOdometer = odometer;
    LOGGER.log('country:', country);
    const ymm =
      mostRecentLookup && mostRecentLookup.mid
        ? YMMfromMid(mostRecentLookup.mid)
        : undefined;
    LOGGER.info('ymm:', ymm);
    const years = yield call(getYearsHelper, {
      vin,
      inventoryData,
      ymmsLookup,
      vinRefinement
    });
    LOGGER.info('years from helper:', years);
    const make = yield call(getMakesHelper, {
      vin,
      inventoryData,
      ymmsLookup,
      vinRefinement
    });
    LOGGER.info('make from helper:', make);
    const models = yield call(
      getModelsHelper,
      { vin, inventoryData, ymmsLookup, vinRefinement },
      make.id
    );
    LOGGER.info('models from helper:', models);
    const odo = getOdometerHelper({
      adjustedOdometer,
      inventoryData,
      mostRecentLookup
    });
    LOGGER.info('odometer from helper:', odo);

    if (ymm && years && make && Array.isArray(models) && models.length > 0) {
      yield call(sonarSearchSaga, { ymm, years, make, models, country, odo });
    } else {
      LOGGER.info('not enough data to execute sonarSearchSaga');
      const statusMsg = formatStatusMsg({ ymm, years, make, models });
      yield put(sonarSearchStatus(statusMsg));
    }
    LOGGER.info('similarVehiclesSaga complete.');
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      //yield put(similarVehiclesFailure(err.message));
    }
  }
}

// pull taxonomy yearId either from inventoryData or by matching current selection
function* getYearsHelper(criteria) {
  const { vin, inventoryData, ymmsLookup, vinRefinement } = criteria;
  let listingYearId;
  if (vin && inventoryData && inventoryData.yearId) {
    listingYearId = inventoryData.yearId;
  }
  const ymmsYear = vin
    ? String(vinRefinement.selections.year)
    : ymmsLookup.selections.year;
  const response = yield call(getTaxonomyYears);
  if (response.success) {
    LOGGER.groupCollapsed('getYearsHelper');
    const toYearItemDefault = findYear(
      response.payload,
      listingYearId || ymmsYear
    );
    LOGGER.info('toYearItemDefault:', toYearItemDefault);
    if (toYearItemDefault) {
      let toYearItem = findYear(
        response.payload,
        String(parseInt(toYearItemDefault.name) + 1)
      );
      if (!toYearItem) {
        toYearItem = toYearItemDefault;
      }
      LOGGER.info('toYearItem:', toYearItem);
      const lastYear = String(parseInt(toYearItemDefault.name) - 1);
      let fromYearItem = findYear(response.payload, lastYear);
      if (!fromYearItem) {
        fromYearItem = toYearItemDefault;
      }
      LOGGER.info('fromYearItem:', fromYearItem);
      LOGGER.groupEnd();
      return {
        itemDefault: toYearItemDefault,
        fromYear: fromYearItem,
        toYear: toYearItem
      };
    }
  } else if (listingYearId) {
    LOGGER.groupCollapsed('getYearsHelper');
    LOGGER.info('listingYearId:', listingYearId);
    LOGGER.groupEnd();
    return {
      itemDefault: { id: listingYearId, name: inventoryData.year },
      fromYear: { id: listingYearId, name: inventoryData.year },
      toYear: { id: listingYearId, name: inventoryData.year }
    };
  }
  return undefined;
}

function findYear(years, searchYear) {
  LOGGER.info('searchYear:', searchYear);
  let yearItem = find(years, item => item.name === searchYear);
  if (!yearItem) {
    yearItem = find(years, item => item.id === searchYear);
  }
  LOGGER.info('searchYear response:', yearItem);
  return yearItem || undefined;
}

// pull taxonomy makeId either from inventoryData or by matching current selection
function* getMakesHelper(criteria) {
  const { vin, inventoryData, ymmsLookup, vinRefinement } = criteria;
  if (vin && inventoryData && inventoryData.makeId) {
    return { id: inventoryData.makeId, name: inventoryData.make };
  }
  const source = vin ? vinRefinement : ymmsLookup;
  const vehicle = vehicleSummary(source.selections, source.possibilities);
  const currentVehicle = clone(vehicle);
  hackMakes(currentVehicle);
  const response = yield call(getTaxonomyMakes);
  if (response.success) {
    const item = find(
      response.payload,
      m => m.name.toUpperCase() === currentVehicle.make
    );
    if (item) {
      return item;
    }
  }
  return undefined;
}

// pull taxonomy makeId either from inventoryData or by matching current selection
function* getModelsHelper(criteria, makeId) {
  const { vin, inventoryData, ymmsLookup, vinRefinement } = criteria;
  if (vin && inventoryData && inventoryData.modelIds) {
    return formatInvModels(inventoryData.modelIds, inventoryData.models);
  }
  const source = vin ? vinRefinement : ymmsLookup;
  const vehicle = vehicleSummary(source.selections, source.possibilities);
  const currentVehicle = clone(vehicle);
  hackModels(currentVehicle);
  const response = yield call(getTaxonomyModels, makeId);
  if (response.success) {
    LOGGER.info('looking for exact match currentModel=', currentVehicle.model);
    const item = find(
      response.payload,
      m => m.name.toUpperCase() === currentVehicle.model
    );
    if (item) {
      return [item];
    }
    if (enableFuzzySearch) {
      LOGGER.groupCollapsed('fuzzySearch');
      const words = currentVehicle.model.split(' ');
      if (words.length > 0) {
        const eligibles = filter(response.payload, m =>
          m.name.toUpperCase().startsWith(words[0])
        );
        const answer = fuzzySearch(words, eligibles);
        LOGGER.info('fuzzy search answer', answer);
        LOGGER.groupEnd();
        if (answer && answer.length > 0) {
          return answer;
        }
      }
    }
  }
  return undefined;
}

function getOdometerHelper(criteria) {
  const { adjustedOdometer, inventoryData, mostRecentLookup } = criteria;
  if (adjustedOdometer && validOdometer(adjustedOdometer)) {
    LOGGER.info('using adjusted odometer value:', adjustedOdometer);
    return parseInt(adjustedOdometer);
  }
  if (
    inventoryData &&
    inventoryData.odometer &&
    validOdometer(inventoryData.odometer) &&
    (inventoryData.status === 'Live' || inventoryData.status === 'Preview')
  ) {
    LOGGER.info('using inventoryData odometer value:', inventoryData.odometer);
    return parseInt(inventoryData.odometer);
  }
  if (
    mostRecentLookup &&
    mostRecentLookup.averageOdometer &&
    validOdometer(mostRecentLookup.averageOdometer)
  ) {
    LOGGER.info(
      'using mostRecentLookup averageOdometer value:',
      mostRecentLookup.averageOdometer
    );
    return parseInt(mostRecentLookup.averageOdometer);
  }
  return 0;
}

function validOdometer(odometer) {
  if (odometer && isInteger(parseInt(odometer)) && parseInt(odometer) > 0) {
    return true;
  }
  return false;
}

function formatInvModels(modelIds, models) {
  const oIds = [];
  modelIds.forEach(id => {
    oIds.push({ id });
  });
  const oNames = [];
  models.forEach(name => {
    oNames.push({ name });
  });
  const taxonomyModels = merge(oIds, oNames);
  LOGGER.info('formatInvModels:', taxonomyModels);
  return taxonomyModels;
}

function fuzzySearch(words, array) {
  const count = words.length;
  let hits = 0;
  let currentSet = array;
  let previousSet = currentSet;
  let i = 0;
  let search = '';
  for (i = 0; i < count; i += 1) {
    if (i > 0) {
      search = search.concat(' ').concat(words[i]);
    } else {
      search = words[i];
    }
    currentSet = fuzzyMatch(search, currentSet);
    LOGGER.info('fuzzySearch currentSet', currentSet, search, i);
    if (!currentSet) {
      break;
    }
    hits += 1;
    previousSet = currentSet;
    if (previousSet && previousSet.length === 1) {
      break;
    }
  }
  LOGGER.info('fuzzySearch hits', hits);
  const score = (hits / count) * 100;
  LOGGER.info('fuzzySearch score', score);
  if (hits > 0 && previousSet && previousSet.length > 1) {
    const singleSet = exactMatch(words[0], previousSet);
    if (singleSet && singleSet.length === 1) {
      return singleSet;
    }
  }
  if (previousSet && previousSet.length === 1) {
    return previousSet;
  }
  if (
    count === 2 &&
    hits > 0 &&
    score > 49 &&
    previousSet &&
    previousSet.length === 2
  ) {
    return previousSet;
  }
  if (score > 65 && previousSet && previousSet.length < 3) {
    return previousSet;
  }
  return undefined;
}

function fuzzyMatch(word, array) {
  const narrow = filter(Array.isArray(array) ? array : [array], item =>
    item.name.toUpperCase().startsWith(word)
  );
  if (narrow && narrow.length > 0) {
    return narrow;
  }
  return undefined;
}

function exactMatch(word, array) {
  const narrow = filter(
    Array.isArray(array) ? array : [array],
    item => item.name.toUpperCase() === word
  );
  if (narrow && narrow.length > 0) {
    return narrow;
  }
  return undefined;
}

function hackMakes(currentVehicle) {
  if (currentVehicle) {
    if (currentVehicle.make === 'B M W') {
      currentVehicle.make = 'BMW'; // eslint-disable-line no-param-reassign
    }
  }
}

function hackModels(currentVehicle) {
  if (currentVehicle) {
    if (currentVehicle.make === 'MINI') {
      if (
        currentVehicle.model === 'COOPER 3C' ||
        currentVehicle.model === 'COOPER 4C' ||
        currentVehicle.model === 'COOPER'
      ) {
        if (currentVehicle.style && currentVehicle.style.length > 0) {
          currentVehicle.model = currentVehicle.style; // eslint-disable-line no-param-reassign
          currentVehicle.model = currentVehicle.model.replace('2D ', ''); // eslint-disable-line no-param-reassign
          return;
        }
      }
      currentVehicle.model = currentVehicle.model.replace('COOPER ', ''); // eslint-disable-line no-param-reassign
    }
    if (currentVehicle.make === 'MERCEDES-BENZ') {
      currentVehicle.model = currentVehicle.model.replace(' CLASS', '-CLASS'); // eslint-disable-line no-param-reassign
    }
  }
}

function formatStatusMsg({ ymm, years, make, models }) {
  if (!ymm) {
    return 'unable to resolve ymm';
  }
  if (!years) {
    return 'unable to resolve toYearId and fromYearId';
  }
  if (!make) {
    return 'unable to resolve makeId';
  }
  if (!models) {
    return 'unable to resolve modelId';
  }
  return 'unable to resolve search criteria';
}

// handle actual sonar search call
function* sonarSearchSaga(searchParameters) {
  try {
    LOGGER.info('sonarSearchSaga:', searchParameters);
    yield put(sonarSearchRequested(searchParameters));
    const { ymm, years, make, models, country, odo } = searchParameters;
    const makeSeriesModelTrimIds = formatMakeSeriesModelTrimIds(
      make.id,
      models.map(m => m.id)
    );
    const response = yield call(getSimilarVehicles, {
      ymm,
      years,
      make,
      makeSeriesModelTrimIds,
      country,
      odo
    });
    if (!response.success) {
      throw response.payload;
    }
    if (response.success) {
      yield put(sonarSearchReceived(response.payload));
    }
    LOGGER.info('sonarSearchSaga complete.');
  } catch (err) {
    if (!(yield cancelled())) {
      LOGGER.error(err);
      yield put(sonarSearchFailure(err.message));
    }
  }
}

function formatMakeSeriesModelTrimIds(makeId, modelIds) {
  const makeSeriesModelTrimIds = [];
  modelIds.forEach(modelId => {
    makeSeriesModelTrimIds.push({ makeId, modelId });
  });
  LOGGER.info('formatMakeSeriesModelTrimId:', makeSeriesModelTrimIds);
  return makeSeriesModelTrimIds;
}
////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/
export default similarVehiclesSaga;
export {
  sonarSearchSaga,
  getYearsHelper,
  getMakesHelper,
  getModelsHelper,
  getOdometerHelper,
  fuzzySearch
};
