////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Internal */
import styles from '../styles.scss';

/* Component */
import TransactionsHeader from '../shared/TransactionsHeader';
import TabletTableHeader from './header';
import TabletTableBody from './body';
import TableFooter from '../shared/TableFooter';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TransactionsTablet({
  columns,
  filterCount,
  loading,
  isCollapsed,
  onCollapse,
  onChangeSort,
  onShowMore,
  onShowLess,
  sortBy,
  sortOrder,
  successfulLookup,
  displayedTransactions,
  rawTransactions,
  filteredTransactions,
  viewport,
  visibleRows,
  odometerUnits
}) {
  return (
    <span>
      <TransactionsHeader
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        filterCount={filterCount}
        visibleRows={visibleRows}
        filteredTransactions={filteredTransactions}
      />
      <div
        className={`${styles.collapsible} ${
          isCollapsed ? styles.isCollapsed : ''
        }`}
      >
        <TabletTableHeader
          columns={columns}
          sortBy={sortBy}
          sortOrder={sortOrder}
          onChangeSort={onChangeSort}
          odometerUnits={odometerUnits}
        />
        <TabletTableBody
          columns={columns}
          loading={loading}
          transactions={displayedTransactions}
          visibleRows={visibleRows}
          successfulLookup={successfulLookup}
        />
        <TableFooter
          loading={loading}
          onShowMore={onShowMore}
          onShowLess={onShowLess}
          transactions={filteredTransactions}
          rawTransactions={rawTransactions}
          viewport={viewport}
          visibleRows={visibleRows}
        />
      </div>
    </span>
  );
}

TransactionsTablet.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.string),
  filterCount: PropTypes.number,
  loading: PropTypes.bool,
  onChangeSort: PropTypes.func,
  onShowMore: PropTypes.func,
  onShowLess: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.oneOf(['asc', 'desc']),
  successfulLookup: PropTypes.bool,
  displayedTransactions: PropTypes.arrayOf(PropTypes.object),
  rawTransactions: PropTypes.arrayOf(PropTypes.object),
  filteredTransactions: PropTypes.arrayOf(PropTypes.object),
  viewport: PropTypes.string,
  visibleRows: PropTypes.number,
  odometerUnits: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TransactionsTablet;
