////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  MULTI_DECODE_MODAL_OPENED,
  MULTI_DECODE_MODAL_CLOSED
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialState = false;

function showMultiDecodeModal(state = initialState, action = {}) {
  switch (action.type) {
    case MULTI_DECODE_MODAL_OPENED:
      return action.showMultiDecodeModal;
    case MULTI_DECODE_MODAL_CLOSED:
      return false;
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { showMultiDecodeModal };
