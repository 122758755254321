////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Component */
import TransactionsHeader from '../shared/TransactionsHeader';
import DesktopTableHeader from './header';
import DesktopTableBody from './body';
import TableFooter from '../shared/TableFooter';

/* Local */
import styles from '../styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function TransactionsDesktop({
  columns,
  filterCount,
  isCollapsed,
  onCollapse,
  loading,
  onChangeSort,
  onShowMore,
  onShowLess,
  sortBy,
  sortOrder,
  successfulLookup,
  rawTransactions,
  displayedTransactions,
  filteredTransactions,
  viewport,
  visibleRows,
  odometerUnits
}) {
  return (
    <div>
      <TransactionsHeader
        isCollapsed={isCollapsed}
        onCollapse={onCollapse}
        filterCount={filterCount}
        filteredTransactions={filteredTransactions}
        visibleRows={visibleRows}
      />
      <div
        className={`${styles.collapsible} ${
          isCollapsed ? styles.isCollapsed : ''
        }`}
      >
        <table className={`mui-table ${styles.muiTable}`} data-test="TxnTable">
          <DesktopTableHeader
            columns={columns}
            onChangeSort={onChangeSort}
            sortBy={sortBy}
            sortOrder={sortOrder}
            odometerUnits={odometerUnits}
          />
          <DesktopTableBody
            columns={columns}
            loading={loading}
            transactions={displayedTransactions}
            visibleRows={visibleRows}
            successfulLookup={successfulLookup}
          />
          <TableFooter
            loading={loading}
            onShowMore={onShowMore}
            onShowLess={onShowLess}
            rawTransactions={rawTransactions}
            transactions={filteredTransactions}
            viewport={viewport}
            visibleRows={visibleRows}
          />
        </table>
      </div>
    </div>
  );
}

TransactionsDesktop.propTypes = {
  isCollapsed: PropTypes.bool,
  onCollapse: PropTypes.func,
  columns: PropTypes.arrayOf(PropTypes.string),
  filterCount: PropTypes.number,
  loading: PropTypes.bool,
  onChangeSort: PropTypes.func,
  onShowMore: PropTypes.func,
  onShowLess: PropTypes.func,
  sortBy: PropTypes.string,
  sortOrder: PropTypes.string,
  successfulLookup: PropTypes.bool,
  displayedTransactions: PropTypes.arrayOf(PropTypes.object),
  rawTransactions: PropTypes.arrayOf(PropTypes.object),
  filteredTransactions: PropTypes.arrayOf(PropTypes.object),
  viewport: PropTypes.string,
  visibleRows: PropTypes.number,
  odometerUnits: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default TransactionsDesktop;
