////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  OPEN_LOOKUP_WIZARD,
  CLOSE_LOOKUP_WIZARD,
  SET_WIZARD_STEP
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function openLookupWizard() {
  const validViewport = window.innerWidth < 568;

  return {
    type: OPEN_LOOKUP_WIZARD,
    showLookupWizard: validViewport
  };
}

function closeLookupWizard() {
  return {
    type: CLOSE_LOOKUP_WIZARD,
    showLookupWizard: false
  };
}

function setWizardStep(step) {
  return {
    type: SET_WIZARD_STEP,
    currentWizardStep: step
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { openLookupWizard, closeLookupWizard, setWizardStep };
