////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';
import { map, groupBy, capitalize } from 'lodash';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

class List extends Component {
  render() {
    const { currentWizardStep, items, disabled, apiError } = this.props;
    const lookupType = apiError ? apiError.lookupType : '';
    const errorInCurrentStep =
      lookupType && lookupType.includes(currentWizardStep.toUpperCase());
    const shouldGroupItems = ['make', 'model'].includes(currentWizardStep);
    const list = this.pickListHandler(
      errorInCurrentStep,
      apiError,
      shouldGroupItems,
      items,
      disabled
    );

    return (
      <div className={styles.selectionList}>
        <div className={`bold ${styles.currentStep}`}>
          Select a{' '}
          <span data-test="currentWizardStep">
            {capitalize(currentWizardStep)}
          </span>
        </div>
        <ul
          className={`${styles.list} ${styles.parentList}`}
          data-test={`${currentWizardStep}ItemList`}
        >
          {list}
        </ul>
      </div>
    );
  }

  pickListHandler = (
    errorInCurrentStep,
    apiError,
    shouldGroupItems,
    items,
    disabled
  ) => {
    if (errorInCurrentStep) {
      return this.apiErrorItemList(apiError);
    }
    return shouldGroupItems
      ? this.groupedItemList(items, disabled)
      : this.flatItemList(items, disabled);
  };

  apiErrorItemList = apiError => {
    const buttonText =
      apiError.lookupType === 'YEARS' ? 'Refresh' : 'Retry Lookup';
    return (
      <li className={`${styles.apiErrorContainer}`}>
        <div className={`${styles.apiErrorItem} mui-row`}>
          <div className="mui-col mui-col-1-1" data-test="apiErrorMsg">
            <i
              className={`${styles.apiErrorIcon} icon-notification-o mui-m-h-r`}
            />
            {apiError.message}
          </div>
        </div>
        <div className={`${styles.apiErrorBtn} mui-row`}>
          <div className="mui-col mui-col-1-1">
            <button
              type="button"
              onClick={this.apiButtonAction}
              className="mui-button--sm mui-button--tertiary"
              data-test="ApiErrorRetry"
            >
              {buttonText}
            </button>
          </div>
        </div>
      </li>
    );
  };

  flatItemList = (items, disabled) => {
    return items.map(item => (
      <li
        key={item.id || item}
        onClick={() => this.props.handleSelectItem(item)}
        className={`${styles.item} ${disabled ? styles.disabled : ''}`}
      >
        {item.name || item}
        <i className="icon-chevron-right pull--right" />
      </li>
    ));
  };

  groupedItemList = (items, disabled) => {
    const groupedItems = groupBy(items, item => {
      // eslint-disable-next-line no-restricted-globals
      return isNaN(item.name.charAt(0)) ? item.name.charAt(0) : '#';
    });

    return map(groupedItems, (values, key) => (
      <ul className={styles.list} key={key}>
        <div id={key} className={styles.charHeading}>
          {key}
        </div>
        {this.flatItemList(values, disabled)}
      </ul>
    ));
  };

  apiButtonAction = () => {
    return window.location.reload();
  };
}

List.propTypes = {
  currentWizardStep: PropTypes.string,
  items: PropTypes.any,
  disabled: PropTypes.bool,
  handleSelectItem: PropTypes.func,
  apiError: PropTypes.shape({
    lookupType: PropTypes.string,
    message: PropTypes.string
  })
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default List;
