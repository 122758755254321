////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, select, take, actionChannel } from 'redux-saga/effects';
import { buffers } from 'redux-saga';
import pickBy from 'lodash/pickBy';
import deepEqual from 'deep-equal';

/* Local */
import { stateToQuery } from 'SRC/util/queryParams';
import { truthyOrZero } from 'SRC/util/helperUtils';
import {
  urlMatchers,
  temporaryMatchers,
  stateMatchers,
  actionableMatchers,
  completeValuationActions
} from './config';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* syncURL(history, currentAction) {
  const currentState = yield select(state => state);
  const location = history.getCurrentLocation();

  // Based on the current location and state, construct a new query string
  const newQuery = pickBy(
    { ...location.query, ...stateToQuery(currentState) },
    truthyOrZero
  );
  // special case INIT state once
  if (currentAction && currentAction.type === 'INIT') {
    history.replace({
      ...location,
      query: newQuery,
      state: { action: currentAction, state: currentState }
    });
    return;
  }
  // If currentAction is one of urlMatchers it is keepable
  //  a) we want to keep that one and push a new if url has changed
  // unless location.state.action (prevAction) is one of replaceMatcher and url has changed
  //  b) then we replace existing with currentAction, url and state
  // if currentAction is a stateMatcher and url has not changed
  //  c) replace so that state is updated
  const keepable = urlMatchers.some(matcher =>
    matcher(currentAction, location.query)
  );
  const stateOnly = stateMatchers.some(matcher =>
    matcher(currentAction, location.query)
  );
  const previousAction =
    location.state && location.state.action ? location.state.action : {};

  let forceReplace = temporaryMatchers.some(matcher =>
    matcher(previousAction, location.query)
  );

  if (!forceReplace && completeValuationActions.includes(currentAction.type)) {
    const hasHistory = previousAction.type !== 'INIT';
    const wizardClosed =
      !location.query.year && !location.query.make && !location.query.model;
    const hasNoLookup = !location.query.mid && !location.query.vin;
    const wizardOnFirstStep = location.query.wizard === 'year';
    forceReplace =
      wizardOnFirstStep && hasHistory && hasNoLookup && wizardClosed;
  }

  // Handle history updates
  if (keepable && !deepEqual(location.query, newQuery)) {
    if (!forceReplace) {
      history.push({
        ...location,
        query: newQuery,
        state: { action: currentAction, state: currentState }
      });
    } else {
      history.replace({
        ...location,
        query: newQuery,
        state: { action: currentAction, state: currentState }
      });
    }
  } else if (stateOnly && deepEqual(location.query, newQuery)) {
    history.replace({
      ...location,
      query: newQuery,
      state: { action: currentAction, state: currentState }
    });
  }
}

function* respondToStateChanges(history) {
  const bufferedActions = yield actionChannel('*', buffers.expanding(10));
  yield call(syncURL, history, { type: 'INIT' });

  // eslint-disable-next-line no-constant-condition
  while (true) {
    // Wait for any action to happen (meaning the state might have changed)
    const action = yield take(bufferedActions);
    const { query } = history.getCurrentLocation();

    if (actionableMatchers.some(matcher => matcher(action, query))) {
      yield call(syncURL, history, action);
    }
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { syncURL, respondToStateChanges };
