/* eslint-disable */
import getCookie, { getAuthTicket } from 'SRC/util/cookie';
import { ComponentLoader } from 'PUBLIC/component-loader.min.js';
const { universalHeaderEndpoint } = INJECTED_CONFIG;

// -------- Header --------
window.MMR = window.MMR || Object.create(null);
window.MMR.layout = '';

// patch IE
if (!document.location.origin) {
  const url = `${document.location.protocol}//${document.location.hostname}`;
  const port = window.location.port ? `:${window.location.port}` : '';
  document.location.origin = `${url}${port}`;
}

if (
  getCookie('manheim_mobile_application_flag') ||
  /m\.manheim/.test(document.referrer) ||
  window.location.search.indexOf('mobile=true') !== -1
) {
  window.MMR.layout = 'mobile';
} else if (window.location.search.indexOf('popup=true') !== -1) {
  window.MMR.layout = 'popup';
}

function buildUniversalUrl(type = 'header', layout = 'responsive') {
  let url = `${universalHeaderEndpoint}${type}/${layout}.html`;
  if (document.location.origin.includes('.man-')) {
    const preprodEnv = document.location.origin.split('.')[2];
    if (preprodEnv !== 'man-uat') {
      url = url.replace('man-uat', preprodEnv);
    }
  }
  LOGGER.info('buildUniversalUrl=', url);
  return url;
}
const layout =
  window.MMR.layout && window.MMR.layout !== ''
    ? window.MMR.layout
    : 'responsive';
const universal_header_url = buildUniversalUrl('header', layout);
const universal_footer_url = buildUniversalUrl('footer', layout);
const auth_ticket_value = getAuthTicket();

function loadHeader(header_url, auth_ticket_value) {
  var opts = {
    selector: '#universal_header_container',
    url: header_url,
    placement: window.ComponentLoader.INSERT_APPEND,
    cache: true
  };

  if (auth_ticket_value) {
    opts.headers = { Authorization: 'Bearer ' + auth_ticket_value };
  }

  try {
    window.ComponentLoader(opts);
  } catch (err) {
    LOGGER.error(err);
  }
}

if (layout !== 'popup') {
  loadHeader(universal_header_url, auth_ticket_value);
}

function loadFooter(footer_url) {
  var opts = {
    headers: {},
    selector: '#universal_footer_container',
    url: footer_url,
    placement: window.ComponentLoader.INSERT_APPEND,
    cache: false
  };

  try {
    window.ComponentLoader(opts);
  } catch (err) {
    console.error(err);
  }
}

loadFooter(universal_footer_url);
