////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { combineReducers } from 'redux';

/* Internal */
import {
  VIN_CHANGED,
  VIN_FULLY_DECODED,
  VIN_DECODER_REPLIED,
  YMMS_FIELD_SELECTED,
  VIN_LOOKUP_CLEARED,
  VIN_PARTIALLY_DECODED,
  VIN_FIELD_SELECTED,
  VIN_STYLES_LOADED,
  CLEAR_VALUATION
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialPossibilities = {
  years: [],
  makes: [],
  models: [],
  styles: [],
  vehicles: []
};

function possibilities(state = initialPossibilities, action = {}) {
  switch (action.type) {
    case VIN_DECODER_REPLIED:
      return {
        years: action.years,
        makes: action.makes,
        models: action.models,
        styles: action.styles,
        vehicles: action.vehicles
      };
    case VIN_CHANGED:
    case VIN_LOOKUP_CLEARED:
    case CLEAR_VALUATION:
      return initialPossibilities;
    case YMMS_FIELD_SELECTED:
      if (action.keepSelection === true) {
        return state;
      }
      return initialPossibilities;
    case VIN_PARTIALLY_DECODED: {
      const multipleModels = action.models && action.models.length > 1;
      return {
        years: action.years || initialPossibilities.years,
        makes: action.makes || initialPossibilities.makes,
        models: action.models || initialPossibilities.models,
        styles: multipleModels ? initialPossibilities.styles : action.styles,
        vehicles: action.vehicles
      };
    }
    case VIN_STYLES_LOADED:
      return {
        ...state,
        styles: action.styles
      };
    default:
      return state;
  }
}

const initialSelections = {
  year: null,
  make: null,
  model: null,
  style: null,
  vehicle: null
};

function selections(state = initialSelections, action = {}) {
  switch (action.type) {
    case VIN_FULLY_DECODED:
      return {
        year: action.vehicle.description.year,
        make: action.vehicle.description.make.id,
        model: action.vehicle.description.model.id,
        style: action.vehicle.description.style.id,
        vehicle: action.vehicle
      };
    case VIN_CHANGED:
    case VIN_LOOKUP_CLEARED:
    case CLEAR_VALUATION:
      return initialSelections;
    case YMMS_FIELD_SELECTED:
      if (action.keepSelection === true) {
        return state;
      }
      return initialSelections;
    case VIN_PARTIALLY_DECODED:
      return {
        ...state,
        year: action.year || initialSelections.year,
        make: action.make || initialSelections.make,
        model: action.model || initialSelections.model,
        style: action.style || initialSelections.style
      };
    case VIN_FIELD_SELECTED: {
      return { ...state, [action.field]: action.value };
    }
    default:
      return state;
  }
}

const initialVinValues = {
  years: null,
  makes: null,
  models: null,
  styles: null
};

function vinValues(state = initialVinValues, action = {}) {
  switch (action.type) {
    case VIN_DECODER_REPLIED:
      return {
        years: action.years,
        makes: action.makes,
        models: action.models,
        styles: action.styles
      };
    case VIN_LOOKUP_CLEARED:
      return initialVinValues;
    default:
      return state;
  }
}

const vinRefinement = combineReducers({ possibilities, selections, vinValues });

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default vinRefinement;
export { possibilities, selections, vinValues };
