////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { call, put } from 'redux-saga/effects';

/* Internal */
import { getEdition } from 'SRC/api';
import {
  editionLoaded,
  failedLoadingEdition
} from 'SRC/store/actions/creators';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* editionSaga({ value: country }) {
  try {
    const edition = yield call(getEdition, country);

    if (edition.success) {
      yield put(editionLoaded(country, edition.payload));
    } else {
      throw edition.payload;
    }
  } catch (err) {
    LOGGER.error(err);
    yield put(failedLoadingEdition());
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default editionSaga;
