////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function MMRHorizontalBar() {
  return (
    <div className={`${styles.container} mui-col mui-col-1-1`}>
      <span>MMR</span>
      <svg width="60" height="28">
        <rect width="60" height="28" style={{ fill: 'white' }} />
      </svg>
    </div>
  );
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default MMRHorizontalBar;
