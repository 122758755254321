import { isObject } from 'SRC/util/helperUtils';

function midToYMMS(mid) {
  const stringMid = String(mid);
  const [year, make, model, style] = [
    stringMid.slice(0, 4),
    stringMid.slice(4, 7),
    stringMid.slice(7, 11),
    stringMid.slice(11, 15)
  ];

  return { year, make, model, style };
}

function ymmsToMid({ year, make, model, style }) {
  const makeID = isObject(make) ? make.id : make;
  const modelID = isObject(model) ? model.id : model;
  const styleID = isObject(style) ? style.id : style;

  if (year && makeID && modelID && styleID) {
    return String(String(year) + makeID + modelID + styleID);
  }

  return null;
}

function hrefToMid(href) {
  const parsedHref = href.split('/id/')[1].split('?')[0];
  return parsedHref;
}

function YMMfromMid(mid) {
  const stringMid = String(mid);
  return stringMid.slice(0, 11);
}

export { midToYMMS, ymmsToMid, hrefToMid, YMMfromMid };
