////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  MID_CHANGED,
  VIN_FULLY_DECODED,
  VIN_FIELD_SELECTED,
  OPEN_LOOKUP_WIZARD,
  CLOSE_LOOKUP_WIZARD,
  SET_WIZARD_STEP,
  YMMS_YEARS_LOADED,
  YMMS_MAKES_LOADED,
  YMMS_MODELS_LOADED,
  YMMS_STYLES_LOADED,
  YMMS_FIELD_SELECTED,
  YMMS_SELECTION_CLEARED,
  FAILED_LOADING_YMMS_YEARS,
  FAILED_LOADING_YMMS_MAKES,
  FAILED_LOADING_YMMS_MODELS,
  FAILED_LOADING_YMMS_STYLES
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const initialShowState = false;

function showLookupWizard(state = initialShowState, action = {}) {
  switch (action.type) {
    case VIN_FULLY_DECODED:
    case MID_CHANGED:
    case CLOSE_LOOKUP_WIZARD:
      return false;
    case YMMS_FIELD_SELECTED:
      if (action.field === 'style') {
        return false;
      }
      return state;
    case VIN_FIELD_SELECTED:
      return action.field !== 'style';
    case OPEN_LOOKUP_WIZARD:
      return action.showLookupWizard;
    default:
      return state;
  }
}

const initialStepState = 'year';

function currentWizardStep(state = initialStepState, action = {}) {
  switch (action.type) {
    case OPEN_LOOKUP_WIZARD:
    case YMMS_YEARS_LOADED:
    case FAILED_LOADING_YMMS_YEARS: // crh test getting to empty step
      return 'year';
    case YMMS_MAKES_LOADED:
    case FAILED_LOADING_YMMS_MAKES: // crh test getting to empty step
      return 'make';
    case YMMS_MODELS_LOADED:
    case FAILED_LOADING_YMMS_MODELS: // crh test getting to empty step
      return 'model';
    case YMMS_STYLES_LOADED:
    case FAILED_LOADING_YMMS_STYLES: // crh test getting to empty step
      return 'style';
    case YMMS_FIELD_SELECTED:
      if (action.field === 'style') {
        return 'year';
      }
      return state;
    case SET_WIZARD_STEP:
      return action.currentWizardStep;
    case YMMS_SELECTION_CLEARED:
      return action.field;
    default:
      return state;
  }
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { showLookupWizard, currentWizardStep };
