////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { isEmpty, pickBy } from 'lodash';

/* Internal */
import resizable from 'SRC/components/resizable';
import { adjustmentsFootnote, truthyOrZero } from 'SRC/util/helperUtils';

/* Component */
import Title from 'SRC/components/Title';
import CurrencyDisplay from 'SRC/components/CurrencyDisplay';
import Speedometer from 'SRC/components/Speedometer';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

let memo = null;

function AdjustedMMR({
  adjustedBy,
  adjustedMMRValue,
  currency,
  error,
  selections,
  wholesaleValues,
  successfulLookup,
  adjustmentsCompleted,
  viewport
}) {
  const notAdjustedMessage = adjustmentsFootnote(adjustedBy, selections);
  const { above, average, below } = Object(wholesaleValues);
  const { region, color, grade, odometer } = selections;
  const triangle = triangleDirection(adjustedMMRValue, average);
  const adjustmentRequested = !isEmpty(
    pickBy({ region, color, grade, odometer }, truthyOrZero)
  );
  const valuationAvailable = !!average;
  const showAdjusted = adjustmentRequested && valuationAvailable;

  // our state may change in-between request for adjustment
  // and response from API so we want to re-render current value
  // since we cannot implement shouldComponentUpdate
  if (memo && successfulLookup && !adjustmentsCompleted) {
    return memo;
  }

  memo = (
    <div
      className={`mui-row ${styles.container} font--lato`}
      data-test="AdjustedMMR"
    >
      <div className={`mui-col mui-col-1-1 ${styles.adjustedMMRContainer}`}>
        <div className="mui-col mui-col-1-1">
          <Title size="16px" printVersion="ADJUSTED">
            {viewport === 'lg' ? 'ADJUSTED' : 'ADJUSTED MMR'}
          </Title>
        </div>
        <div className={`mui-col mui-col-1-1 ${styles.currencyContainer}`}>
          <CurrencyDisplay
            value={showAdjusted && adjustedMMRValue}
            currency={currency}
            asterisk={notAdjustedMessage}
            error={error}
            size="30px"
            showZero
          />
          {showAdjusted && triangle}
        </div>
        <div className="mui-col mui-col-1-1" data-test="MMRNotAdjusted">
          {notAdjustedMessage && showAdjusted && (
            <div
              className={`${styles.footnote}`}
              data-test="MMRNotAdjustedFooter"
            >
              {notAdjustedMessage}
            </div>
          )}
        </div>
      </div>
      <div className="mui-col mui-col-1-1 speedometerContainer">
        <Speedometer
          below={below}
          average={average}
          above={above}
          adjusted={adjustedMMRValue}
          currency={currency}
          active={Boolean(showAdjusted)}
        />
      </div>
    </div>
  );
  return memo;
}

function triangleDirection(adjusted, wholesale) {
  if (adjusted < wholesale) {
    return (
      <i className={`${styles.red} icon-triangle-down`} data-test="deltaIcon" />
    );
  }
  if (adjusted > wholesale) {
    return (
      <i className={`${styles.green} icon-triangle-up`} data-test="deltaIcon" />
    );
  }
  return false;
}
// Optional, provides type-checking in development only
AdjustedMMR.propTypes = {
  adjustedBy: PropTypes.object,
  adjustedMMRValue: PropTypes.number,
  currency: PropTypes.string,
  adjustmentsCompleted: PropTypes.bool,
  wholesaleValues: PropTypes.shape({
    above: PropTypes.number,
    average: PropTypes.number,
    below: PropTypes.number
  })
};

const stateProps = state => ({
  adjustedBy: state.mostRecentLookup.adjustedPricing.adjustedBy,
  adjustedMMRValue:
    state.mostRecentLookup.adjustedPricing.wholesale &&
    state.mostRecentLookup.adjustedPricing.wholesale.average,
  currency: state.mostRecentLookup.currency,
  adjustmentsCompleted: state.mostRecentLookup.adjustmentsCompleted,
  wholesaleValues: state.mostRecentLookup.wholesaleValues,
  selections: state.adjustments.selections,
  successfulLookup: state.mostRecentLookup.successfulLookup
});

const resizableAdjustedMMR = resizable(AdjustedMMR);

const AdjustedMMRContainer = connect(stateProps)(resizableAdjustedMMR);

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default AdjustedMMRContainer;
export { AdjustedMMR };
