/* eslint-disable no-console, prefer-rest-params */
import { EXTERNAL } from 'SRC/constants';
import { retrieve } from 'SRC/util/localStorage';

function getDebugFlag() {
  const storedDebug = retrieve('MMR_DEBUG');
  return storedDebug === true;
}

const shouldLog =
  (MMR_ENV === 'prod' && getDebugFlag()) ||
  (MMR_ENV !== 'prod' && NODE_ENV !== 'test' && window.name !== 'nodejs');

// IE9 logging workaround
if (
  Function.prototype.bind &&
  typeof window === 'object' &&
  typeof window.console === 'object' &&
  typeof console.log === 'object'
) {
  [
    'log',
    'info',
    'warn',
    'error',
    'table',
    'assert',
    'dir',
    'clear',
    'profile',
    'profileEnd'
  ].forEach(function bindConsoleMethod(method) {
    console[method] = this.bind(console[method], console);
  }, Function.prototype.call);
}

function log() {
  if (shouldLog && 'log' in console) {
    console.log(...arguments);
  }
}

function info() {
  if (shouldLog && 'info' in console) {
    console.info(...arguments);
  }
}

function error(err) {
  try {
    if (shouldLog && 'error' in console) {
      if (Array.isArray(err)) {
        err.forEach(error);
      } else if (err instanceof Error) {
        console.error(err.stack);
        if (!EXTERNAL) {
          Rollbar.error(err);
        }
      } else {
        console.error(err);
      }
    }
  } catch (loggingError) {
    // Swallow the error, it wasn't meant to be.
    console.error(
      'Something went wrong processing this error, ' +
        'most likely Rollbar failed to initialize'
    );
    console.error(loggingError);
  }
}

function group() {
  if (shouldLog && 'group' in console) {
    console.group(...arguments);
  }
}

function groupCollapsed() {
  if (shouldLog && 'groupCollapsed' in console) {
    console.groupCollapsed(...arguments);
  }
}

function groupEnd() {
  if (shouldLog && 'groupEnd' in console) {
    console.groupEnd(...arguments);
  }
}

function table() {
  if (shouldLog && 'table' in console) {
    console.table(...arguments);
  }
}

function logGapiCall(requests, unfetchedRequests, responses, rawResponse) {
  try {
    if (shouldLog) {
      const total = requests.length;
      const actual = unfetchedRequests.length;
      const fromCache = total - actual;
      const hrefs = requests.map(r => r.href);
      const hrefsToFetch = unfetchedRequests.map(r => r.href);
      const allSuccessful =
        responses && responses.every(response => response.success);
      groupCollapsed(
        `%c%cGAPI call%c: %c${total} total href(s)%c (%c${fromCache} from cache%c, %c${actual} requested%c)`,
        'font-style: bold',
        ...[
          allSuccessful ? 'green' : 'red',
          'black',
          'blue',
          'black',
          fromCache > 0 ? 'teal' : 'black',
          'black',
          actual > 0 ? 'orange' : 'black',
          'black'
        ].map(color => `color:${color}`)
      );
      groupCollapsed('all hrefs');
      hrefs.forEach((href, i) => {
        log('%d: %c%s', i, 'color:darkred', href);
      });
      groupEnd();
      groupCollapsed('actually requested hrefs (not in cache)');
      hrefsToFetch.forEach((href, i) => {
        log('%d: %c%s', i, 'color:darkred', href);
      });
      groupEnd();
      groupCollapsed('transformed responses');
      responses.forEach((response, i) => {
        groupCollapsed(`${i}`);
        log(response.payload);
        groupCollapsed('Stringified:');
        log(JSON.stringify(response.payload, null, 2));
        groupEnd();
        groupEnd();
      });
      groupEnd();
      if (actual > 0) {
        groupCollapsed('raw response');
        log(rawResponse);
        groupCollapsed('Stringified:');
        log(JSON.stringify(rawResponse, null, 2));
        groupEnd();
        groupEnd();
      }
      groupCollapsed('response cache after this call');
      log(window.MMR.responseCache);
      groupEnd();
      groupEnd();
      groupEnd();
    }
  } catch (err) {
    console.log('Error logging GAPI call:', err);
  }
}

export {
  log,
  info,
  error,
  group,
  groupCollapsed,
  groupEnd,
  table,
  logGapiCall
};
