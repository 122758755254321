////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { takeEvery, takeLatest } from 'redux-saga';
import { call, fork, put } from 'redux-saga/effects';

/* Internal */
import { beginInitialLoading } from 'SRC/store/actions/creators';
import {
  ADJUSTMENT_CHANGED,
  MID_CHANGED,
  VALUATION_CHANGED,
  MID_CHANGED_FAILURE,
  VIN_CHANGED,
  YMMS_FIELD_SELECTED,
  VIN_FIELD_SELECTED,
  ADJUSTMENT_SELECTED,
  MULTIPLE_YMMS_FIELDS_SELECTED,
  VIN_FULLY_DECODED,
  VIN_CHANGED_FAILURE,
  ALL_ADJUSTMENTS_CLEARED,
  RESET_VALUATION,
  REFRESH_CACHE,
  CHECK_CACHE,
  SHOW_TRANSACTIONS,
  INVENTORY_DATA_REQUESTED,
  SONAR_SEARCH_RECEIVED
} from 'SRC/store/actions/types';
import { INITIAL_CACHE_MONITOR_WAIT, EXTERNAL } from 'SRC/constants';

/* Local */
import { onMobileApp, mobileAppPlatform } from 'SRC/util/cookie';
import authenticationSaga from './authentication';
import oauthAuthenticationSaga from './oauthAuthentication';
import adjustmentChangedSaga from './adjustmentChanged';
import midChangedSaga from './midChanged';
import analyticsSaga, { componentImpressionSaga } from './analytics';
import vinChangedSaga, { vinDecodedSaga } from './vinChanged';
import {
  ymmsFieldSelectedSaga,
  multipleYmmsFieldsSelectedSaga
} from './ymmsFieldSelected';
import { vinFieldSelectedSaga } from './vinFieldSelected';
import { adjustmentSelectedSaga } from './adjustments';
import urlSaga from './url';
import titleSaga from './title';
import editionSaga from './edition';
import initialResourcesSaga from './initialResources';
import resetValuationSaga from './resetValuation';
import cacheMonitorSaga, { runCacheMonitorWorker } from './cache';
import inventoryDataSaga from './inventoryData';
import regionFilterSaga from './regionFilter';
import similarVehiclesSaga from './similarVehicles';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function* listeners() {
  yield [
    takeEvery(RESET_VALUATION, resetValuationSaga),
    takeEvery(
      [ADJUSTMENT_SELECTED, ALL_ADJUSTMENTS_CLEARED],
      adjustmentSelectedSaga
    ),
    takeLatest(ADJUSTMENT_CHANGED, adjustmentChangedSaga),
    takeLatest(MID_CHANGED, midChangedSaga),
    takeLatest(VIN_CHANGED, vinChangedSaga),
    takeLatest(INVENTORY_DATA_REQUESTED, inventoryDataSaga),
    takeLatest(VIN_FULLY_DECODED, vinDecodedSaga),
    takeEvery(YMMS_FIELD_SELECTED, ymmsFieldSelectedSaga),
    takeEvery(VIN_FIELD_SELECTED, vinFieldSelectedSaga),
    takeEvery(MULTIPLE_YMMS_FIELDS_SELECTED, multipleYmmsFieldsSelectedSaga),
    takeEvery(
      [VALUATION_CHANGED, MID_CHANGED_FAILURE, SHOW_TRANSACTIONS],
      analyticsSaga
    ),
    takeEvery(
      action =>
        action.type === ADJUSTMENT_SELECTED && action.field === 'country',
      editionSaga
    ),
    takeLatest([ADJUSTMENT_CHANGED, VIN_CHANGED_FAILURE], analyticsSaga),
    EXTERNAL ? null : takeEvery([REFRESH_CACHE, CHECK_CACHE], cacheMonitorSaga),
    takeLatest(VALUATION_CHANGED, regionFilterSaga),
    EXTERNAL || onMobileApp()
      ? null
      : takeLatest(
          [VALUATION_CHANGED, MID_CHANGED_FAILURE, ADJUSTMENT_CHANGED],
          similarVehiclesSaga
        ),
    takeEvery(SONAR_SEARCH_RECEIVED, componentImpressionSaga)
  ];
}

function* mainSaga() {
  try {
    // Temporary fix to prevent loader on iOS mobile app because of iOS url
    // history issues
    if (mobileAppPlatform() === 'iPhone') {
      if (
        window.location.search.indexOf('mid') === -1 &&
        window.location.search.indexOf('vin') === -1
      ) {
        // Set initial page load state
        yield put(beginInitialLoading());
      }
    } else {
      yield put(beginInitialLoading());
    }

    // Authenticate and potentially redirect the user to log in
    if (window.location.search.indexOf('oauth=true') === -1) {
      yield call(authenticationSaga);
    } else {
      yield call(oauthAuthenticationSaga);
    }

    // Pre-load colors, grades, editions, locations, regions, and years
    yield call(initialResourcesSaga);

    // Kick off all listeners which will cause further saga activity
    yield fork(listeners);

    // Kick off the URL saga, which keeps the URL and state in sync
    yield fork(urlSaga);

    // Kick off the title saga, which keeps the page title in sync with state
    yield fork(titleSaga);

    // Kick off the cache monitor
    yield fork(runCacheMonitorWorker, INITIAL_CACHE_MONITOR_WAIT);

    displaySubNavLinks();
  } catch (err) {
    LOGGER.error(err);
  }
}

function* externalSaga() {
  try {
    // Temporary fix to prevent loader on iOS mobile app because of iOS url
    // history issues
    if (mobileAppPlatform() === 'iPhone') {
      if (
        window.location.search.indexOf('mid') === -1 &&
        window.location.search.indexOf('vin') === -1
      ) {
        // Set initial page load state
        yield put(beginInitialLoading());
      }
    } else {
      yield put(beginInitialLoading());
    }

    // Authenticate and potentially redirect the user to log in
    if (window.location.search.indexOf('oauth=true') === -1) {
      yield call(authenticationSaga);
    } else {
      yield call(oauthAuthenticationSaga);
    }

    if (!window.MMR.token) {
      return;
    }
    // Pre-load colors, grades, editions, locations, regions, and years
    yield call(initialResourcesSaga);

    // Kick off all listeners which will cause further saga activity
    yield fork(listeners);

    // Kick off the URL saga, which keeps the URL and state in sync
    yield fork(urlSaga);
  } catch (err) {
    LOGGER.error(err);
  }
}

function displaySubNavLinks() {
  document.getElementById('subnav').style.visibility = 'visible';
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default mainSaga;
export { listeners, externalSaga };
