/* Internal */
import { AB_TEST_CONFIG_CHANGED } from 'SRC/store/actions/types';

function abTestConfigChanged(payload) {
  return {
    type: AB_TEST_CONFIG_CHANGED,
    payload
  };
}

export { abTestConfigChanged };
