////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { Component } from 'react';
import PropTypes from 'prop-types';

/* Component */
import Svg from 'SRC/components/Svg';

/* Local */
import styles from './styles.scss';
import usImage from '../assets/US.svg';
import caImage from '../assets/CA.svg';

////////////////////////////////////////////////////////////////////////////////
/*** Core**********************************************************************/

class Option extends Component {
  render() {
    const flagImage = this.props.option.value === 'CA' ? caImage : usImage;
    return (
      <div
        className={this.props.className}
        onMouseDown={this.handleMouseDown}
        onMouseEnter={this.handleMouseEnter}
        onMouseMove={this.handleMouseMove}
        title={this.props.option.title}
        data-test={`select${this.props.option.value}`}
      >
        <Svg
          markup={flagImage}
          width="20px"
          height="15px"
          className={styles.flag}
        />
        {this.props.children}
      </div>
    );
  }

  handleMouseDown = event => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option.value, event);
  };

  handleMouseEnter = event => {
    this.props.onFocus(this.props.option, event);
  };

  handleMouseMove = event => {
    if (this.props.isFocused) return;
    this.props.onFocus(this.props.option, event);
  };
}

Option.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  isFocused: PropTypes.bool,
  onFocus: PropTypes.func,
  onSelect: PropTypes.func,
  option: PropTypes.object.isRequired
};

export default Option;
