/*export const NULL_VALUE = '- -';*/
export const ZERO_TXNS = 'No transactions available';
export const NULL_VALUE = '- -';
export const INITIAL_PAGE_SIZE = 25;
export const PAGE_INCREMENT = 25;
export const COLUMN_HEADERS = {
  DATE_SOLD: 'Date',
  SALE_PRICE: 'Price',
  ODOMETER: 'Odo',
  CONDITION_GRADE: 'Cond',
  ENGINE_TRANSMISSION: 'Eng/T',
  EXTERIOR_COLOR: 'Ext Color',
  TYPE: 'Type',
  REGION: 'Region',
  AUCTION: 'Auction'
};
export const NUMBER_DISCLAIMER = 'Numbers may not add exactly due to rounding';
export const TRANSACTION_NOT_IN_SAMPLE = '* Transactions not in sample';
export const TRANSACTION_AUTOGRADE_CAPTION = 'Condition Reports from AutoGrade';
export const NOT_ENOUGH_DATA =
  'Not enough MMR transaction data available to generate a value';
export const NO_RESULTS_FOUND =
  'No results found. Please check your VIN and try again.';
export const VIN_VALIDATION =
  'VIN must not contain non-alphanumeric characters.';
export const FAKE_RAM_MODEL_ID = 'fake_ram';
export const RAM_MAKE_ID = '332';
export const DODGE_MAKE_ID = '014';
export const INITIAL_CACHE_MONITOR_WAIT = 300000;
export const CACHE_MONITOR_INTERVAL = 300000; //5min is 300000ms
export const EXTERNAL = window.MMR && window.MMR.external;
export const MMR_DECODER_API_FAILURE_MSG =
  'The year/make/model lookup is unavailable. You may still enter a VIN.';
export const TAXONOMY_VEHICLE_TYPE_PASSENGER =
  '00000000-0000-1000-0000-000100000100';
export const TAXONOMY_STATUS_LIVE = '00000000-0000-1000-0000-000000050083';
export const TAXONOMY_STATUS_PREVIEW = '00000000-0000-1000-0000-000000050084';
export const TAXONOMY_CHANNEL_OVE = '00000000-0000-1000-0000-000000000053';
export const TAXONOMY_REGION_IDS_CANADA = [
  '00000000-0000-2000-0000-000000000232'
];
export const TAXONOMY_REGION_IDS_US = [
  '00000000-0000-2000-0000-000000000231',
  '00000000-0000-2000-0000-000000000227',
  '00000000-0000-2000-0000-000000000228',
  '00000000-0000-2000-0000-000000000230',
  '00000000-0000-2000-0000-000000000229'
];
export const SIMVEH_SEARCH_RADIUS = 100;
export const OVE_SRP_LINK_PATH = '/search/results#/results';
export const OVE_VDP_LINK_PATH = '/search/results#/details';
export const AUTOCHECK_SALT = 'd85a5aa5f00c9d72d780b646cc22f9ee';
