////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* Internal */
import {
  FILTER_CLICKED,
  CLEAR_FILTERS,
  CANCEL_FILTERS,
  APPLY_FILTERS,
  OPEN_TRANSACTION_FILTERS,
  CLOSE_TRANSACTION_FILTERS,
  REMOVE_FILTER
} from 'SRC/store/actions/types';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function filterClicked(groupName, event) {
  return {
    type: FILTER_CLICKED,
    groupName,
    label: event.target.value,
    selected: event.target.checked
  };
}

function clearFilters() {
  return {
    type: CLEAR_FILTERS
  };
}

function cancelFilters(appliedFilters) {
  return {
    type: CANCEL_FILTERS,
    appliedFilters
  };
}

function applyFilters(selectedFilters) {
  return {
    type: APPLY_FILTERS,
    selectedFilters
  };
}

function removeFilter(groupName, label) {
  return {
    type: REMOVE_FILTER,
    groupName,
    label
  };
}

function openTransactionFilters() {
  return {
    type: OPEN_TRANSACTION_FILTERS
  };
}

function closeTransactionFilters() {
  return {
    type: CLOSE_TRANSACTION_FILTERS
  };
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  filterClicked,
  clearFilters,
  cancelFilters,
  applyFilters,
  removeFilter,
  openTransactionFilters,
  closeTransactionFilters
};
