////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { retrieve } from './localStorage';

/* Internal */

////////////////////////////////////////////////////////////////////////////////

/*** Core *********************************************************************/

function mollifyHost(host) {
  let mollifiedHost = host;
  if (document.location.origin.includes('.man-')) {
    const preprodEnv = document.location.origin.split('.')[2];
    mollifiedHost = mollifiedHost.replace('man-uat', preprodEnv);
    LOGGER.info('mollifiedHost=', mollifiedHost);
  }
  return mollifiedHost;
}

function retrieveAuthBase64() {
  const storedAuth = retrieve('Auth');
  if (!(storedAuth && storedAuth.authTicket)) {
    return '';
  }
  const base64AuthTicket = Buffer.from(storedAuth.authTicket).toString(
    'base64'
  );
  return base64AuthTicket;
}

function getAuthTokenUrlPart() {
  const authBase64Token = retrieveAuthBase64();
  return authBase64Token ? `auth_tkt=${authBase64Token}` : '';
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export { mollifyHost, getAuthTokenUrlPart };
