import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import './misc/rollbar'; // Sets config on `window` and loads Rollbar library
import './misc/optimizely';
import './misc/universal_header_footer'; // Loads universal header footer

import App from './app';
import store from './store';
import { onMobileApp } from './util/cookie';

import './app.css';

const shouldProfile =
  NODE_ENV === 'development' &&
  MMR_ENV === 'development' &&
  window.location.search.includes('profile');

if (shouldProfile) {
  require.ensure(['./components/PerfProfiler'], () => {
    const PerfProfiler = require('./components/PerfProfiler').default; // eslint-disable-line global-require
    ReactDOM.render(
      <Provider store={store}>
        <div>
          <PerfProfiler />
          <App onMobileApp={onMobileApp()} />
        </div>
      </Provider>,
      document.getElementById('root')
    );
  });
} else {
  ReactDOM.render(
    <Provider store={store}>
      <App onMobileApp={onMobileApp()} />
    </Provider>,
    document.getElementById('root')
  );
}
