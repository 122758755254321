////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import { chunk, find, takeWhile, zipWith } from 'lodash';

/* Internal */
import queryParams from 'SRC/util/queryParams';
import { hasProp } from 'SRC/util/helperUtils';
import { midToYMMS, hrefToMid } from 'SRC/util/mids';
import {
  NOT_ENOUGH_DATA,
  NO_RESULTS_FOUND,
  VIN_VALIDATION,
  TAXONOMY_VEHICLE_TYPE_PASSENGER,
  TAXONOMY_STATUS_LIVE,
  TAXONOMY_STATUS_PREVIEW,
  TAXONOMY_CHANNEL_OVE,
  TAXONOMY_REGION_IDS_US,
  TAXONOMY_REGION_IDS_CANADA
} from 'SRC/constants';
import { gapiCall, singleGapiCall, addToCache } from './gatewayClient';

const {
  valuationsBaseURL,
  decoderBaseURL,
  sonarBaseURL,
  sonarVersion
} = INJECTED_CONFIG;

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

const urls = {
  valuation(
    id,
    country,
    region,
    color,
    grade,
    rawOdometer,
    type = 'mid',
    geolocation
  ) {
    const params = queryParams({
      country,
      region,
      color,
      grade,
      odometer:
        (rawOdometer ? rawOdometer.toString().trim() : rawOdometer) || null,
      geolocation,
      include: 'retail,historical,forecast'
    });

    return `${valuationsBaseURL}/valuations/${type}/${id}?${params}`;
  },
  valuationSamples(mid, country) {
    const params = queryParams({
      country,
      orderBy: 'purchaseDate desc',
      start: 1,
      limit: 100
    });

    return `${valuationsBaseURL}/valuation-samples/id/${mid}?${params}`;
  },
  yearList(country) {
    return `${decoderBaseURL}/yearList?country=${countryFor(country)}`;
  },
  makeList(year, country) {
    return `${decoderBaseURL}/makeList?year=${year}&country=${countryFor(
      country
    )}`;
  },
  modelList(make, year, country) {
    return `${decoderBaseURL}/modelList/make/${make}?year=${year}&country=${countryFor(
      country
    )}`;
  },
  styleList(model, make, year, country) {
    return `${decoderBaseURL}/styleList/make/${make}/model/${model}/year/${year}?country=${countryFor(
      country
    )}`;
  },
  locations() {
    return `${valuationsBaseURL}/locations?operatedBy=MANHEIM&locationType=AUCTION`;
  },
  regions(country) {
    return `${valuationsBaseURL}/valuations/regions?country=${country}`;
  },
  colors() {
    return `${valuationsBaseURL}/valuations/colors`;
  },
  grades() {
    return `${valuationsBaseURL}/valuations/grades`;
  },
  edition(country) {
    return `${valuationsBaseURL}/valuations/edition?country=${country}`;
  },
  batchMid() {
    return `${valuationsBaseURL}/valuations/batch/ids`;
  },
  listings(value, type = 'id') {
    return `${sonarBaseURL}/listings/${type}/${value}?fields=id%2Cmid%2Cvin%2CpickupLocation%2CpickupRegion%2CpickupLocationCountry%2Cchannels%2Ccurrency%2Cstatuses%2CconditionGradeNumeric%2Codometer%2CodometerUnits%2Ctrims%2CexteriorColor%2CyearId%2CmakeId%2CmodelIds%2CisAutogradeConditionGrade%2Cyear%2Cmake%2Cmodels%2ChasAutocheck%2CautocheckCsHash%2CisEligibleForCarfax%2CcarfaxCsHash%2CsellerNumber&include=true`;
  },
  taxonomyYears() {
    return `${sonarBaseURL}/search/taxonomy/years?includeUnreviewed=false`;
  },
  taxonomyMakes(vehicleType = TAXONOMY_VEHICLE_TYPE_PASSENGER) {
    return `${sonarBaseURL}/search/taxonomy/makes?vehicleTypeIds=${vehicleType}&includeUnreviewed=false`;
  },
  taxonomyModels(makeId) {
    return `${sonarBaseURL}/search/taxonomy/models?makeIds=${makeId}&includeUnreviewed=false`;
  },
  sonarSearches() {
    return `${sonarBaseURL}/searches`;
  }
};

function countryFor(country) {
  return country === 'CA' ? 'ALL' : country;
}

const requests = {
  listingsFromIds(value) {
    return {
      href: urls.listings(value),
      api_version: sonarVersion
    };
  },
  listingsFromVins(value) {
    return {
      href: urls.listings(value, 'vin'),
      api_version: sonarVersion,
      transform: body => {
        if (Array.isArray(body.items)) {
          return body.items;
        }
        return body;
      }
    };
  },
  valuation(mid, country, region, color, grade, rawOdometer) {
    return {
      href: urls.valuation(
        mid,
        country,
        region,
        color,
        grade,
        rawOdometer,
        'id'
      ),
      transform: body => {
        const results = body.items[0];
        const ymms = midToYMMS(hrefToMid(results.href));
        return {
          ...results,
          description: {
            ...results.description,
            make: {
              name: results.description.make,
              id: ymms.make
            },
            model: {
              name: results.description.model,
              id: ymms.model
            },
            style: {
              name: results.description.trim,
              id: ymms.style
            }
          }
        };
      },
      validate: response => {
        if (response.code && response.code === 404) {
          return new Error(NOT_ENOUGH_DATA);
        }
      }
    };
  },
  vinValuation(vin, country, region, color, grade, rawOdometer, geolocation) {
    return {
      href: urls.valuation(
        vin,
        country,
        region,
        color,
        grade,
        rawOdometer,
        'vin',
        geolocation
      ),

      transform: body => {
        const results = body.items;
        if (!Array.isArray(results) || results.length === 0) {
          return new Error(NO_RESULTS_FOUND);
        }
        const vehicles = results.map(result => ({
          // expand make, model, style using the midToYMMS tool
          // and add replace the description in each result
          ...result,
          vin,
          mid: hrefToMid(result.href),
          description: (() => {
            const ymms = midToYMMS(hrefToMid(result.href));
            return {
              ...result.description,
              make: {
                name: result.description.make,
                id: ymms.make
              },
              model: {
                name: result.description.model,
                id: ymms.model
              },
              style: {
                name: result.description.trim,
                id: ymms.style
              }
            };
          })()
        }));
        return {
          year: vehicles.map(v => v.description.year),
          make: vehicles.map(v => v.description.make),
          model: vehicles.map(v => v.description.model),
          style: vehicles.map(v => v.description.style),
          vehicles
        };
      },
      validate: response => {
        if (
          !Array.isArray(response.body.items) ||
          response.body.items.length === 0
        ) {
          return new Error(NO_RESULTS_FOUND);
        }
      }
    };
  },
  edition(country) {
    return {
      href: urls.edition(country),
      transform: body => body.items[0].edition
    };
  },
  batchMid(name, hrefs) {
    return {
      name,
      href: urls.batchMid(),
      method: 'POST',
      body: hrefs,
      batch: true,
      transform: body => {
        const items = hrefs.map(href => {
          return (
            find(body.items[0].items, item => item.href === href) ||
            find(body.errors[0].errors, error => error.href === href)
          );
        });

        return items;
      }
    };
  },
  yearList: taxonomyRequest('year', urls.yearList),
  makeList: taxonomyRequest('make', urls.makeList),
  modelList: taxonomyRequest('model', urls.modelList),
  styleList: taxonomyRequest('style', urls.styleList),
  valuationSamples: itemsRequest(urls.valuationSamples),
  locations: itemsRequest(urls.locations),
  regions: itemsRequest(urls.regions),
  colors: itemsRequest(urls.colors),
  grades: itemsRequest(urls.grades),
  taxonomyYears: sonarTaxonomyRequest(urls.taxonomyYears),
  taxonomyMakes: sonarTaxonomyRequest(urls.taxonomyMakes),
  taxonomyModels: sonarTaxonomyRequest(urls.taxonomyModels),
  sonarSearches(searchParameters) {
    return {
      name: formatSonarSearchName(searchParameters),
      href: urls.sonarSearches(),
      method: 'POST',
      body: formatSonarSearchBody(searchParameters)
    };
  }
};

function formatSonarSearchName(searchParameters) {
  if (searchParameters && searchParameters.odo > 0) {
    return `ymm=${searchParameters.ymm}_${searchParameters.country}_odo=${searchParameters.odo}`;
  }
  return `ymm=${searchParameters.ymm}_${searchParameters.country}`;
}

function formatSonarSearchBody(searchParameters) {
  const pickupRegionsByCountry =
    searchParameters.country === 'CA'
      ? TAXONOMY_REGION_IDS_CANADA
      : TAXONOMY_REGION_IDS_US;
  const useOdo = searchParameters.odo > 0;
  let transientAttrs = { includeFacets: false };
  if (useOdo) {
    const { startOdoValue, stopOdoValue } = formatSonarOdometerRange(
      searchParameters.odo
    );
    transientAttrs = {
      includeFacets: false,
      startOdo: startOdoValue,
      stopOdo: stopOdoValue
    };
  }
  return {
    statusIds: [TAXONOMY_STATUS_LIVE, TAXONOMY_STATUS_PREVIEW],
    channelIds: [TAXONOMY_CHANNEL_OVE],
    fromYearId: searchParameters.years.fromYear.id,
    toYearId: searchParameters.years.toYear.id,
    makeSeriesModelTrimIds: searchParameters.makeSeriesModelTrimIds,
    vehicleTypeIds: [TAXONOMY_VEHICLE_TYPE_PASSENGER],
    pickupRegionIds: pickupRegionsByCountry,
    includeTestData: false,
    sortOptions: 'relevance:desc',
    searchType: 'RS',
    executeNow: true,
    limit: 12,
    fields: [
      'currency',
      'mainImage',
      'exteriorColor',
      'vin',
      'mid',
      'pickupLocation',
      'hasConditionReport',
      'conditionGrade',
      'odometer',
      'odometerUnits',
      'year',
      'make',
      'models',
      'valuationsMmr',
      'hasMmrValuation',
      'hasSellerDisclosure',
      'sellerDisclosureGrade',
      'trims'
    ],
    includeFilters: false,
    includeSortOptions: false,
    ...transientAttrs
  };
}
function formatSonarOdometerRange(odo) {
  let startOdoValue = 0;
  if (odo >= 10000) {
    startOdoValue = odo - 10000;
  }
  const stopOdoValue = odo + 10000;
  return { startOdoValue, stopOdoValue };
}

function taxonomyRequest(field, constructURL) {
  return (...args) => ({
    href: constructURL(...args),
    transform: body => {
      return field === 'year' ? body[field] : body[field].filter(hasNameAndId);
    },
    validate: response => {
      if (!response.body[field]) {
        return new Error(`Response for ${field} does not have the right data`);
      }
    }
  });
}

function itemsRequest(constructURL) {
  return (...args) => ({
    href: constructURL(...args),
    transform: body => body.items
  });
}

function sonarTaxonomyRequest(constructURL) {
  return (...args) => ({
    href: constructURL(...args),
    transform: body => {
      return body.items.filter(hasNameAndId);
    }
  });
}

function hasNameAndId(obj) {
  return hasProp(obj, 'name') && hasProp(obj, 'id');
}

////////////////////////////////////////////////////////////////////////////////
// API Call Functions

function getInitialResources() {
  return gapiCall([
    requests.colors(),
    requests.grades(),
    requests.edition('US'),
    requests.edition('CA'),
    requests.locations(),
    requests.regions('US'),
    requests.regions('CA'),
    requests.yearList('US'),
    requests.yearList('CA'),
    requests.taxonomyYears(),
    requests.taxonomyMakes()
  ]);
}

function getMMRAndTransactions(
  mid,
  country,
  region,
  color,
  grade,
  rawOdometer
) {
  return gapiCall([
    requests.valuation(mid, country, region, color, grade, rawOdometer),
    requests.valuationSamples(mid, country)
  ]);
}

function getMMR(mid, country, region, color, grade, rawOdometer) {
  return singleGapiCall(
    requests.valuation(mid, country, region, color, grade, rawOdometer)
  );
}

function getTransactions(mid, country) {
  return singleGapiCall(requests.valuationSamples(mid, country));
}

function getYears(country) {
  return singleGapiCall(requests.yearList(country));
}

function getMakes(year, country) {
  return singleGapiCall(requests.makeList(year, country));
}

function getModels(make, year, country) {
  return singleGapiCall(requests.modelList(make, year, country));
}

function getStyles(model, make, year, country) {
  return singleGapiCall(requests.styleList(model, make, year, country));
}

function getMultipleFields(year, make, model, country) {
  const numRequests = takeWhile([year, make, model], Boolean).length;
  const allRequests = [
    requests.makeList(year, country),
    requests.modelList(make, year, country),
    requests.styleList(model, make, year, country)
  ];

  return gapiCall(allRequests.slice(0, numRequests));
}

function getVINValuation(
  rawVin,
  country,
  region,
  color,
  grade,
  rawOdometer,
  geolocation
) {
  const vin = rawVin ? rawVin.toString().trim() : rawVin;

  if (!/^[A-Za-z0-9]*$/.test(vin)) {
    // vin contains symbols
    return Promise.reject(new Error(VIN_VALIDATION));
  }

  return singleGapiCall(
    requests.vinValuation(
      vin,
      country,
      region,
      color,
      grade,
      rawOdometer,
      geolocation
    )
  );
}

function getRegions(country) {
  return singleGapiCall(requests.regions(country));
}

function getGrades() {
  return singleGapiCall(requests.grades());
}

function getColors() {
  return singleGapiCall(requests.colors());
}

function getEdition(country) {
  return singleGapiCall(requests.edition(country));
}

function getListingsFromId(value) {
  return singleGapiCall(requests.listingsFromIds(value));
}

function getListingsFromVin(value) {
  return singleGapiCall(requests.listingsFromVins(value));
}

function getTaxonomyYears() {
  return singleGapiCall(requests.taxonomyYears());
}

function getTaxonomyMakes() {
  return singleGapiCall(requests.taxonomyMakes());
}

function getTaxonomyModels(makeId) {
  return singleGapiCall(requests.taxonomyModels(makeId));
}

function getSimilarVehicles(searchParameters) {
  return singleGapiCall(requests.sonarSearches(searchParameters));
}
////////////////////////////////////////////////////////////////////////////////
// Helpers

function expandRegionAndLocation(transactions) {
  // if we do not have an array of transactions
  // we have no work to do expanding them
  if (!Array.isArray(transactions)) {
    return [];
  }
  const hrefs = regionAndLocationHrefsFrom(transactions);
  // no need to call if we have no data
  if (hrefs.length === 0) {
    return [];
  }
  return gapiCall(hrefs.map(href => ({ href })))
    .then(unwrapPayload)
    .then(responses => reassembleTransactions(transactions, responses));
}

// For testing, if all hrefs are guaranteed to be in the cache beforehand
expandRegionAndLocation.sync = function expandRegionAndLocationSync(
  transactions
) {
  const responses = unwrapPayload(
    gapiCall.sync(
      regionAndLocationHrefsFrom(transactions).map(href => ({ href }))
    )
  );

  return reassembleTransactions(transactions, responses);
};

function regionAndLocationHrefsFrom(transactions) {
  return (
    transactions
      .map(transaction => [transaction.location.href, transaction.region.href])
      .reduce(flatten, [])
      // Apparently invalid hrefs are a thing...
      .map(href => href.replace(/\s/g, ''))
      .map(href =>
        href.replace('/valuations/regions/id/P?', '/valuations/regions/id/NA?')
      )
      .map(href =>
        href.replace('/valuations/regions/P?', '/valuations/regions/NA?')
      )
  );
}

function flatten(accumulatedValues, nextValues) {
  return accumulatedValues.concat(nextValues);
}

function unwrapPayload(responses) {
  return responses.map(response => {
    if (response.success) {
      return response.payload;
    }
    return null;
  });
}

function reassembleTransactions(transactions, responses) {
  return zipWith(
    transactions,
    chunk(responses, 2),
    // For each transaction, fill in the region and location by looking up the
    // response body for that href
    (transaction, [location, region]) => ({
      ...transaction,
      location: location || { locationName: '' },
      region: region && region.id && region.name ? region : { name: '' }
    })
  );
}

function cacheAllItems(items) {
  items.forEach(item => {
    addToCache({ href: item.href }, { body: item });
  });
}

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export {
  getInitialResources,
  taxonomyRequest,
  unwrapPayload,
  reassembleTransactions,
  regionAndLocationHrefsFrom,
  getMMRAndTransactions,
  getMMR,
  getTransactions,
  getYears,
  getMakes,
  getModels,
  getStyles,
  getMultipleFields,
  getVINValuation,
  getRegions,
  getGrades,
  getColors,
  getEdition,
  getListingsFromId,
  getListingsFromVin,
  expandRegionAndLocation,
  cacheAllItems,
  sonarTaxonomyRequest,
  getTaxonomyYears,
  getTaxonomyMakes,
  getTaxonomyModels,
  getSimilarVehicles
};
