////////////////////////////////////////////////////////////////////////////////
/*** Dependencies (external, internal, component, local, stubs, under test) ***/

/* External */
import PropTypes from 'prop-types';

/* Local */
import styles from './styles.scss';

////////////////////////////////////////////////////////////////////////////////
/*** Core *********************************************************************/

function LookupButtons({ handleNewLookup, handleEditLookup }) {
  return (
    <div className="mui-p-h-tb mui-p-rl">
      <div className={styles.newLookup}>
        <div className="mui-row">
          <div className="mui-col mui-col-1-2">
            <button
              type="button"
              onClick={handleNewLookup}
              className="mui-button--sm mui-button--tertiary"
              data-test="SelectVehicleNew"
            >
              New Lookup
            </button>
          </div>
          <div className="mui-col mui-col-1-2">
            <button
              type="button"
              onClick={handleEditLookup}
              className="mui-button--sm mui-button--tertiary"
              data-test="SelectVehicleEdit"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

LookupButtons.propTypes = {
  handleEditLookup: PropTypes.func,
  handleNewLookup: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////
/*** Exports (default, others) ************************************************/

export default LookupButtons;
